import React from "react";
import { Table } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import { dateFormatter } from "../utils/helpers";

const QuantitativeKitchenTableExport = ({
  quantitative: data,
  selectedClassGroup,
}) => {
  if (!data) {
    return "";
  }

  let quantitative = data;

  const id_day = quantitative?.id_day;

  const filteredCostPerDays = quantitative?.costPerDays;

  const updatedTimes = quantitative?.times
    ?.slice(id_day === 1 ? 0 : 1)
    ?.map((time) => ({
      ...time,
      foods: time.foods
        ?.filter(
          (fo) =>
            Number(fo.is_main) === 1 &&
            fo?.groups?.[0]?.ingredents?.filter((i) => Number(i.is_main) === 1)
              ?.length > 0
        )
        ?.map((f) => ({
          ...f,
          groups: f.groups?.map((g) => ({
            ...g,
            ingredents: g.ingredents?.filter((i) => Number(i.is_main) === 1),
          })),
        }))
        ?.filter((g) => g.groups?.length > 0),
    }));

  quantitative = {
    ...quantitative,
    costPerDays: filteredCostPerDays,
    times: updatedTimes,
  };

  const sumTotalRegisterByIdTime = (idTime) => {
    let times = quantitative.times;
    if (!Array.isArray(times)) {
      throw new Error("Lỗi");
    }

    return times
      .filter(
        (time) =>
          time.id_time === idTime && time.menu_date === quantitative.menu_date
      )
      .reduce((sum, time) => {
        const foodRegisterSum = time.foods
          ?.filter((fo) => Number(fo.is_main) === 1)
          ?.reduce((foodSum, food) => {
            const groupRegisterSum = food.groups?.reduce(
              (groupSum, group) => groupSum + (group.totalRegister || 0),
              0
            );

            return foodSum + (groupRegisterSum || 0);
          }, 0);

        return sum + (foodRegisterSum || 0);
      }, 0);
  };

  const sumMoreMealByIdTime = (idTime) => {
    let times = quantitative.times;
    if (!Array.isArray(times)) {
      throw new Error("Lỗi");
    }

    return times
      .filter(
        (time) =>
          time.id_time === idTime && time.menu_date !== quantitative.menu_date
      )
      .reduce((sum, time) => {
        const foodMoreMealSum = time.foods
          ?.filter(
            (food0) =>
              Number(food0.is_main) === 1 &&
              food0?.groups?.[0]?.ingredents?.length > 0
          )
          ?.reduce((foodSum, food) => {
            const groupMoreMealSum = food.groups?.reduce(
              (groupSum, group) => groupSum + (group.more_meal || 0),
              0
            );

            return foodSum + (groupMoreMealSum || 0);
          }, 0);

        return sum + (foodMoreMealSum || 0);
      }, 0);
  };

  return (
    <div className="table-responsive-100">
      <Table bordered>
        <thead>
          <tr>
            <th colSpan={10} className="text-center">
              TỔNG HỢP ĐỊNH LƯỢNG NGÀY{" "}
              {dateFormatter(new Date(quantitative.menu_date * 1000))}
            </th>
          </tr>
          <tr>
            <th colSpan={10}>&nbsp;</th>
          </tr>
          <tr className="tr-sticky-first">
            <th className="text-center align-content-center">CA SX</th>
            <th className="text-center align-content-center">MÓN ĂN </th>
            <th className="text-center align-content-center">SỐ PHẦN ĂN</th>
            <th className="text-center align-content-center">
              NGUYÊN LIỆU CHÍNH
            </th>
            <th className="text-center align-content-center">
              ĐỊNH LƯỢNG (Gr)
            </th>
            <th className="text-center align-content-center">
              SL TP SỬ DỤNG (Kg)
            </th>
            <th className="text-center align-content-center">DT</th>
            <th className="text-center align-content-center">
              TỔNG SL P/ĂN ĐẶT
            </th>
            <th className="text-center align-content-center">
              SL PĂ TRƯỜNG ĐẶT
            </th>
            <th className="text-center align-content-center">SL PĂ DỰ TRÙ</th>
          </tr>
        </thead>
        <tbody>
          {quantitative.times?.map((timeItem, timeIndex) => {
            const totalIngredientsByTime = timeItem?.foods?.reduce(
              (sum, food) => {
                return sum + (food?.groups?.[0]?.ingredents?.length || 0);
              },
              0
            );

            if (timeItem?.foods?.length === 0 || totalIngredientsByTime === 0) {
              return (
                <tr key={`item-key-${timeIndex}`}>
                  <td className="fw-bold">{timeItem.time_name}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              );
            } else {
              const totalIngredientsByTime = timeItem?.foods?.reduce(
                (sum, food) => {
                  return sum + (food?.groups?.[0]?.ingredents?.length || 0);
                },
                0
              );

              return (
                timeItem?.foods?.length > 0 &&
                timeItem.foods?.map((foodItem, foodIndex) => {
                  const numberOfMeals =
                    timeItem?.foods?.length > 0
                      ? timeItem.menu_date === quantitative.menu_date
                        ? foodItem?.groups?.reduce(
                            (groupSum, group) =>
                              groupSum + (group.totalRegister || 0),
                            0
                          )
                        : foodItem?.groups?.reduce(
                            (groupSum, group) =>
                              groupSum + (group.more_meal || 0),
                            0
                          )
                      : 0;

                  const totalIngredientsByFood =
                    foodItem?.groups?.[0]?.ingredents?.length || 0;

                  return foodItem.groups[0]?.ingredents?.map(
                    (ingredientItem, ingredientIndex) => {
                      const ingredientQuantity = Number(
                        ingredientItem.ing_quantity || 0
                      ).toFixed(1);

                      const ingredientQuantityByMarketUnit = Number(
                        (parseFloat(ingredientQuantity) * numberOfMeals) /
                          parseFloat(ingredientItem?.volume_cook || 0)
                      ).toFixed(1);

                      let totalRegisterByIdTime = "";

                      if (
                        foodIndex === 0 &&
                        ingredientIndex === 0 &&
                        timeItem.menu_date === quantitative.menu_date
                      ) {
                        totalRegisterByIdTime = sumTotalRegisterByIdTime(
                          timeItem.id_time
                        );
                      } else if (
                        foodIndex === 0 &&
                        ingredientIndex === 0 &&
                        timeItem.menu_date !== quantitative.menu_date &&
                        timeItem.id_time === 1
                      ) {
                        // totalRegisterByIdTime = numberOfMeals;
                        totalRegisterByIdTime = sumMoreMealByIdTime(
                          timeItem.id_time
                        );
                      }

                      return (
                        <tr
                          key={`item-key-${timeIndex}-${foodIndex}-${ingredientIndex}`}
                        >
                          {(foodIndex === 0 && ingredientIndex === 0) ||
                          totalIngredientsByFood === 0 ? (
                            <td
                              rowSpan={totalIngredientsByTime}
                              className="fw-bold"
                            >
                              {foodIndex === 0 && ingredientIndex === 0
                                ? timeItem.time_name
                                : ""}
                            </td>
                          ) : (
                            <></>
                          )}

                          {ingredientIndex === 0 ? (
                            <td
                              rowSpan={totalIngredientsByFood}
                              className="fw-bold"
                            >
                              {foodItem.food_name}
                            </td>
                          ) : (
                            <></>
                          )}

                          {ingredientIndex === 0 ? (
                            <td
                              rowSpan={totalIngredientsByFood}
                              className="text-center"
                            >
                              <CurrencyFormat
                                value={
                                  ingredientIndex === 0 ? numberOfMeals : ""
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                decimalScale={0}
                              />
                            </td>
                          ) : (
                            <></>
                          )}

                          <td>{ingredientItem.ingredent_name || ""}</td>
                          <td className="text-end">
                            <CurrencyFormat
                              value={ingredientQuantity}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={1}
                            />
                          </td>
                          <td className="text-end">
                            <CurrencyFormat
                              value={ingredientQuantityByMarketUnit}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={1}
                            />
                          </td>
                          <td></td>
                          {foodIndex === 0 && ingredientIndex === 0 ? (
                            <>
                              <td
                                rowSpan={totalIngredientsByTime}
                                className="text-center"
                              >
                                <CurrencyFormat
                                  value={totalRegisterByIdTime}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                              </td>
                              <td
                                rowSpan={totalIngredientsByTime}
                                className="text-center"
                              >
                                <CurrencyFormat
                                  value={totalRegisterByIdTime}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                              </td>
                              <td rowSpan={totalIngredientsByTime}></td>
                            </>
                          ) : (
                            <></>
                          )}
                        </tr>
                      );
                    }
                  );
                })
              );
            }
          })}
          <tr>
            <td colSpan={10}>&nbsp;</td>
          </tr>
          <tr>
            <th colSpan={3} className="text-center">
              ĐẠI DIỆN NHÀ ĂN
            </th>
            <th colSpan={3} className="text-center">
              KẾ TOÁN
            </th>
            <th colSpan={4} className="text-center">
              ĐẠI DIỆN NHÀ TRƯỜNG
            </th>
          </tr>
        </tbody>
      </Table>

      <Table
        bordered
        id={`quantitative-kitchen-table-by-group${selectedClassGroup?.id}`}
        className="d-none"
      >
        <thead>
          <tr>
            <th>
              TỔNG HỢP ĐỊNH LƯỢNG NGÀY{" "}
              {dateFormatter(new Date(quantitative.menu_date * 1000))}
            </th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
          <tr>
            <th className="text-center align-content-center">CA SX</th>
            <th className="text-center align-content-center">MÓN ĂN </th>
            <th className="text-center align-content-center">SỐ PHẦN ĂN</th>
            <th className="text-center align-content-center">
              NGUYÊN LIỆU CHÍNH
            </th>
            <th className="text-center align-content-center">
              ĐỊNH LƯỢNG (Gr)
            </th>
            <th className="text-center align-content-center">
              SL TP SỬ DỤNG (Kg)
            </th>
            <th className="text-center align-content-center">DT</th>
            <th className="text-center align-content-center">
              TỔNG SL P/ĂN ĐẶT
            </th>
            <th className="text-center align-content-center">
              SL PĂ TRƯỜNG ĐẶT
            </th>
            <th className="text-center align-content-center">SL PĂ DỰ TRÙ</th>
          </tr>
        </thead>
        <tbody>
          {quantitative.times?.map((timeItem, timeIndex) => {
            if (timeItem?.foods?.length === 0) {
              return (
                <tr key={`item-key-${timeIndex}`}>
                  <td>{timeItem.time_name}</td>
                  <td>empty</td>
                  <td>empty</td>
                  <td>empty</td>
                  <td>empty</td>
                  <td>empty</td>
                  <td>empty</td>
                  <td>empty</td>
                  <td>empty</td>
                  <td>empty</td>
                </tr>
              );
            } else {
              return (
                timeItem?.foods?.length > 0 &&
                timeItem.foods?.map((foodItem, foodIndex) => {
                  const numberOfMeals =
                    timeItem?.foods?.length > 0
                      ? timeItem.menu_date === quantitative.menu_date
                        ? foodItem?.groups?.reduce(
                            (groupSum, group) =>
                              groupSum + (group.totalRegister || 0),
                            0
                          )
                        : foodItem?.groups?.reduce(
                            (groupSum, group) =>
                              groupSum + (group.more_meal || 0),
                            0
                          )
                      : 0;

                  return foodItem.groups[0]?.ingredents?.map(
                    (ingredientItem, ingredientIndex) => {
                      const ingredientQuantity = Number(
                        ingredientItem.ing_quantity || 0
                      ).toFixed(1);

                      const ingredientQuantityByMarketUnit = Number(
                        (parseFloat(ingredientQuantity) * numberOfMeals) /
                          parseFloat(ingredientItem?.volume_cook || 0)
                      ).toFixed(1);

                      let totalRegisterByIdTime = "empty";

                      if (
                        foodIndex === 0 &&
                        ingredientIndex === 0 &&
                        timeItem.menu_date === quantitative.menu_date
                      ) {
                        totalRegisterByIdTime = sumTotalRegisterByIdTime(
                          timeItem.id_time
                        );
                      } else if (
                        timeItem.menu_date !== quantitative.menu_date &&
                        ingredientIndex === 0
                      ) {
                        // totalRegisterByIdTime = numberOfMeals;
                        totalRegisterByIdTime = sumMoreMealByIdTime(
                          timeItem.id_time
                        );
                      }

                      return (
                        <tr
                          key={`item-key-${timeIndex}-${foodIndex}-${ingredientIndex}`}
                        >
                          <td>
                            {foodIndex === 0 && ingredientIndex === 0
                              ? timeItem.time_name
                              : "empty"}
                          </td>
                          <td>
                            {ingredientIndex === 0
                              ? foodItem.food_name
                              : "empty"}
                          </td>
                          <td className="text-end">
                            {ingredientIndex === 0 ? (
                              <CurrencyFormat
                                value={numberOfMeals}
                                displayType={"text"}
                                thousandSeparator={true}
                                decimalScale={0}
                              />
                            ) : (
                              "empty"
                            )}
                          </td>
                          <td>{ingredientItem.ingredent_name || "empty"}</td>
                          <td className="text-end">
                            <CurrencyFormat
                              value={ingredientQuantity}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={1}
                            />
                          </td>
                          <td className="text-end">
                            <CurrencyFormat
                              value={ingredientQuantityByMarketUnit}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={1}
                            />
                          </td>
                          <td>empty</td>
                          {foodIndex === 0 && ingredientIndex === 0 ? (
                            <>
                              <td className="text-end">
                                {totalRegisterByIdTime !== "empty" ? (
                                  <CurrencyFormat
                                    value={totalRegisterByIdTime}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />
                                ) : (
                                  "empty"
                                )}
                              </td>
                              <td className="text-end">
                                {totalRegisterByIdTime !== "empty" ? (
                                  <CurrencyFormat
                                    value={totalRegisterByIdTime}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />
                                ) : (
                                  "empty"
                                )}
                              </td>
                            </>
                          ) : (
                            <>
                              <td>empty</td>
                              <td>empty</td>
                            </>
                          )}
                          <td>empty</td>
                        </tr>
                      );
                    }
                  );
                })
              );
            }
          })}
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
          <tr>
            <th className="text-center">ĐẠI DIỆN NHÀ ĂN</th>
            <th></th>
            <th></th>
            <th className="text-center">KẾ TOÁN</th>
            <th></th>
            <th></th>
            <th className="text-center">ĐẠI DIỆN NHÀ TRƯỜNG</th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default QuantitativeKitchenTableExport;
