import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import moment from "moment";
import api from "../../../../helper/axiosInstance";

const Book3 = ({ school, quantitative, formSetting, times }) => {
  const [sections, setSections] = useState({});

  useEffect(() => {
    getAppSetting();
  }, []);

  const getAppSetting = async () => {
    await api
      .get(`/app-setting-step-3-show`)
      .then((res) => {
        setSections(res.data.data);
      })
      .catch((error) => {});
  };

  const costPerDays = quantitative?.costPerDays || [];
  let bookIndex = 0;
  let bookIndexreport = 0;

  return (
    <div style={{ overflowX: "scroll" }}>
      <Table bordered style={{ minWidth: 999 }}>
        <thead>
          <tr>
            <td className="align-top" colSpan={6}>
              Tên cơ sở: {school.school_name}
              <br />
              Người kiểm tra: {formSetting.step3_checker}
              <br />
              Thời gian kiểm tra: ngày{" "}
              {new Date(quantitative?.menu_date * 1000).getDate()} tháng{" "}
              {new Date(quantitative?.menu_date * 1000).getMonth() + 1} năm{" "}
              {new Date(quantitative?.menu_date * 1000).getFullYear()}
              <br />
              Địa điểm kiểm tra: {formSetting.step3_check_address}
            </td>
            <th className="align-top" colSpan={4}>
              Mẫu số 3: Kiểm tra trước khi ăn (Bước 3)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="text-center">
            <td className="align-middle" rowSpan={2}>
              TT
            </td>
            <td className="align-middle" rowSpan={2}>
              Ca/bữa ăn (Bữa ăn, giờ ăn...)
            </td>
            <td className="align-middle" rowSpan={2}>
              Tên món ăn
            </td>
            <td className="align-middle" rowSpan={2}>
              Số lượng suất ăn
            </td>
            <td className="align-middle" rowSpan={2}>
              Thời gian chia món ăn xong (ngày, giờ)
            </td>
            <td className="align-middle" rowSpan={2}>
              Thời gian bắt đầu ăn (ngày, giờ)
            </td>
            <td className="align-middle" rowSpan={2}>
              Dụng cụ chia, chứa đựng, che đậy, bảo quản thức ăn
            </td>
            <td className="align-middle" colSpan={2}>
              Kiểm tra cảm quan món ăn (màu, mùi, vị, trạng thái, bảo quản...)
            </td>
            <td className="align-middle" rowSpan={2}>
              Biện pháp xử lý/ Ghi chú
            </td>
          </tr>
          <tr className="text-center">
            <td className="align-middle">Đạt</td>
            <td className="align-middle">Không đạt</td>
          </tr>
          <tr className="text-center">
            <td className="align-middle">(1)</td>
            <td className="align-middle">(2)</td>
            <td className="align-middle">(3)</td>
            <td className="align-middle">(4)</td>
            <td className="align-middle">(5)</td>
            <td className="align-middle">(6)</td>
            <td className="align-middle">(7)</td>
            <td className="align-middle">(8)</td>
            <td className="align-middle">(9)</td>
            <td className="align-middle">(10)</td>
          </tr>

          {quantitative?.times?.map((timeItem) => {
            return (
              <React.Fragment key={timeItem.id_time}>
                {timeItem?.foods.map((foodItem) => {
                  let mealQuantity = 0;

                  foodItem?.groups.map((groupItem) => {
                    mealQuantity += groupItem.more_meal
                      ? groupItem.more_meal
                      : Number(
                          costPerDays.find(
                            (costPerDay) => costPerDay.id_group === groupItem.id
                          )?.meal_quantity
                        );
                    return groupItem;
                  });

                  bookIndex++;
                  return (
                    <tr key={foodItem.id} className="align-middle">
                      <td className="text-center">{bookIndex}</td>
                      <td>
                        {
                          times.find(
                            (timeObj) => timeObj.id === timeItem.id_time
                          )?.time_name_now
                        }{" "}
                        {moment(
                          Number(
                            formSetting.step3_2?.find(
                              (item, timeObjIndex) =>
                                parseInt(item.time) === timeItem.id_time
                            )?.val
                          )
                        ).format("HH:mm")}
                      </td>
                      <td>{foodItem.food_name}</td>
                      <td className="text-center">{mealQuantity}</td>
                      <td className="text-center">
                        {
                          times.find(
                            (timeObj) => timeObj.id === timeItem.id_time
                          )?.time_name_now
                        }{" "}
                        {moment(
                          Number(
                            formSetting.step3_5?.find(
                              (item, timeObjIndex) =>
                                parseInt(item.time) === timeItem.id_time
                            )?.val
                          )
                        ).format("HH:mm")}
                      </td>
                      <td className="text-center">
                        {
                          times.find(
                            (timeObj) => timeObj.id === timeItem.id_time
                          )?.time_name_now
                        }{" "}
                        {moment(
                          Number(
                            formSetting.step3_6?.find(
                              (item, timeObjIndex) =>
                                parseInt(item.time) === timeItem.id_time
                            )?.val
                          )
                        ).format("HH:mm")}
                      </td>
                      <td>{formSetting.step3_7}</td>
                      <td className="text-center">
                        {sections?.section_8 || ""}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                  );
                })}
              </React.Fragment>
            );
          })}
        </tbody>
        <tfoot>
          <tr className="text-center" style={{ height: 50 }}>
            <td colSpan={3}>{sections?.position_1 || "Ban giám hiệu"}</td>
            <td colSpan={4}>{sections?.position_2 || "Tổ trưởng kiểm thực"}</td>
            <td colSpan={3}>{sections?.position_3 || "Người kiểm tra"}</td>
          </tr>
        </tfoot>
      </Table>

      <Table bordered id="book-step-3" className="d-none">
        <thead>
          <tr>
            <td className="align-top">
              Tên cơ sở: {school.school_name}
              {"\n"}
              Người kiểm tra: {formSetting.step3_checker}
              {"\n"}
              Thời gian kiểm tra: ngày{" "}
              {new Date(quantitative?.menu_date * 1000).getDate()} tháng{" "}
              {new Date(quantitative?.menu_date * 1000).getMonth() + 1} năm{" "}
              {new Date(quantitative?.menu_date * 1000).getFullYear()}
              {"\n"}
              Địa điểm kiểm tra: {formSetting.step3_check_address}
            </td>
            <td>empty</td>
            <td>empty</td>
            <td>empty</td>
            <td>empty</td>
            <td>empty</td>

            <th className="align-top">
              Mẫu số 3: Kiểm tra trước khi ăn (Bước 3)
            </th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
          </tr>
        </thead>
        <tbody>
          <tr className="text-center">
            <td className="align-middle">TT</td>
            <td className="align-middle">Ca/bữa ăn (Bữa ăn, giờ ăn...)</td>
            <td className="align-middle">Tên món ăn</td>
            <td className="align-middle">Số lượng suất ăn</td>
            <td className="align-middle">
              Thời gian chia món ăn xong (ngày, giờ)
            </td>
            <td className="align-middle">Thời gian bắt đầu ăn (ngày, giờ)</td>
            <td className="align-middle">
              Dụng cụ chia, chứa đựng, che đậy, bảo quản thức ăn
            </td>
            <td className="align-middle">
              Kiểm tra cảm quan món ăn (màu, mùi, vị, trạng thái, bảo quản...)
            </td>
            <td>empty</td>

            <td className="align-middle">Biện pháp xử lý/ Ghi chú</td>
          </tr>
          <tr className="text-center">
            <td>empty</td>
            <td>empty</td>
            <td>empty</td>
            <td>empty</td>
            <td>empty</td>
            <td>empty</td>
            <td>empty</td>

            <td className="align-middle">Đạt</td>
            <td className="align-middle">Không đạt</td>

            <td>empty</td>
          </tr>
          <tr className="text-center">
            <td className="align-middle">(1)</td>
            <td className="align-middle">(2)</td>
            <td className="align-middle">(3)</td>
            <td className="align-middle">(4)</td>
            <td className="align-middle">(5)</td>
            <td className="align-middle">(6)</td>
            <td className="align-middle">(7)</td>
            <td className="align-middle">(8)</td>
            <td className="align-middle">(9)</td>
            <td className="align-middle">(10)</td>
          </tr>

          {quantitative?.times?.map((timeItem) => {
            return (
              <React.Fragment key={timeItem.id_time}>
                {timeItem.foods.map((foodItem) => {
                  let mealQuantity = 0;

                  foodItem.groups.map((groupItem) => {
                    mealQuantity += groupItem.more_meal
                      ? groupItem.more_meal
                      : Number(
                          costPerDays.find(
                            (costPerDay) => costPerDay.id_group === groupItem.id
                          )?.meal_quantity
                        );
                    return groupItem;
                  });

                  bookIndexreport++;
                  return (
                    <tr key={foodItem.id} className="align-middle">
                      <td className="text-center">{bookIndexreport}</td>
                      <td>
                        {
                          times.find(
                            (timeObj) => timeObj.id === timeItem.id_time
                          )?.time_name_now
                        }{" "}
                        {moment(
                          Number(
                            formSetting.step3_2?.find(
                              (item, timeObjIndex) =>
                                parseInt(item.time) === timeItem.id_time
                            )?.val
                          )
                        ).format("HH:mm")}
                      </td>
                      <td>{foodItem.food_name}</td>
                      <td className="text-center">{mealQuantity}</td>
                      <td className="text-center">
                        {
                          times.find(
                            (timeObj) => timeObj.id === timeItem.id_time
                          )?.time_name_now
                        }{" "}
                        {moment(
                          Number(
                            formSetting.step3_5?.find(
                              (item, timeObjIndex) =>
                                parseInt(item.time) === timeItem.id_time
                            )?.val
                          )
                        ).format("HH:mm")}
                      </td>
                      <td className="text-center">
                        {
                          times.find(
                            (timeObj) => timeObj.id === timeItem.id_time
                          )?.time_name_now
                        }{" "}
                        {moment(
                          Number(
                            formSetting.step3_6?.find(
                              (item, timeObjIndex) =>
                                parseInt(item.time) === timeItem.id_time
                            )?.val
                          )
                        ).format("HH:mm")}
                      </td>
                      <td>{formSetting.step3_7}</td>
                      <td>{sections?.section_8 || "empty"}</td>
                      <td>empty</td>
                      <td>empty</td>
                    </tr>
                  );
                })}
              </React.Fragment>
            );
          })}
        </tbody>
        <tfoot>
          <tr className="text-center" style={{ height: 50 }}>
            <td>{sections?.position_1 || "Ban giám hiệu"}</td>
            <td>empty</td>
            <td>empty</td>
            <td>{sections?.position_2 || "Tổ trưởng kiểm thực"}</td>
            <td>empty</td>
            <td>empty</td>
            <td>empty</td>
            <td>{sections?.position_3 || "Người kiểm tra"}</td>
            <td>empty</td>
            <td>empty</td>
          </tr>
        </tfoot>
      </Table>
    </div>
  );
};

export default Book3;
