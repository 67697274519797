import React, { useState, useEffect } from "react";
import api from "../../../helper/axiosInstance";

const Countdown = ({ exp_date }) => {
  const [exp, setExp] = useState(0);
  const [timeLeft, setTimeLeft] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getDetailschool();
  }, []);

  const getDetailschool = async () => {
    try {
      const res = await api.get(`/school-show`);
      setExp(res.data.data.exp_date);
    } catch (error) {
      console.error("Error fetching school details:", error);
    } finally {
      setLoading(false);
    }
  };

  const targetDate = new Date(exp * 1000);

  const calculateTimeLeft = () => {
    const now = new Date();
    const difference = exp * 1000 - now;

    if (difference <= 0) {
      return null;
    }

    let years = targetDate.getFullYear() - now.getFullYear();
    let months = targetDate.getMonth() - now.getMonth();
    let days = targetDate.getDate() - now.getDate();
    let hours = targetDate.getHours() - now.getHours();
    let minutes = targetDate.getMinutes() - now.getMinutes();
    let seconds = targetDate.getSeconds() - now.getSeconds();

    if (seconds < 0) {
      seconds += 60;
      minutes -= 1;
    }
    if (minutes < 0) {
      minutes += 60;
      hours -= 1;
    }
    if (hours < 0) {
      hours += 24;
      days -= 1;
    }
    if (days < 0) {
      const lastMonth = new Date(
        now.getFullYear(),
        now.getMonth() + 1,
        0
      ).getDate();
      days += lastMonth;
      months -= 1;
    }
    if (months < 0) {
      months += 12;
      years -= 1;
    }

    return {
      years,
      months,
      days,
      hours,
      minutes,
      seconds,
    };
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [exp]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!timeLeft) {
    return <div>Countdown reached!</div>;
  }

  return (
    <div style={styles.container}>
      <h2 className="text-white">Thời hạn sử dụng</h2>
      <div style={styles.countdown}>
        <div style={styles.box}>
          <span style={styles.number}>{timeLeft.years}</span>
          <span> năm</span>
        </div>
        <div style={styles.box}>
          <span style={styles.number}>{timeLeft.months}</span>
          <span> tháng</span>
        </div>
        <div style={styles.box}>
          <span style={styles.number}>{timeLeft.days}</span>
          <span> ngày</span>
        </div>
        {/* <div style={styles.box}>
          <span style={styles.number}>{timeLeft.hours}</span>
          <span> giờ</span>
        </div>
        <div style={styles.box}>
          <span style={styles.number}>{timeLeft.minutes}</span>
          <span> phút</span>
        </div>
        <div style={styles.box}>
          <span style={styles.number}>{timeLeft.seconds}</span>
          <span> giây</span>
        </div> */}
      </div>
    </div>
  );
};

const styles = {
  container: {
    // textAlign: "center",
    // fontFamily: "Arial, sans-serif",
    // padding: "0 20px 20px 20px",

    padding: "10px 20px 16px 20px",
    // backgroundColor: "#f9f9f9",
    // borderRadius: "8px",
    // width: "100%",
    // // margin: "0 auto",
    // boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    // margin: "5px 0",
    textAlign: "center",
    fontFamily: "Arial, sans-serif",
    // padding: "10px 20px 20px 20px",

    backgroundColor: "rgb(50, 197, 210)",
    borderRadius: "8px",
    width: "100%",
    margin: "10px 0",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
  countdown: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  box: {
    flex: "1 1 30%",
    // textAlign: "center",
    // // padding: "0 10px",
    // backgroundColor: "#fff",
    // borderRadius: "4px",
    // // margin: "10px 0",
    // marginTop: "10px",
    // marginRight: "5px",
    // padding: "5px",
    // boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    color: "#fff",
  },
  number: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#fff",
  },
};

export default Countdown;
