import React, { Component } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Row,
  Col,
  Card,
  Container,
  Breadcrumb,
  Modal,
} from "react-bootstrap";
import Select from "react-select";
import { startOfWeek, endOfWeek } from "date-fns";
import CheckboxTree from "react-checkbox-tree";
import swal from "sweetalert";
import api from "../../../helper/axiosInstance";
import DatePickerCustom from "../../uiElements/DatePickerCustom";
import TableRegister from "./TableRegister";
import Check from "../other/Check";
import { toastError } from "../../../helper/messages";
import AlertDismissible from "../other/AlertDismissible";

class RegisterMealIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menus: [],
      checkLoad: true,
      startDate: new Date(),
      endDate: new Date(),
      listClass: [],
      choiceClass: { value: 0, label: "Tất cả lớp" },
      schoolInfo: {},
      modalRegister: false,
      modalApprove: false,
      modalDelete: false,
      arrClassChoice: [],
      arrDay: [],
      menuRegister: [],
      listDaySetting: [],
      selectedDay: new Date().getDay() === 0 ? 7 : new Date().getDay(),
      settingsMealRegistration: {},
    };
  }

  componentDidMount() {
    document.title = "Đăng ký suất ăn";
    this.getDetailschool();
    this.setState({
      startDate: new Date(
        startOfWeek(new Date(), { weekStartsOn: 1 }).setHours(0, 0, 0)
      ),
      endDate: new Date(
        endOfWeek(new Date(), { weekStartsOn: 1 }).setHours(23, 59, 59)
      ),
    });
    this.getClassRoom(
      new Date(startOfWeek(new Date(), { weekStartsOn: 1 }).setHours(0, 0, 0)) /
        1000,
      new Date(
        endOfWeek(new Date(), { weekStartsOn: 1 }).setHours(23, 59, 59)
      ) / 1000
    );
    this.getSettingsMealRegistration();
  }

  stringFirstDate = (date) =>
    ("0" + date.getDate()).slice(-2) +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2);

  stringLastDate = (date) =>
    ("0" + date.getDate()).slice(-2) +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "/" +
    date.getFullYear();

  getDetailschool = () => {
    api
      .get(`/detailSchool`)
      .then((res) => {
        if (res.data.status === true) {
          this.setState({
            schoolInfo: res.data.data[0],
          });
        }
      })
      .catch((err) => {});
  };

  getClassRoom = (start, end) => {
    api
      .get(`/classroom-shows?start_date=${parseInt(start)}&end_date=${end}`)
      .then((res) => {
        let arr = [];
        res.data.data?.classrooms?.map((item) => {
          return arr.push({
            value: item.id,
            label: item.class_name,
            id_group: item.id_group,
            group_name: item.group_name,
          });
        });

        this.setState({ listClass: arr, choiceClass: arr[0] });
        this.getRegister(start * 1000, end * 1000, arr[0]);
      })
      .catch((err) => {});
  };

  getNumberOfPortions(arr, id_day, id_time, id_food) {
    const dayItem = arr?.find(
      (item) => Number(item.day?.id) === Number(id_day)
    );
    if (!dayItem) return "";

    const timeItem = dayItem?.times?.find(
      (time) => Number(time.time.id) === Number(id_time)
    );
    if (!timeItem) return "";

    const foodItem = timeItem?.foods?.find(
      (food) => Number(food.id) === Number(id_food)
    );
    if (!foodItem) return "";

    return foodItem.number_of_portions || "";
  }

  getStatus(arr, id_day, id_time, id_food) {
    const dayItem = arr?.find(
      (item) => Number(item.day?.id) === Number(id_day)
    );
    if (!dayItem) return "";

    const timeItem = dayItem?.times?.find(
      (time) => Number(time.time.id) === Number(id_time)
    );
    if (!timeItem) return "";

    const foodItem = timeItem?.foods?.find(
      (food) => Number(food.id) === Number(id_food)
    );
    if (!foodItem) return "";

    return foodItem.status || "";
  }

  getMaxNumberOfPortions(arr, id_day, id_time) {
    const menuForDay = arr
      .find((menu) => menu.day.id === id_day)
      ?.times?.find((time) => time.time.id === id_time);

    if (!menuForDay) {
      return 0;
    }

    const foods = menuForDay?.foods;

    if (!foods || foods.length === 0) {
      return 0;
    }

    const firstCategoryId = foods[0].id_category;
    return foods
      .filter((food) => food.id_category === firstCategoryId)
      .reduce((total, food) => total + food.number_of_portions, 0);
  }

  getMenu = (arrRegister, start, end, classChoice) => {
    api
      .get(
        `/get-menu-by-group?term_at=${parseInt(end / 1000)}&start_at=${parseInt(
          start / 1000
        )}&id_group=${classChoice?.id_group}`
      )
      .then((res) => {
        this.setState({
          checkLoad: false,
        });

        let arr = {
          ...res.data.data,
          menu_has_days: res.data.data?.menu_has_days?.map((item) => ({
            ...item,
            status: arrRegister?.filter((e) => e.day?.id === item.id_day)[0]
              ?.status,
            menu_has_times: item.menu_has_times?.map((e) => ({
              ...e,
              foods: e.foods?.map((f) => ({
                ...f,
                id_day: item.id_day,
                quantity: this.getNumberOfPortions(
                  arrRegister,
                  item.id_day,
                  e.id_time,
                  f.id
                ),
                status: this.getStatus(
                  arrRegister,
                  item.id_day,
                  e.id_time,
                  f.id
                ),
                totalQuantity: this.getMaxNumberOfPortions(
                  arrRegister,
                  item.id_day,
                  e.id_time
                ),
                totalCategory: e.foods?.filter(
                  (food) => food.id_category === f.id_category
                )?.length,
                id_time: e.id_time,
                active_category_id: "",
              })),
            })),
          })),
        };
        this.setState({ menus: arr.menu_has_days || [] });
      })
      .catch((err) => {
        toastError(err);
      });
  };

  getSetting = (start, end) => {
    api
      .get(
        `/get-setting-by-date-period?end_date=${parseInt(
          end / 1000
        )}&start_date=${parseInt(start / 1000)}`
      )
      .then((res) => {
        this.setState({
          checkLoad: false,
        });
        let arr = [];
        res.data.data?.map((item) => arr?.push(item.days));
        let result = [
          ...new Map(arr.flat().map((day) => [day.day_name, day])).values(),
        ];

        this.setState({ listDaySetting: result || [] });
      })
      .catch((err) => {
        toast(`Lỗi `, {
          type: "error",
          autoClose: 1000,
        });
      });
  };

  handleRegisterCall(check) {
    if (check)
      this.getRegister(
        this.state.startDate,
        this.state.endDate,
        this.state.choiceClass
      );
    else return;
  }

  getRegister = (start, end, classChoice) => {
    this.getSetting(start, end);
    api
      .get(
        `/get-registration-list-of-meals-by-class?end_at=${parseInt(
          end / 1000
        )}&start_at=${parseInt(start / 1000)}&id_class=${classChoice?.value}`
      )
      .then((res) => {
        this.setState({
          checkLoad: false,
        });
        this.setState({ menuRegister: res.data.data || [] }, () => {
          this.getMenu(this.state.menuRegister, start, end, classChoice);
        });
      })
      .catch((err) => {
        toast(`Lỗi `, {
          type: "error",
          autoClose: 1000,
        });
      });
  };

  getSettingsMealRegistration = () => {
    api
      .get(`/get-settings-meal-registration`)
      .then((res) => {
        const settings = res.data.data;
        this.setState({
          settingsMealRegistration: settings.payload || {},
        });
      })
      .catch((err) => {});
  };

  submitRegister = () => {
    let data = {
      id_class: this.state.choiceClass?.value,
      id_menu: this.state.menus[0]?.id_menu,
      mealsPerDay: this.transformData(this.state.menus),
      register_by: Number(localStorage?.getItem("auth_id_user")) ?? null,
    };

    let arr = this.state.menus

      ?.filter((dayItem) =>
        this.state.arrDay?.some(
          (time) => Number(time) === Number(dayItem.id_day)
        )
      )
      ?.map((dayItem) => ({
        id_day: dayItem.id_day,
        day_name: dayItem.day_of_week?.day_name,
        meals: dayItem.menu_has_times?.map((timeItem) => ({
          id_time: timeItem.id_time,
          name_time: timeItem.time_of_day.time_name_def,
          // time_name: timeItem.time_name,
          category: timeItem.foods?.reduce((acc, food) => {
            const existingCategory = acc?.find(
              (c) => c.id_Category === food.id_category
            );

            const { timesId = [], categoriesId = [] } =
              this.state.settingsMealRegistration;

            const checkTimeConstraint = timesId.includes(food.id_time);

            const checkCategoryConstraint = categoriesId.includes(
              food.id_category
            );

            const totalCurrent =
              checkTimeConstraint && checkCategoryConstraint
                ? timeItem.foods
                    ?.filter((e) => e.id_category === food.id_category)
                    ?.reduce((sum, food) => sum + Number(food.quantity), 0)
                : food.totalQuantity;

            if (existingCategory) {
              existingCategory.totalCurrent = Number(totalCurrent);
            } else {
              acc.push({
                id_Category: food.id_category,
                category_name: food.category.category_name,
                totalCurrent: Number(totalCurrent),
                totalQuantity: Number(food.totalQuantity),
              });
            }

            return acc;
          }, []),
          foods: timeItem?.foods?.map((foodItem) => {
            const { timesId = [], categoriesId = [] } =
              this.state.settingsMealRegistration;

            const checkTimeConstraint = timesId.includes(foodItem.id_time);

            const checkCategoryConstraint = categoriesId.includes(
              foodItem.id_category
            );

            const totalCurrent =
              checkTimeConstraint && checkCategoryConstraint
                ? timeItem.foods
                    ?.filter((e) => e.id_category === foodItem.id_category)
                    ?.reduce((sum, food) => sum + Number(food.quantity), 0)
                : foodItem.totalQuantity;

            return {
              id_food: foodItem.id,
              name: foodItem.food_name,
              portions: parseInt(foodItem.quantity) || 0,
              id_category: foodItem.id_category,
              totalQuantity: foodItem.totalQuantity,
              totalCurrent: totalCurrent,
            };
          }),
        })),
      }));
    let hasError = false;

    arr?.forEach((day) => {
      day?.meals?.forEach((meal) => {
        const allTotalCurrents = meal.category?.map(
          (cate) => cate.totalCurrent
        );
        const allEqual = allTotalCurrents?.every(
          (value) => value === allTotalCurrents[0]
        );

        if (!allEqual) {
          hasError = true;
          toast(
            `Tổng số lượng món ăn của các danh mục trong buổi ${meal.name_time} ngày ${day.day_name} không bằng nhau`,
            {
              type: "error",
              autoClose: 2000,
            }
          );
        }
      });
    });
    if (!hasError)
      api
        .post(`meal-registration-create-or-update`, data, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          toast(res.data.messages || "Thành công", { type: "success" });
          this.setState({ modalRegister: false, arrDay: [] });

          this.getRegister(
            this.state.startDate,
            this.state.endDate,
            this.state.choiceClass
          );
        })
        .catch((error) => {
          if (error.response.data.messages) {
            let isErr = false;
            this.setState({ errors: error.response.data.messages });
            Object?.keys(error.response.data.messages)?.forEach((key) => {
              toast.error(error.response.data.messages[key]);
              isErr = true;
            });
            if (!isErr)
              toast(error.response.data.messages?.id_class || `Lỗi `, {
                type: "error",
                autoClose: 1000,
              });
          } else
            toast(error.message || `Hệ thống xảy ra lỗi `, {
              type: "error",
              autoClose: 1000,
            });
        });
  };

  submitApprove = () => {
    let data = {
      id_class: this.state.arrClassChoice,
      id_menu: this.state.menus[0]?.id_menu,
      id_day: this.state.selectedDay,
      status: "approved",
    };

    api
      .put(`update-status-registration-list-of-meals-by-class-and-day`, data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        toast(res.data.messages || "Thành công", { type: "success" });
        this.setState({ modalApprove: false, arrDay: [], arrClassChoice: [] });

        this.getRegister(
          this.state.startDate,
          this.state.endDate,
          this.state.choiceClass
        );
      })
      .catch((error) => {
        toast(`Lỗi `, {
          type: "error",
          autoClose: 1000,
        });
      });
  };

  submitDelete = () => {
    swal({
      title: `Bạn chắc muốn xóa?`,
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then((ok) => {
      if (ok) {
        let data = {
          id_menu: this.state.menus[0]?.id_menu,
          days: this.state.arrDay,
          classes: this.state.arrClassChoice,
        };
        api
          .delete(`meal-registration-delete`, { data })
          .then((res) => {
            toast(res.data.messages || "Thành công", { type: "success" });
            this.setState({
              modalApprove: false,
              arrDay: [],
              arrClassChoice: [],
              modalDelete: false,
            });

            this.getRegister(
              this.state.startDate,
              this.state.endDate,
              this.state.choiceClass
            );
          })
          .catch((error) => {
            toast(`Lỗi `, {
              type: "error",
              autoClose: 1000,
            });
          });
      }
    });
  };

  transformData = (arr) => {
    return arr
      ?.filter((dayItem) =>
        this.state.arrDay?.some(
          (time) => Number(time) === Number(dayItem.id_day)
        )
      )
      ?.map((dayItem) => ({
        id_day: dayItem.id_day,
        meals: dayItem.menu_has_times.map((timeItem) => ({
          id_time: timeItem.id_time,
          foods: timeItem.foods.map((foodItem) => ({
            id_food: foodItem.id,
            portions: parseInt(foodItem.quantity) || 0,
          })),
        })),
      }));
  };

  loading = () => {
    return (
      <Card>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ width: "3rem", height: "3rem" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </Card.Body>
      </Card>
    );
  };
  setMenus = (updatedMenus) => {
    this.setState({ menus: updatedMenus });
  };

  dataTable() {
    const uniqueTimesid = this.state.menus
      ?.flatMap((item) =>
        item.menu_has_times.map((time) => ({
          id_time: time.time_of_day.id,
          time_name: time.time_of_day.time_name_def,
          // totalQuantity: time.totalQuantity,
        }))
      )
      .reduce((acc, current) => {
        if (!acc.some((time) => time.id_time === current.id_time)) {
          acc.push(current);
        }
        return acc;
      }, []);

    // const uniqueCategories = [
    //   ...new Set(
    //     this.state.menus?.flatMap((item) =>
    //       item.menu_has_times.flatMap((time) =>
    //         time.foods.map((food) => food.category.category_name)
    //       )
    //     )
    //   ),
    // ];

    return (
      <TableRegister
        listQuantitatives={this.state.menus}
        setMenus={this.setMenus}
        listDays={this.state.menus || []}
        listTimes={uniqueTimesid || []}
        choiceClass={this.state.choiceClass}
        isCallRegister={(value) => {
          this.handleRegisterCall(value);
        }}
        settingsMealRegistration={this.state.settingsMealRegistration}
      />
    );
  }
  getOption = (data, type, checkAll) => {
    var arr_ = [];
    data.map((item, index) =>
      arr_.push({
        value: item.id_day || item.value || item.id,
        label: item.day_of_week?.day_name || item.label || item.day_name,
      })
    );
    const nodes = [
      {
        value: "0",
        label: "Tất cả",

        children: arr_,
      },
    ];
    return (
      <>
        <CheckboxTree
          nodes={checkAll ? nodes : arr_}
          showExpandAll
          checked={
            type === "class" ? this.state.arrClassChoice : this.state.arrDay
          }
          expanded={[0]}
          onCheck={(checked) => {
            if (!checkAll) {
              this.setState({
                arrDay: [],
              });
            }
            const newChecked = checkAll
              ? checked
              : [checked[checked.length - 1]];
            this.setState((prevState) => ({
              arrClassChoice:
                type === "class" ? newChecked : prevState.arrClassChoice,
              arrDay: type === "class" ? prevState.arrDay : newChecked,
            }));
          }}
          icons={{
            check: (
              <i
                className="fa-solid fa-square-check"
                style={{ color: "#005eff" }}
              ></i>
            ),
            uncheck: (
              <i
                className="fa-regular fa-square"
                style={{ color: "#005eff" }}
              ></i>
            ),
            halfCheck: (
              <i
                className="fa-regular fa-square "
                style={{ color: "#99beff" }}
              ></i>
            ),
            expandClose: "",
            expandOpen: "",
            parentClose: "",
            parentOpen: "",
            leaf: "",
          }}
        />
      </>
    );
  };
  hideModal = () => {
    this.setState({
      modalDelete: false,
      modalApprove: false,
      modalRegister: false,
      arrClassChoice: [],
      arrDay: [],
    });
  };
  renderRegisterList() {
    return (
      <>
        <Card className="mb-4">
          <Card.Header>
            <div className="d-block d-md-flex justify-content-between">
              <div className="d-block d-md-flex">
                <Card.Title className="me-md-2 d-md-flex align-items-center">
                  Đăng ký suất ăn {this.stringFirstDate(this.state.startDate)} -{" "}
                  {this.stringLastDate(this.state.endDate)}{" "}
                  <div className="d-flex mb-2 mb-md-0 align-items-center z-index-1021">
                    <div className="me-2 d-flex align-items-center mt-2 mt-md-0 ms-md-2">
                      <DatePickerCustom
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        onChange={(date) => {
                          const startDateGMT = startOfWeek(date, {
                            weekStartsOn: 1,
                          });
                          const endDateGMT = startOfWeek(
                            endOfWeek(date, { weekStartsOn: 1 })
                          );

                          this.setState({
                            startDate: startDateGMT,
                            endDate: endDateGMT,
                          });

                          this.getRegister(
                            startDateGMT,
                            endDateGMT,
                            this.state.choiceClass
                          );
                        }}
                        showWeekNumbers
                        dateFormat="yyyy-MM-dd"
                        calendarClassName="custom-calendar"
                      />
                    </div>

                    <Select
                      options={this.state.listClass}
                      value={this.state.choiceClass}
                      onChange={(choice) => {
                        this.setState(
                          {
                            choiceClass: choice,
                          },
                          () => {
                            this.getRegister(
                              this.state.startDate,
                              this.state.endDate,
                              this.state.choiceClass
                            );
                          }
                        );
                      }}
                      className="w-100 mt-2 mt-md-0 "
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          minWidth: "15rem",
                        }),
                      }}
                    ></Select>
                  </div>
                </Card.Title>

                <div className="d-flex align-items-center">
                  {new Check().permission([164]) ? (
                    <Button
                      variant="success me-2"
                      onClick={() =>
                        this.setState({
                          modalRegister: true,
                          arrDay: this.state.menus?.map((item) => item.id_day),
                        })
                      }
                      disabled={
                        this.state.menus?.filter((e) => e.status !== "approved")
                          ?.length < 1
                      }
                      title="Đăng ký suất ăn"
                    >
                      <i className="fa-solid fa-pencil" /> Đăng ký
                    </Button>
                  ) : (
                    <></>
                  )}
                  {new Check().permission([165]) ? (
                    <Button
                      variant="info me-2"
                      onClick={() =>
                        this.setState({
                          modalApprove: true,
                          arrClassChoice: [this.state.choiceClass?.value],
                        })
                      }
                      title="Duyệt đăng ký"
                    >
                      <i className="fa-solid fa-check" /> Duyệt
                    </Button>
                  ) : (
                    <></>
                  )}
                  {new Check().permission([169]) ? (
                    <Button
                      variant="danger"
                      onClick={() =>
                        this.setState({
                          modalDelete: true,
                          arrClassChoice: [],
                        })
                      }
                      title="Xóa đăng ký"
                    >
                      <i className="fa-solid fa-trash" /> Xóa
                    </Button>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <p>
              <strong>Chú thích:</strong>
              <br />
              <span
                className="fa-solid fa-square "
                style={{ color: "#7fbf7f" }}
              ></span>{" "}
              Buổi có ràng buộc số lượng món chính và món phụ.
              <br />
              <span
                className="fa-solid fa-square"
                style={{ color: "#a8eae8" }}
              ></span>{" "}
              Buổi không ràng số lượng buộc món chính và món phụ.
            </p>
            {this.state.checkLoad ? (
              this.loading()
            ) : this.state.menus ? (
              this.dataTable()
            ) : (
              <p className="text-center">Không có dữ liệu. . .</p>
            )}
          </Card.Body>
        </Card>

        <Modal show={this.state.modalRegister} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              Đăng ký suất ăn - {this.state.choiceClass?.label}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AlertDismissible
              content={
                <>
                  <span className="text-danger">**</span>
                  Lưu ý: Khi bỏ chọn ngày thì hệ thống sẽ xóa đăng ký của lớp{" "}
                  <strong> {this.state.choiceClass?.label}</strong> trong ngày
                  hôm đó.
                </>
              }
              showModal={true}
            />

            <h5>Chọn ngày </h5>
            {this.getOption(
              this.state.menus?.filter((e) => e.status !== "approved"),
              "day",
              true
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success mx-1"
              onClick={() => {
                this.submitRegister();
              }}
            >
              <i className="fa-solid fa-pencil" /> Đăng ký
            </Button>

            <Button variant="secondary" onClick={() => this.hideModal()}>
              <i className="fa-solid fa-times" /> Đóng
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.modalApprove} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>Duyệt đăng ký suất ăn</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <h5>Chọn ngày:</h5>
                {this.state.listDaySetting.map((day) => (
                  <div key={day.id} className="ms-3">
                    <label>
                      <input
                        type="radio"
                        name="day"
                        checked={this.state.selectedDay === day.id}
                        onChange={() => this.setState({ selectedDay: day.id })}
                      />
                      <span className="ms-2">{day.day_name}</span>
                    </label>
                  </div>
                ))}
              </Col>
              <Col>
                <h5>Chọn lớp:</h5>
                {this.getOption(this.state.listClass, "class", true)}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success mx-1"
              onClick={() => {
                if (this.state.arrClassChoice?.length < 1) {
                  toast(`Vui lòng chọn ít nhất 1 lớp`, {
                    type: "error",
                    autoClose: 1000,
                  });
                } else {
                  if (!this.state.selectedDay) {
                    toast(`Vui lòng chọn ít nhất 1 ngày`, {
                      type: "error",
                      autoClose: 1000,
                    });
                  } else {
                    this.submitApprove();
                  }
                }
              }}
            >
              <i className="fa-solid fa-check" /> Duyệt
            </Button>

            <Button variant="secondary" onClick={() => this.hideModal()}>
              <i className="fa-solid fa-times" /> Đóng
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.modalDelete} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>Xóa đăng ký suất ăn</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <h5>Chọn ngày:</h5>
                {this.getOption(this.state.listDaySetting, "day", true)}
              </Col>
              <Col>
                <h5>Chọn lớp:</h5>
                {this.getOption(this.state.listClass, "class", true)}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger mx-1"
              onClick={() => {
                if (this.state.arrClassChoice?.length < 1) {
                  toast(`Vui lòng chọn ít nhất 1 lớp`, {
                    type: "error",
                    autoClose: 1000,
                  });
                } else {
                  if (this.state.arrDay?.length < 1) {
                    toast(`Vui lòng chọn ít nhất 1 ngày`, {
                      type: "error",
                      autoClose: 1000,
                    });
                  } else {
                    this.submitDelete();
                  }
                }
              }}
            >
              <i className="fa-solid fa-trash" /> Xóa
            </Button>

            <Button variant="secondary" onClick={() => this.hideModal()}>
              <i className="fa-solid fa-times" /> Đóng
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  render() {
    return (
      <>
        <Container fluid className="px-3">
          <Breadcrumb className="mb-2 ">
            <Breadcrumb.Item active>Suất ăn</Breadcrumb.Item>
            <Breadcrumb.Item active>Đăng ký suất ăn</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Col>{this.renderRegisterList()}</Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default RegisterMealIndex;
