import React, { useState, useEffect } from "react";
import Footer from "../../../layouts/frontend/Footer";
import Navbar from "../../../layouts/frontend/Navbar";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import api from "../../../helper/axiosInstance";
import ForgotPasswordModal from "./ForgotPasswordModal";
import { useNavigate } from "react-router-dom";

const Login = () => {
  useEffect(() => {
    document.title = "Đăng nhập";
    checkLogin();
  }, []);

  const navigate = useNavigate();

  const [passwordShown, setPasswordShown] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const [loginInput, setLogin] = useState({
    user_account: localStorage.getItem("auth_account") || "",
    user_phone: localStorage.getItem("auth_phone") || "",
    user_password: "",
  });

  const checkRememberMe =
    localStorage.getItem("auth_name") && localStorage.getItem("auth_phone");

  const [rememberMe, setRememberMe] = useState(checkRememberMe ? true : false);
  const [isLoading, setIsLoading] = useState(false);
  const [errMessages, setErrMessages] = useState({});

  const checkLogin = () => {
    if (localStorage.getItem("access_token")) {
      navigate("/admin-v2");
    }
  };

  const handleInput = (e) => {
    e.persist();
    let field = e.target.name;
    let value = e.target.value;

    setLogin({ ...loginInput, [field]: value });

    setErrMessages({ ...errMessages, [field]: "" });
  };

  const loginSubmit = async (e) => {
    e.preventDefault();

    let data = {
      user_phone: loginInput.user_phone,
      user_account: loginInput.user_account,
      user_password: loginInput.user_password,
    };

    setIsLoading(true);
    await api
      .post("/login", data, {
        headers: { "Content-Type": "application/json" },
      })
      .then(
        (res) => {
          const result = res.data.data;
          const permissions = result.permissions.map(
            (permission) => permission.id
          );

          if (rememberMe) {
            localStorage.setItem("auth_phone", loginInput.user_phone);
            localStorage.setItem("auth_account", loginInput.user_account);
          }

          localStorage.setItem("auth_id_shool", result.user.id_school);
          localStorage.setItem("auth_id_user", result.user.id);
          localStorage.setItem("auth_name", result.user.user_name);
          localStorage.setItem("permission", permissions);
          localStorage.setItem("access_token", result.token);
          localStorage.setItem("version", "2.0");

          toast(res.data.message, { type: "success" });
          setIsLoading(false);
          window.location.href = "/admin-v2";
        },
        [navigate]
      )
      .catch((error) => {
        const messages = error?.response?.data?.messages;
        setErrMessages(messages);

        if (typeof messages === "string") {
          toast(messages, { type: "error", autoClose: 3000 });
        }

        setIsLoading(false);
      });
  };

  const hideForgotPasswordModal = () => {
    setShowForgotPasswordModal(!showForgotPasswordModal);
  };

  return (
    <>
      <div
        style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <Navbar />
        <div></div>
        <div id="content" style={{ flexGrow: "1" }}>
          <main>
            <Container className="py-5" id="login">
              <Row className="justify-content-center">
                <Col md={4}>
                  <Card className="text-center" variant="info">
                    <Card.Header>
                      <h2 className="fw-bold">
                        <span className="text-primary">K</span>
                        <span className="text-info">a</span>
                        <span className="text-primary">f</span>
                        <span className="text-info">oo</span>
                        <span className="text-primary">d</span>
                      </h2>
                    </Card.Header>
                    <Form onSubmit={loginSubmit}>
                      <Card.Body className="text-start">
                        {rememberMe && checkRememberMe ? (
                          <>
                            <h4>Xin chào,</h4>
                            <h3>{localStorage.getItem("auth_name")}</h3>
                          </>
                        ) : (
                          <>
                            <Form.Floating>
                              <Form.Control
                                onChange={handleInput}
                                value={loginInput.user_phone}
                                name="user_phone"
                                type="text"
                                placeholder="Số điện thoại"
                                className={
                                  errMessages?.user_phone ? "is-invalid" : ""
                                }
                              />
                              <Form.Label>
                                <i className="fa-solid fa-phone" /> Số điện
                                thoại
                                <span className="text-danger">*</span>
                              </Form.Label>
                            </Form.Floating>
                            <span className="text-danger">
                              {errMessages?.user_phone}
                            </span>

                            <Form.Floating className="mt-3">
                              <Form.Control
                                onChange={handleInput}
                                value={loginInput.user_account}
                                name="user_account"
                                placeholder="Tài khoản"
                                className={
                                  errMessages?.user_account ? "is-invalid" : ""
                                }
                              />
                              <Form.Label>
                                <i className="fa-solid fa-user" /> Tài khoản
                              </Form.Label>
                            </Form.Floating>
                            <span className="text-danger">
                              {errMessages?.user_account}
                            </span>
                          </>
                        )}

                        <Form.Floating className="mt-3">
                          <InputGroup className="has-validation w-100 is-invalid">
                            <Form.Floating
                              style={{ width: "calc(100% - 46px)" }}
                            >
                              <Form.Control
                                onChange={handleInput}
                                value={loginInput.user_password}
                                type={passwordShown ? "text" : "password"}
                                name="user_password"
                                className={`border-end-0 rounded-end-0 
                              ${
                                errMessages?.user_password ? "is-invalid" : ""
                              }`}
                                placeholder="Mật khẩu"
                              />
                              <Form.Label>
                                <i className="fa-solid fa-key" /> Mật khẩu
                                <span className="text-danger">*</span>
                              </Form.Label>
                            </Form.Floating>

                            <InputGroup.Text
                              onClick={togglePassword}
                              style={{ width: "46px" }}
                              className={
                                errMessages?.user_password ? "is-invalid" : ""
                              }
                            >
                              <i
                                className={
                                  passwordShown
                                    ? "fa-regular fa-eye-slash"
                                    : "fa-regular fa-eye"
                                }
                              />
                            </InputGroup.Text>
                          </InputGroup>
                        </Form.Floating>
                        <span className="text-danger">
                          {errMessages?.user_password}
                        </span>

                        <div className="mt-2 d-flex justify-content-between">
                          {rememberMe && checkRememberMe ? (
                            <Link
                              className="text-muted fw-bold"
                              onClick={() => {
                                setRememberMe(false);
                                localStorage.removeItem("auth_account");
                                localStorage.removeItem("auth_phone");
                                localStorage.removeItem("auth_name");
                              }}
                            >
                              Tài khoản khác
                            </Link>
                          ) : (
                            <Form.Check
                              type="switch"
                              checked={rememberMe}
                              onChange={(e) => {
                                setRememberMe(!rememberMe);

                                if (!e.target.checked) {
                                  localStorage.removeItem("auth_account");
                                  localStorage.removeItem("auth_phone");
                                  localStorage.removeItem("auth_name");
                                }
                              }}
                              name="rememberMe"
                              label="Ghi nhớ tôi"
                              className="text-muted fw-bold"
                            />
                          )}

                          <Link
                            className="text-muted fw-bold"
                            onClick={() => setShowForgotPasswordModal(true)}
                          >
                            Quên mật khẩu?
                          </Link>
                        </div>

                        {!rememberMe || !checkRememberMe ? (
                          <div className="text-muted text-start">
                            <i>
                              <span className="text-danger">**</span>
                              <strong>Lưu ý:</strong> Nếu là người đại diện thì
                              không bắt buộc điền vào ô "Tài khoản"!
                            </i>
                          </div>
                        ) : null}

                        <Col sm={6} className="d-grid gap-2 mx-auto mt-2">
                          <Button
                            size="md"
                            type="submit"
                            variant="success"
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <>
                                <i className="fa-solid fa-spinner fa-spin"></i>{" "}
                                Đang xử lý
                              </>
                            ) : (
                              <>
                                Đăng nhập{" "}
                                <i className="fa-solid fa-right-to-bracket" />
                              </>
                            )}
                          </Button>
                        </Col>
                      </Card.Body>
                    </Form>
                  </Card>
                </Col>
              </Row>
              <div className="text-center mt-2">
                <Link to="tel:+84889881010" className="text-dark">
                  Bạn chưa có tài khoản? Liên hệ hotline{" "}
                  <strong>0889 88 1010</strong>
                </Link>
              </div>
            </Container>
          </main>
        </div>

        <Footer />

        <ForgotPasswordModal
          show={showForgotPasswordModal}
          onHide={() => hideForgotPasswordModal()}
        />
      </div>
    </>
  );
};

export default Login;
