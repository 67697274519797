import React, { Component } from "react";

class Check extends Component {
  permission(data, type = "and") {
    if (localStorage.getItem("role_rank") === "1") {
      return true;
    }

    const permission = localStorage.getItem("permission") || [];

    if (data.length === 0) {
      return false;
    } else {
      let result = false;
      let errCode = 0;

      data.map((item, i) => {
        if (permission.length > 0) {
          item = item.toString();

          switch (type) {
            case "or":
              if (permission.indexOf(item) > -1) {
                result = true;
              }
              break;
            case "and":
              if (permission.indexOf(item) > -1) {
                result = true;
              } else {
                errCode++;
                result = false;
              }
              break;
            default:
              errCode++;
              result = false;
          }
        }
      });

      return result && errCode === 0 ? true : false;
    }
  }
}

export default Check;
