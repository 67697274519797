import React from "react";
import { Link, NavLink } from "react-router-dom";
// import Logo from "../../assets/admin/assets/img/logo_kafood_4.png";
import Check from "../../components/admin2/other/Check";
import { ReactComponent as LogoKafood } from "../../assets/admin/assets/img/logo-kafood.svg";

const sidebarItems = [
  {
    title: "Suất ăn",
    link: "mealPages",
    icon: <i className="ka ka-spoon-fork" />,

    permission: new Check().permission(["72", 164, 165, 169], "or"),
    subItems: [
      {
        title: `Danh sách cắt suất`,
        link: "meal",
        icon: <i className="ka ka-file-check-times" />,
        permission: new Check().permission(["72"]),
      },
      {
        title: `Cắt suất ăn`,
        link: "meal-cut",
        icon: <i className="ka ka-scissors" />,
        permission: new Check().permission(["72"]),
      },
      {
        title: `Thống kê sĩ số`,
        link: "student-number-report",
        icon: <i className="ka ka-chart-column-sine" />,
        permission: new Check().permission(["72"]),
      },
      {
        title: `Đăng ký suất ăn`,
        link: "kitchen-register-meal",
        icon: <i className="ka ka-file-pen" />,
        permission: new Check().permission([164, 165, 169], "or"),
      },
    ],
  },
  {
    title: "Thực đơn",
    link: "menusPages",
    icon: <i className="ka ka-book-menu" />,
    permission: new Check().permission(
      ["52", "56", "57", "116", "120", 163, 167, 168],
      "or"
    ),
    subItems: [
      {
        title: "Thực đơn",
        link: "menu",
        icon: <i className="ka ka-file-menu" />,
        permission: new Check().permission(["52"]),
      },
      {
        title: "Tính khẩu phần ăn",
        link: "portion-calculate",
        icon: <i className="ka ka-calculator-apple" />,
        permission: new Check().permission(["120"]),
      },
      {
        title: "Năng lượng D.dưỡng",
        link: "nutrition-report",
        icon: <i className="ka ka-nutritional-energy" />,
        permission: new Check().permission(["56"]),
      },
      {
        title: "Danh sách đi chợ",
        link: "market",
        icon: <i className="ka ka-file-check-list-bag" />,
        permission: new Check().permission(["116"]),
      },
      {
        title: "Định lượng cho bếp",
        link: "quantitative-kitchen",
        icon: <i className="ka ka-pot-ingredients" />,
        permission: new Check().permission(["57"]),
      },
      {
        title: "Thực đơn nhóm",
        link: "kitchen-menu",
        icon: <i className="ka ka-file-menu" />,
        permission: new Check().permission([163]),
      },
      {
        title: "Tính khẩu phần ăn",
        link: "kitchen-portion-calculate",
        icon: <i className="ka ka-calculator-apple" />,
        permission: new Check().permission([163]),
      },
      {
        title: "Định lượng cho bếp",
        link: "kitchen-ingredient-quantity",
        icon: <i className="ka ka-pot-ingredients" />,
        permission: new Check().permission([167]),
      },
      {
        title: "Phiếu đi chợ ngày",
        link: "kitchen-voucher-daily",
        icon: <i className="ka ka-file-check-list-bag" />,
        permission: new Check().permission([168]),
      },
    ],
  },
  {
    title: "Kho hàng",
    link: "storesPages",
    icon: <i className="ka ka-warehouse" />,
    permission: new Check().permission(["40", "44", "46", "47", "50"], "or"),
    subItems: [
      {
        title: "Mua hàng",
        link: "buy",
        icon: <i className="ka ka-cart-shopping" />,
        permission: new Check().permission(["47"]),
      },
      {
        title: "Phiếu Nhập-Xuất",
        link: "receipt",
        icon: <i className="ka ka-boxes-arrows-up-down" />,
        permission: new Check().permission([46]),
      },
      {
        title: "Xuất kho vật tư",
        link: "material",
        icon: <i className="ka ka-boxes-up" />,
        permission: new Check().permission(["48"]),
      },
      {
        title: "Xuất-Nhập-Tồn",
        link: "store",
        icon: <i className="ka ka-warehouse-arrows-left-right" />,
        permission: new Check().permission(["46"]),
      },

      {
        title: "Nguyên liệu",
        link: "ingredient",
        icon: <i className="ka ka-salad-food" />,
        permission: new Check().permission(["40"]),
      },
      {
        title: "Ngân hàng Ng.liệu",
        link: "bank-ingredient",
        icon: <i className="ka ka-book-agriculture" />,
        permission: new Check().permission(["44"]),
      },
      {
        title: "Lịch sử kho",
        link: "store-inventory",
        icon: <i className="ka ka-box-clock" />,
        permission: new Check().permission(["50"]),
      },
      {
        title: "Tổng hợp Kho-Vật tư",
        link: "store-material",
        icon: <i className="ka ka-file-boxes-pie-chart" />,
        permission: new Check().permission([46]),
      },
      {
        title: "Nhà cung cấp",
        link: "supplier",
        icon: <i className="ka ka-store-user-tie" />,
        permission: new Check().permission(["23"]),
      },
      {
        title: "Lịch sử giá",
        link: "price-history",
        icon: <i className="ka ka-dollar-circle-clock" />,
        permission: new Check().permission(["23"]),
      },
    ],
  },
  {
    title: "Món ăn ",
    link: "foodsPages",
    icon: <i className="ka ka-rice-bowl" />,
    permission: new Check().permission(["28", "33", "35"], "or"),
    subItems: [
      {
        title: "Danh mục món",
        link: "food",
        icon: <i className="ka ka-file-square-list-cloche" />,
        permission: new Check().permission(["28"]),
      },
      {
        title: "Ngân hàng món",
        link: "bank-food",
        icon: <i className="ka ka-book-cook" />,
        permission: new Check().permission([33]),
      },
    ],
  },
  {
    title: "Học sinh",
    link: "studentPages",
    icon: <i className="ka ka-student" />,
    permission: new Check().permission(["7", "12", "17", 86, 124], "or"),
    subItems: [
      {
        title: `Danh sách`,
        link: "student",
        icon: <i className="ka ka-file-list-user" />,
        permission: new Check().permission(["17"]),
      },
      {
        title: `Nhóm học sinh`,
        link: "student-group",
        icon: <i className="ka ka-students" />,
        permission: new Check().permission(["7"]),
      },
      {
        title: `Tài khoản phụ huynh`,
        link: "parent-account",
        icon: <i className="ka ka-shield-clock-circle-user" />,
        permission: new Check().permission([124]),
      },
      {
        title: "Lịch tiêm ngừa",
        link: "vaccination",
        icon: <i className="ka ka-calendar-circle-check-syringe" />,
        permission: new Check().permission([171]),
      },
      // {
      //   title: "Sổ bé ngoan",
      //   link: "abc",
      //   icon: <i className="fa-solid fa-fan" />,
      //   permission: id_school === "1" ? true , // ** test
      // },
      // {
      //   title: "Sổ theo dõi sức khỏe",
      //   link: "abc",
      //   icon: <i className="fa-solid fa-book-medical" />,
      //   permission: id_school === "1" ? true , // ** test
      // },
      {
        title: `Sổ nhận thuốc`,
        link: "medication",
        // icon: <i className="fa-solid fa-capsules" />,
        icon: <i className="ka ka-medicine-bottle" />,
        permission: new Check().permission([86]),
      },
      // {
      //   title: "Kho ảnh",
      //   link: "abc",
      //   icon: <i className="fa-solid fa-images" />,
      //   permission: id_school === "1" ? true , // ** test V2
      // },
    ],
  },
  {
    title: "Môn học và học phí",
    link: "TuitionPages",
    icon: <i className="ka ka-subjects-tuition-fees" />,
    permission: new Check().permission([129, 134, 139, 144], "or"),
    subItems: [
      {
        title: "Phiếu thu",
        link: "bill",
        icon: <i className="ka ka-file-circle-dollar-circle-check " />,
        permission: new Check().permission([129]),
      },
      {
        title: `Môn học`,
        link: "subject",
        icon: <i className="ka ka-book" />,
        permission: new Check().permission([139]),
      },
      {
        title: `Nhóm môn học`,
        link: "study-group",
        icon: <i className="ka ka-books-pencil " />,
        permission: new Check().permission([144]),
      },
      {
        title: "Khoản thu",
        link: "tuition",
        icon: <i className="ka ka-hand-sack-dollar-up" />,
        permission: new Check().permission([134]),
      },
    ],
  },
  {
    title: "Nhân viên",
    link: "userPages",
    icon: <i className="ka ka-user-woman-shirt" />,
    permission: new Check().permission(["2", "111", "62"], "or"),
    subItems: [
      {
        title: "Danh sách",
        link: "user",
        icon: <i className="ka ka-file-list-user" />,
        permission: new Check().permission(["2"]),
      },
      {
        title: "Chức vụ",
        link: "role",
        icon: <i className="ka ka-user-tasks" />,
        permission: new Check().permission(["62"]),
      },
      {
        title: "Mẫu đánh giá",
        link: "evaluation",
        icon: <i className="ka ka-clipboard-list-check-pen" />,
        permission: new Check().permission(["111"]),
      },
    ],
  },
  {
    title: "Báo cáo",
    link: "reportPages",
    icon: <i className="ka ka-file-report" />,
    permission: new Check().permission(
      ["82", "83", "84", "89", "90", "91", "92"],
      "or"
    ),
    subItems: [
      {
        title: `Chi tiết sĩ số`,
        link: "report-student-attendance",
        icon: <i className="ka ka-file-check-list-user" />,
        permission: new Check().permission(["92"]),
      },
      {
        title: `Chi tiết cắt suất ăn`,
        link: "report-meal-cuts",
        icon: <i className="ka ka-file-chart-list-scissors" />,
        permission: new Check().permission(["82"]),
      },
      {
        title: "Chi phí nguyên liệu",
        link: "report-expense-ingredients",
        icon: <i className="ka ka-file-list-dollar-circle-box" />,
        permission: new Check().permission([84]),
      },
      {
        title: `Sổ tài chính`,
        link: "finance-book",
        icon: <i className="ka ka-book-dollar" />,
        permission: new Check().permission(["89"]),
      },
      {
        title: "Mua hàng theo NCC",
        link: "report-supplier",
        icon: <i className="ka ka-store-user-box" />,
        permission: new Check().permission(["91"]),
      },
      {
        title: `Tổng hợp đăng ký suất ăn`,
        link: "kitchen-report-register-meal",
        icon: <i className="fa-solid fa-file-lines" />,
        permission: new Check().permission([166]),
      },
    ],
  },
  {
    title: "Biểu mẫu",
    link: "Pages",
    icon: <i className="ka ka-book-pen" />,
    permission: new Check().permission(["77", "78", "79"], "or"),
    subItems: [
      {
        title: "Sổ 3 bước",
        link: "3-step-book",
        icon: <i className="ka ka-book-123" />,
        permission: new Check().permission(["77"]),
      },
      {
        title: "Sổ lưu mẫu ngày",
        link: "sample-book",
        icon: <i className="ka ka-book-day" />,
        permission: new Check().permission(["78"]),
      },
      {
        title: "Cài đặt biểu mẫu",
        link: "form-setting",
        icon: <i className="ka ka-book-gear" />,
        permission: new Check().permission(["79"]),
      },
    ],
  },
  {
    title: "Bài viết",
    link: "post",
    // icon: <i className="fa-solid fa-file-lines"></i>,
    icon: <i className="ka ka-newspaper" />,
    permission: new Check().permission([149, 154], "or"),
  },
  {
    title: "Cài đặt",
    link: "settingsPages",
    // icon: <i className="fa-solid fa-gears" />,
    icon: <i className="ka ka-gear-control" />,
    permission: new Check().permission(
      ["59", "67", "100", 159, 160, 161],
      "or"
    ),
    subItems: [
      {
        title: "Trường học",
        link: "school",
        // icon: <i className="fa-solid fa-school" />,
        icon: <i className="ka ka-school-flag" />,
        permission: new Check().permission([159]),
      },
      {
        title: "Năm học - Nghỉ lễ",
        link: "year-school",
        icon: <i className="ka ka-calendar-circle-check" />,
        permission: new Check().permission(["100"]),
      },
      {
        title: "Tiền ăn - Ngày học",
        link: "mainsetting",
        // icon: <i className="fa-solid fa-gear" />,
        icon: <i className="ka ka-calender-circle-dollar" />,
        permission: new Check().permission(["59"]),
      },
      {
        title: "Đơn vị tính",
        link: "unit",
        icon: <i className="ka ka-weigh-vegetables" />,
        permission: new Check().permission(["67"], "or"),
      },
      {
        title: "Thanh toán",
        link: "payment-setting",
        // icon: <i className="fa-solid fa-money-check-dollar" />,
        icon: <i className="ka ka-hands-holding-dollar" />,
        permission: new Check().permission([160]),
      },
      {
        title: "Thông báo",
        link: "notification",
        // icon: <i className="fa-solid fa-bell" />,
        icon: <i className="ka ka-comment-bell" />,
        permission: new Check().permission([161]),
      },
      {
        title: "Bếp ăn",
        link: "kitchen-deadline-register-meal",
        icon: <i className="ka ka-chef" />,
        permission: new Check().permission([165]),
      },
    ],
  },
];

const Sidebar = () => {
  const sidenavToggle = () => {
    const sbNavFixed = document.getElementById("sb-nav-fixed");
    let testClass = sbNavFixed.className;

    if (
      testClass === "sb-nav-fixed sb-sidenav-toggled" &&
      window.innerWidth < 992
    ) {
      sbNavFixed.classList.remove("sb-sidenav-toggled");
    }
  };

  return (
    <nav
      className="sb-sidenav accordion sb-sidenav-light"
      id="sidenavAccordion"
    >
      <div className="sb-sidenav-menu">
        <div className="nav">
          <NavLink className="nav-link bg-primary logo-sidebar" to="/admin-v2">
            {/* <img src={Logo} style={{ width: 69, height: 17 }} alt="Kafood" /> */}
            <LogoKafood width={80} height={50} />
          </NavLink>

          <NavLink
            className="nav-link"
            to="/admin-v2/dashboard"
            onClick={sidenavToggle}
          >
            <i className="ka ka-analytics me-1"></i>
            Tổng quan
          </NavLink>

          {sidebarItems
            .filter((item) => item.permission === true)
            .map((sidebarItem, sidebarIndex) => {
              if (sidebarItem.subItems?.length > 0)
                return (
                  <React.Fragment key={`sidebar_${sidebarIndex}`}>
                    {sidebarIndex === sidebarItems.length - 1 ? (
                      <div className="sb-sidenav-menu-heading">Hệ thống</div>
                    ) : (
                      <></>
                    )}

                    <Link
                      className="nav-link collapsed"
                      to="#"
                      data-bs-toggle="collapse"
                      data-bs-target={`#${sidebarItem.link}`}
                      aria-expanded="false"
                      aria-controls={sidebarItem.link}
                    >
                      <div className="me-1">{sidebarItem.icon}</div>
                      {sidebarItem.title}
                      <div className="sb-sidenav-collapse-arrow">
                        <i className="fas fa-angle-down"></i>
                      </div>
                    </Link>

                    <div
                      className="collapse"
                      id={sidebarItem.link}
                      aria-labelledby="headingTwo"
                      data-bs-parent="#sidenavAccordion"
                    >
                      <nav className="sb-sidenav-menu-nested nav accordion">
                        {sidebarItem?.subItems
                          ?.filter((item) => item.permission === true)
                          ?.map((subItem, subIndex) => {
                            return (
                              <NavLink
                                key={`sidebar_${sidebarIndex}_${subIndex}`}
                                className="nav-link"
                                to={`/admin-v2/${subItem.link}`}
                                onClick={sidenavToggle}
                              >
                                <div className="me-1">{subItem.icon}</div>
                                {subItem.title}
                              </NavLink>
                            );
                          })}
                      </nav>
                    </div>
                  </React.Fragment>
                );
              else
                return (
                  <NavLink
                    key={`sidebar_${sidebarIndex}`}
                    className="nav-link"
                    to={sidebarItem.link}
                  >
                    <div className="me-1">{sidebarItem.icon}</div>
                    {sidebarItem.title}
                  </NavLink>
                );
            })}
        </div>
      </div>
      <div className="sb-sidenav-footer py-2">
        <div className="small text-muted">
          Version: {process.env.REACT_APP_VERSION}
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
