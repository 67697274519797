import * as XLSX from "xlsx-js-style";
import { dateFormatter } from "../../utils/helpers";
import { groupArrayByField } from "../../utils/helpers";
const exportExpenseReport = (ingredientList) => {
  const title = ["Phiếu đi chợ"];
  const header = ["STT", "Ngày đi chợ", "Ngày lưu", "Tổng tiền"];
  const aoaAccounts = [
    title,
    header,
    ...ingredientList.map((voucherItem, index) => {
      return [
        index + 1,
        dateFormatter(new Date(voucherItem.vou_date * 1000)) || 0,
        dateFormatter(new Date(voucherItem.updated_at * 1000)) || 0,
        voucherItem.vou_price || 0,
      ];
    }),
    [
      "",
      "Tổng",
      "",
      ingredientList?.reduce((sum, account) => sum + account.vou_price, 0),
    ],
  ];
  var ws1 = XLSX.utils.aoa_to_sheet(aoaAccounts);
  const colAlpha = ["A", "B", "C", "D"];
  ws1[`A1`].s = {
    font: {
      name: "Times New Roman",
      sz: 16,
      bold: true,
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
    },
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      left: { style: "thin" },
      right: { style: "thin" },
    },
  };
  const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 3 } }];
  ws1["!merges"] = merge;
  colAlpha.forEach((alpha) => {
    ws1[`${alpha}2`].s = {
      font: {
        name: "Times New Roman",
        sz: 12,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      },
    };
  });

  for (let i = 0; i < ingredientList?.length; i++) {
    colAlpha.forEach((alpha) => {
      if (ws1[`${alpha}${3 + i}`].v === "empty") {
        ws1[`${alpha}${3 + i}`].v = " ";
      }
      ws1[`${alpha}${3 + i}`].s = {
        font: {
          name: "Times New Roman",
          sz: 12,
        },
        alignment: {
          vertical: "center",
          horizontal: alpha === "D" ? "right" : "center",
          wrapText: true,
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
        ...(alpha === "D" && { numFmt: "#,##0" }),
      };
    });
  }
  colAlpha.forEach((alpha, index) => {
    ws1[`${alpha}${3 + ingredientList?.length}`].s = {
      font: {
        name: "Times New Roman",
        sz: 12,
        bold: index === 1 || index === 3,
      },
      alignment: {
        vertical: "center",
        horizontal: alpha === "A" ? "center" : alpha === "D" ? "right" : "left",
      },
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      },
      ...(alpha === "D" && { numFmt: "#,##0" }),
    };
  });
  ws1["!cols"] = [{ wch: 5 }, { wch: 15 }, { wch: 15 }, { wch: 15 }];
  return ws1;
};

const exportIngredientReport = (listVoucher) => {
  let ingredientArray = [];

  listVoucher.map((voucherItem) => {
    ingredientArray = ingredientArray.concat(voucherItem.ingredents);
    return voucherItem;
  });

  const groupIngredientArray = groupArrayByField(
    ingredientArray,
    "id_ingredent"
  )
    ?.flatMap((item) => {
      return item;
    })
    ?.sort((a, b) => {
      if (a.ingredent_type === b.ingredent_type) {
        return a.ingredent_name?.localeCompare(b.ingredent_name);
      }
      return a.ingredent_type - b.ingredent_type;
    });

  const title = ["Nguyên liệu"];
  const header = [
    "STT",
    "Tên nguyên liệu",
    "Đơn vị tính",
    "Số lượng",
    "Tổng tiền",
  ];
  const aoaAccounts = [
    title,
    header,
    ...groupIngredientArray?.map((item, index) => {
      return [
        index + 1,
        item.ingredent_name || 0,
        item.unit_market_name || 0,
        item.ing_quantity || 0,
        item.ing_price * item.ing_quantity || 0,
      ];
    }),
    [
      "",
      "Tổng",
      "",
      "",
      groupIngredientArray?.reduce(
        (sum, account) => sum + account.ing_price * account.ing_quantity,
        0
      ) || 0,
    ],
  ];
  var ws1 = XLSX.utils.aoa_to_sheet(aoaAccounts);
  const colAlpha = ["A", "B", "C", "D", "E"];
  ws1[`A1`].s = {
    font: {
      name: "Times New Roman",
      sz: 16,
      bold: true,
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
    },
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      left: { style: "thin" },
      right: { style: "thin" },
    },
  };
  const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 4 } }];
  ws1["!merges"] = merge;
  colAlpha.forEach((alpha) => {
    ws1[`${alpha}2`].s = {
      font: {
        name: "Times New Roman",
        sz: 12,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      },
    };
  });

  for (let i = 0; i < groupIngredientArray?.length; i++) {
    colAlpha.forEach((alpha) => {
      if (ws1[`${alpha}${3 + i}`].v === "empty") {
        ws1[`${alpha}${3 + i}`].v = " ";
      }
      ws1[`${alpha}${3 + i}`].s = {
        font: {
          name: "Times New Roman",
          sz: 12,
        },
        alignment: {
          vertical: "center",
          horizontal:
            alpha === "D" || alpha === "E"
              ? "right"
              : alpha === "A"
              ? "center"
              : "left",
          wrapText: true,
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
        ...(alpha === "D" && { numFmt: "#,##0.00" }),
        ...(alpha === "E" && { numFmt: "#,##0" }),
      };
    });
  }
  colAlpha.forEach((alpha, index) => {
    ws1[`${alpha}${3 + groupIngredientArray?.length}`].s = {
      font: {
        name: "Times New Roman",
        sz: 12,
        bold: index === 1 || index === 3,
      },
      alignment: {
        vertical: "center",
        horizontal:
          alpha === "D" || alpha === "E"
            ? "right"
            : alpha === "A"
            ? "center"
            : "left",
      },
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      },
      ...(alpha === "E" && { numFmt: "#,##0" }),
    };
  });
  ws1["!cols"] = [
    { wch: 5 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
  ];
  return ws1;
};
export { exportExpenseReport, exportIngredientReport };
