import React from "react";
import { Table } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import { dateFormatter } from "../utils/helpers";

const ProductPlanExport = ({ listQuantitative: data, selectedDay }) => {
  if (!data) {
    return "";
  }

  const renderTable = (data) => {
    let quantitative = data;
    const filteredCostPerDays = quantitative?.costPerDays;

    const updatedTimes = quantitative?.times?.map((time) => ({
      ...time,
      foods: time.foods?.map((f) => ({
        ...f,
        groups: f.groups?.map((g) => ({
          ...g,
          ingredents: g.ingredents,
        })),
      })),
    }));

    quantitative = {
      ...quantitative,
      costPerDays: filteredCostPerDays,
      times: updatedTimes,
    };
    const id_to_day = updatedTimes[0]?.id_day || "";

    return (
      <div className="table-responsive-100">
        <Table bordered>
          <thead>
            <tr>
              <th colSpan={7} className="text-center">
                BẢNG ĐỊNH LƯỢNG CHI TIẾT{" "}
                {dateFormatter(new Date(quantitative.menu_date * 1000))}
              </th>
            </tr>
            <tr>
              <th colSpan={7}></th>
            </tr>
            <tr className="text-center tr-sticky-first">
              <th>CA</th>
              <th>TÊN MÓN</th>
              <th>SỐ PHẦN</th>
              <th>THÀNH PHẦN</th>
              <th>QUY CÁCH</th>
              <th>ĐỊNH LƯỢNG (g)</th>
              <th>KHỐI LƯỢNG (kg)</th>
            </tr>
          </thead>
          <tbody>
            {quantitative?.times?.map((timeItem, timeIndex) => {
              const countIngredientByTime = timeItem.foods?.reduce(
                (foodSum, food) => {
                  const group = food.groups[0];
                  const groupIngredientCount =
                    timeItem.id_day === id_to_day
                      ? group?.ingredents?.length || 0
                      : group?.ingredents?.filter(
                          (ingredient) => ingredient.is_main === 1
                        )?.length || 0;

                  return foodSum + groupIngredientCount;
                },
                0
              );

              if (timeItem?.foods?.length === 0) {
                return (
                  <tr key={timeItem.id}>
                    <td className="align-items-center">{timeItem.time_name}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                );
              }

              return (
                <React.Fragment key={timeItem.id}>
                  {timeItem?.foods?.length > 0 &&
                    timeItem.foods?.map((foodItem, foodIndex) => {
                      let numberOfMeals = 0;
                      let ingredients = [];
                      if (id_to_day === timeItem.id_day) {
                        numberOfMeals =
                          foodItem?.groups?.reduce(
                            (groupSum, group) =>
                              groupSum + (group.totalRegister || 0),
                            0
                          ) || 0;

                        ingredients = foodItem.groups[0]?.ingredents || [];
                      } else {
                        numberOfMeals =
                          foodItem?.groups?.reduce(
                            (groupSum, group) =>
                              groupSum + (group.more_meal || 0),
                            0
                          ) || 0;

                        ingredients =
                          foodItem.groups[0]?.ingredents.filter(
                            (ingredient) => ingredient.is_main === 1
                          ) || [];
                      }

                      return (
                        <React.Fragment key={foodItem.id}>
                          {ingredients?.map((ingredientItem, ingIndex) => {
                            const countIngredientByFood =
                              timeItem.id_day === id_to_day
                                ? foodItem.groups[0]?.ingredents?.length || 1
                                : foodItem.groups[0]?.ingredents.filter(
                                    (ingredient) => ingredient.is_main === 1
                                  )?.length || 1;

                            const qtyByUnitCook =
                              Number(ingredientItem.ing_quantity).toFixed(1) ||
                              0;

                            const totalQtyByUnitMarket =
                              (qtyByUnitCook / ingredientItem.volume_cook) *
                              numberOfMeals;

                            return (
                              <tr key={ingredientItem.id}>
                                {foodIndex === 0 && ingIndex === 0 ? (
                                  <td
                                    rowSpan={countIngredientByTime}
                                    className="align-content-center"
                                  >
                                    {timeItem.time_name}
                                  </td>
                                ) : (
                                  <></>
                                )}

                                {ingIndex === 0 ? (
                                  <>
                                    <td
                                      rowSpan={countIngredientByFood}
                                      className="align-content-center"
                                    >
                                      {foodItem.food_name}
                                    </td>
                                    <td
                                      rowSpan={countIngredientByFood}
                                      className="text-center align-content-center"
                                    >
                                      <CurrencyFormat
                                        value={numberOfMeals}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        decimalScale={0}
                                      />
                                    </td>
                                  </>
                                ) : (
                                  <></>
                                )}
                                <td>{ingredientItem.ingredent_name || ""}</td>
                                <td></td>
                                <td className="text-end">
                                  <CurrencyFormat
                                    value={ingredientItem.ing_quantity}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    decimalScale={1}
                                    fixedDecimalScale={true}
                                  />
                                </td>
                                <td className="text-end">
                                  <CurrencyFormat
                                    value={totalQtyByUnitMarket}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    decimalScale={1}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </React.Fragment>
                      );
                    })}
                </React.Fragment>
              );
            })}
          </tbody>
        </Table>

        <Table
          className="d-none"
          bordered
          id={`data-${quantitative.day_name}-${quantitative.id}`}
        >
          <thead>
            <tr>
              <th className="text-center">
                BẢNG ĐỊNH LƯỢNG CHI TIẾT{" "}
                {dateFormatter(new Date(quantitative.menu_date * 1000))}
              </th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
            </tr>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
            <tr className="text-center">
              <th>CA</th>
              <th>TÊN MÓN</th>
              <th>SỐ PHẦN</th>
              <th>THÀNH PHẦN</th>
              <th>QUY CÁCH</th>
              <th>ĐỊNH LƯỢNG (g)</th>
              <th>KHỐI LƯỢNG (kg)</th>
            </tr>
          </thead>
          <tbody>
            {quantitative?.times?.map((timeItem, timeIndex) => {
              if (timeItem?.foods?.length === 0) {
                return (
                  <tr key={timeItem.id}>
                    <td>{timeItem.time_name}</td>
                    <td>empty</td>
                    <td>empty</td>
                    <td>empty</td>
                    <td>empty</td>
                    <td>empty</td>
                    <td>empty</td>
                  </tr>
                );
              }

              return (
                <React.Fragment key={timeItem.id}>
                  {timeItem?.foods?.length > 0 &&
                    timeItem.foods?.map((foodItem, foodIndex) => {
                      let numberOfMeals = 0;
                      let ingredients = [];
                      if (id_to_day === timeItem.id_day) {
                        numberOfMeals =
                          foodItem?.groups?.reduce(
                            (groupSum, group) =>
                              groupSum + (group.totalRegister || 0),
                            0
                          ) || 0;

                        ingredients = foodItem.groups[0]?.ingredents || [];
                      } else {
                        numberOfMeals =
                          foodItem?.groups?.reduce(
                            (groupSum, group) =>
                              groupSum + (group.more_meal || 0),
                            0
                          ) || 0;

                        ingredients =
                          foodItem.groups[0]?.ingredents.filter(
                            (ingredient) => ingredient.is_main === 1
                          ) || [];
                      }

                      return (
                        <React.Fragment key={foodItem.id}>
                          {ingredients?.map((ingredientItem, ingIndex) => {
                            const countIngredientByFood =
                              timeItem.id_day === id_to_day
                                ? foodItem.groups[0]?.ingredents?.length || 1
                                : foodItem.groups[0]?.ingredents.filter(
                                    (ingredient) => ingredient.is_main === 1
                                  )?.length || 1;

                            const qtyByUnitCook =
                              Number(ingredientItem.ing_quantity).toFixed(1) ||
                              0;

                            const totalQtyByUnitMarket =
                              (qtyByUnitCook / ingredientItem.volume_cook) *
                              numberOfMeals;

                            return (
                              <tr key={ingredientItem.id}>
                                {foodIndex === 0 && ingIndex === 0 ? (
                                  <td>{timeItem.time_name}</td>
                                ) : (
                                  <td>empty</td>
                                )}

                                {ingIndex === 0 ? (
                                  <>
                                    <td>{foodItem.food_name}</td>
                                    <td className="text-center">
                                      <CurrencyFormat
                                        value={numberOfMeals}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        decimalScale={0}
                                      />
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td>empty</td>
                                    <td>empty</td>
                                  </>
                                )}
                                <td>{ingredientItem.ingredent_name || ""}</td>
                                <td>empty</td>
                                <td className="text-end">
                                  <CurrencyFormat
                                    value={ingredientItem.ing_quantity}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    decimalScale={1}
                                    fixedDecimalScale={true}
                                  />
                                </td>
                                <td className="text-end">
                                  <CurrencyFormat
                                    value={totalQtyByUnitMarket}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    decimalScale={1}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </React.Fragment>
                      );
                    })}
                </React.Fragment>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  };

  return (
    <>
      {data
        ?.filter((e) => Number(e.id_day) === Number(selectedDay))
        ?.map((item) => {
          return renderTable(item);
        })}
    </>
  );
};

export default ProductPlanExport;
