import { Button } from "react-bootstrap";
import api from "../axiosInstance";
import FillExcelTemplate from "./FillExcelTemplate";

const TestFiles = () => {
  //20250210 Export file
  const handleExportPortion = async () => {
    try {
      // Gọi API từ Node.js để tải file Excel
      const response = await api.get(
        `/file-report-template-download?code=FILE_0001`,
        {
          responseType: "blob",
        }
      );

      FillExcelTemplate(
        response.data,
        {
          ngay_thang: "Thứ 2; ngày 01/01/2025",
          do_tuoi: "Mẫu giáo",
          tong_so_tre: 12,
          thuc_don: `Ăn Sáng:\n- Bạch tuộc xào sa tế\n- Bạch tuộc xào chua ngọt\n- Bắp bò kho mật mía\n- Bẹ gà kho nước mắm\n- Bò bít tết\n- Bạch tuộc xào cần hành\n- Cá basa kho khóm\nĂn Phụ sáng:\n- Bò xay chiên bơ\n- Bạch tuộc xào cần hành\n- Bẹ gà kho hành tây\n- Bẹ gà kho nước mắm\n- Bò bít tết\n- Bánh canh tôm + Thịt 2\nĂn Trưa:\n- Bạch tuộc xào cần hành\n- Bánh bao\nĂn Tối:\n
`,
          chuc_danh_nguoi_lap: "Người lập",
          chuc_danh_nguoi_phe_duyet: "Hiệu trưởng",
          nguyen_lieu: [
            {
              ten_nguyen_lieu: "Mật đá",
              nang_luong: 100,
              chat_dam_dv: 11.4,
              chat_dam_tv: 11.3,
              chat_bot_duong: 20,
              an_duoc: 100,
              di_cho: 150000,
            },
            {
              ten_nguyen_lieu: "Nước",
              nang_luong: 50,
              chat_dam_dv: 11.4,
              chat_dam_tv: 5.5,
              chat_beo_dv: 10,
              an_duoc: 100,
            },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Nước", nang_luong: 50, chat_dam_tv: 5.5 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            {
              ten_nguyen_lieu: "Gia vị 2",
              nang_luong: 200,
              chat_bot_duong: 20,
              chat_dam_dv: 20,
              chat_dam_tv: 5.5,
            },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            {
              ten_nguyen_lieu: "Gia vị",
              nang_luong: 200,
              chat_dam_tv: 20,
              di_cho: 150000,
            },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            {
              ten_nguyen_lieu: "Gia vị",
              nang_luong: 200,
              chat_dam_tv: 20,
              di_cho: 170000,
            },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
            { ten_nguyen_lieu: "Gia vị", nang_luong: 200, chat_dam_tv: 20 },
          ],
          mon_an: [
            {
              ten_mon: "Trứng chiên",
              don_vi: "Phần",
              gia_mon: 15,
            },
            {
              ten_mon: "Cơm chiên",
              don_vi: "Phần",
              gia_mon: 20,
            },
          ],
        },
        "exported_template.xlsx"
      );
    } catch (error) {
      console.error("Error downloading Excel file:", error);
    }
  };

  //20250210 Export file

  const downloadFile = async (code = "FILE_0001") => {
    try {
      const response = await api.get(
        `/file-report-template-download?code=${code}`,
        {
          responseType: "blob",
        }
      );

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = `${code}.xlsx`;
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading Excel file:", error);
    }
  };

  return (
    <>
      <Button
        size="sm"
        variant="success"
        onClick={() => handleExportPortion()}
        className="me-2"
      >
        <i className="fa-solid fa-file-export" /> Xuất file test
      </Button>

      <Button
        size="sm"
        variant="success"
        onClick={() => downloadFile()}
        className="me-2"
      >
        <i className="fa-solid fa-file-export" /> Download File
      </Button>
    </>
  );
};

export default TestFiles;
