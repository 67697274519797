import React, { useState } from "react";
import { Button, Table } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import { Link } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import swal from "sweetalert";

const FoodQuantitativeTable = ({
  quantitative,
  setQuantitative,
  selectedClassGroup,
  ingredients,
}) => {
  if (!quantitative) return <></>;

  const times = quantitative?.times;

  return (
    <Table bordered responsive>
      <thead className="align-middle text-center bg-light">
        <tr>
          <th style={{ width: "10rem" }}>Buổi</th>
          <th style={{ width: "10rem" }}>Danh mục</th>
          <th style={{ minWidth: "20rem" }}>Món ăn</th>
          <th style={{ width: "10rem" }}>Dự trù (phần)</th>
          <th style={{ width: "10rem" }}>Giá món (đ)</th>
        </tr>
      </thead>
      <tbody>
        {times?.map((timeItem, timeIndex) => {
          const groupId = selectedClassGroup.id;

          const foods = timeItem.foods?.filter((food) =>
            food.groups.some((group) => group.id === groupId)
          );

          return (
            <React.Fragment key={`time-item-${timeIndex}`}>
              {foods.length === 0 ? (
                <tr key={`time-item-${timeIndex}`} className="align-middle">
                  <td className="fw-bold">{timeItem.time_name}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              ) : (
                foods.map((foodItem, foodIndex) => {
                  const foodId = foodItem.id;
                  const group =
                    foodItem?.groups.find(
                      (groupItem) => groupItem.id === groupId
                    ) || {};

                  const ingredents = group?.ingredents || [];

                  const totalMoney = ingredents.reduce((total, ingredient) => {
                    const ing_quantity = Number(
                      ingredient.ing_quantity
                    ).toFixed(1);

                    const volume_cook = parseFloat(ingredient.volume_cook);

                    const ing_price_expect = parseFloat(
                      Number(ingredient.ing_price_expect).toFixed(0)
                    );

                    const money =
                      Number(
                        (parseFloat(ing_quantity) *
                          parseFloat(ing_price_expect)) /
                          parseFloat(volume_cook)
                      ).toFixed(0) || 0;

                    return total + parseInt(Number(money).toFixed(0));
                  }, 0);

                  return (
                    <>
                      <React.Fragment
                        key={`time-item-${timeIndex}-${foodIndex}`}
                      >
                        <tr className="align-middle">
                          {foodIndex === 0 ? (
                            <td rowSpan={foods.length * 2} className="fw-bold">
                              {timeItem.time_name}
                            </td>
                          ) : (
                            <></>
                          )}
                          <td>{foodItem.category_name}</td>
                          <td>
                            <Link
                              className="d-flex justify-content-between align-content-center text-dark"
                              data-bs-toggle="collapse"
                              aria-expanded="false"
                              to={`#ingredients-${timeItem.id}-${foodItem.id}`}
                              title="Xem chi tiết nguyên liệu"
                            >
                              <div
                                className="d-flex justify-content-between"
                                style={{ width: "100%" }}
                              >
                                <span>
                                  <i className="ms-2 me-2 fas fa-angle-down" />
                                  <strong>{foodItem.food_name}</strong>
                                </span>
                              </div>
                            </Link>
                          </td>
                          <td className="text-end">
                            <CurrencyFormat
                              value={group?.more_meal || 0}
                              thousandSeparator={true}
                              displayType="input"
                              allowNegative={false}
                              decimalScale={2}
                              className="form-control text-end"
                              onValueChange={(values) => {
                                setQuantitative({
                                  ...quantitative,
                                  times: times?.map((timeObj) => {
                                    const timeId = timeObj.id;
                                    const findGroup = times
                                      .filter((time) => time.id === timeId)
                                      .flatMap((time) => time.foods)
                                      .filter((food) => food.id === foodId)
                                      .flatMap((food) => food.groups)
                                      .find((group) => group.id === groupId);

                                    if (findGroup && timeItem.id === timeId) {
                                      return {
                                        ...timeObj,
                                        foods: timeObj.foods?.map(
                                          (foodObj, foodIndex) => {
                                            if (foodObj.id === foodId) {
                                              return {
                                                ...foodItem,
                                                groups: foodItem.groups?.map(
                                                  (groupObj) => {
                                                    if (
                                                      groupObj.id === groupId
                                                    ) {
                                                      return {
                                                        ...groupObj,
                                                        more_meal: values.value,
                                                      };
                                                    }

                                                    return groupObj;
                                                  }
                                                ),
                                              };
                                            }

                                            return foodObj;
                                          }
                                        ),
                                      };
                                    }

                                    return timeObj;
                                  }),
                                });
                              }}
                            />
                          </td>
                          <td className="text-end">
                            <CurrencyFormat
                              value={totalMoney}
                              thousandSeparator={true}
                              displayType="text"
                              allowNegative={false}
                              decimalScale={0}
                              className="fw-bold"
                            />
                          </td>
                        </tr>

                        <tr>
                          <td
                            colSpan={4}
                            id={`ingredients-${timeItem.id}-${foodItem.id}`}
                            className="collapse"
                          >
                            <Table bordered hover className="">
                              <thead>
                                <tr className="text-center bg-light">
                                  <th>STT</th>
                                  <th>Nguyên liệu</th>
                                  <th style={{ width: "10rem" }}>Đơn vị</th>
                                  <th style={{ width: "10rem" }}>Định lượng</th>
                                  <th style={{ width: "10rem" }}>Đơn giá</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {ingredents.map(
                                  (ingredientItem, ingredientIndex) => {
                                    const ingredientId = ingredientItem.id;
                                    const ing_quantity =
                                      ingredientItem.ing_quantity;

                                    return (
                                      <tr
                                        key={`ingredient-item-${timeIndex}-${foodIndex}-${ingredientIndex}`}
                                        className="align-middle"
                                      >
                                        <td className="p-1 text-center">
                                          {ingredientIndex + 1}
                                        </td>
                                        <td className="p-1">
                                          {ingredientItem.ingredent_name}
                                        </td>
                                        <td className="p-1 ">
                                          {ingredientItem.unit_cook_name}
                                        </td>
                                        <td className="p-1 text-end">
                                          <CurrencyFormat
                                            value={ing_quantity}
                                            thousandSeparator={true}
                                            displayType="input"
                                            allowNegative={false}
                                            decimalScale={2}
                                            className="form-control text-end"
                                            onValueChange={(values) => {
                                              setQuantitative({
                                                ...quantitative,
                                                times: times?.map((timeObj) => {
                                                  const timeId = timeObj.id;
                                                  const findGroup = times
                                                    .filter(
                                                      (time) =>
                                                        time.id === timeId
                                                    )
                                                    .flatMap(
                                                      (time) => time.foods
                                                    )
                                                    .filter(
                                                      (food) =>
                                                        food.id === foodId
                                                    )
                                                    .flatMap(
                                                      (food) => food.groups
                                                    )
                                                    .find(
                                                      (group) =>
                                                        group.id === groupId
                                                    );

                                                  if (findGroup) {
                                                    return {
                                                      ...timeObj,
                                                      foods: timeObj.foods?.map(
                                                        (
                                                          foodObj,
                                                          foodIndex
                                                        ) => {
                                                          if (
                                                            foodObj.id ===
                                                            foodId
                                                          ) {
                                                            return {
                                                              ...foodItem,
                                                              groups:
                                                                foodItem.groups?.map(
                                                                  (
                                                                    groupObj
                                                                  ) => {
                                                                    return {
                                                                      ...groupObj,
                                                                      ingredents:
                                                                        groupObj.ingredents?.map(
                                                                          (
                                                                            ingredientObj
                                                                          ) => {
                                                                            if (
                                                                              ingredientObj.id ===
                                                                              ingredientId
                                                                            ) {
                                                                              return {
                                                                                ...ingredientObj,
                                                                                ing_quantity:
                                                                                  values.value,
                                                                              };
                                                                            }

                                                                            return ingredientObj;
                                                                          }
                                                                        ),

                                                                      more_meal:
                                                                        findGroup.more_meal,
                                                                    };
                                                                    // }

                                                                    // return groupObj;
                                                                  }
                                                                ),
                                                            };
                                                          }

                                                          return foodObj;
                                                        }
                                                      ),
                                                    };
                                                  }

                                                  return timeObj;
                                                }),
                                              });
                                            }}
                                          />
                                        </td>
                                        <td className="p-1 text-end">
                                          <CurrencyFormat
                                            value={
                                              ingredientItem.ing_price_expect ||
                                              0
                                            }
                                            thousandSeparator={true}
                                            displayType="input"
                                            allowNegative={false}
                                            decimalScale={2}
                                            className="form-control text-end"
                                            suffix={` đ/${ingredientItem.unit_market_name}`}
                                            onValueChange={(values) => {
                                              setQuantitative({
                                                ...quantitative,
                                                times: quantitative.times?.map(
                                                  (timeItem) => {
                                                    return {
                                                      ...timeItem,
                                                      foods:
                                                        timeItem.foods?.map(
                                                          (foodItem) => {
                                                            return {
                                                              ...foodItem,
                                                              groups:
                                                                foodItem.groups?.map(
                                                                  (
                                                                    groupItem
                                                                  ) => {
                                                                    return {
                                                                      ...groupItem,
                                                                      ingredents:
                                                                        groupItem.ingredents?.map(
                                                                          (
                                                                            ingredientObj
                                                                          ) => {
                                                                            if (
                                                                              ingredientObj.id ===
                                                                              ingredientItem.id
                                                                            ) {
                                                                              return {
                                                                                ...ingredientObj,
                                                                                ing_price_expect:
                                                                                  values.value,
                                                                              };
                                                                            }
                                                                            return ingredientObj;
                                                                          }
                                                                        ),
                                                                    };
                                                                  }
                                                                ),
                                                            };
                                                          }
                                                        ),
                                                    };
                                                  }
                                                ),
                                              });
                                            }}
                                          />
                                        </td>
                                        <td className="p-1 text-center">
                                          <Button
                                            variant="outline-danger"
                                            size="sm"
                                            title="Xóa nguyên liệu khỏi món"
                                            onClick={() => {
                                              swal({
                                                title: `Bạn muốn xóa nguyên liệu?`,
                                                icon: "error",
                                                buttons: ["Đóng", "Xóa"],
                                                dangerMode: true,
                                              }).then((ok) => {
                                                if (ok) {
                                                  setQuantitative({
                                                    ...quantitative,
                                                    times: times?.map(
                                                      (timeObj) => {
                                                        const timeId =
                                                          timeObj.id;
                                                        const findGroup = times
                                                          .filter(
                                                            (time) =>
                                                              time.id === timeId
                                                          )
                                                          .flatMap(
                                                            (time) => time.foods
                                                          )
                                                          .filter(
                                                            (food) =>
                                                              food.id === foodId
                                                          )
                                                          .flatMap(
                                                            (food) =>
                                                              food.groups
                                                          )
                                                          .find(
                                                            (group) =>
                                                              group.id ===
                                                                groupId &&
                                                              group.ingredents.some(
                                                                (ingredient) =>
                                                                  ingredient.id ===
                                                                  ingredientId
                                                              )
                                                          );

                                                        if (findGroup) {
                                                          return {
                                                            ...timeObj,
                                                            foods:
                                                              timeObj.foods?.map(
                                                                (
                                                                  foodObj,
                                                                  foodIndex
                                                                ) => {
                                                                  if (
                                                                    foodObj.id ===
                                                                    foodId
                                                                  ) {
                                                                    return {
                                                                      ...foodItem,
                                                                      groups:
                                                                        foodItem.groups?.map(
                                                                          (
                                                                            groupObj
                                                                          ) => {
                                                                            if (
                                                                              groupObj.id ===
                                                                              groupId
                                                                            ) {
                                                                              if (
                                                                                groupObj
                                                                                  ?.ingredents
                                                                                  ?.length >
                                                                                1
                                                                              ) {
                                                                                return {
                                                                                  ...groupObj,
                                                                                  ingredents:
                                                                                    groupObj.ingredents?.filter(
                                                                                      (
                                                                                        ingredientObj
                                                                                      ) =>
                                                                                        ingredientObj.id !==
                                                                                        ingredientId
                                                                                    ),
                                                                                  more_meal:
                                                                                    findGroup.more_meal,
                                                                                };
                                                                              } else {
                                                                                toast(
                                                                                  "Món ăn phải có ít nhất 1 nguyên liệu.",
                                                                                  {
                                                                                    type: "error",
                                                                                  }
                                                                                );
                                                                                return groupObj;
                                                                              }
                                                                            }

                                                                            return groupObj;
                                                                          }
                                                                        ),
                                                                    };
                                                                  }

                                                                  return foodObj;
                                                                }
                                                              ),
                                                          };
                                                        }
                                                        return timeObj;
                                                      }
                                                    ),
                                                  });
                                                }
                                              });
                                            }}
                                          >
                                            <i className="fa-solid fa-times" />
                                          </Button>
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}

                                <tr>
                                  <td colSpan={6} className="px-0 px-md-5">
                                    <Select
                                      options={ingredients.filter(
                                        (a) =>
                                          !ingredents.some((b) => a.id === b.id)
                                      )}
                                      className="text-start mx-0 mx-md-5"
                                      getOptionLabel={(option) =>
                                        option.ingredent_name
                                      }
                                      getOptionValue={(option) => option.id}
                                      placeholder={<>Chọn nguyên liệu</>}
                                      value={"Chọn nguyên liệu"}
                                      onChange={(choice) => {
                                        const ingredientId = choice.id;
                                        const ingredientAdd = {
                                          ...choice,
                                          ing_quantity: 0,
                                          tmp_ing_quantity: 0,
                                        };

                                        setQuantitative({
                                          ...quantitative,
                                          times: times?.map((timeObj) => {
                                            const timeId = timeObj.id;

                                            const findGroup = times
                                              .filter(
                                                (time) => time.id === timeId
                                              )
                                              .flatMap((time) => time.foods)
                                              .filter(
                                                (food) => food.id === foodId
                                              )
                                              .flatMap((food) => food.groups)
                                              .find(
                                                (group) => group.id === groupId
                                              );

                                            if (findGroup) {
                                              return {
                                                ...timeObj,
                                                foods: timeObj.foods?.map(
                                                  (foodObj, foodIndex) => {
                                                    if (foodObj.id === foodId) {
                                                      return {
                                                        ...foodItem,
                                                        groups:
                                                          foodItem.groups?.map(
                                                            (groupObj) => {
                                                              const checkIssetIngredient =
                                                                groupObj.ingredents?.some(
                                                                  (
                                                                    ingredient
                                                                  ) =>
                                                                    ingredient.id ===
                                                                    ingredientId
                                                                );

                                                              if (
                                                                !checkIssetIngredient
                                                              ) {
                                                                let ingredentsAdd =
                                                                  groupObj.ingredents;

                                                                ingredentsAdd.push(
                                                                  ingredientAdd
                                                                );

                                                                return {
                                                                  ...groupObj,
                                                                  ingredents:
                                                                    ingredentsAdd,
                                                                  more_meal:
                                                                    findGroup.more_meal,
                                                                };
                                                              }
                                                              return {
                                                                ...groupObj,
                                                                more_meal:
                                                                  findGroup.more_meal,
                                                              };
                                                            }
                                                          ),
                                                      };
                                                    }
                                                    return foodObj;
                                                  }
                                                ),
                                              };
                                            }

                                            return timeObj;
                                          }),
                                        });
                                      }}
                                      menuPortalTarget={document.querySelector(
                                        "body"
                                      )}
                                      styles={{
                                        menuPortal: (base) => ({
                                          ...base,
                                          zIndex: 9999,
                                        }),
                                      }}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </td>
                        </tr>
                      </React.Fragment>
                    </>
                  );
                })
              )}
            </React.Fragment>
          );
        })}
      </tbody>
    </Table>
  );
};

export default FoodQuantitativeTable;
