import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx-js-style";
import { toast } from "react-toastify";
import {
  Card,
  Container,
  Table,
  Button,
  Col,
  Row,
  Spinner,
} from "react-bootstrap";
import Select from "react-select";
import swal from "sweetalert";
import CurrencyFormat from "react-currency-format";
import DatePicker from "react-datepicker";
import { vi } from "date-fns/locale";
import { startOfWeek, endOfWeek } from "date-fns";
import { groupArrayByField, priceFormatter } from "../../../utils/helpers";
import { exportNutritionWeek } from "./exportFunctions";
import { EAT_URBAN_AREAS, CALORIES } from "../../../../../constants";
import api from "../../../../../helper/axiosInstance";

const qualified = { color: "green" };
const unqualified = { color: "red" };

const NutritionWeekReport = () => {
  const [listQuantitatives, setListQuantitatives] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [classGroups, setClassGroups] = useState([]);
  const [selectedClassGroup, setSelectedClassGroups] = useState({});

  const [startDate, setStartDate] = useState(new Date());
  const [termDate, setTermDate] = useState(new Date());

  useEffect(() => {
    let initialWeek = setDatesOfWeek(new Date());

    getListQuantitatives(initialWeek[0], initialWeek[6]);
    getListClassGroups();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const CustomInput = ({ value, onClick }) => (
    <Button variant="primary" onClick={onClick}>
      <i className="fa-solid fa-calendar" />
    </Button>
  );
  // API
  const getListQuantitatives = async (startDate, endDate) => {
    setIsLoading(true);
    setListQuantitatives([]);
    await api
      .get(`menu-show`, {
        params: {
          start_at: startDate.getTime() / 1000,
          term_at: endDate.getTime() / 1000,
        },
      })
      .then((res) => {
        setIsLoading(false);

        if (res.data.errCode === 0) {
          const newListQuantitatives = res.data.days.map((quantitativeItem) => {
            return {
              ...quantitativeItem,
              times: quantitativeItem.times?.map((timeItem) => {
                return {
                  ...timeItem,
                  foods: timeItem.foods?.map((foodItem) => {
                    return {
                      ...foodItem,
                      groups: foodItem.groups?.map((groupItem) => {
                        return {
                          ...groupItem,
                          ingredents:
                            groupItem.more_meal === null
                              ? groupItem.ingredents
                              : [], //Là TH Nấu riêng nên không TKPA => []
                        };
                      }),
                    };
                  }),
                };
              }),
            };
          });

          setListQuantitatives(newListQuantitatives);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((error) => {});
  };

  const getListClassGroups = async () => {
    await api
      .get(`/group-show`)
      .then((res) => {
        if (res.data.errCode === 0) {
          setClassGroups(res.data.groups);
          setSelectedClassGroups(res.data.groups[0]);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((error) => {});
  };
  // API

  // Logic
  const exportExcel = () => {
    const wb = XLSX.utils.book_new();

    let ingredientArray = [];
    let menuWeekReport = [];

    listQuantitatives.map((quantitativeItem) => {
      return quantitativeItem.times?.map((timeItem) => {
        timeItem.foods?.map((foodItem) => {
          foodItem.groups
            ?.find((groupItem) => groupItem.id === selectedClassGroup.id)
            ?.ingredents?.map((ingredientItem) => {
              ingredientArray.push(ingredientItem);
              return ingredientItem;
            });
          return foodItem;
        });
        return timeItem;
      });
    });

    const groupIngredientArray = groupArrayByField(ingredientArray, "id");

    listQuantitatives?.map((dayItem) => {
      if (
        !dayItem.costPerDays.find(
          (costPerDay) => costPerDay.meal_quantity !== 0
        )
      ) {
        return dayItem;
      }
      menuWeekReport.push(
        `${dayItem.day_name}: ${stringLastDate(
          new Date(dayItem.menu_date * 1000)
        )}`
      );
      dayItem.times?.map((timeItem) => {
        menuWeekReport.push(
          `${timeItem.time_name}:${timeItem.foods.map((foodItem) => {
            return " " + foodItem.food_name;
          })}`.replaceAll(",", " - ")
        );
        return timeItem;
      });
      return dayItem;
    });

    if (menuWeekReport.length > groupIngredientArray.length) {
      for (
        let i = 0;
        i < menuWeekReport.length - groupIngredientArray.length;
        i++
      ) {
        groupIngredientArray.push([{ id: i + menuWeekReport }]);
      }
    }

    const ws = exportNutritionWeek(
      groupIngredientArray.length,
      listQuantitatives.length
    );

    XLSX.utils.book_append_sheet(wb, ws, "Khẩu phần dinh dưỡng tuần");
    XLSX.writeFile(
      wb,
      `Khẩu phần dinh dưỡng tuần (${stringFirstDate(
        startDate
      )} - ${stringLastDate(termDate)}).xlsx`
    );
  };

  const handleXportNutrionWeekReport = () => {
    swal({
      title: `Bạn muốn xuất file dinh dưỡng tuần`,
      text: `${stringFirstDate(startDate)} - ${stringLastDate(termDate)}`,
      icon: "info",
      buttons: ["Đóng", "Xuất"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        exportExcel();
      }
    });
  };

  const datesOfWeek = (current) => {
    var week = [];
    // Starting Monday not Sunday
    current.setDate(current.getDate() - current.getDay() + 1);
    current.setHours(0, 0, 0, 0);
    for (var i = 0; i < 7; i++) {
      week.push(new Date(current));
      current.setDate(current.getDate() + 1);
      current.setHours(0, 0, 0, 0);
    }
    return week;
  };
  const setDatesOfWeek = (theDate) => {
    const week = datesOfWeek(theDate);

    setStartDate(week[0]);
    setTermDate(week[week.length - 1]);
    return week;
  };

  const stringFirstDate = (date) =>
    ("0" + date.getDate()).slice(-2) +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2);
  const stringLastDate = (date) =>
    ("0" + date.getDate()).slice(-2) +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "/" +
    date.getFullYear();
  // Logic

  const NutritionWeekReport = () => {
    const numberDate = listQuantitatives.filter((quantitativeItem) =>
      quantitativeItem.costPerDays.find(
        (costPerDay) => costPerDay.meal_quantity > 0
      )
    ).length;

    let totalMealQuantity = 0;
    let totalCostInWeek = 0;
    let ingredientArray = [];
    let menuWeekReport = [];

    listQuantitatives.map((quantitativeItem) => {
      const numberChildren =
        quantitativeItem.costPerDays.find(
          (costPerDay) => costPerDay.id_group === selectedClassGroup.id
        )?.meal_quantity || 0;

      return quantitativeItem.times?.map((timeItem) => {
        timeItem.foods?.map((foodItem) => {
          foodItem.groups
            ?.find((groupItem) => groupItem.id === selectedClassGroup.id)
            ?.ingredents?.map((ingredientItem) => {
              const ingredientQuantity = ingredientItem.ing_quantity;
              totalCostInWeek += Math.round(
                Number(
                  (ingredientQuantity *
                    numberChildren *
                    ingredientItem.ing_price_expect) /
                    ingredientItem.volume_cook
                )
              );

              ingredientArray.push({
                ...ingredientItem,
                id_day: quantitativeItem.id_day,
              });
              return ingredientItem;
            });
          return foodItem;
        });
        return timeItem;
      });
    });

    const groupIngredientArray = groupArrayByField(ingredientArray, "id").sort(
      (a, b) => {
        let amountA = a.reduce((accumulator, currentValue) => {
          return Number(
            accumulator +
              (currentValue.ing_quantity * currentValue.volume_market) /
                currentValue.volume_cook
          );
        }, 0);

        let amountB = b.reduce((accumulator, currentValue) => {
          return Number(
            accumulator +
              (currentValue.ing_quantity * currentValue.volume_market) /
                currentValue.volume_cook
          );
        }, 0);

        return a[0].ingredent_type - b[0].ingredent_type || amountB - amountA;
      }
    );

    listQuantitatives?.map((dayItem) => {
      if (
        !dayItem.costPerDays.find(
          (costPerDay) => costPerDay.meal_quantity !== 0
        )
      ) {
        return dayItem;
      }
      menuWeekReport.push(
        `${dayItem.day_name}: ${stringLastDate(
          new Date(dayItem.menu_date * 1000)
        )}`
      );
      dayItem.times?.map((timeItem) => {
        menuWeekReport.push(
          `${timeItem.time_name}:${timeItem.foods.map((foodItem) => {
            if (
              foodItem.groups.some(
                (groupItem) => groupItem.id === selectedClassGroup.id
              )
            ) {
              return " " + foodItem.food_name;
            }

            return "";
          })}`.replaceAll(",", " - ")
        );
        return timeItem;
      });
      return dayItem;
    });

    if (menuWeekReport.length > groupIngredientArray.length) {
      for (
        let i = 0;
        i < menuWeekReport.length - groupIngredientArray.length;
        i++
      ) {
        groupIngredientArray.push([{ id: i + menuWeekReport, passItem: true }]);
      }
    }

    let energyStat = 0;
    let glucidStat = 0;
    let animalProteinStat = 0;
    let plantProteinStat = 0;
    let animalLipidStat = 0;
    let plantLipidStat = 0;

    let energyRatio = Number(selectedClassGroup.enegry_ratio);
    let recommendTo = Number(selectedClassGroup.recommended_from);

    let animalProteinNorm = Number(
      (recommendTo * EAT_URBAN_AREAS.PROTIT * 60 * energyRatio) / 4000000
    );
    let plantProteinNorm = Number(
      (recommendTo * EAT_URBAN_AREAS.PROTIT * 40 * energyRatio) / 4000000
    );

    let animalLipidNorm = Number(
      (recommendTo * EAT_URBAN_AREAS.LIPID * 60 * energyRatio) / 9000000
    );
    let plantLipidNorm = Number(
      (recommendTo * EAT_URBAN_AREAS.LIPID * 40 * energyRatio) / 9000000
    );

    let glucidNorm = Number(
      (recommendTo * EAT_URBAN_AREAS.GLUCID * energyRatio) / 40000
    );
    let energyNorm = (recommendTo * energyRatio) / 100;

    return (
      <React.Fragment>
        <div className="d-flex flex-column-reverse">
          <div style={{ overflowX: "scroll" }}>
            {/* thực đơn */}
            <Table bordered style={{ minWidth: 777 }}>
              <thead className="align-middle text-center">
                <tr>
                  {listQuantitatives.map((dayItem) => {
                    return <th key={dayItem.id_day}>{dayItem.day_name}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {listQuantitatives.map((dayItem) => {
                    return (
                      <td key={dayItem.id} style={{ maxWidth: 111 }}>
                        {dayItem.times.map((timeItem) => {
                          return (
                            <div key={timeItem.id_time}>
                              <div className="fw-bold d-inline">{`${timeItem.time_name}:`}</div>
                              {`${timeItem.foods.map((foodItem) => {
                                if (
                                  foodItem.groups.some(
                                    (groupItem) =>
                                      groupItem.id === selectedClassGroup.id
                                  )
                                ) {
                                  return " " + foodItem.food_name;
                                }

                                return "";
                              })}`.replaceAll(",", " - ")}
                            </div>
                          );
                        })}
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            </Table>
          </div>

          <div style={{ overflowX: "scroll" }}>
            <Table bordered>
              <thead className="align-middle text-center">
                <tr>
                  <th rowSpan={3}>STT</th>
                  <th rowSpan={3}>Tên thực phẩm</th>
                  {listQuantitatives.map((dayItem) => {
                    return <th key={dayItem.id_day}>{dayItem.day_name}</th>;
                  })}
                  <th colSpan={2}>Tổng cộng (kg)</th>
                  <th rowSpan={3}>Số (g) 1 suất ăn bình quân/ngày</th>
                  <th colSpan={2}>Chất đạm (g)</th>
                  <th colSpan={2}>Chất béo (g)</th>
                  <th rowSpan={3}>Chất bột đường (g)</th>
                  <th rowSpan={3}>Năng lượng (kcal)</th>
                </tr>
                <tr>
                  {listQuantitatives.map((dayItem) => {
                    return (
                      <th key={dayItem.id}>
                        {stringFirstDate(new Date(dayItem.menu_date * 1000))}
                      </th>
                    );
                  })}

                  <th>Ngày</th>
                  <th>{numberDate}</th>

                  <th rowSpan={2}>Động vật</th>
                  <th rowSpan={2}>Thực vật</th>
                  <th rowSpan={2}>Động vật</th>
                  <th rowSpan={2}>Thực vật</th>
                </tr>
                <tr>
                  {listQuantitatives.map((dayItem) => {
                    const numberChildren = Number(
                      dayItem.costPerDays.find(
                        (costPerDay) =>
                          costPerDay.id_group === selectedClassGroup.id
                      )?.meal_quantity
                    );
                    totalMealQuantity += numberChildren;
                    return (
                      <th key={dayItem.id}>
                        {numberChildren !== 0 ? (
                          <CurrencyFormat
                            value={numberChildren}
                            thousandSeparator={true}
                            allowNegative={false}
                            isNumericString
                            displayType="text"
                          />
                        ) : (
                          ""
                        )}
                      </th>
                    );
                  })}

                  <th>Suất ăn</th>
                  <th>
                    <CurrencyFormat
                      value={totalMealQuantity}
                      thousandSeparator={true}
                      allowNegative={false}
                      isNumericString
                      displayType="text"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {groupIngredientArray.map(
                  (groupIngredientItem, groupIngredientIndex) => {
                    if (groupIngredientItem[0].passItem) {
                      return "";
                    }

                    // Gram
                    let gramMarket = 0;
                    let gramMarketDates = [];

                    let averageGramEatable = 0;
                    let averageGramMarket = 0;

                    listQuantitatives.map((dayItem) => {
                      const numberChildren =
                        dayItem.costPerDays.find(
                          (costPerDay) =>
                            costPerDay.id_group === selectedClassGroup.id
                        )?.meal_quantity || 0;

                      let gramEatableDate = 0;
                      let gramMarketDate = 0;

                      groupIngredientItem
                        ?.filter(
                          (ingredientItem) =>
                            ingredientItem.id_day === dayItem.id_day
                        )
                        ?.map((ingredientItem) => {
                          const ingredientQuantity =
                            ingredientItem.ing_quantity;

                          gramEatableDate += Number(
                            ((ingredientQuantity *
                              numberChildren *
                              ingredientItem.volume_market) /
                              ingredientItem.volume_cook) *
                              ((100 - ingredientItem.ingredent_ratio) / 100)
                          );
                          gramMarketDate += Number(
                            (ingredientQuantity *
                              numberChildren *
                              ingredientItem.volume_market) /
                              ingredientItem.volume_cook
                          );

                          return ingredientItem;
                        });

                      if (numberChildren !== 0) {
                        gramMarket += gramMarketDate;
                        gramMarketDates.push({
                          id_day: dayItem.id_day,
                          gramMarket: gramMarketDate,
                        });

                        averageGramEatable += gramEatableDate / numberChildren;
                        averageGramMarket += gramMarketDate / numberChildren;
                      } else {
                        gramMarketDates.push({
                          id_day: dayItem.id_day,
                          gramMarket: 0,
                        });
                      }

                      return dayItem;
                    });

                    if (numberDate !== 0) {
                      averageGramEatable = averageGramEatable / numberDate;
                      averageGramMarket = averageGramMarket / numberDate;
                    }

                    // Nutrition
                    let energy = 0;
                    let animalProtein = 0;
                    let plantProtein = 0;
                    let animalLipid = 0;
                    let plantLipid = 0;
                    let glucid = 0;

                    if (averageGramEatable !== 0) {
                      if (groupIngredientItem[0].id_subspecies === 1) {
                        animalProtein =
                          (averageGramEatable *
                            Number(groupIngredientItem[0].ingredent_protein)) /
                          100;
                        animalLipid =
                          (averageGramEatable *
                            Number(groupIngredientItem[0].ingredent_lipid)) /
                          100;
                      } else {
                        plantProtein =
                          (averageGramEatable *
                            Number(groupIngredientItem[0].ingredent_protein)) /
                          100;
                        plantLipid =
                          (averageGramEatable *
                            Number(groupIngredientItem[0].ingredent_lipid)) /
                          100;
                      }

                      energy =
                        (averageGramEatable *
                          Number(groupIngredientItem[0].ingredent_energy)) /
                        100;
                      glucid =
                        (averageGramEatable *
                          Number(groupIngredientItem[0].ingredent_glucid)) /
                        100;
                    }

                    energyStat += energy;
                    glucidStat += glucid;
                    animalProteinStat += animalProtein;
                    plantProteinStat += plantProtein;
                    animalLipidStat += animalLipid;
                    plantLipidStat += plantLipid;

                    return (
                      <tr
                        key={groupIngredientItem[0].id}
                        className="align-middle text-center"
                      >
                        <td>{groupIngredientIndex + 1}</td>
                        <td className="text-start">
                          {groupIngredientItem[0].ingredent_name}
                        </td>
                        {gramMarketDates.map((dayItem) => {
                          return (
                            <td key={dayItem.id_day}>
                              {dayItem.gramMarket !== 0
                                ? priceFormatter.format(
                                    dayItem.gramMarket / 1000
                                  )
                                : ""}
                            </td>
                          );
                        })}
                        <td colSpan={2}>
                          {gramMarket !== 0
                            ? priceFormatter.format(gramMarket / 1000)
                            : ""}
                        </td>
                        <td>
                          {averageGramMarket !== 0
                            ? priceFormatter.format(averageGramMarket)
                            : ""}
                        </td>
                        <td>
                          {animalProtein !== 0
                            ? priceFormatter.format(animalProtein)
                            : ""}
                        </td>
                        <td>
                          {plantProtein !== 0
                            ? priceFormatter.format(plantProtein)
                            : ""}
                        </td>
                        <td>
                          {animalLipid !== 0
                            ? priceFormatter.format(animalLipid)
                            : ""}
                        </td>
                        <td>
                          {plantLipid !== 0
                            ? priceFormatter.format(plantLipid)
                            : ""}
                        </td>
                        <td>
                          {glucid !== 0 ? priceFormatter.format(glucid) : ""}
                        </td>
                        <td>
                          {energy !== 0 ? priceFormatter.format(energy) : ""}
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </Table>
          </div>

          <Row>
            <Col sm={12} md={4}>
              <div className="d-flex align-content-between flex-wrap h-100 ">
                <div className="fw-bold text-center w-100 mb-3">
                  <div>
                    Tuần lễ từ ngày {stringFirstDate(startDate)} đến ngày{" "}
                    {stringLastDate(termDate)}
                  </div>
                  <div>
                    {selectedClassGroup.group_name} (Buổi
                    {listQuantitatives[0]?.times?.map((timeItem, index) => {
                      if (index === listQuantitatives[0]?.times?.length - 1) {
                        return ` ${timeItem.time_name}`;
                      }

                      return ` ${timeItem.time_name} -`;
                    })}
                    )
                  </div>
                </div>

                <Table bordered style={{ marginBottom: "2rem" }}>
                  <tbody>
                    <tr className="align-middle">
                      <th className="">Tổng số tiền chi trong tuần:</th>
                      <td className="text-end">
                        <CurrencyFormat
                          value={totalCostInWeek || 0}
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                        />
                      </td>
                    </tr>
                    <tr className="align-middle">
                      <th className="">Bình quân chi 1 suất:</th>
                      <td className="text-end">
                        <CurrencyFormat
                          value={
                            Math.round(totalCostInWeek / totalMealQuantity) || 0
                          }
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                        />
                      </td>
                    </tr>
                    <tr className="align-middle">
                      <th className="">Giá thành kcal:</th>
                      <td className="text-end">
                        {totalMealQuantity !== 0 && energyStat !== 0 ? (
                          <CurrencyFormat
                            value={priceFormatter.format(
                              Number(
                                totalCostInWeek /
                                  (energyStat * totalMealQuantity)
                              )
                            )}
                            thousandSeparator={true}
                            allowNegative={false}
                            isNumericString
                            displayType="text"
                          />
                        ) : (
                          0
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col sm={12} md={8}>
              <div style={{ overflowX: "scroll" }}>
                <Table bordered style={{ minWidth: 444 }}>
                  <thead className="align-middle text-center">
                    <tr>
                      <th rowSpan={2}></th>
                      <th colSpan={2}>Đạm (P)</th>
                      <th colSpan={2}>Béo (L)</th>
                      <th rowSpan={2}>Bột đường (G)</th>
                      <th rowSpan={2}>Năng lượng</th>
                    </tr>
                    <tr>
                      <th>ĐV</th>
                      <th>TV</th>
                      <th>ĐV</th>
                      <th>TV</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="align-middle text-center">
                      <th className="text-start">Trung bình từng loại (g):</th>
                      <td>
                        {animalProteinStat !== 0
                          ? priceFormatter.format(animalProteinStat)
                          : ""}
                      </td>
                      <td>
                        {plantProteinStat !== 0
                          ? priceFormatter.format(plantProteinStat)
                          : ""}
                      </td>
                      <td>
                        {animalLipidStat !== 0
                          ? priceFormatter.format(animalLipidStat)
                          : ""}
                      </td>
                      <td>
                        {plantLipidStat !== 0
                          ? priceFormatter.format(plantLipidStat)
                          : ""}
                      </td>
                      <td rowSpan={2}>
                        {glucidStat !== 0
                          ? priceFormatter.format(glucidStat)
                          : ""}
                      </td>
                      <td rowSpan={2}>
                        {energyStat !== 0
                          ? priceFormatter.format(energyStat)
                          : ""}{" "}
                        kcal
                      </td>
                    </tr>
                    <tr className="align-middle text-center">
                      <th className="text-start">Trung bình trong ngày (g):</th>
                      <td colSpan={2}>
                        {animalProteinStat !== 0
                          ? priceFormatter.format(
                              animalProteinStat + plantProteinStat
                            )
                          : ""}
                      </td>

                      <td colSpan={2}>
                        {animalLipidStat !== 0
                          ? priceFormatter.format(
                              animalLipidStat + plantLipidStat
                            )
                          : ""}
                      </td>

                      {/* <td>
                        {glucidStat !== 0
                          ? priceFormatter.format(glucidStat)
                          : ""}
                      </td> */}
                      {/* <td>
                        {energyStat !== 0
                          ? priceFormatter.format(energyStat)
                          : ""}
                      </td> */}
                    </tr>
                    <tr className="align-middle text-center">
                      <th className="text-start">Tỉ lệ ĐV / TV (%):</th>
                      <td>
                        {priceFormatter.format(
                          (animalProteinStat /
                            (animalProteinStat + plantProteinStat)) *
                            100 || 0
                        )}
                      </td>
                      <td>
                        {priceFormatter.format(
                          (plantProteinStat /
                            (animalProteinStat + plantProteinStat)) *
                            100 || 0
                        )}
                      </td>
                      <td>
                        {priceFormatter.format(
                          (animalLipidStat /
                            (animalLipidStat + plantLipidStat)) *
                            100 || 0
                        )}
                      </td>
                      <td>
                        {priceFormatter.format(
                          (plantLipidStat /
                            (animalLipidStat + plantLipidStat)) *
                            100 || 0
                        )}
                      </td>
                      <td></td>
                      <th></th>
                    </tr>
                    <tr className="align-middle text-center">
                      <th className="text-start">
                        Tỉ lệ P-L-G khuyến nghị (%):
                      </th>
                      <th colSpan={2}>{EAT_URBAN_AREAS.PROTIT}</th>
                      <th colSpan={2}>{EAT_URBAN_AREAS.LIPID}</th>
                      <th>{EAT_URBAN_AREAS.GLUCID}</th>
                      <th>{selectedClassGroup?.recommended_from} kcal</th>
                    </tr>

                    <tr className="align-middle text-center">
                      <th className="text-start">Định mức ở trường (g):</th>
                      <th>{priceFormatter.format(animalProteinNorm)}</th>
                      <th>{priceFormatter.format(plantProteinNorm)}</th>
                      <th>{priceFormatter.format(animalLipidNorm)}</th>
                      <th>{priceFormatter.format(plantLipidNorm)}</th>
                      <th>{priceFormatter.format(glucidNorm)}</th>
                      <th>
                        <CurrencyFormat
                          value={energyNorm}
                          thousandSeparator={true}
                          allowNegative={false}
                          isNumericString
                          displayType="text"
                        />{" "}
                        kcal
                      </th>
                    </tr>
                    <tr className="align-middle text-center">
                      <th className="text-start">Tỉ lệ đạt từng loại (%):</th>
                      <td>
                        {priceFormatter.format(
                          Number((animalProteinStat * 100) / animalProteinNorm)
                        )}
                      </td>
                      <td>
                        {priceFormatter.format(
                          Number((plantProteinStat * 100) / plantProteinNorm)
                        )}
                      </td>
                      <td>
                        {priceFormatter.format(
                          Number((animalLipidStat * 100) / animalLipidNorm)
                        )}
                      </td>
                      <td>
                        {priceFormatter.format(
                          Number((plantLipidStat * 100) / plantLipidNorm)
                        )}
                      </td>
                      <td rowSpan={2}>
                        {" "}
                        {priceFormatter.format(
                          Number(
                            ((glucidStat * 100) /
                              ((selectedClassGroup?.recommended_from *
                                EAT_URBAN_AREAS?.GLUCID) /
                                CALORIES.GLUCID)) *
                              100
                          )
                        )}
                      </td>
                      <td rowSpan={2}>
                        {priceFormatter.format(
                          Number(
                            (energyStat * 100) /
                              selectedClassGroup?.recommended_from
                          )
                        )}
                      </td>
                    </tr>
                    <tr className="align-middle text-center">
                      <th className="text-start">Tỉ lệ đạt trong ngày (%):</th>
                      <td colSpan={2}>
                        {priceFormatter.format(
                          Number(
                            (parseFloat(animalProteinStat + plantProteinStat) /
                              ((selectedClassGroup?.recommended_from *
                                (EAT_URBAN_AREAS.PROTIT / 100)) /
                                CALORIES.PROTEIN)) *
                              100
                          )
                        )}
                        {/* trung bình trong ngày / khuyến nghị cả ngày */}
                      </td>
                      <td colSpan={2}>
                        {priceFormatter.format(
                          Number(
                            (parseFloat(animalLipidStat + plantLipidStat) /
                              ((selectedClassGroup?.recommended_from *
                                (EAT_URBAN_AREAS.LIPID / 100)) /
                                CALORIES.LIPID)) *
                              100
                          )
                        )}
                        {/* trung bình trong ngày / khuyến nghị cả ngày */}
                      </td>
                    </tr>
                    <tr className="align-middle text-center">
                      <th className="text-start">Tỉ lệ đạt ở trường (%):</th>
                      <td
                        colSpan={2}
                        style={
                          100 <=
                            Number(
                              ((animalProteinStat + plantProteinStat) * 100) /
                                (animalProteinNorm + plantProteinNorm)
                            ) &&
                          Number(
                            ((animalProteinStat + plantProteinStat) * 100) /
                              (animalProteinNorm + plantProteinNorm)
                          )
                            ? qualified
                            : unqualified
                        }
                      >
                        {priceFormatter.format(
                          Number(
                            ((animalProteinStat + plantProteinStat) * 100) /
                              (animalProteinNorm + plantProteinNorm)
                          )
                        )}
                      </td>
                      <td
                        colSpan={2}
                        style={
                          100 <=
                            Number(
                              ((animalLipidStat + plantLipidStat) * 100) /
                                (animalLipidNorm + plantLipidNorm)
                            ) &&
                          Number(
                            ((animalLipidStat + plantLipidStat) * 100) /
                              (animalLipidNorm + plantLipidNorm)
                          )
                            ? qualified
                            : unqualified
                        }
                      >
                        {priceFormatter.format(
                          Number(
                            ((animalLipidStat + plantLipidStat) * 100) /
                              (animalLipidNorm + plantLipidNorm)
                          )
                        )}
                      </td>
                      {/* <td>từng loại/ ở trường</td> */}

                      <td
                        style={
                          100 <= Number((glucidStat / glucidNorm) * 100) &&
                          Number((glucidStat / glucidNorm) * 100)
                            ? qualified
                            : unqualified
                        }
                      >
                        {priceFormatter.format(
                          Number((glucidStat / glucidNorm) * 100)
                        )}{" "}
                      </td>
                      <td
                        style={
                          100 <= Number((energyStat * 100) / energyNorm) &&
                          Number((energyStat * 100) / energyNorm)
                            ? qualified
                            : unqualified
                        }
                      >
                        {priceFormatter.format(
                          Number((energyStat * 100) / energyNorm)
                        )}{" "}
                      </td>
                    </tr>
                    <tr className="align-middle text-center">
                      <th className="text-start">Tỉ lệ P-L-G (%):</th>
                      <td colSpan={2}>
                        {priceFormatter.format(
                          Number(
                            (((animalProteinStat + plantProteinStat) *
                              CALORIES.PROTEIN) /
                              (glucidStat * CALORIES.GLUCID +
                                (animalLipidStat + plantLipidStat) *
                                  CALORIES.LIPID +
                                (animalProteinStat + plantProteinStat) *
                                  CALORIES.PROTEIN)) *
                              100
                          ) || 0
                        )}
                      </td>
                      <td colSpan={2}>
                        {priceFormatter.format(
                          Number(
                            (((animalLipidStat + plantLipidStat) *
                              CALORIES.LIPID) /
                              (glucidStat * CALORIES.GLUCID +
                                (animalLipidStat + plantLipidStat) *
                                  CALORIES.LIPID +
                                (animalProteinStat + plantProteinStat) *
                                  CALORIES.PROTEIN)) *
                              100
                          ) || 0
                        )}
                      </td>
                      <td>
                        {priceFormatter.format(
                          Number(
                            ((glucidStat * CALORIES.GLUCID) /
                              (glucidStat * CALORIES.GLUCID +
                                (animalLipidStat + plantLipidStat) *
                                  CALORIES.LIPID +
                                (animalProteinStat + plantProteinStat) *
                                  CALORIES.PROTEIN)) *
                              100
                          ) || 0
                        )}{" "}
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </div>

        <Table bordered id="nutrition-week-report" className="d-none">
          <thead className="align-middle text-center d-none">
            <tr>
              <th colSpan={18}>
                BẢNG TỔNG HỢP KHẨU PHẦN DINH DƯỠNG TRONG TUẦN
              </th>
            </tr>
            <tr>
              <th colSpan={18}>
                Tuần lễ từ ngày {stringLastDate(startDate)} đến ngày{" "}
                {stringLastDate(termDate)}
              </th>
            </tr>
            <tr>
              <th colSpan={18}>
                {selectedClassGroup.group_name} (Buổi
                {listQuantitatives[0]?.times?.map((timeItem, index) => {
                  if (index === listQuantitatives[0]?.times?.length - 1) {
                    return ` ${timeItem.time_name}`;
                  }

                  return ` ${timeItem.time_name} -`;
                })}
                )
              </th>
            </tr>
            <tr>
              <th>STT</th>
              <th>Tên thực phẩm</th>
              {listQuantitatives.map((dayItem) => {
                return <th key={dayItem.id_day}>{dayItem.day_name}</th>;
              })}
              <th>Tổng cộng (kg)</th>
              <th>empty</th>
              <th>Số (g) 1 suất ăn bình quân/ngày</th>
              <th>Chất đạm (g)</th>
              <th>empty</th>
              <th>Chất béo (g)</th>
              <th>empty</th>
              <th>Chất bột đường (g)</th>
              <th>Năng lượng (kcal)</th>
              <th>Thực đơn</th>
            </tr>
            <tr>
              <th>empty</th>
              <th>empty</th>
              {listQuantitatives.map((dayItem) => {
                return (
                  <th key={dayItem.id}>
                    {stringFirstDate(new Date(dayItem.menu_date * 1000))}
                  </th>
                );
              })}
              <th>Ngày</th>
              <th>{numberDate}</th>
              <th>empty</th>
              <th>Động vật</th>
              <th>Thực vật</th>
              <th>Động vật</th>
              <th>Thực vật</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
            </tr>
            <tr>
              <th>empty</th>
              <th>empty</th>
              {listQuantitatives.map((dayItem) => {
                const numberChildren = Number(
                  dayItem.costPerDays.find(
                    (costPerDay) =>
                      costPerDay.id_group === selectedClassGroup.id
                  )?.meal_quantity
                );
                return (
                  <th key={dayItem.id}>
                    {numberChildren !== 0 ? (
                      <CurrencyFormat
                        value={numberChildren}
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString
                        displayType="text"
                      />
                    ) : (
                      "empty"
                    )}
                  </th>
                );
              })}
              <th>Suất ăn</th>
              <th>
                <CurrencyFormat
                  value={totalMealQuantity}
                  thousandSeparator={true}
                  allowNegative={false}
                  isNumericString
                  displayType="text"
                />
              </th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
            </tr>
          </thead>
          <tbody>
            {groupIngredientArray.map(
              (groupIngredientItem, groupIngredientIndex) => {
                let gramMarket = 0;
                let gramMarketDates = [];

                let averageGramEatable = 0;
                let averageGramMarket = 0;

                listQuantitatives.map((dayItem) => {
                  const numberChildren =
                    dayItem.costPerDays.find(
                      (costPerDay) =>
                        costPerDay.id_group === selectedClassGroup.id
                    )?.meal_quantity || 0;

                  let gramEatableDate = 0;
                  let gramMarketDate = 0;

                  groupIngredientItem
                    ?.filter(
                      (ingredientItem) =>
                        ingredientItem.id_day === dayItem.id_day
                    )
                    ?.map((ingredientItem) => {
                      const ingredientQuantity = ingredientItem.ing_quantity;

                      gramEatableDate += Number(
                        ((ingredientQuantity *
                          numberChildren *
                          ingredientItem.volume_market) /
                          ingredientItem.volume_cook) *
                          ((100 - ingredientItem.ingredent_ratio) / 100)
                      );
                      gramMarketDate += Number(
                        (ingredientQuantity *
                          numberChildren *
                          ingredientItem.volume_market) /
                          ingredientItem.volume_cook
                      );

                      return ingredientItem;
                    });

                  if (numberChildren !== 0) {
                    gramMarket += gramMarketDate;
                    gramMarketDates.push({
                      id_day: dayItem.id_day,
                      gramMarket: gramMarketDate,
                    });

                    averageGramEatable += gramEatableDate / numberChildren;
                    averageGramMarket += gramMarketDate / numberChildren;
                  } else {
                    gramMarketDates.push({
                      id_day: dayItem.id_day,
                      gramMarket: 0,
                    });
                  }

                  return dayItem;
                });

                if (numberDate !== 0) {
                  averageGramEatable = averageGramEatable / numberDate;
                  averageGramMarket = averageGramMarket / numberDate;
                }

                // Nutrition
                let energy = 0;
                let animalProtein = 0;
                let plantProtein = 0;
                let animalLipid = 0;
                let plantLipid = 0;
                let glucid = 0;

                if (averageGramEatable !== 0) {
                  if (groupIngredientItem[0].id_subspecies === 1) {
                    animalProtein =
                      (averageGramEatable *
                        Number(groupIngredientItem[0].ingredent_protein)) /
                      100;
                    animalLipid =
                      (averageGramEatable *
                        Number(groupIngredientItem[0].ingredent_lipid)) /
                      100;
                  } else {
                    plantProtein =
                      (averageGramEatable *
                        Number(groupIngredientItem[0].ingredent_protein)) /
                      100;
                    plantLipid =
                      (averageGramEatable *
                        Number(groupIngredientItem[0].ingredent_lipid)) /
                      100;
                  }

                  energy =
                    (averageGramEatable *
                      Number(groupIngredientItem[0].ingredent_energy)) /
                    100;
                  glucid =
                    (averageGramEatable *
                      Number(groupIngredientItem[0].ingredent_glucid)) /
                    100;
                }
                return (
                  <tr
                    key={groupIngredientItem[0].id}
                    className="align-middle text-center d-none"
                  >
                    <td>
                      {groupIngredientItem[0].passItem
                        ? "empty"
                        : groupIngredientIndex + 1}
                    </td>
                    <td className="text-start">
                      {groupIngredientItem[0].passItem
                        ? "empty"
                        : groupIngredientItem[0].ingredent_name}
                    </td>
                    {gramMarketDates.map((dayItem) => {
                      return (
                        <td key={dayItem.id_day}>
                          {dayItem.gramMarket !== 0
                            ? priceFormatter.format(dayItem.gramMarket / 1000)
                            : "empty"}
                        </td>
                      );
                    })}
                    <td>
                      {gramMarket !== 0
                        ? priceFormatter.format(gramMarket / 1000)
                        : "empty"}
                    </td>
                    <td>empty</td>
                    <td>
                      {averageGramMarket !== 0
                        ? priceFormatter.format(averageGramMarket)
                        : "empty"}
                    </td>
                    <td>
                      {animalProtein !== 0
                        ? priceFormatter.format(animalProtein)
                        : "empty"}
                    </td>
                    <td>
                      {plantProtein !== 0
                        ? priceFormatter.format(plantProtein)
                        : "empty"}
                    </td>
                    <td>
                      {animalLipid !== 0
                        ? priceFormatter.format(animalLipid)
                        : "empty"}
                    </td>
                    <td>
                      {plantLipid !== 0
                        ? priceFormatter.format(plantLipid)
                        : "empty"}
                    </td>
                    <td>
                      {glucid !== 0 ? priceFormatter.format(glucid) : "empty"}
                    </td>
                    <td>
                      {energy !== 0 ? priceFormatter.format(energy) : "empty"}
                    </td>
                    <td>{menuWeekReport[groupIngredientIndex] || "empty"}</td>
                  </tr>
                );
              }
            )}
            <tr className="align-middle text-center">
              <th></th>
              <th></th>
              {listQuantitatives.map((quantitativeItem) => {
                return <td key={quantitativeItem.id}></td>;
              })}

              <th>empty</th>
              <th></th>
              <th></th>
              <td>Đạm (P)</td>
              <th>empty</th>
              <td>Béo (L)</td>
              <th>empty</th>
              <td>Bột đường (G)</td>
              <td>Năng lượng</td>
            </tr>
            <tr className="align-middle text-center">
              <th></th>
              <th></th>
              {listQuantitatives.map((quantitativeItem) => {
                return <td key={quantitativeItem.id}></td>;
              })}
              <th></th>
              <th></th>
              <th></th>
              <td>ĐV</td>
              <th>TV</th>
              <td>ĐV</td>
              <th>TV</th>
              <td>empty</td>
              <td>empty</td>
            </tr>
            <tr className="align-middle text-center">
              <th></th>
              <th></th>
              {listQuantitatives.map((quantitativeItem) => {
                return <td key={quantitativeItem.id}></td>;
              })}
              <th className="text-end">Trung bình từng loại (g):</th>
              <th>empty</th>
              <th>empty</th>
              <td>
                {animalProteinStat !== 0
                  ? priceFormatter.format(animalProteinStat)
                  : "empty"}
              </td>
              <td>
                {plantProteinStat !== 0
                  ? priceFormatter.format(plantProteinStat)
                  : "empty"}
              </td>
              <td>
                {animalLipidStat !== 0
                  ? priceFormatter.format(animalLipidStat)
                  : "empty"}
              </td>
              <td>
                {plantLipidStat !== 0
                  ? priceFormatter.format(plantLipidStat)
                  : "empty"}
              </td>
              <td>
                {glucidStat !== 0 ? priceFormatter.format(glucidStat) : "empty"}
              </td>
              <td>
                {energyStat !== 0 ? priceFormatter.format(energyStat) : "0"}{" "}
                kcal
              </td>
            </tr>
            <tr className="align-middle text-center">
              <th></th>
              <th></th>
              {listQuantitatives.map((quantitativeItem) => {
                return <td key={quantitativeItem.id}></td>;
              })}
              <th className="text-end">Trung bình trong ngày (g):</th>
              <th>empty</th>
              <th>empty</th>
              <th>
                {animalProteinStat !== 0
                  ? priceFormatter.format(animalProteinStat + plantProteinStat)
                  : "empty"}
              </th>
              <th>empty</th>
              <th>
                {animalLipidStat !== 0
                  ? priceFormatter.format(animalLipidStat + plantLipidStat)
                  : "empty"}
              </th>
              <th>empty</th>
              <th>empty</th>
              <td>empty</td>
            </tr>
            <tr className="align-middle text-center">
              <th></th>
              <th></th>
              {listQuantitatives.map((quantitativeItem) => {
                return <td key={quantitativeItem.id}></td>;
              })}
              <th className="text-end">Tỉ lệ ĐV / TV (%):</th>
              <th>empty</th>
              <th>empty</th>
              <td>
                {priceFormatter.format(
                  (animalProteinStat / (animalProteinStat + plantProteinStat)) *
                    100
                )}
              </td>
              <td>
                {priceFormatter.format(
                  (plantProteinStat / (animalProteinStat + plantProteinStat)) *
                    100
                )}
              </td>
              <td>
                {priceFormatter.format(
                  (animalLipidStat / (animalLipidStat + plantLipidStat)) * 100
                )}
              </td>
              <td>
                {priceFormatter.format(
                  (plantLipidStat / (animalLipidStat + plantLipidStat)) * 100
                )}
              </td>
              <td>empty</td>
              <td>empty</td>
            </tr>
            <tr className="align-middle text-center">
              <th className="text-end">Tổng số tiền chi trong tuần:</th>
              <th>empty</th>
              <th>empty</th>
              <th>
                <CurrencyFormat
                  value={totalCostInWeek || 0}
                  thousandSeparator={true}
                  allowNegative={false}
                  isNumericString
                  displayType="text"
                />
              </th>
              {listQuantitatives?.slice(2)?.map((quantitativeItem, index) => {
                return (
                  <td key={quantitativeItem.id}>
                    {index === 0 ? "empty" : ""}
                  </td>
                );
              })}
              <th>Tỉ lệ P-L-G khuyến nghị (%):</th>
              <th>empty</th>
              <th>empty</th>
              <td>{EAT_URBAN_AREAS.PROTIT}</td>
              <th>empty</th>
              <td>{EAT_URBAN_AREAS.LIPID}</td>
              <th>empty</th>
              <td>{EAT_URBAN_AREAS.GLUCID}</td>
              <td>{selectedClassGroup?.recommended_from} kcal</td>
            </tr>
            <tr className="align-middle text-center">
              <th className="text-end">Bình quân chi 1 suất:</th>
              <th>empty</th>
              <th>empty</th>
              <th>
                <CurrencyFormat
                  value={Math.round(totalCostInWeek / totalMealQuantity) || 0}
                  thousandSeparator={true}
                  allowNegative={false}
                  isNumericString
                  displayType="text"
                />
              </th>
              {listQuantitatives?.slice(2)?.map((quantitativeItem, index) => {
                return (
                  <td key={quantitativeItem.id}>
                    {index === 0 ? "empty" : ""}
                  </td>
                );
              })}
              <th className="text-end">Định mức ở trường (g):</th>
              <th>empty</th>
              <th>empty</th>
              <td>{priceFormatter.format(animalProteinNorm)}</td>
              <td>{priceFormatter.format(plantProteinNorm)}</td>
              <td>{priceFormatter.format(animalLipidNorm)}</td>
              <td>{priceFormatter.format(plantLipidNorm)}</td>
              <td>{priceFormatter.format(glucidNorm)}</td>
              <td>
                {energyNorm !== 0 ? priceFormatter.format(energyNorm) : "0"}{" "}
                kcal
              </td>
            </tr>
            <tr className="align-middle text-center">
              <th className="text-end">Giá thành kcal:</th>
              <th>empty</th>
              <th>empty</th>
              <th>
                {totalMealQuantity !== 0 && energyStat !== 0 ? (
                  <CurrencyFormat
                    value={
                      priceFormatter.format(
                        Number(
                          totalCostInWeek / (energyStat * totalMealQuantity)
                        )
                      ) || 0
                    }
                    thousandSeparator={true}
                    allowNegative={false}
                    isNumericString
                    displayType="text"
                  />
                ) : (
                  "empty"
                )}
              </th>
              {listQuantitatives?.slice(2)?.map((quantitativeItem, index) => {
                return (
                  <td key={quantitativeItem.id}>
                    {index === 0 ? "empty" : ""}
                  </td>
                );
              })}
              <th className="text-end">Tỉ lệ đạt từng loại (%):</th>
              <th>empty</th>
              <th>empty</th>
              <td>
                {priceFormatter.format(
                  Number((animalProteinStat * 100) / animalProteinNorm)
                )}
              </td>
              <td>
                {priceFormatter.format(
                  Number((plantProteinStat * 100) / plantProteinNorm)
                )}
              </td>
              <td>
                {priceFormatter.format(
                  Number((animalLipidStat * 100) / animalLipidNorm)
                )}
              </td>
              <td>
                {priceFormatter.format(
                  Number((plantLipidStat * 100) / plantLipidNorm)
                )}
              </td>
              <td>
                {/* {priceFormatter.format(Number((glucidStat * 100) / glucidNorm))} */}
                {priceFormatter.format(
                  Number(
                    ((glucidStat * 100) /
                      ((selectedClassGroup?.recommended_from *
                        EAT_URBAN_AREAS?.GLUCID) /
                        CALORIES.GLUCID)) *
                      100
                  )
                )}
              </td>
              <td>
                {priceFormatter.format(
                  Number(
                    (energyStat * 100) / selectedClassGroup?.recommended_from
                  )
                )}
              </td>
            </tr>
            <tr className="align-middle text-center">
              <th></th>
              <th></th>
              {listQuantitatives.map((quantitativeItem) => {
                return <td key={quantitativeItem.id}></td>;
              })}
              <th className="text-end">Tỉ lệ đạt trong ngày (%):</th>
              <th>empty</th>
              <th>empty</th>
              <td>
                {priceFormatter.format(
                  Number(
                    (parseFloat(animalProteinStat + plantProteinStat) /
                      ((selectedClassGroup?.recommended_from *
                        (EAT_URBAN_AREAS.PROTIT / 100)) /
                        CALORIES.PROTEIN)) *
                      100
                  )
                )}
              </td>
              <td>empty</td>
              <td>
                {priceFormatter.format(
                  Number(
                    (parseFloat(animalLipidStat + plantLipidStat) /
                      ((selectedClassGroup?.recommended_from *
                        (EAT_URBAN_AREAS.LIPID / 100)) /
                        CALORIES.LIPID)) *
                      100
                  )
                )}
              </td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
            </tr>
            <tr className="align-middle text-center">
              <th></th>
              <th></th>
              {listQuantitatives.map((quantitativeItem) => {
                return <td key={quantitativeItem.id}></td>;
              })}
              <th className="text-end">Tỉ lệ đạt ở trường (%):</th>
              <th>empty</th>
              <th>empty</th>
              <td>
                {priceFormatter.format(
                  Number(
                    ((animalProteinStat + plantProteinStat) * 100) /
                      (animalProteinNorm + plantProteinNorm)
                  )
                )}
              </td>
              <td>empty</td>
              <td>
                {priceFormatter.format(
                  Number(
                    ((animalLipidStat + plantLipidStat) * 100) /
                      (animalLipidNorm + plantLipidNorm)
                  )
                )}
              </td>
              <td>empty</td>
              <td>
                {priceFormatter.format(Number((glucidStat / glucidNorm) * 100))}
              </td>
              <td>
                {priceFormatter.format(Number((energyStat * 100) / energyNorm))}{" "}
              </td>
            </tr>
            <tr className="align-middle text-center">
              <th></th>
              <th></th>
              {listQuantitatives.map((quantitativeItem) => {
                return <td key={quantitativeItem.id}></td>;
              })}
              <th className="text-end">Tỉ lệ P-L-G (%):</th>
              <th>empty</th>
              <th>empty</th>
              <td>
                {priceFormatter.format(
                  Number(
                    (((animalProteinStat + plantProteinStat) *
                      CALORIES.PROTEIN) /
                      (glucidStat * CALORIES.GLUCID +
                        (animalLipidStat + plantLipidStat) * CALORIES.LIPID +
                        (animalProteinStat + plantProteinStat) *
                          CALORIES.PROTEIN)) *
                      100
                  )
                )}
              </td>
              <td>empty</td>
              <td>
                {priceFormatter.format(
                  Number(
                    (((animalLipidStat + plantLipidStat) * CALORIES.LIPID) /
                      (glucidStat * CALORIES.GLUCID +
                        (animalLipidStat + plantLipidStat) * CALORIES.LIPID +
                        (animalProteinStat + plantProteinStat) *
                          CALORIES.PROTEIN)) *
                      100
                  )
                )}
              </td>
              <td>empty</td>
              <td>
                {priceFormatter.format(
                  Number(
                    ((glucidStat * CALORIES.GLUCID) /
                      (glucidStat * CALORIES.GLUCID +
                        (animalLipidStat + plantLipidStat) * CALORIES.LIPID +
                        (animalProteinStat + plantProteinStat) *
                          CALORIES.PROTEIN)) *
                      100
                  )
                )}
              </td>
              <td>empty</td>
            </tr>
            <tr className="align-middle text-center">
              {(() => {
                const arr = [];
                for (let i = 0; i < 10 + listQuantitatives.length; i++) {
                  if (i === 0) {
                    arr.push(<td key={i}></td>);
                    continue;
                  }

                  if (
                    i ===
                    Math.ceil((10 + listQuantitatives.length) / 2) + 1
                  ) {
                    arr.push(<td key={i}></td>);
                    continue;
                  }

                  arr.push(<td key={i}></td>);
                }
                return arr;
              })()}
              <td></td>
            </tr>
            <tr className="align-middle text-center">
              {(() => {
                const arr = [];
                for (let i = 0; i < 10 + listQuantitatives.length; i++) {
                  if (i === 0) {
                    arr.push(<td key={i}>Người lập</td>);
                    continue;
                  }

                  if (
                    i ===
                    Math.ceil((10 + listQuantitatives.length) / 2) + 1
                  ) {
                    arr.push(<td key={i}>Hiệu phó bán trú</td>);
                    continue;
                  }

                  arr.push(<td key={i}>empty</td>);
                }
                return arr;
              })()}
              <td>empty</td>
            </tr>
          </tbody>
        </Table>
      </React.Fragment>
    );
  };

  return (
    <Container fluid>
      <Card>
        <Card.Header>
          <div className="d-block d-md-flex justify-content-between">
            <div className="d-block d-md-flex">
              <Card.Title className="me-3 d-flex align-items-center">
                Khẩu phần dinh dưỡng tuần {stringFirstDate(startDate)} -{" "}
                {stringLastDate(termDate)}
              </Card.Title>
              <div className="d-flex align-items-center">
                <div
                  className="me-2 d-flex align-items-center"
                  style={{ zIndex: "1021" }}
                >
                  <DatePicker
                    disabled={isLoading}
                    selected={startDate}
                    startDate={startDate}
                    endDate={termDate}
                    onChange={(date) => {
                      const startDateGMT = startOfWeek(date, {
                        weekStartsOn: 1,
                      });
                      const endDateGMT = startOfWeek(
                        endOfWeek(date, { weekStartsOn: 1 })
                      );
                      setDatesOfWeek(
                        startOfWeek(date, {
                          weekStartsOn: 1,
                        })
                      );
                      getListQuantitatives(
                        new Date(startDateGMT),
                        new Date(endDateGMT)
                      );
                    }}
                    showWeekNumbers
                    dateFormat="yyyy-MM-dd"
                    calendarClassName="custom-calendar"
                    customInput={<CustomInput />}
                    locale={vi}
                  />
                </div>

                <Select
                  options={classGroups}
                  placeholder={<div>Tất cả</div>}
                  getOptionLabel={(option) => option.group_name}
                  getOptionValue={(option) => option.id}
                  value={selectedClassGroup}
                  onChange={(choice) => {
                    setSelectedClassGroups(choice);
                  }}
                />
              </div>
            </div>

            <Button
              variant="success"
              size="sm"
              onClick={() => {
                handleXportNutrionWeekReport();
              }}
              disabled={isLoading || listQuantitatives.length === 0}
              className="mt-3 mt-md-0 me-3"
            >
              Xuất file <i className="fa-solid fa-file-export"></i>
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          {isLoading ? (
            <div className="d-flex justify-content-center my-5">
              <Spinner
                animation="border"
                role="status"
                variant="primary"
                style={{ width: "3rem", height: "3rem" }}
              >
                <span className="visually-hidden">Đang tải...</span>
              </Spinner>
            </div>
          ) : listQuantitatives.length !== 0 ? (
            NutritionWeekReport()
          ) : (
            ""
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default NutritionWeekReport;
