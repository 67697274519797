import React, { Component } from "react";
import { toast } from "react-toastify";
import swal from "sweetalert";
import {
  Button,
  Row,
  Col,
  Card,
  Container,
  Breadcrumb,
  Form,
  Modal,
} from "react-bootstrap";
import DataTables from "../datatable/DataTables";
import BillModal from "./BillModal";
import Check from "../other/Check";
import BillEditModal from "./BillEditModal";
import { getlastDayOfMonth, priceFormatterNoDecimal } from "../utils/helpers";
import Select from "react-select";
import * as XLSX from "xlsx-js-style";
import api from "../../../helper/axiosInstance";
import DatePickerCustom from "../../uiElements/DatePickerCustom";
import PrintMultipleButton from "./PrintMultipleButton";
import PrintMultipleButton2 from "./PrintMultipleButton2";

class BillIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bills: [],
      modalRoleShow: false,
      bill: {},
      selectedRows: [],
      loadingBills: true,
      editModal: false,
      filterText: "",
      studentsSearch: [],
      startDate: new Date(),
      endDate: new Date(),
      listClass: [],
      choiceClass: { value: 0, label: "Tất cả lớp" },
      schoolInfo: {},
      billExport: [],
      isClear: false,
      discounts: [],
      bank: {
        account: "",
        owner: "",
        branch: "",
      },
      showQr: false,
      vtbPayment: {},
      hdbPayment: {},
      providerBank: "",
      showTypeExport: false,
    };
  }

  componentDidMount() {
    document.title = "Phiếu thu";
    this.getDetailschool();
    this.getDiscount();
    this.getTransfer();
    this.getPaymentSettings();
    this.setState({
      startDate: new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0)),
      endDate: this.lastDayy(
        new Date().getFullYear(),
        Number(new Date().getMonth()) + 1
      ),
    });
    this.getClassRoom(
      new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0)) / 1000,
      this.lastDayy(
        new Date().getFullYear(),
        Number(new Date().getMonth()) + 1
      ) / 1000
    );
    this.getBills(
      new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0)),
      this.lastDayy(new Date().getFullYear(), Number(new Date().getMonth()) + 1)
    );
  }
  getDiscount = () => {
    api
      .get("/discount-shows")
      .then((res) => {
        this.setState({
          discounts: res.data.data
            ?.filter((e) => e.target === "bill")
            ?.map((discount) => {
              return {
                value: discount.id,
                label: discount.title,
                name: discount.title,
                amount: discount.value,
                type: discount.type,
              };
            }),
        });
      })
      .catch((err) => {});
  };
  getTransfer = () => {
    api
      .get("/get-transfer-setting")
      .then((res) => {
        this.setState({
          bank: res.data.data || {
            account: "",
            owner: "",
            branch: "",
          },
        });
      })
      .catch((err) => {});
  };
  getDetailschool = () => {
    api
      .get(`/detailSchool`)
      .then((res) => {
        if (res.data.status === true) {
          this.setState({
            schoolInfo: res.data.data[0],
          });
        }
      })
      .catch((err) => {});
  };
  confirmationPayment = (type) => {
    let data = {
      bills: this.state.billExport?.map((e) => Number(e.id)),
      status: type, // view_only, allow_payment
    };

    api
      .patch(`/bill-allow-payment`, data)
      .then((res) => {
        toast(res.data.messages || "Thành công", { type: "success" });
        this.getBills(this.state.startDate, this.state.endDate);
      })
      .catch((err) => {
        toast("Hệ thống xảy ra lỗi", { type: "error" });
      });
  };
  tuitionReminder = () => {
    swal({
      title: "Bạn muốn nhắc nhỡ đóng học phí?",
      text: "Thông báo này sẽ gửi đến tất cả phiếu thu chưa thanh toán.",
      icon: "warning",
      buttons: ["Đóng", "Nhắc nhỡ"],
      warningMode: true,
    })
      .then((ok) => {
        if (ok) {
          api
            .post(`/bill-notify`)
            .then((res) => {
              toast(res.data.messages || "Tạo thành công", { type: "success" });
            })
            .catch((err) => {
              toast("Hệ thống xảy ra lỗi", { type: "error" });
            });
        }
      })
      .catch((err) => {});
  };
  exportListBillCreated = (ListBill) => {
    // onHide(true);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      wb,
      this.book1(
        ListBill.sort(function (a, b) {
          return b.student?.classes[0]?.id - a.student?.classes[0]?.id;
        })
      ),
      "Phiếu thu"
    );

    XLSX.writeFile(wb, "Danh sách phiếu thu.xlsx");
  };

  book1 = (ListBill) => {
    const title = ["Danh sách phiếu thu"];
    const header = ["STT", "Tên học sinh", "Lớp", "Tổng tiền", "Trạng thái"];
    const aoaAccounts = [
      title,
      header,
      ...ListBill.map((account, index) => {
        return [
          index + 1,
          account.student?.student_name,
          account.student?.classes[0]?.class_name || "empty",
          account.trans_amount,
          Number(account.pay_status) === 1
            ? "Đã thanh toán"
            : "Chưa thanh toán",
        ];
      }),
      [
        "",
        "Tổng",
        "",
        ListBill.reduce((sum, account) => sum + account.trans_amount, 0),
        "",
      ],
    ];
    var ws1 = XLSX.utils.aoa_to_sheet(aoaAccounts);
    const colAlpha = ["A", "B", "C", "D", "E"];
    ws1[`A1`].s = {
      font: {
        name: "Times New Roman",
        sz: 16,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      },
    };
    const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 4 } }]; // merge A1:E1
    ws1["!merges"] = merge;

    colAlpha.forEach((alpha) => {
      ws1[`${alpha}2`].s = {
        font: {
          name: "Times New Roman",
          sz: 12,
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };
    });

    for (let i = 0; i < ListBill.length; i++) {
      colAlpha.forEach((alpha) => {
        if (ws1[`${alpha}${3 + i}`].v === "empty") {
          ws1[`${alpha}${3 + i}`].v = " ";
        }
        ws1[`${alpha}${3 + i}`].s = {
          font: {
            name: "Times New Roman",
            sz: 12,
          },
          alignment: {
            vertical: "center",
            horizontal:
              alpha === "A" ? "center" : alpha === "D" ? "right" : "left",
            wrapText: true,
          },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
          ...(alpha === "D" && { numFmt: "#,##0" }),
        };
      });
    }
    colAlpha.forEach((alpha, index) => {
      ws1[`${alpha}${3 + ListBill.length}`].s = {
        font: {
          name: "Times New Roman",
          sz: 12,
          bold: index === 1 || index === 3, // Bold "Tổng cộng" and total amount
        },
        alignment: {
          vertical: "center",
          horizontal:
            alpha === "A" ? "center" : alpha === "D" ? "right" : "left",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
        ...(alpha === "D" && { numFmt: "#,##0" }),
      };
    });

    ws1["!cols"] = [
      { wch: 5 },
      { wch: 25 },
      { wch: 10 },
      { wch: 25 },
      { wch: 20 },
      { wch: 20 },
      { wch: 25 },
    ];

    return ws1;
  };
  getClassRoom = (start, end) => {
    api
      .get(`/classroom-shows?start_date=${parseInt(start)}&end_date=${end}`)
      .then((res) => {
        let arr = [];
        arr.push({ value: 0, label: "Tất cả lớp" });
        res.data.data?.classrooms?.map((item) => {
          return arr.push({ value: item.id, label: item.class_name });
        });
        this.setState({ listClass: arr });
      })
      .catch((err) => {});
  };

  getPaymentSettings = async () => {
    try {
      const response = await api({
        method: "get",
        url: "/payment-setting-shows",
      });

      const settings = response.data.data;

      settings?.forEach((setting) => {
        if (setting.setting_key === "HDB_API_KEY") {
          this.setState({
            hdbPayment: {
              id: setting.id || "",
              provider: "hdbank",
              name: "HDBank",
              logo: setting.logo || "",
              setting_key: setting.setting_key,
            },
          });
        }

        if (setting.setting_key === "VTB_API_KEY") {
          this.setState({
            vtbPayment: {
              id: setting.id,
              provider: "vietinbank",
              logo: setting.logo || "",
              name: "Vietinbank",
              setting_key: setting.setting_key,
            },
          });
        }
      });
    } catch (error) {
      toast.error("Đã có lỗi xảy ra khi tải dữ liệu");
    }
  };
  getBills = (start, end) => {
    this.setState({ loadingBills: true });
    api
      .get(
        `/bill-shows?end_date=${parseInt(end / 1000)}&start_date=${parseInt(
          start / 1000
        )}`
      )
      .then((res) => {
        let bills = res.data.data?.docs || [];

        if (bills.length > 0) {
          bills = bills.map((billItem) => {
            const className = billItem.student?.classes?.[0]?.class_name || "";
            const schoolYearName =
              billItem.student.classes?.[0]?.schoolYear.year_name || "";

            let details = billItem.details;

            // At the request of school Em Maison
            if (billItem.id_school === 102) {
              details = billItem.details?.map((detailItem) => {
                return {
                  ...detailItem,
                  content:
                    detailItem.bill_detailable === "FoodMoney"
                      ? `Tiền ăn lớp ${className} (Năm học ${schoolYearName})`
                      : detailItem.content,
                };
              });
            }

            billItem.details = details;

            return {
              ...billItem,
            };
          });
        }

        this.setState({ loadingBills: false, bills: res.data.data?.docs }, () =>
          this.filterStudent(this.state.choiceClass?.value || 0, "")
        );
      })
      .catch((error) => {
        console.error("Error>>", error);
      });
  };
  toSlug(str) {
    if (str !== "undefined") {
      str = str.toLowerCase(); // Chuyển hết sang chữ thường
      str = str
        .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
        .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp
      str = str.replace(/[đĐ]/g, "d"); // Thay ký tự đĐ
      str = str.replace(/([^0-9a-z-\s])/g, ""); // Xóa ký tự đặc biệt
    }

    return str;
  }
  filterStudent = (idClass, text) => {
    this.setState({ filterText: text });
    const filteredStudents = this.state.bills?.filter((student) => {
      const matchesText =
        this.toSlug(student?.student?.student_name)?.includes(
          this.toSlug(text?.trim())
        ) ||
        this.toSlug(student?.billcode)?.includes(this.toSlug(text?.trim()));

      const matchesClass =
        idClass === 0 ||
        Number(student?.student?.classes[0]?.id) === Number(idClass);

      return matchesText && matchesClass;
    });

    this.setState({
      studentsSearch: filteredStudents,
      isClear: false,

      filterText: text,
    });
  };
  getRole = (id) => {
    if (id !== "") {
      api
        .get(`/bill-show?id=${id}`)
        .then((res) => {
          let bill = res.data.data;

          const className = bill.student.classes?.[0]?.class_name || "";
          const schoolYearName =
            bill.student.classes?.[0]?.schoolYear.year_name || "";

          let details = bill.details;

          // At the request of school Em Maison
          if (bill.id_school === 102) {
            details = bill.details?.map((detailItem) => {
              return {
                ...detailItem,
                content:
                  detailItem.bill_detailable === "FoodMoney"
                    ? `Tiền ăn lớp ${className} (Năm học ${schoolYearName})`
                    : detailItem.content,
              };
            });
          }

          bill.details = details;

          this.setState({
            bill: bill,
          });

          this.setState({ editModal: true });
        })
        .catch((err) => {
          console.error("err>>", err);
        });
    }
  };

  handleDelete = (item) => {
    swal({
      title: "Bạn muốn xóa phiếu thu?",

      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    })
      .then((ok) => {
        if (ok) {
          api
            .delete(`/bill-delete?id=${item.id}`)
            .then((res) => {
              toast(res.data.message, { type: "success", autoClose: 1000 });
              this.getBills(this.state.startDate, this.state.endDate);
            })
            .catch((err) => {
              toast(err.response.data.data.messages || "Lỗi", {
                type: "error",
                autoClose: 1000,
              });
            })
            .catch((err) => {});
        }
      })
      .catch((err) => {});
  };

  loading = () => {
    return (
      <Card>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ width: "3rem", height: "3rem" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </Card.Body>
      </Card>
    );
  };

  lastDayy = (year, month) => {
    const nextMonth = new Date(year, month, 1);
    nextMonth.setDate(nextMonth.getDate() - 1);
    return new Date(nextMonth.setHours(23, 59, 59));
  };
  onSelectedRowsChange = (row) => {
    this.setState({ billExport: row });
  };
  dataTable() {
    const columns = [
      {
        name: "Họ và tên",
        selector: (row) => row.student?.student_name,
        sortable: true,
        wrap: true,
      },
      {
        name: "Lớp",
        selector: (row) => row.student?.classes[0]?.class_name || "",
        wrap: true,
        sortable: true,
      },
      {
        name: "Tổng tiền",
        selector: (row) => Number(row.trans_amount),
        cell: (row) => (
          <div>{priceFormatterNoDecimal.format(row.trans_amount || 0)}</div>
        ),
        sortable: true,
        right: true,
      },

      {
        name: "Thanh toán",
        selector: (row) => row.pay_status,
        cell: (row) =>
          row.pay_status === 1 ? (
            <div className="text-success">Đã thanh toán</div>
          ) : row.pay_status === 0 ? (
            <div className="text-danger">Chưa thanh toán</div>
          ) : (
            ""
          ),
        sortable: true,
      },
      {
        name: "Trạng thái",
        selector: (row) => row.status,
        cell: (row) =>
          row.status === "allow_payment" ? (
            <div className="text-success">Đã xác nhận</div>
          ) : row.status === "view_only" ? (
            <div className="text-danger">Chưa xác nhận</div>
          ) : (
            ""
          ),
        sortable: true,
      },
      {
        name: "Phương thức",
        selector: (row) => row.setting,
        center: true,
      },
    ];

    const totalRow = {
      trans_amount: this.state.studentsSearch.reduce(
        (acc, cur) => acc + Number(cur.trans_amount),
        0
      ),
      id: "total-student",

      class_name: "Tổng",
      name: "Tổng",
      pay_status: "2",
    };

    const data = [];

    const selectedRows = (items) => {
      this.setState({ selectedRows: items });
    };

    if (this.state.studentsSearch?.length > 0) {
      this.state.studentsSearch.map((item, i) => {
        data.push({
          ...item,
          setting: (
            <div className="d-flex">
              {new Check().permission(["129", "131"], "or") ? (
                <Button
                  size="sm"
                  variant="warning me-1 m-1"
                  type="button"
                  onClick={() => this.getRole(item.id)}
                  title="Chi tiết phiếu thu"
                >
                  <i className="fa-solid fa-pencil"></i>
                </Button>
              ) : null}

              {new Check().permission(["132"]) ? (
                <Button
                  size="sm"
                  variant="danger me-1 m-1"
                  type="button"
                  onClick={() => this.handleDelete(item)}
                  disabled={item.pay_status}
                  title="Xóa phiếu thu"
                >
                  <i className="fas fa-trash"></i>
                </Button>
              ) : null}
            </div>
          ),
        });

        return item;
      });
    }

    const temporaryData = [...data, totalRow];
    const selectableRowDisabled = (row) => {
      return row.id === "total-student";
    };

    return (
      <DataTables
        selectableRows
        data={temporaryData}
        columns={columns}
        clearSelectedRows={this.state.isClear}
        selectedRows={selectedRows}
        onSelectedRowsChange={(item) =>
          this.onSelectedRowsChange(item.selectedRows)
        }
        selectableRowDisabled={selectableRowDisabled}
      />
    );
  }

  refreshRole = () => {
    this.setState({
      bill: {},
    });
  };

  renderRoleList() {
    const modalClose = () => {
      this.setState({ modalRoleShow: false, editModal: false });
      this.refreshRole();
    };

    const modalSubmit = () => {
      this.setState({
        modalRoleShow: false,
        editModal: false,
        billExport: [],
        isClear: true,
      });
      this.refreshRole();
      this.getBills(this.state.startDate, this.state.endDate);
    };

    return (
      <>
        <Card.Header>
          <div className="d-block d-md-flex justify-content-between">
            <div className="d-block d-md-flex">
              <Card.Title className="me-3 d-flex align-items-center align-content-center">
                <i className="fas fa-list me-1"></i> Danh sách phiếu thu tháng{" "}
                {this.state.startDate?.getMonth() + 1}/
                {this.state.startDate?.getFullYear()}{" "}
              </Card.Title>

              <div className="d-block d-md-flex align-items-center">
                <div
                  className="me-2 d-md-flex d-block align-items-center"
                  style={{ zIndex: "1021" }}
                >
                  <DatePickerCustom
                    selected={this.state.startDate}
                    onChange={(date) => {
                      this.setState({
                        startDate: date,
                        endDate: getlastDayOfMonth(
                          date?.getFullYear(),
                          date?.getMonth()
                        ),
                      });
                      this.getClassRoom(
                        parseInt(date / 1000),
                        parseInt(
                          getlastDayOfMonth(
                            date?.getFullYear(),
                            date?.getMonth()
                          ) / 1000
                        )
                      );
                      this.getBills(
                        date,
                        getlastDayOfMonth(date?.getFullYear(), date?.getMonth())
                      );
                    }}
                    showMonthYearPicker
                  />
                </div>
              </div>
            </div>

            <div className="d-md-flex d-block">
              <Button
                variant="danger mt-2 mt-md-0 me-2"
                size="sm"
                hidden={this.state.choiceClass?.value < 1}
                onClick={() => this.setState({ showTypeExport: true })}
                title="Xuất phiếu thu"
              >
                <i className="fa-solid fa-file-pdf me-2"></i>Xuất PDF
              </Button>
              <Button
                variant="info mt-2 mt-md-0 me-2"
                size="sm"
                disabled={this.state.billExport?.length < 1}
                onClick={() => {
                  swal({
                    title: "Chọn trạng thái phiếu thu",
                    icon: "warning",
                    buttons: {
                      cancel: "Đóng",
                      catch: {
                        text: "Xác nhận",
                        value: "true",
                        className: "btn-success",
                      },
                      defeat: {
                        text: "Hủy xác nhận",
                        value: "false",
                        className: "btn-danger",
                      },
                    },
                  }).then((value) => {
                    switch (value) {
                      case "false":
                        this.confirmationPayment("view_only");
                        break;
                      case "true":
                        this.confirmationPayment("allow_payment");
                        break;
                      default:
                        break;
                    }
                  });
                }}
                title="Thay đổi trạng thái của phiếu thu"
              >
                <i className="fa-solid fa-group-arrows-rotate"></i> Xác nhận
              </Button>

              <Button
                variant="warning mt-2 mt-md-0 me-2"
                size="sm"
                onClick={() => this.tuitionReminder()}
                title="Thông báo này sẽ gửi đến tất cả phiếu thu chưa thanh toán"
              >
                <i className="fa-solid fa-bell"></i> Nhắc nhỡ
              </Button>

              <Button
                type="button"
                variant="success mt-2  mt-md-0 me-2"
                size="sm"
                onClick={() =>
                  this.exportListBillCreated(this.state.studentsSearch)
                }
                disabled={this.state.studentsSearch?.length < 1}
              >
                <i className="fa-solid fa-file-excel"></i> Xuất DSPT
              </Button>
              {new Check().permission(["63"]) ? (
                <Button
                  type="button"
                  variant="success mt-2 mt-md-0"
                  size="sm"
                  onClick={() => this.setState({ modalRoleShow: true })}
                >
                  <i className="fa-solid fa-plus"></i> Thêm
                </Button>
              ) : null}
            </div>
          </div>
        </Card.Header>

        <Card.Body>
          <Row>
            <Col md={3} xs={6} style={{ zIndex: 3 }} className="mb-2">
              <Select
                options={this.state.listClass}
                value={this.state.choiceClass}
                onChange={(choice) => {
                  this.setState(
                    {
                      choiceClass: choice,
                      isClear: true,
                      billExport: [],
                    },
                    () => {
                      this.filterStudent(choice?.value, this.state.filterText);
                    }
                  );
                }}
              ></Select>
            </Col>

            <Col md={3} xs={6}>
              <Form.Control
                className="mb-1"
                style={{ height: 38 }}
                type="text"
                placeholder="Tìm kiếm"
                title="Nhập tên học sinh hoặc Mã phiếu"
                value={this.state.filterText}
                onChange={(e) => {
                  this.filterStudent(
                    this.state.choiceClass?.value,
                    e.target.value
                  );
                }}
              />
            </Col>
          </Row>
          {console.log("this.state.loadingBills>>", this.state.loadingBills)}
          {this.state.loadingBills ? this.loading() : this.dataTable()}
        </Card.Body>

        {this.state.modalRoleShow ? (
          <>
            <BillModal
              show={this.state.modalRoleShow}
              databill={this.state.bills}
              startDate={this.state.startDate}
              dataClass={this.state.listClass}
              discounts={this.state.discounts}
              onHide={modalClose}
              modal={{
                title: "Thêm phiếu thu",
                button: (
                  <>
                    <i className="fa-solid fa-check" /> Lưu
                  </>
                ),
              }}
              dataBill={this.state.bill}
              submit={modalSubmit}
            />
          </>
        ) : null}
        {this.state.editModal ? (
          <>
            <BillEditModal
              show={this.state.editModal}
              startDate={this.state.startDate}
              onHide={modalClose}
              modal={{
                title: "Chi tiết phiếu thu",
                button: (
                  <>
                    <i className="fa-solid fa-check" /> Lưu
                  </>
                ),
              }}
              dataBill={this.state.bill}
              submit={modalSubmit}
              discounts={this.state.discounts}
            />
          </>
        ) : null}
      </>
    );
  }

  render() {
    return (
      <>
        <Container fluid className="px-3">
          <Breadcrumb className="mb-2 ">
            <Breadcrumb.Item active>Học phí</Breadcrumb.Item>
            <Breadcrumb.Item active>Phiếu thu</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Col>
              <Card className="mb-4">{this.renderRoleList()}</Card>
            </Col>
            {this.state.showTypeExport && (
              <Modal
                show={this.state.showTypeExport}
                onHide={() => {
                  this.setState({ showTypeExport: false });
                }}
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Chọn mẫu file cần xuất</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div>
                    {this.state.choiceClass?.value > 0 && (
                      <label
                        className={`align-content-center align-items-center me-2 d-flex mb-2`}
                      >
                        <input
                          type="checkbox"
                          name="type"
                          title={
                            this.state.billExport?.length === 1 &&
                            this.state.billExport[0]?.status === "view_only"
                              ? "Vui lòng xác nhận phiếu thu"
                              : "Hiển thị QR Code"
                          }
                          checked={this.state.showQr}
                          disabled={
                            (this.state.billExport?.length === 1 &&
                              this.state.billExport[0]?.status ===
                                "view_only") ||
                            this.state.billExport[0]?.pay_status == 1
                          }
                          onChange={() =>
                            this.setState({
                              showQr: !this.state.showQr,
                              providerBank: "",
                            })
                          }
                        />
                        <span className="ms-1 " style={{ marginTop: "-2px" }}>
                          Đính kèm QR
                        </span>
                      </label>
                    )}
                    {this.state.showQr && (
                      <div className="ms-3">
                        <label className="align-content-center align-items-center me-2 d-flex mb-2 ">
                          <input
                            type="checkbox"
                            name="type"
                            title="Hiển thị QR Code"
                            hidden={!this.state.hdbPayment}
                            checked={this.state.providerBank === "HDB_API_KEY"}
                            onChange={() =>
                              this.setState({
                                providerBank:
                                  this.state.hdbPayment?.setting_key,
                              })
                            }
                          />
                          <span className="ms-1 " style={{ marginTop: "-2px" }}>
                            {this.state.hdbPayment?.name}
                          </span>
                        </label>
                        <label className="align-content-center align-items-center me-2 d-flex mb-2">
                          <input
                            type="checkbox"
                            name="type"
                            title="Hiển thị QR Code"
                            hidden={!this.state.vtbPayment}
                            checked={this.state.providerBank === "VTB_API_KEY"}
                            onChange={() =>
                              this.setState({
                                providerBank:
                                  this.state.vtbPayment?.setting_key,
                              })
                            }
                          />
                          <span className="ms-1 " style={{ marginTop: "-2px" }}>
                            {this.state.vtbPayment?.name}
                          </span>
                        </label>
                      </div>
                    )}
                  </div>
                  <div className="d-inline-flex">
                    <PrintMultipleButton
                      schoolInfo={this.state.schoolInfo}
                      item={this.state.billExport}
                      choiceClass={this.state.choiceClass}
                      showQr={this.state.showQr}
                      providerBank={this.state.providerBank}
                    />
                    <PrintMultipleButton2
                      schoolInfo={this.state.schoolInfo}
                      item={this.state.billExport}
                      choiceClass={this.state.choiceClass}
                      bank={this.state.bank}
                      showQr={this.state.showQr}
                      providerBank={this.state.providerBank}
                    />
                  </div>
                  {this.state.providerBank === "HDB_API_KEY" && (
                    <p className="text-muted">
                      <i className="text-danger">**</i>
                      Lưu ý: Nếu chọn thanh toán qua HDBank vui lòng nhập đầy đủ
                      thông tin học sinh về Mã định danh dân cư.
                    </p>
                  )}
                </Modal.Body>
              </Modal>
            )}
          </Row>
        </Container>
      </>
    );
  }
}

export default BillIndex;
