import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Colors,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Colors
);
const AttendanceReport = ({ data }) => {
  const labels = data?.map((item) =>
    new Date(item.date * 1000).toLocaleDateString("vi-VN")
  );

  const totalStudents = data?.map((item) =>
    item?.class?.reduce((sum, cls) => sum + cls.countStudent, 0)
  );

  const totalOffStudents = data.map((item) =>
    item?.class?.reduce((sum, cls) => sum + cls.countStudentOff, 0)
  );

  const chartData = {
    labels,
    datasets: [
      {
        label: "Số học sinh đi học",
        data: totalStudents.map(
          (total, index) => total - totalOffStudents[index]
        ),
        backgroundColor: "rgba(54, 162, 235, 0.6)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
      {
        label: "Số học sinh nghỉ học",
        data: totalOffStudents,
        backgroundColor: "rgba(255, 99, 132, 0.6)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: `Tổng hợp số lượng có mặt / nghỉ của học sinh trong tuần từ ${new Date(
          data[0]?.date * 1000
        ).toLocaleDateString("vi-VN")} đến ${new Date(
          data[data?.length - 1]?.date * 1000
        ).toLocaleDateString("vi-VN")}`,
      },
    },
    scales: {
      x: {
        title: {
          display: false,
          text: "Ngày",
        },
      },
      y: {
        title: {
          display: false,
          text: "Số lượng học sinh",
        },
      },
    },
  };

  return <Bar data={chartData} options={options} />;
};

export default AttendanceReport;
