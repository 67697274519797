import React from "react";
import { Table } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import { dateFormatter } from "../utils/helpers";

const QuantitativeKitchenTable = ({ quantitative }) => {
  if (!quantitative) {
    return "";
  }

  const costPerDays = quantitative.costPerDays || [];

  return (
    <>
      <div className="table-responsive-600">
        <Table bordered>
          <thead className="align-middle text-center">
            <tr>
              <th colSpan={6 + costPerDays.length * 3}>
                TỔNG HỢP ĐỊNH LƯỢNG CHO BẾP (
                {dateFormatter(new Date(quantitative.menu_date * 1000))})
              </th>
            </tr>

            <tr>
              <th rowSpan={2}>BUỔI</th>
              <th rowSpan={2}>TÊN MÓN ĂN </th>
              <th rowSpan={2}>NGUYÊN LIỆU</th>
              <th rowSpan={2}>ĐVT</th>
              <th colSpan={costPerDays.length}>ĐỊNH LƯỢNG CHUẨN</th>
              <th colSpan={costPerDays.length}>
                TỔNG SỐ LƯỢNG <br />
                (suất ăn)
              </th>
              <th colSpan={costPerDays.length}>ĐỊNH LƯỢNG</th>
              <th rowSpan={2}>
                TỔNG ĐỊNH LƯỢNG <br />
                CẦN MUA
              </th>
              <th rowSpan={2}>GHI CHÚ</th>
            </tr>

            <tr className="tr-sticky-second">
              {costPerDays?.map((groupItem) => {
                return <th key={groupItem.id_group}>{groupItem.group_name}</th>;
              })}

              {costPerDays?.map((groupItem) => {
                return <th key={groupItem.id_group}>{groupItem.group_name}</th>;
              })}

              {costPerDays?.map((groupItem) => {
                return <th key={groupItem.id_group}>{groupItem.group_name}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {quantitative.times?.map((timeItem) => {
              let timeQuantityIngredient = 0;

              if (timeItem.foods.length !== 0) {
                timeItem.foods?.map((foodItem) => {
                  if (foodItem.groups[0].ingredents.length === 0) {
                    timeQuantityIngredient++;

                    return foodItem;
                  }

                  return foodItem.groups[0]?.ingredents?.map(
                    (ingredientItem) => {
                      timeQuantityIngredient++;
                      return ingredientItem;
                    }
                  );
                });
              } else {
                return (
                  <tr key={timeItem.id}>
                    <td className="fw-bold">{timeItem.time_name}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    {costPerDays?.map((groupItem) => {
                      return <td key={groupItem.id_group}></td>;
                    })}
                    {costPerDays?.map((groupItem) => {
                      return <td key={groupItem.id_group}></td>;
                    })}
                    {costPerDays?.map((groupItem) => {
                      return <td key={groupItem.id_group}></td>;
                    })}
                    <td></td>
                    <td></td>
                  </tr>
                );
              }

              let ingredientTotalQuantitative1 = 0;

              return (
                <React.Fragment key={timeItem.id}>
                  <tr className="text-end align-middle">
                    <td
                      rowSpan={
                        timeQuantityIngredient !== 0
                          ? timeQuantityIngredient
                          : 1
                      }
                      className="text-center fw-bold"
                    >
                      {timeItem.time_name}
                    </td>
                    <td
                      rowSpan={
                        timeItem.foods[0]?.groups[0]?.ingredents?.length !== 0
                          ? timeItem.foods[0]?.groups[0]?.ingredents?.length
                          : 1
                      }
                      className="text-start fw-bold"
                    >
                      {timeItem.foods[0]?.food_name}
                    </td>
                    <td className="text-start">
                      {
                        timeItem.foods[0]?.groups[0]?.ingredents[0]
                          ?.ingredent_name
                      }
                    </td>
                    <td className="text-start">
                      {
                        timeItem.foods[0]?.groups[0]?.ingredents[0]
                          ?.unit_cook_name
                      }
                    </td>

                    {costPerDays?.map((groupItem) => {
                      const ingQuantity =
                        timeItem.foods[0]?.groups?.find(
                          (groupObj) => groupObj.id === groupItem.id_group
                        )?.ingredents[0]?.ing_quantity || 0;

                      return (
                        <td key={groupItem.id_group}>
                          <CurrencyFormat
                            value={parseFloat(ingQuantity)}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalScale={2}
                          />
                        </td>
                      );
                    })}

                    {costPerDays?.map((groupItem) => {
                      let mealQuantity = 0;
                      if (
                        timeItem.foods[0]?.groups?.some(
                          (groupObj) => groupObj.id === groupItem.id_group
                        )
                      ) {
                        mealQuantity =
                          timeItem.foods[0]?.groups?.find(
                            (groupObj) => groupObj.id === groupItem.id_group
                          )?.totalRegister || 0;
                      }

                      return (
                        <td key={groupItem.id_group}>
                          <CurrencyFormat
                            value={mealQuantity}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalScale={0}
                          />
                        </td>
                      );
                    })}

                    {costPerDays?.map((groupItem) => {
                      const mealQuantity =
                        timeItem.foods[0]?.groups?.find(
                          (groupObj) => groupObj.id === groupItem.id_group
                        )?.totalRegister || 0;
                      const amountQuantity =
                        timeItem.foods[0]?.groups?.find(
                          (groupObj) => groupObj.id === groupItem.id_group
                        )?.ingredents[0]?.ing_quantity || 0;

                      const groupQuantitative = Number(
                        Number(amountQuantity).toFixed(4) * mealQuantity
                      ).toFixed(4);

                      ingredientTotalQuantitative1 +=
                        parseFloat(groupQuantitative);

                      return (
                        <td key={groupItem.id_group}>
                          <CurrencyFormat
                            value={parseFloat(groupQuantitative)}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalScale={2}
                          />
                        </td>
                      );
                    })}

                    <td>
                      <CurrencyFormat
                        value={parseFloat(ingredientTotalQuantitative1)}
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalScale={2}
                      />
                    </td>

                    <td></td>
                  </tr>

                  {timeItem.foods[0]?.groups[0]?.ingredents
                    ?.slice(1, timeItem.foods[0]?.groups[0]?.length)
                    ?.map((ingredientItem) => {
                      let ingredientTotalQuantitative2 = 0;

                      return (
                        <tr
                          key={ingredientItem.id}
                          className="text-end align-middle"
                        >
                          <td className="text-start">
                            {ingredientItem.ingredent_name}
                          </td>
                          <td className="text-start">
                            {ingredientItem.unit_cook_name}
                          </td>

                          {costPerDays?.map((groupItem) => {
                            const ingQuantity =
                              timeItem.foods[0]?.groups
                                ?.find(
                                  (groupObj) =>
                                    groupObj.id === groupItem.id_group
                                )
                                ?.ingredents?.find(
                                  (ingredientObj) =>
                                    ingredientObj.id === ingredientItem.id
                                )?.ing_quantity || 0;

                            return (
                              <td key={groupItem.id_group}>
                                <CurrencyFormat
                                  value={parseFloat(ingQuantity)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                />
                              </td>
                            );
                          })}

                          {costPerDays?.map((groupItem) => {
                            let mealQuantity = 0;
                            if (
                              timeItem.foods[0]?.groups?.some(
                                (groupObj) => groupObj.id === groupItem.id_group
                              )
                            ) {
                              mealQuantity =
                                timeItem.foods[0]?.groups?.find(
                                  (groupObj) =>
                                    groupObj.id === groupItem.id_group
                                )?.totalRegister || 0;
                            }

                            return (
                              <td key={groupItem.id_group}>
                                <CurrencyFormat
                                  value={mealQuantity}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                              </td>
                            );
                          })}

                          {costPerDays?.map((groupItem) => {
                            const mealQuantity =
                              timeItem.foods[0]?.groups?.find(
                                (groupObj) => groupObj.id === groupItem.id_group
                              )?.totalRegister || 0;
                            const amountQuantity =
                              timeItem.foods[0]?.groups
                                ?.find(
                                  (groupObj) =>
                                    groupObj.id === groupItem.id_group
                                )
                                ?.ingredents?.find(
                                  (ingredientObj) =>
                                    ingredientObj.id === ingredientItem.id
                                )?.ing_quantity || 0;

                            const groupQuantitative = Number(
                              Number(amountQuantity).toFixed(4) * mealQuantity
                            ).toFixed(4);

                            ingredientTotalQuantitative2 +=
                              parseFloat(groupQuantitative);

                            return (
                              <td key={groupItem.id_group}>
                                <CurrencyFormat
                                  value={parseFloat(groupQuantitative)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                />
                              </td>
                            );
                          })}

                          <td>
                            <CurrencyFormat
                              value={parseFloat(ingredientTotalQuantitative2)}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={2}
                            />
                          </td>
                          <td></td>
                        </tr>
                      );
                    })}

                  {timeItem.foods
                    .slice(1, timeItem.foods.length)
                    ?.map((foodItem) => {
                      let ingredientTotalQuantitative3 = 0;

                      return (
                        <React.Fragment key={foodItem.id}>
                          <tr className="text-end align-middle">
                            <td
                              rowSpan={
                                foodItem.groups[0]?.ingredents?.length !== 0
                                  ? foodItem.groups[0]?.ingredents?.length
                                  : 1
                              }
                              className="text-start fw-bold"
                            >
                              {foodItem.food_name}
                            </td>

                            <td className="text-start">
                              {
                                foodItem.groups[0]?.ingredents[0]
                                  ?.ingredent_name
                              }
                            </td>

                            <td className="text-start">
                              {
                                foodItem.groups[0]?.ingredents[0]
                                  ?.unit_cook_name
                              }
                            </td>

                            {costPerDays?.map((groupItem) => {
                              const ingQuantity =
                                foodItem.groups?.find(
                                  (groupObj) =>
                                    groupObj.id === groupItem.id_group
                                )?.ingredents[0]?.ing_quantity || 0;

                              return (
                                <td key={groupItem.id_group}>
                                  <CurrencyFormat
                                    value={parseFloat(ingQuantity)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                  />
                                </td>
                              );
                            })}

                            {costPerDays?.map((groupItem) => {
                              let mealQuantity = 0;

                              if (
                                foodItem?.groups?.some(
                                  (groupObj) =>
                                    groupObj.id === groupItem.id_group
                                )
                              ) {
                                mealQuantity =
                                  foodItem?.groups?.find(
                                    (groupObj) =>
                                      groupObj.id === groupItem.id_group
                                  )?.totalRegister || 0;
                              }

                              return (
                                <td key={groupItem.id_group}>{mealQuantity}</td>
                              );
                            })}

                            {costPerDays?.map((groupItem) => {
                              let mealQuantity = 0;
                              let amountQuantity = 0;
                              if (
                                foodItem?.groups?.some(
                                  (groupObj) =>
                                    groupObj.id === groupItem.id_group
                                )
                              ) {
                                mealQuantity =
                                  foodItem?.groups?.find(
                                    (groupObj) =>
                                      groupObj.id === groupItem.id_group
                                  )?.totalRegister || 0;

                                amountQuantity =
                                  foodItem.groups?.find(
                                    (groupObj) =>
                                      groupObj.id === groupItem.id_group
                                  )?.ingredents[0]?.ing_quantity || 0;
                              }

                              const groupQuantitative = Number(
                                Number(amountQuantity).toFixed(4) * mealQuantity
                              ).toFixed(4);

                              ingredientTotalQuantitative3 +=
                                parseFloat(groupQuantitative);

                              return (
                                <td key={groupItem.id_group}>
                                  <CurrencyFormat
                                    value={parseFloat(groupQuantitative)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                  />
                                </td>
                              );
                            })}
                            <td>
                              <CurrencyFormat
                                value={parseFloat(ingredientTotalQuantitative3)}
                                displayType={"text"}
                                thousandSeparator={true}
                                decimalScale={2}
                              />
                            </td>
                            <td></td>
                          </tr>

                          {foodItem.groups[0]?.ingredents
                            ?.slice(1, foodItem.groups[0]?.length)
                            ?.map((ingredientItem) => {
                              let ingredientTotalQuantitative4 = 0;

                              return (
                                <tr
                                  key={ingredientItem.id}
                                  className="text-end align-middle"
                                >
                                  <td className="text-start">
                                    {ingredientItem.ingredent_name}
                                  </td>
                                  <td className="text-start">
                                    {ingredientItem.unit_cook_name}
                                  </td>

                                  {costPerDays?.map((groupItem) => {
                                    const ingQuantity =
                                      foodItem.groups
                                        ?.find(
                                          (groupObj) =>
                                            groupObj.id === groupItem.id_group
                                        )
                                        ?.ingredents?.find(
                                          (ingredientObj) =>
                                            ingredientObj.id ===
                                            ingredientItem.id
                                        )?.ing_quantity || 0;

                                    return (
                                      <td key={groupItem.id_group}>
                                        <CurrencyFormat
                                          value={parseFloat(ingQuantity)}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          decimalScale={2}
                                        />
                                      </td>
                                    );
                                  })}

                                  {costPerDays?.map((groupItem) => {
                                    let mealQuantity = 0;
                                    if (
                                      foodItem?.groups?.some(
                                        (groupObj) =>
                                          groupObj.id === groupItem.id_group
                                      )
                                    ) {
                                      mealQuantity =
                                        foodItem?.groups?.find(
                                          (groupObj) =>
                                            groupObj.id === groupItem.id_group
                                        )?.totalRegister || 0;
                                    }

                                    return (
                                      <td key={groupItem.id_group}>
                                        <CurrencyFormat
                                          value={mealQuantity}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          decimalScale={0}
                                        />
                                      </td>
                                    );
                                  })}

                                  {costPerDays?.map((groupItem) => {
                                    const mealQuantity =
                                      foodItem?.groups?.find(
                                        (groupObj) =>
                                          groupObj.id === groupItem.id_group
                                      )?.totalRegister || 0;
                                    const amountQuantity =
                                      foodItem.groups
                                        ?.find(
                                          (groupObj) =>
                                            groupObj.id === groupItem.id_group
                                        )
                                        ?.ingredents?.find(
                                          (ingredientObj) =>
                                            ingredientObj.id ===
                                            ingredientItem.id
                                        )?.ing_quantity || 0;

                                    const groupQuantitative = Number(
                                      Number(amountQuantity).toFixed(4) *
                                        mealQuantity
                                    ).toFixed(4);

                                    ingredientTotalQuantitative4 +=
                                      parseFloat(groupQuantitative);

                                    return (
                                      <td key={groupItem.id_group}>
                                        <CurrencyFormat
                                          value={parseFloat(groupQuantitative)}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          decimalScale={2}
                                        />
                                      </td>
                                    );
                                  })}
                                  <td>
                                    <CurrencyFormat
                                      value={parseFloat(
                                        ingredientTotalQuantitative4
                                      )}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      decimalScale={2}
                                    />
                                  </td>
                                  <td></td>
                                </tr>
                              );
                            })}
                        </React.Fragment>
                      );
                    })}
                </React.Fragment>
              );
            })}
          </tbody>
        </Table>
      </div>

      <Table
        bordered
        responsive
        id="quantitative-kitchen-table"
        className="d-none"
      >
        <thead>
          <tr>
            <th>
              TỔNG HỢP ĐỊNH LƯỢNG CHO BẾP (
              {dateFormatter(new Date(quantitative.menu_date * 1000))})
            </th>

            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            {costPerDays?.map((groupItem) => {
              return <th key={groupItem.id_group}>empty</th>;
            })}
            {costPerDays?.map((groupItem) => {
              return <th key={groupItem.id_group}>empty</th>;
            })}
            {costPerDays?.map((groupItem) => {
              return <th key={groupItem.id_group}>empty</th>;
            })}
          </tr>
          <tr>
            <th>BUỔI</th>
            <th>TÊN MÓN ĂN </th>
            <th>NGUYÊN LIỆU</th>
            <th>ĐVT</th>
            <th>ĐỊNH LƯỢNG CHUẨN</th>
            {costPerDays?.slice(1).map((groupItem) => {
              return <th key={groupItem.id_group}>empty</th>;
            })}
            <th>TỔNG SỐ LƯỢNG (suất ăn)</th>
            {costPerDays?.slice(1).map((groupItem) => {
              return <th key={groupItem.id_group}>empty</th>;
            })}
            <th>ĐỊNH LƯỢNG</th>
            {costPerDays?.slice(1).map((groupItem) => {
              return <th key={groupItem.id_group}>empty</th>;
            })}
            <th>TỔNG ĐỊNH LƯỢNG CẦN MUA</th>
            <th>GHI CHÚ</th>
          </tr>
          <tr>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>

            {costPerDays?.map((groupItem) => {
              return <th key={groupItem.id_group}>{groupItem.group_name}</th>;
            })}
            {costPerDays?.map((groupItem) => {
              return <th key={groupItem.id_group}>{groupItem.group_name}</th>;
            })}
            {costPerDays?.map((groupItem) => {
              return <th key={groupItem.id_group}>{groupItem.group_name}</th>;
            })}

            <th>empty</th>
            <th>empty</th>
          </tr>
        </thead>
        <tbody>
          {quantitative.times?.map((timeItem) => {
            if (timeItem.foods.length === 0) {
              return (
                <tr key={timeItem.id}>
                  <td>{timeItem.time_name}</td>
                  <td>empty</td>
                  <td>empty</td>
                  <td>empty</td>
                  {costPerDays?.map((groupItem) => {
                    return <td key={groupItem.id_group}>empty</td>;
                  })}
                  {costPerDays?.map((groupItem) => {
                    return <td key={groupItem.id_group}>empty</td>;
                  })}
                  {costPerDays?.map((groupItem) => {
                    return <td key={groupItem.id_group}>empty</td>;
                  })}
                  <td>empty</td>
                  <td>empty</td>
                </tr>
              );
            }

            let ingredientTotalQuantitative1 = 0;

            return (
              <React.Fragment key={timeItem.id}>
                <tr>
                  <td>{timeItem.time_name}</td>
                  <td>{timeItem.foods[0]?.food_name}</td>
                  <td>
                    {" "}
                    {
                      timeItem.foods[0]?.groups[0]?.ingredents[0]
                        ?.ingredent_name
                    }{" "}
                  </td>
                  <td>
                    {
                      timeItem.foods[0]?.groups[0]?.ingredents[0]
                        ?.unit_cook_name
                    }
                  </td>

                  {costPerDays?.map((groupItem) => {
                    const ingQuantity =
                      timeItem.foods[0]?.groups?.find(
                        (groupObj) => groupObj.id === groupItem.id_group
                      )?.ingredents[0]?.ing_quantity || 0;

                    return (
                      <td key={groupItem.id_group}>
                        <CurrencyFormat
                          value={parseFloat(ingQuantity)}
                          displayType={"text"}
                          thousandSeparator={true}
                          decimalScale={2}
                        />
                      </td>
                    );
                  })}

                  {costPerDays?.map((groupItem) => {
                    let mealQuantity = 0;
                    if (
                      timeItem.foods[0]?.groups?.some(
                        (groupObj) => groupObj.id === groupItem.id_group
                      )
                    ) {
                      mealQuantity =
                        timeItem.foods[0]?.groups?.find(
                          (groupObj) => groupObj.id === groupItem.id_group
                        )?.totalRegister || 0;
                    }

                    return (
                      <td key={groupItem.id_group}>
                        <CurrencyFormat
                          value={mealQuantity}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </td>
                    );
                  })}
                  {costPerDays?.map((groupItem) => {
                    const mealQuantity =
                      timeItem.foods[0]?.groups?.find(
                        (groupObj) => groupObj.id === groupItem.id_group
                      )?.totalRegister || 0;
                    const amountQuantity =
                      timeItem.foods[0]?.groups?.find(
                        (groupObj) => groupObj.id === groupItem.id_group
                      )?.ingredents[0]?.ing_quantity || 0;

                    const groupQuantitative = Number(
                      Number(amountQuantity).toFixed(4) * mealQuantity
                    ).toFixed(4);

                    ingredientTotalQuantitative1 +=
                      parseFloat(groupQuantitative);

                    return (
                      <td key={groupItem.id_group}>
                        <CurrencyFormat
                          value={parseFloat(groupQuantitative)}
                          displayType={"text"}
                          thousandSeparator={true}
                          decimalScale={2}
                        />
                      </td>
                    );
                  })}
                  <td>
                    <CurrencyFormat
                      value={parseFloat(ingredientTotalQuantitative1)}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={2}
                    />
                  </td>
                  <td>empty</td>
                </tr>

                {timeItem.foods[0]?.groups[0]?.ingredents
                  ?.slice(1, timeItem.foods[0]?.groups[0]?.length)
                  ?.map((ingredientItem) => {
                    let ingredientTotalQuantitative2 = 0;

                    return (
                      <tr key={ingredientItem.id}>
                        <td>empty</td>
                        <td>empty</td>
                        <td>{ingredientItem.ingredent_name}</td>
                        <td>{ingredientItem.unit_cook_name}</td>
                        {costPerDays?.map((groupItem) => {
                          const ingQuantity =
                            timeItem.foods[0]?.groups
                              ?.find(
                                (groupObj) => groupObj.id === groupItem.id_group
                              )
                              ?.ingredents?.find(
                                (ingredientObj) =>
                                  ingredientObj.id === ingredientItem.id
                              )?.ing_quantity || 0;

                          return (
                            <td key={groupItem.id_group}>
                              <CurrencyFormat
                                value={parseFloat(ingQuantity)}
                                displayType={"text"}
                                thousandSeparator={true}
                                decimalScale={2}
                              />
                            </td>
                          );
                        })}
                        {costPerDays?.map((groupItem) => {
                          let mealQuantity = 0;

                          if (
                            timeItem.foods[0]?.groups?.some(
                              (groupObj) => groupObj.id === groupItem.id_group
                            )
                          ) {
                            mealQuantity =
                              timeItem.foods[0]?.groups?.find(
                                (groupObj) => groupObj.id === groupItem.id_group
                              )?.totalRegister || 0;
                          }

                          return (
                            <td key={groupItem.id_group}>
                              <CurrencyFormat
                                value={parseInt(mealQuantity)}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                            </td>
                          );
                        })}

                        {costPerDays?.map((groupItem) => {
                          const mealQuantity =
                            timeItem.foods[0]?.groups?.find(
                              (groupObj) => groupObj.id === groupItem.id_group
                            )?.totalRegister || 0;

                          const amountQuantity =
                            timeItem.foods[0]?.groups
                              ?.find(
                                (groupObj) => groupObj.id === groupItem.id_group
                              )
                              ?.ingredents?.find(
                                (ingredientObj) =>
                                  ingredientObj.id === ingredientItem.id
                              )?.ing_quantity || 0;

                          const groupQuantitative = Number(
                            Number(amountQuantity).toFixed(4) * mealQuantity
                          ).toFixed(4);

                          ingredientTotalQuantitative2 +=
                            parseFloat(groupQuantitative);

                          return (
                            <td key={groupItem.id_group}>
                              <CurrencyFormat
                                value={parseFloat(groupQuantitative)}
                                displayType={"text"}
                                thousandSeparator={true}
                                decimalScale={2}
                              />
                            </td>
                          );
                        })}

                        <td>
                          <CurrencyFormat
                            value={parseFloat(ingredientTotalQuantitative2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalScale={2}
                          />
                        </td>
                        <td>empty</td>
                      </tr>
                    );
                  })}

                {timeItem.foods
                  .slice(1, timeItem.foods.length)
                  ?.map((foodItem) => {
                    let ingredientTotalQuantitative3 = 0;

                    return (
                      <React.Fragment key={foodItem.id}>
                        <tr>
                          <td>empty</td>
                          <td>{foodItem.food_name}</td>
                          <td>
                            {foodItem.groups[0]?.ingredents[0]?.ingredent_name}
                          </td>
                          <td>
                            {foodItem.groups[0]?.ingredents[0]?.unit_cook_name}
                          </td>

                          {costPerDays?.map((groupItem) => {
                            const ingQuantity =
                              foodItem.groups?.find(
                                (groupObj) => groupObj.id === groupItem.id_group
                              )?.ingredents[0]?.ing_quantity || 0;

                            return (
                              <td key={groupItem.id_group}>
                                <CurrencyFormat
                                  value={parseFloat(ingQuantity)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                />
                              </td>
                            );
                          })}

                          {costPerDays?.map((groupItem) => {
                            let mealQuantity = 0;

                            // if (
                            //   timeItem.foods[0]?.groups?.some(
                            //     (groupObj) => groupObj.id === groupItem.id_group
                            //   )
                            // ) {
                            //   mealQuantity =
                            //     timeItem.foods[0]?.groups?.find(
                            //       (groupObj) =>
                            //         groupObj.id === groupItem.id_group
                            //     )?.totalRegister || 0;
                            // }
                            if (
                              foodItem?.groups?.some(
                                (groupObj) => groupObj.id === groupItem.id_group
                              )
                            ) {
                              mealQuantity =
                                foodItem?.groups?.find(
                                  (groupObj) =>
                                    groupObj.id === groupItem.id_group
                                )?.totalRegister || 0;
                            }

                            return (
                              <td key={groupItem.id_group}>{mealQuantity}</td>
                            );
                          })}

                          {costPerDays?.map((groupItem) => {
                            let mealQuantity = 0;

                            // if (
                            //   timeItem.foods[0]?.groups?.some(
                            //     (groupObj) => groupObj.id === groupItem.id_group
                            //   )
                            // ) {
                            //   mealQuantity =
                            //     timeItem.foods[0]?.groups?.find(
                            //       (groupObj) =>
                            //         groupObj.id === groupItem.id_group
                            //     )?.totalRegister || 0;
                            // }
                            if (
                              foodItem?.groups?.some(
                                (groupObj) => groupObj.id === groupItem.id_group
                              )
                            ) {
                              mealQuantity =
                                foodItem?.groups?.find(
                                  (groupObj) =>
                                    groupObj.id === groupItem.id_group
                                )?.totalRegister || 0;
                            }

                            const amountQuantity =
                              foodItem.groups?.find(
                                (groupObj) => groupObj.id === groupItem.id_group
                              )?.ingredents[0]?.ing_quantity || 0;

                            const groupQuantitative = Number(
                              Number(amountQuantity).toFixed(4) * mealQuantity
                            ).toFixed(4);

                            ingredientTotalQuantitative3 +=
                              parseFloat(groupQuantitative);

                            return (
                              <td key={groupItem.id_group}>
                                <CurrencyFormat
                                  value={parseFloat(groupQuantitative)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                />
                              </td>
                            );
                          })}
                          <td>
                            <CurrencyFormat
                              value={parseFloat(ingredientTotalQuantitative3)}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={2}
                            />
                          </td>
                          <td>empty</td>
                        </tr>

                        {foodItem.groups[0]?.ingredents
                          ?.slice(1, foodItem.groups[0]?.length)
                          ?.map((ingredientItem) => {
                            let ingredientTotalQuantitative4 = 0;

                            return (
                              <tr key={ingredientItem.id}>
                                <td>empty</td>
                                <td>empty</td>
                                <td>{ingredientItem.ingredent_name}</td>
                                <td>{ingredientItem.unit_cook_name}</td>

                                {costPerDays?.map((groupItem) => {
                                  const ingQuantity =
                                    foodItem.groups
                                      ?.find(
                                        (groupObj) =>
                                          groupObj.id === groupItem.id_group
                                      )
                                      ?.ingredents?.find(
                                        (ingredientObj) =>
                                          ingredientObj.id === ingredientItem.id
                                      )?.ing_quantity || 0;
                                  return (
                                    <td key={groupItem.id_group}>
                                      <CurrencyFormat
                                        value={parseFloat(ingQuantity)}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                      />
                                    </td>
                                  );
                                })}
                                {costPerDays?.map((groupItem) => {
                                  let mealQuantity = 0;

                                  if (
                                    foodItem?.groups?.some(
                                      (groupObj) =>
                                        groupObj.id === groupItem.id_group
                                    )
                                  ) {
                                    mealQuantity =
                                      foodItem?.groups?.find(
                                        (groupObj) =>
                                          groupObj.id === groupItem.id_group
                                      )?.totalRegister || 0;
                                  }

                                  return (
                                    <td key={groupItem.id_group}>
                                      <CurrencyFormat
                                        value={mealQuantity}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />
                                    </td>
                                  );
                                })}
                                {costPerDays?.map((groupItem) => {
                                  const mealQuantity =
                                    foodItem?.groups?.find(
                                      (groupObj) =>
                                        groupObj.id === groupItem.id_group
                                    )?.totalRegister || 0;

                                  const amountQuantity =
                                    foodItem.groups
                                      ?.find(
                                        (groupObj) =>
                                          groupObj.id === groupItem.id_group
                                      )
                                      ?.ingredents?.find(
                                        (ingredientObj) =>
                                          ingredientObj.id === ingredientItem.id
                                      )?.ing_quantity || 0;

                                  const groupQuantitative = Number(
                                    Number(amountQuantity).toFixed(4) *
                                      mealQuantity
                                  ).toFixed(4);

                                  ingredientTotalQuantitative4 +=
                                    parseFloat(groupQuantitative);

                                  return (
                                    <td key={groupItem.id_group}>
                                      <CurrencyFormat
                                        value={parseFloat(groupQuantitative)}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                      />
                                    </td>
                                  );
                                })}
                                <td>
                                  <CurrencyFormat
                                    value={parseFloat(
                                      ingredientTotalQuantitative4
                                    )}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                  />
                                </td>
                                <td>empty</td>
                              </tr>
                            );
                          })}
                      </React.Fragment>
                    );
                  })}
              </React.Fragment>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default QuantitativeKitchenTable;
