import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Modal,
  Button,
  Form,
  ProgressBar,
  Alert,
  Container,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import api from "../../../helper/axiosInstance";
import { toast } from "react-toastify";
import { TYPE_PREFIX } from "../../../constants";
import Select from "react-select";
const ImageUploadModal = ({ show, onHide, onUploadSuccess, prefix }) => {
  const [fileObjects, setFileObjects] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isLoadingPreviews, setIsLoadingPreviews] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [errors, setErrors] = useState([]);
  const [visiblePreviews, setVisiblePreviews] = useState([]);
  const [prefixType, setPrefixType] = useState(
    prefix?.length > 0 ? prefix[0] : TYPE_PREFIX[0]
  );

  const MAX_FILE_SIZE = 10 * 1024 * 1024;
  const MAX_FILES = 50;
  const ALLOWED_TYPES = ["image/jpeg", "image/png", "image/gif", "image/webp"];
  const PREVIEW_BATCH_SIZE = 1;
  const PREVIEW_DELAY = 100;

  useEffect(() => {
    return () => {
      fileObjects.forEach((fileObj) => {
        if (fileObj.previewUrl) {
          URL.revokeObjectURL(fileObj.previewUrl);
        }
      });
    };
  }, [fileObjects]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight;
      updateVisiblePreviews();
    };

    const updateVisiblePreviews = () => {
      const visibleFiles = fileObjects;
      // .slice(0, 12);
      setVisiblePreviews(visibleFiles.map((file) => file.id));
    };

    updateVisiblePreviews();
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [fileObjects]);

  const createThumbnail = useCallback((file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const MAX_WIDTH = 300;
          const MAX_HEIGHT = 200;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height = Math.round((height * MAX_WIDTH) / width);
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width = Math.round((width * MAX_HEIGHT) / height);
              height = MAX_HEIGHT;
            }
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              const thumbnailUrl = URL.createObjectURL(blob);
              resolve(thumbnailUrl);
            },
            file.type,
            0.7
            // 70%
          );
        };
        img.onerror = () => reject(new Error("Failed to load image"));
        img.src = event.target.result;
      };
      reader.onerror = () => reject(new Error("Failed to read file"));
      reader.readAsDataURL(file);
    });
  }, []);

  const validateFiles = (fileList) => {
    const newErrors = [];
    const validFiles = [];

    if (fileList.length > MAX_FILES) {
      newErrors.push(`Chỉ được chọn tối đa ${MAX_FILES} hình ảnh.`);
      return { validFiles, newErrors, fileCount: fileList.length };
    }

    Array.from(fileList).forEach((file) => {
      if (!ALLOWED_TYPES.includes(file.type)) {
        newErrors.push(
          `File "${file.name}" không phải là định dạng hình ảnh hợp lệ.`
        );
      } else if (file.size > MAX_FILE_SIZE) {
        newErrors.push(
          `File "${file.name}" vượt quá kích thước tối đa (10MB).`
        );
      } else {
        validFiles.push(file);
      }
    });

    return {
      validFiles,
      newErrors,
      fileCount: fileList.length,
    };
  };

  const createPreviews = async (validFiles) => {
    setIsLoadingPreviews(true);
    setErrors([]);

    try {
      const newFileObjects = [];

      for (let i = 0; i < validFiles?.length; i += PREVIEW_BATCH_SIZE) {
        const batch = validFiles?.slice(i, i + PREVIEW_BATCH_SIZE);

        const batchPromises = batch.map(async (file) => {
          try {
            const thumbnailUrl = await createThumbnail(file);
            return {
              file,
              previewUrl: thumbnailUrl,
              id: Math.random().toString(36).substring(2, 11),
            };
          } catch (error) {
            console.error("err", file.name, error);
            return {
              file,
              previewUrl: null,
              id: Math.random().toString(36).substring(2, 11),
              error: true,
            };
          }
        });

        const batchObjects = await Promise.all(batchPromises);
        newFileObjects.push(...batchObjects);

        setFileObjects((prevFileObjects) => [
          ...prevFileObjects,
          ...batchObjects,
        ]);

        setVisiblePreviews((prev) => [
          ...prev,
          ...batchObjects.map((obj) => obj.id),
        ]);

        if (i + PREVIEW_BATCH_SIZE < validFiles.length) {
          await new Promise((resolve) => setTimeout(resolve, PREVIEW_DELAY));
        }
      }

      return newFileObjects;
    } catch (error) {
      console.error("Error in createPreviews:", error);
      setErrors((prev) => [
        ...prev,
        "Có lỗi xảy ra khi tạo xem trước hình ảnh.",
      ]);
      return [];
    } finally {
      setIsLoadingPreviews(false);
    }
  };

  const handleFileSelect = async (e) => {
    fileObjects.forEach((fileObj) => {
      if (fileObj.previewUrl) {
        URL.revokeObjectURL(fileObj.previewUrl);
      }
    });

    setFileObjects([]);
    setVisiblePreviews([]);
    setErrors([]);

    const { validFiles, newErrors, fileCount } = validateFiles(e.target.files);

    if (newErrors.length > 0) {
      setErrors(newErrors);
    }

    if (validFiles.length > 0) {
      await createPreviews(validFiles);
    } else if (fileCount > 0) {
      setErrors(["Không có file nào hợp lệ để tải lên."]);
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();

    if (fileObjects.length === 0) {
      setErrors(["Vui lòng chọn ít nhất một hình ảnh để tải lên."]);
      return;
    }

    setIsUploading(true);
    setErrors([]);

    const formData = new FormData();
    fileObjects.forEach((fileObj) => {
      formData.append("files", fileObj.file);
    });

    try {
      const response = await api({
        method: "post",
        url: `files-upload?project=kafood&prefix=${prefixType?.value}`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          setUploadProgress(progress);
        },
      });

      toast(response.data.message || "Tải lên thành công!", {
        type: "success",
      });
      onUploadSuccess();

      fileObjects.forEach((fileObj) => {
        if (fileObj.previewUrl) {
          URL.revokeObjectURL(fileObj.previewUrl);
        }
      });

      setFileObjects([]);
      onHide();
    } catch (err) {
      setErrors([err.response?.data?.messages || "Lỗi khi tải lên hình ảnh"]);
      toast.error(err.response?.data?.messages || "Đã có lỗi xảy ra");
    } finally {
      setIsUploading(false);
    }
  };

  const removeFile = (id) => {
    const fileToRemove = fileObjects.find((fileObj) => fileObj.id === id);
    if (fileToRemove && fileToRemove.previewUrl) {
      URL.revokeObjectURL(fileToRemove.previewUrl);
    }
    setFileObjects(fileObjects.filter((fileObj) => fileObj.id !== id));
    setVisiblePreviews(visiblePreviews.filter((visibleId) => visibleId !== id));
  };

  const clearAllFiles = () => {
    fileObjects.forEach((fileObj) => {
      if (fileObj.previewUrl) {
        URL.revokeObjectURL(fileObj.previewUrl);
      }
    });
    setFileObjects([]);
    setVisiblePreviews([]);
  };
  const fileInputRef = useRef(null);

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title className="d-flex align-items-center">
          <span className="me-2">Thêm hình ảnh mới</span>
          <Select
            options={TYPE_PREFIX}
            value={prefixType}
            placeholder={<div>Chọn loại hình ảnh</div>}
            onChange={(choice) => {
              console.log(choice, "cho");

              setPrefixType(choice);
            }}
            // isMulti
          ></Select>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleUpload}>
          <div title="Chọn file" style={{ cursor: "pointer" }}>
            <div
              onClick={handleClick}
              className="border border-dashed p-4 text-center rounded-md"
              disabled={isLoadingPreviews || isUploading}
            >
              <i className="fa-solid fa-cloud-arrow-up  text-primary text-xxl-center fa-3x"></i>
              <p>
                Chọn file hình ảnh (
                {prefixType?.value !== "logo" && `tối đa ${MAX_FILES} hình, `}
                mỗi hình dưới 10MB)
              </p>
            </div>
            <div>
              <input
                type="file"
                accept="image/*"
                multiple={prefixType?.value !== "logo"}
                ref={fileInputRef}
                onChange={handleFileSelect}
                hidden
                disabled={isLoadingPreviews || isUploading}
              />
            </div>

            <small className="text-muted">
              Định dạng hỗ trợ: JPG, PNG, GIF, WEBP
            </small>
          </div>

          <br />
          {errors.length > 0 && (
            <Alert variant="danger" className="mb-3">
              <ul className="mb-0">
                {errors.map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            </Alert>
          )}

          {isUploading && (
            <div className="mb-4">
              <p className="mb-2">Đang tải lên: {uploadProgress}%</p>
              <ProgressBar
                now={uploadProgress}
                label={`${uploadProgress}%`}
                variant="primary"
                animated
              />
            </div>
          )}

          {isLoadingPreviews && (
            <div className="d-flex align-items-center mb-3">
              <Spinner animation="border" size="sm" className="me-2" />
              <span>
                Đang tạo xem trước ({/* {fileObjects.length} /{" "} */}
                {fileObjects.length + (isLoadingPreviews ? "..." : "0")})
              </span>
            </div>
          )}

          {fileObjects.length > 0 && (
            <div className="mb-4">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <p className="fw-bold m-0">
                  Xem trước ({fileObjects.length} hình):
                </p>
                <Button
                  variant="outline-danger"
                  size="sm"
                  onClick={clearAllFiles}
                  disabled={isLoadingPreviews || isUploading}
                >
                  Xóa tất cả
                </Button>
              </div>
              <Container fluid className="p-0">
                <Row>
                  {fileObjects.map((fileObj) => (
                    <Col xs={6} md={4} lg={3} className="mb-3" key={fileObj.id}>
                      <div className="position-relative">
                        <div
                          className="img-thumbnail"
                          style={{
                            width: "100%",
                            height: "120px",
                            position: "relative",
                            overflow: "hidden",
                            background: "#f8f9fa",
                          }}
                        >
                          {visiblePreviews.includes(fileObj.id) ? (
                            fileObj.error ? (
                              <div className="d-flex align-items-center justify-content-center h-100 text-danger">
                                <small>Lỗi tải hình</small>
                              </div>
                            ) : (
                              <img
                                src={
                                  fileObj.previewUrl ||
                                  "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Crect width='100' height='100' fill='%23f0f0f0'/%3E%3Cpath d='M30,40 L70,40 L70,70 L30,70 Z' fill='%23ddd'/%3E%3Cpath d='M50,20 C55.5228,20 60,24.4772 60,30 C60,35.5228 55.5228,40 50,40 C44.4772,40 40,35.5228 40,30 C40,24.4772 44.4772,20 50,20 Z' fill='%23ddd'/%3E%3C/svg%3E"
                                }
                                alt={`Xem trước ${fileObj.file.name}`}
                                loading="lazy"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src =
                                    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Crect width='100' height='100' fill='%23f0f0f0'/%3E%3Cpath d='M30,40 L70,40 L70,70 L30,70 Z' fill='%23ddd'/%3E%3Cpath d='M50,20 C55.5228,20 60,24.4772 60,30 C60,35.5228 55.5228,40 50,40 C44.4772,40 40,35.5228 40,30 C40,24.4772 44.4772,20 50,20 Z' fill='%23ddd'/%3E%3C/svg%3E";
                                }}
                              />
                            )
                          ) : (
                            <div className="d-flex align-items-center justify-content-center h-100">
                              <Spinner animation="border" size="sm" />
                            </div>
                          )}
                          <div
                            className="position-absolute bottom-0 start-0 small p-1 bg-light text-truncate"
                            style={{ width: "100%", fontSize: "10px" }}
                          >
                            {fileObj.file.name}
                          </div>
                        </div>
                        <Button
                          variant="danger"
                          size="sm"
                          className="position-absolute top-0 end-0 m-1 p-0 d-flex align-items-center justify-content-center"
                          style={{
                            width: "22px",
                            height: "22px",
                            borderRadius: "50%",
                          }}
                          title="Xóa"
                          onClick={() => removeFile(fileObj.id)}
                        >
                          x
                        </Button>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Container>
            </div>
          )}

          <div className="d-flex justify-content-end">
            <Button
              variant="outline-secondary me-2"
              onClick={onHide}
              disabled={isUploading}
            >
              Hủy
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={
                fileObjects.length === 0 || isUploading || isLoadingPreviews
              }
              className="py-2"
            >
              {isUploading
                ? "Đang tải lên..."
                : `Tải lên ${fileObjects.length} hình ảnh`}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ImageUploadModal;
