import React from "react";
import { Table } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";

class MarketExpenseReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      voucher: props.voucher,
      schoolInfo: props.schoolInfo,
      listDay: props.listDay,
      daySelect: props.daySelect,
      totalMealsDate: props.totalMealsDate,
      groups: props.groups,
      menu: props.menu,
      totalIngredientExpensesDate: props.totalIngredientExpensesDate,
      totalRevenueDate: props.totalRevenueDate,
      totalRefundDate: props.totalRefundDate,
      totalFeeOtherDate: props.totalFeeOtherDate,
      totalMoneyDay: props.totalMoneyDay,
      totalMoneyMonth: props.totalMoneyMonth,
      settings: props.settings,
      timestampChose: props.timestampChose,
      mealQuantity: props.mealQuantity,
    };
  }

  getSelectedDay() {
    return this.state.listDay.filter(
      (item) => item.id === this.state.daySelect
    )[0];
  }

  renderMarketItems(items, title, sumMoney) {
    return (
      <>
        <tr>
          <th colSpan={6}>{title}</th>
        </tr>
        {items.map((item, index) => {
          const quantity = Number(item.ing_quantity).toFixed(4);
          const price = Number(item.ing_price).toFixed(0);
          const totalAmount = Number(price * quantity).toFixed(0);

          return (
            <tr key={`${title.toLowerCase()}-item-${index}`}>
              <td className="text-center">{index + 1}</td>
              <td>{item.ingredent_name}</td>
              <td>{item.unit_market_name}</td>
              <td className="text-end">
                <CurrencyFormat
                  value={parseFloat(quantity)}
                  thousandSeparator={true}
                  allowNegative={false}
                  isNumericString
                  displayType="text"
                />
              </td>
              <td className="text-end">
                <CurrencyFormat
                  value={parseInt(price)}
                  thousandSeparator={true}
                  allowNegative={false}
                  isNumericString
                  displayType="text"
                />
              </td>
              <td className="text-end">
                <CurrencyFormat
                  value={parseInt(totalAmount)}
                  thousandSeparator={true}
                  allowNegative={false}
                  isNumericString
                  displayType="text"
                />
              </td>
            </tr>
          );
        })}
        <tr>
          <th colSpan={5} className="text-end">
            {`Cộng ${title.toLowerCase()}:`}
          </th>
          <th className="text-end">
            <CurrencyFormat
              value={parseInt(sumMoney)}
              thousandSeparator={true}
              allowNegative={false}
              isNumericString
              displayType="text"
            />
          </th>
        </tr>
      </>
    );
  }

  renderMenuItems() {
    return this.state.menu?.times?.map((time, timeIndex) => (
      <span key={`time-${timeIndex}`}>
        <strong>
          Ăn {time.time_name}: <br />
        </strong>
        {this.state.menu?.days
          ?.filter((e) => e.id_day === this.state.daySelect)[0]
          ?.times?.filter((e) => e.time_name === time.time_name)[0]
          ?.foods?.map((food, foodIndex) => (
            <span key={`time-${timeIndex}-food-${foodIndex}`}>
              - {food.food_name} <br />
            </span>
          ))}
      </span>
    ));
  }

  renderSpendingNorms() {
    return this.state?.groups.map((item, index) => (
      <span key={`spending-norm-${index}`}>
        <span>
          {item.group_name}:{" "}
          <CurrencyFormat
            value={parseFloat(item.fee_meal) + parseFloat(item.fee_other)}
            thousandSeparator={true}
            allowNegative={false}
            isNumericString
            displayType="text"
          />{" "}
          / Bé
        </span>
        <br />
      </span>
    ));
  }

  renderMainTable() {
    const selectedDay = this.getSelectedDay();
    const storeItems =
      this.state.voucher?.ingredents?.filter(
        (item) => item.ingredent_type === 2
      ) || [];
    const marketItems =
      this.state.voucher?.ingredents?.filter(
        (item) => item.ingredent_type === 1
      ) || [];

    let sumMoneyMarket = 0;
    let sumMoneyStore = 0;

    // Calculate sum for market items
    marketItems.forEach((item) => {
      const qty = Number(item.ing_quantity).toFixed(4);
      const price = Number(item.ing_price).toFixed(0);
      const intoMoney = Number(price * qty).toFixed(0);
      sumMoneyMarket += parseInt(intoMoney);
    });

    // Calculate sum for store items
    storeItems.forEach((item) => {
      const qty = Number(item.ing_quantity).toFixed(4);
      const price = Number(item.ing_price).toFixed(0);
      const intoMoney = Number(price * qty).toFixed(0);
      sumMoneyStore += parseInt(intoMoney);
    });

    return (
      <Table bordered>
        <thead>
          <tr className="align-middle">
            <th colSpan={4}>{this.state.schoolInfo.school_name}</th>
            <td colSpan={3} className="text-end">
              {selectedDay?.day_name} Ngày {selectedDay?.day.getDate()} Tháng{" "}
              {selectedDay?.day.getMonth() + 1} Năm{" "}
              {selectedDay?.day.getFullYear()}
            </td>
          </tr>
          <tr className="text-center align-middle">
            <th colSpan={7}>BẢNG CÔNG KHAI CHI CHỢ</th>
          </tr>
          <tr className="align-middle">
            <td colSpan={4}>
              <strong>Tổng số suất ăn: {this.state.totalMealsDate}</strong>
            </td>
            <td colSpan={3} className="text-end">
              <strong>Định mức chi:</strong> {this.renderSpendingNorms()}
            </td>
          </tr>
          <tr>
            <th className="text-center">Thực đơn</th>
            <th className="text-center">STT</th>
            <th className="text-center">Tên thực phẩm</th>
            <th className="text-center">ĐVT</th>
            <th className="text-center">Số lượng</th>
            <th className="text-center">Đơn giá</th>
            <th className="text-center">Thành tiền</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowSpan={marketItems.length + storeItems.length + 3 + 2}>
              {this.renderMenuItems()}
            </td>
          </tr>
          {this.renderMarketItems(marketItems, "Hàng chợ", sumMoneyMarket)}
          {this.renderMarketItems(storeItems, "Hàng kho", sumMoneyStore)}
        </tbody>
        <tfoot>
          <tr>
            <th colSpan={6}>Tổng cộng tiền thực phẩm</th>
            <th className="text-end">
              <CurrencyFormat
                value={this.state.totalIngredientExpensesDate}
                thousandSeparator={true}
                allowNegative={false}
                isNumericString
                displayType="text"
              />
            </th>
          </tr>
          <tr>
            <td colSpan={6}>
              <strong>Tổng thu trong ngày</strong>
            </td>
            <th className="text-end">
              <CurrencyFormat
                value={parseInt(this.state.totalRevenueDate)}
                thousandSeparator={true}
                allowNegative={false}
                isNumericString
                displayType="text"
              />
            </th>
          </tr>
          <tr>
            <td colSpan={6}>
              <strong>Tổng tiền trả lại</strong>&nbsp;
            </td>
            <th className="text-end">
              <CurrencyFormat
                value={this.state.totalRefundDate}
                thousandSeparator={true}
                allowNegative={false}
                isNumericString
                displayType="text"
              />
            </th>
          </tr>

          <tr>
            <th colSpan={6}>Tổng chi phí khác trong ngày</th>
            <th className="text-end">
              <CurrencyFormat
                value={this.state.totalFeeOtherDate}
                thousandSeparator={true}
                allowNegative={false}
                isNumericString
                displayType="text"
              />
            </th>
          </tr>
          <tr>
            <th colSpan={6}>Tổng chi trong ngày</th>
            <th className="text-end">
              <CurrencyFormat
                value={
                  this.state.totalIngredientExpensesDate +
                  this.state.totalFeeOtherDate
                }
                thousandSeparator={true}
                allowNegative={false}
                isNumericString
                displayType="text"
              />
            </th>
          </tr>
          <tr>
            <th colSpan={6}>Thừa (thiếu) trong ngày</th>
            <th className="text-end">
              <CurrencyFormat
                value={
                  this.state.totalRevenueDate -
                  (this.state.totalIngredientExpensesDate +
                    this.state.totalFeeOtherDate)
                }
                thousandSeparator={true}
                allowNegative={true}
                displayType="text"
              />
            </th>
          </tr>
          <tr>
            <th colSpan={6}>Lũy kế tháng {selectedDay?.day?.getMonth() + 1}</th>
            <th className="text-end">
              <CurrencyFormat
                value={this.state.totalMoneyMonth}
                thousandSeparator={true}
                allowNegative={true}
                displayType="text"
              />
            </th>
          </tr>
        </tfoot>
      </Table>
    );
  }

  renderPrintTable() {
    const selectedDay = this.getSelectedDay();
    const storeItems =
      this.state.voucher?.ingredents?.filter(
        (item) => item.ingredent_type === 2
      ) || [];
    const marketItems =
      this.state.voucher?.ingredents?.filter(
        (item) => item.ingredent_type === 1
      ) || [];

    let sumMoneyMarket = 0;
    let sumMoneyStore = 0;

    // Calculate sum for market items
    marketItems.forEach((item) => {
      const qty = Number(item.ing_quantity).toFixed(4);
      const price = Number(item.ing_price).toFixed(0);
      const intoMoney = Number(price * qty).toFixed(0);
      sumMoneyMarket += parseInt(intoMoney);
    });

    // Calculate sum for store items
    storeItems.forEach((item) => {
      const qty = Number(item.ing_quantity).toFixed(4);
      const price = Number(item.ing_price).toFixed(0);
      const intoMoney = Number(price * qty).toFixed(0);
      sumMoneyStore += parseInt(intoMoney);
    });

    return (
      <Table bordered id="finance-day-book2-template3" className="d-none">
        <thead>
          <tr>
            <th colSpan={4}>{this.state.schoolInfo.school_name}123</th>
            <td colSpan={3}>
              {
                this.state.listDay.filter(
                  (item) => item.id === this.state.daySelect
                )[0]?.day_name
              }{" "}
              Ngày{" "}
              {this.state.listDay
                .filter((item) => item.id === this.state.daySelect)[0]
                ?.day.getDate()}{" "}
              Tháng{" "}
              {this.state.listDay
                .filter((item) => item.id === this.state.daySelect)[0]
                ?.day.getMonth() + 1}{" "}
              Năm{" "}
              {this.state.listDay
                .filter((item) => item.id === this.state.daySelect)[0]
                ?.day.getFullYear()}
            </td>
          </tr>
          <tr>
            <th colSpan={7}>BẢNG CÔNG KHAI CHI CHỢ</th>
          </tr>
          <tr>
            <td colSpan={4}>
              Tổng số suất ăn:&nbsp;{this.state.totalMealsDate}
            </td>
            <td colSpan={3}>
              Định mức chi: <br />
              {this.state?.groups.map((item, index) => {
                return (
                  <span key={`groupKey1${index}`}>
                    {item.group_name}:&nbsp;
                    <CurrencyFormat
                      value={
                        parseFloat(item.fee_meal) + parseFloat(item.fee_other)
                      }
                      thousandSeparator={true}
                      allowNegative={false}
                      isNumericString
                      displayType="text"
                    />
                    &nbsp;/ Bé
                    {index + 1 != this.state.groups?.length ? <br /> : null}
                  </span>
                );
              })}
            </td>
          </tr>
          <tr>
            <th>Thực đơn</th>
            <th>STT</th>
            <th>Tên thực phẩm</th>
            <th>ĐVT</th>
            <th>Số lượng</th>
            <th>Đơn giá</th>
            <th>Thành tiền</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {this.state.menu?.times?.map((time, timeIndex) => {
                return (
                  <span key={`timeKey${timeIndex}`}>
                    <strong>
                      Ăn {time.time_name}: <br />
                    </strong>
                    {this.state.menu?.days
                      ?.filter((e) => e.id_day == this.state.daySelect)[0]
                      ?.times?.filter((e) => e.time_name == time.time_name)[0]
                      ?.foods?.map((food, foodIndex) => {
                        return (
                          <span key={`timeKey${timeIndex}FoodKey${foodIndex}`}>
                            - {food.food_name} <br />
                          </span>
                        );
                      })}
                  </span>
                );
              })}
            </td>
            <th>Hàng chợ</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
          </tr>
          {marketItems.map((item, index) => {
            const qtyMarket = Number(item.ing_quantity).toFixed(4);
            const price = Number(item.ing_price).toFixed(0);
            const intoMoneyMarket = Number(price * qtyMarket).toFixed(0);
            return (
              <tr key={`marketItemKey${index}`}>
                <td></td>
                <td>{index + 1}</td>
                <td>{item.ingredent_name}</td>
                <td>{item.unit_market_name}</td>
                <td className="text-end">
                  <CurrencyFormat
                    value={parseFloat(qtyMarket)}
                    thousandSeparator={true}
                    allowNegative={false}
                    isNumericString
                    displayType="text"
                  />
                </td>
                <td className="text-end">
                  <CurrencyFormat
                    value={parseInt(price)}
                    thousandSeparator={true}
                    allowNegative={false}
                    isNumericString
                    displayType="text"
                  />
                </td>
                <td className="text-end">
                  <CurrencyFormat
                    value={parseInt(intoMoneyMarket)}
                    thousandSeparator={true}
                    allowNegative={false}
                    isNumericString
                    displayType="text"
                  />
                </td>
              </tr>
            );
          })}
          <tr>
            <th></th>
            <th className="text-end">Cộng chợ:</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th className="text-end">
              <CurrencyFormat
                value={parseInt(sumMoneyMarket)}
                thousandSeparator={true}
                allowNegative={false}
                isNumericString
                displayType="text"
              />
            </th>
          </tr>
          <tr>
            <th></th>
            <th>Hàng kho</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
          </tr>
          {storeItems.map((item, index) => {
            const qtyStore = Number(item.ing_quantity).toFixed(4);
            const priceStore = Number(item.ing_price).toFixed(0);
            const intoMoneyStore = Number(priceStore * qtyStore).toFixed(0);
            return (
              <tr key={`storeItemKey1${index}`}>
                <td></td>
                <td>{index + 1}</td>
                <td>{item.ingredent_name}</td>
                <td>{item.unit_market_name}</td>
                <td>
                  <CurrencyFormat
                    value={parseFloat(qtyStore)}
                    thousandSeparator={true}
                    allowNegative={false}
                    isNumericString
                    displayType="text"
                  />
                </td>
                <td>
                  <CurrencyFormat
                    value={parseInt(priceStore)}
                    thousandSeparator={true}
                    allowNegative={false}
                    isNumericString
                    displayType="text"
                  />
                </td>
                <td>
                  <CurrencyFormat
                    value={parseInt(intoMoneyStore)}
                    thousandSeparator={true}
                    allowNegative={false}
                    isNumericString
                    displayType="text"
                  />
                </td>
              </tr>
            );
          })}
          <tr>
            <th></th>
            <th>Cộng kho:</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>
              <CurrencyFormat
                value={parseInt(sumMoneyStore)}
                thousandSeparator={true}
                allowNegative={false}
                isNumericString
                displayType="text"
              />
            </th>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th>Tổng cộng tiền thực phẩm</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>
              <CurrencyFormat
                value={this.state.totalIngredientExpensesDate}
                thousandSeparator={true}
                allowNegative={false}
                isNumericString
                displayType="text"
              />
            </th>
          </tr>
          <tr>
            <td>Tổng thu trong ngày</td>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>
              <CurrencyFormat
                value={parseInt(this.state.totalRevenueDate)}
                thousandSeparator={true}
                allowNegative={false}
                isNumericString
                displayType="text"
              />
            </th>
          </tr>
          <tr>
            <th>Tổng tiền trả lại</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>
              <CurrencyFormat
                value={this.state.totalRefundDate}
                thousandSeparator={true}
                allowNegative={false}
                isNumericString
                displayType="text"
              />
            </th>
          </tr>

          <tr>
            <th>Tổng chi phí khác trong ngày</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>
              <CurrencyFormat
                value={this.state.totalFeeOtherDate}
                thousandSeparator={true}
                allowNegative={false}
                isNumericString
                displayType="text"
              />
            </th>
          </tr>
          <tr>
            <th>Tổng chi trong ngày</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>
              <CurrencyFormat
                value={
                  this.state.totalIngredientExpensesDate +
                  this.state.totalFeeOtherDate
                }
                thousandSeparator={true}
                allowNegative={false}
                isNumericString
                displayType="text"
              />
            </th>
          </tr>
          <tr>
            <th>Thừa (thiếu) trong ngày</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>
              <CurrencyFormat
                value={
                  this.state.totalRevenueDate -
                  (this.state.totalIngredientExpensesDate +
                    this.state.totalFeeOtherDate)
                }
                thousandSeparator={true}
                allowNegative={true}
                displayType="text"
              />
            </th>
          </tr>
          <tr>
            <th>
              Lũy kế tháng{" "}
              {this.state.listDay
                .filter((item) => item.id === this.state.daySelect)[0]
                ?.day?.getMonth() + 1}
            </th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>
              <CurrencyFormat
                value={this.state.totalMoneyMonth}
                thousandSeparator={true}
                allowNegative={true}
                displayType="text"
              />
            </th>
          </tr>
        </tfoot>
      </Table>
    );
  }

  render() {
    const marketDailyNotBeenSaved = this.state.voucher || false;
    const haveSetting =
      this.state.settings?.some(
        (item) =>
          item.setting_date_start <= this.state.timestampChose &&
          item.setting_date_end >= this.state.timestampChose
      ) || false;

    if (marketDailyNotBeenSaved) {
      return (
        <>
          {this.renderMainTable()}
          {this.renderPrintTable()}
        </>
      );
    } else if (!haveSetting) {
      return <h6 className="text-muted">Không tìm thấy cài đặt!</h6>;
    } else {
      return <h6 className="text-muted">Vui lòng lưu Phiếu đi chợ ngày!</h6>;
    }
  }
}

export default MarketExpenseReport;
