import * as XLSX from "xlsx-js-style";

const fontStyle = {
  name: "Times New Roman",
};

const borderStyle = {
  top: { style: "thin" },
  bottom: { style: "thin" },
  left: { style: "thin" },
  right: { style: "thin" },
};

const exportNutritionDay = (ingredientIndex) => {
  const table = document.getElementById("nutrition-day-report");

  var ws = XLSX.utils.table_to_sheet(table, { raw: true });

  // Style cell
  const colAlpha = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L"];

  if (ws[`A1`].v === "empty") {
    ws[`A1`].v = " ";
  }

  ws[`A1`].s = {
    font: fontStyle,
    alignment: {
      vertical: "top",
      horizontal: "left",
    },
  };

  ws[`G1`].s = {
    font: fontStyle,
    alignment: {
      vertical: "top",
      horizontal: "right",
    },
  };

  ws[`A2`].s = {
    font: {
      ...fontStyle,
      bold: true,
      sz: 16,
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
    },
  };

  ws[`A3`].s = {
    font: fontStyle,
    alignment: {
      vertical: "top",
      horizontal: "left",
      wrapText: true,
    },
  };

  ws[`G3`].s = {
    font: fontStyle,
    alignment: {
      vertical: "top",
      horizontal: "left",
      wrapText: true,
    },
  };

  colAlpha.map((alpha) => {
    ws[`${alpha}4`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
        wrapText: true,
      },
      border: borderStyle,
    };

    return alpha;
  });

  colAlpha.map((alpha) => {
    ws[`${alpha}5`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
        wrapText: true,
      },
      border: borderStyle,
    };
    return alpha;
  });

  for (let i = 6; i < ingredientIndex + 6; i++) {
    colAlpha.map((alpha) => {
      if (ws[`${alpha}${i}`].v === "empty") {
        ws[`${alpha}${i}`].v = " ";
      }

      ws[`${alpha}${i}`].s = {
        font: fontStyle,
        alignment: {
          horizontal:
            alpha === "A" ? "center" : alpha === "B" ? "left" : "right",
        },
        border: borderStyle,
      };
      return alpha;
    });
  }

  for (let i = ingredientIndex + 6; i < ingredientIndex + 9; i++) {
    colAlpha.map((alpha) => {
      if (ws[`${alpha}${i}`].v === "empty") {
        ws[`${alpha}${i}`].v = " ";
      }

      ws[`${alpha}${i}`].s = {
        font: {
          ...fontStyle,
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal:
            alpha === "A" || alpha === "D" || alpha === "F"
              ? "center"
              : "right",
          wrapText: true,
        },
        border: borderStyle,
      };

      return alpha;
    });
  }

  ws["!cols"] = [
    { wch: 3.5 },
    { wch: 16.5 },
    { wch: 6 },
    { wch: 5 },
    { wch: 5 },
    { wch: 5 },
    { wch: 5 },
    { wch: 6 },
    { wch: 4.5 },
    { wch: 6 },
    { wch: 8 },
    { wch: 7 },
  ];

  const row = [{ hpt: 30 }, { hpt: 30 }, { hpt: 60 }, { hpt: 30 }, { hpt: 30 }];
  ws["!rows"] = row;

  const merge = [
    // Title
    { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } },
    { s: { r: 0, c: 6 }, e: { r: 0, c: 11 } },

    { s: { r: 1, c: 0 }, e: { r: 1, c: 11 } },

    { s: { r: 2, c: 0 }, e: { r: 2, c: 5 } },
    { s: { r: 2, c: 6 }, e: { r: 2, c: 11 } },
    // Title

    // Header
    { s: { r: 3, c: 0 }, e: { r: 4, c: 0 } },
    { s: { r: 3, c: 1 }, e: { r: 4, c: 1 } },
    { s: { r: 3, c: 2 }, e: { r: 4, c: 2 } },

    { s: { r: 3, c: 3 }, e: { r: 3, c: 4 } },
    { s: { r: 3, c: 5 }, e: { r: 3, c: 6 } },

    { s: { r: 3, c: 7 }, e: { r: 4, c: 7 } },
    { s: { r: 3, c: 8 }, e: { r: 4, c: 8 } },
    { s: { r: 3, c: 9 }, e: { r: 4, c: 9 } },
    { s: { r: 3, c: 10 }, e: { r: 4, c: 10 } },
    { s: { r: 3, c: 11 }, e: { r: 4, c: 11 } },

    // Footer
    {
      s: { r: 6 + ingredientIndex - 1, c: 0 },
      e: { r: 6 + ingredientIndex - 1, c: 1 },
    },
    {
      s: { r: 6 + ingredientIndex - 1, c: 3 },
      e: { r: 6 + ingredientIndex - 1, c: 4 },
    },
    {
      s: { r: 6 + ingredientIndex - 1, c: 5 },
      e: { r: 6 + ingredientIndex - 1, c: 6 },
    },
    {
      s: { r: 6 + ingredientIndex - 1, c: 8 },
      e: { r: 6 + ingredientIndex - 1, c: 11 },
    },

    {
      s: { r: 6 + ingredientIndex, c: 0 },
      e: { r: 6 + ingredientIndex, c: 1 },
    },
    {
      s: { r: 6 + ingredientIndex, c: 3 },
      e: { r: 6 + ingredientIndex, c: 4 },
    },
    {
      s: { r: 6 + ingredientIndex, c: 5 },
      e: { r: 6 + ingredientIndex, c: 6 },
    },
    {
      s: { r: 6 + ingredientIndex, c: 8 },
      e: { r: 6 + ingredientIndex, c: 11 },
    },

    {
      s: { r: 6 + ingredientIndex + 1, c: 0 },
      e: { r: 6 + ingredientIndex + 1, c: 1 },
    },
    {
      s: { r: 6 + ingredientIndex + 1, c: 2 },
      e: { r: 6 + ingredientIndex + 1, c: 11 },
    },
  ];
  ws["!merges"] = merge;

  return ws;
};

const exportNutritionWeekOld = (ingredientIndex, dayLength) => {
  const table = document.getElementById("nutrition-week-report");

  var ws = XLSX.utils.table_to_sheet(table, { raw: true });

  const initColAlpha = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
  ];

  const colAlpha =
    dayLength == 7 ? initColAlpha : initColAlpha.slice(0, -(7 - dayLength));

  // Title
  ws[`A1`].s = {
    font: {
      ...fontStyle,
      bold: true,
      sz: 16,
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
    },
  };

  ws[`A2`].s = {
    font: {
      ...fontStyle,
      bold: true,
      sz: 14,
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
    },
  };

  ws[`A3`].s = {
    font: {
      ...fontStyle,
      bold: true,
      sz: 14,
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
    },
  };

  let index = 4;

  // Header
  for (let i = index; i < 7; i++) {
    colAlpha.map((alpha) => {
      if (ws[`${alpha}${i}`].v === "empty") {
        ws[`${alpha}${i}`].v = " ";
      }

      ws[`${alpha}${i}`].s = {
        fill: {
          patternType: "solid",
          fgColor: { theme: 8, tint: 0.3999755851924192, rgb: "b0edee" },
          bgColor: { indexed: 64 },
        },
        font: {
          ...fontStyle,
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
          wrapText: true,
        },
        border: borderStyle,
      };

      return alpha;
    });

    index++;
  }

  const finalLength = ingredientIndex;

  const merge = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 11 + dayLength } },
    { s: { r: 1, c: 0 }, e: { r: 1, c: 11 + dayLength } },
    { s: { r: 2, c: 0 }, e: { r: 2, c: 11 + dayLength } },

    //Header
    { s: { r: 3, c: 0 }, e: { r: 5, c: 0 } },
    { s: { r: 3, c: 1 }, e: { r: 5, c: 1 } },

    { s: { r: 3, c: 2 + dayLength }, e: { r: 3, c: 3 + dayLength } },
    { s: { r: 3, c: 4 + dayLength }, e: { r: 5, c: 4 + dayLength } },
    { s: { r: 3, c: 5 + dayLength }, e: { r: 3, c: 6 + dayLength } },
    { s: { r: 3, c: 7 + dayLength }, e: { r: 3, c: 8 + dayLength } },

    { s: { r: 4, c: 5 + dayLength }, e: { r: 5, c: 5 + dayLength } },
    { s: { r: 4, c: 6 + dayLength }, e: { r: 5, c: 6 + dayLength } },
    { s: { r: 4, c: 7 + dayLength }, e: { r: 5, c: 7 + dayLength } },
    { s: { r: 4, c: 8 + dayLength }, e: { r: 5, c: 8 + dayLength } },

    { s: { r: 3, c: 9 + dayLength }, e: { r: 5, c: 9 + dayLength } },
    { s: { r: 3, c: 10 + dayLength }, e: { r: 5, c: 10 + dayLength } },
    { s: { r: 3, c: 11 + dayLength }, e: { r: 5, c: 11 + dayLength } },

    // Footer
    { s: { r: finalLength + 7, c: 0 }, e: { r: finalLength + 7, c: 1 } },
    { s: { r: finalLength + 8, c: 0 }, e: { r: finalLength + 8, c: 1 } },
    { s: { r: finalLength + 9, c: 0 }, e: { r: finalLength + 9, c: 1 } },

    { s: { r: finalLength + 7, c: 2 }, e: { r: finalLength + 7, c: 3 } },
    { s: { r: finalLength + 8, c: 2 }, e: { r: finalLength + 8, c: 3 } },
    { s: { r: finalLength + 9, c: 2 }, e: { r: finalLength + 9, c: 3 } },

    {
      s: { r: finalLength + 6, c: 19 - 9 - (7 - dayLength) },
      e: { r: finalLength + 6, c: 19 - 8 - (7 - dayLength) },
    },
    {
      s: { r: finalLength + 7, c: 19 - 9 - (7 - dayLength) },
      e: { r: finalLength + 7, c: 19 - 8 - (7 - dayLength) },
    },
    {
      s: { r: finalLength + 8, c: 19 - 9 - (7 - dayLength) },
      e: { r: finalLength + 8, c: 19 - 8 - (7 - dayLength) },
    },
    {
      s: { r: finalLength + 9, c: 19 - 9 - (7 - dayLength) },
      e: { r: finalLength + 9, c: 19 - 8 - (7 - dayLength) },
    },
    {
      s: { r: finalLength + 10, c: 19 - 9 - (7 - dayLength) },
      e: { r: finalLength + 10, c: 19 - 8 - (7 - dayLength) },
    },

    {
      s: { r: finalLength + 7, c: 19 - 7 - (7 - dayLength) },
      e: { r: finalLength + 7, c: 19 - 6 - (7 - dayLength) },
    },
    {
      s: { r: finalLength + 7, c: 19 - 5 - (7 - dayLength) },
      e: { r: finalLength + 7, c: 19 - 4 - (7 - dayLength) },
    },

    {
      s: { r: finalLength + 9, c: 19 - 3 - (7 - dayLength) },
      e: { r: finalLength + 10, c: 19 - 3 - (7 - dayLength) },
    },
    {
      s: { r: finalLength + 9, c: 19 - 2 - (7 - dayLength) },
      e: { r: finalLength + 10, c: 19 - 2 - (7 - dayLength) },
    },

    {
      s: { r: finalLength + 10, c: 19 - 7 - (7 - dayLength) },
      e: { r: finalLength + 10, c: 19 - 6 - (7 - dayLength) },
    },
    {
      s: { r: finalLength + 10, c: 19 - 5 - (7 - dayLength) },
      e: { r: finalLength + 10, c: 19 - 4 - (7 - dayLength) },
    },

    {
      s: { r: finalLength + 11, c: 0 },
      e: { r: finalLength + 11, c: Math.ceil((10 + dayLength) / 2) },
    },
    {
      s: { r: finalLength + 11, c: Math.ceil((10 + dayLength) / 2) + 1 },
      e: { r: finalLength + 11, c: 19 - 1 - (7 - dayLength) },
    },
  ];

  // Body
  for (let i = index; i < finalLength + index; i++) {
    colAlpha.map((alpha) => {
      if (ws[`${alpha}${i}`].v === "empty") {
        ws[`${alpha}${i}`].v = " ";
      }
      ws[`${alpha}${i}`].s = {
        font: fontStyle,
        alignment: {
          vertical: "center",
          horizontal: alpha === "B" || alpha === "R" ? "left" : "right",
          wrapText: true,
        },
        border: borderStyle,
      };
      return alpha;
    });

    merge.push({
      s: { r: i - 1, c: 2 + dayLength },
      e: { r: i - 1, c: 3 + dayLength },
    });
  }

  index += finalLength;

  const colAlphaFooter1 = ["A", "B", "C", "D"];
  const fullColAlphaFooter2 = [
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
  ];
  const colAlphaFooter2 = fullColAlphaFooter2.slice(
    0,
    fullColAlphaFooter2.length - (7 - dayLength)
  );

  // Footer
  let indexFooter1 = index + 1;

  for (let i = indexFooter1; i < 3 + indexFooter1; i++) {
    colAlphaFooter1.map((alpha) => {
      if (ws[`${alpha}${i}`].v === "empty") {
        ws[`${alpha}${i}`].v = " ";
      }

      ws[`${alpha}${i}`].s = {
        fill: {
          patternType: "solid",
          fgColor: {
            theme: 8,
            tint: 0.3999755851924192,
            rgb: alpha === "A" ? "b0edee" : "ffffff",
          },
          bgColor: { indexed: 64 },
        },
        font: {
          ...fontStyle,
          bold: alpha === "A",
        },
        alignment: {
          vertical: "center",
          horizontal: alpha === "A" ? "right" : "center",
          wrapText: true,
        },
        border: borderStyle,
      };
      return alpha;
    });
  }

  let indexFooter2 = index;

  for (let i = indexFooter2; i < 5 + indexFooter2; i++) {
    colAlphaFooter2.map((alpha) => {
      if (ws[`${alpha}${i}`].v === "empty") {
        ws[`${alpha}${i}`].v = " ";
      }
      ws[`${alpha}${i}`].s = {
        font: {
          ...fontStyle,
          bold:
            alpha === colAlphaFooter2[colAlphaFooter2.length - 8] ||
            i === indexFooter2 + 1,
        },
        alignment: {
          vertical: "center",
          horizontal:
            alpha === colAlphaFooter2[colAlphaFooter2.length - 8]
              ? "right"
              : "center",
          wrapText: true,
        },
        border:
          alpha === colAlphaFooter2[colAlphaFooter2.length - 8]
            ? {}
            : borderStyle,
      };
      return alpha;
    });
    ws[`${colAlphaFooter2[colAlphaFooter2.length - 9]}${i}`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "right",
        wrapText: true,
      },
    };
  }

  // Signature
  ws[`A${5 + indexFooter2}`].s = {
    font: {
      ...fontStyle,
      bold: true,
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
    },
  };

  ws[
    `${initColAlpha[Math.ceil((10 + dayLength) / 2) + 1]}${5 + indexFooter2}`
  ].s = {
    font: {
      ...fontStyle,
      bold: true,
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
    },
  };

  // Cols, row width
  let cols = [{ wch: 2 }, { wch: 15 }];
  for (let i = 0; i < dayLength; i++) {
    cols.push({ wch: 5 });
  }
  cols = cols.concat([
    { wch: 6.5 },
    { wch: 5 },
    { wch: 9 },
    { wch: 5 },
    { wch: 5 },
    { wch: 5 },
    { wch: 5 },
    { wch: 6 },
    { wch: 6 },
    { wch: 17 },
  ]);
  ws["!cols"] = cols;

  const row = [{ hpt: 40 }, { hpt: 25 }, { hpt: 25 }, { hpt: 30 }];
  ws["!rows"] = row;

  ws["!merges"] = merge;

  return ws;
};
const exportNutritionWeek = (ingredientIndex, dayLength) => {
  try {
    const table = document.getElementById("nutrition-week-report");

    var ws = XLSX.utils.table_to_sheet(table, { raw: true });

    const initColAlpha = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
    ];

    const colAlpha =
      dayLength === 7 ? initColAlpha : initColAlpha.slice(0, -(7 - dayLength));

    // Title
    ws[`A1`].s = {
      font: {
        ...fontStyle,
        bold: true,
        sz: 16,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };

    ws[`A2`].s = {
      font: {
        ...fontStyle,
        bold: true,
        sz: 14,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };

    ws[`A3`].s = {
      font: {
        ...fontStyle,
        bold: true,
        sz: 14,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };

    let index = 4;

    // Header
    for (let i = index; i < 7; i++) {
      colAlpha.map((alpha) => {
        if (ws[`${alpha}${i}`].v === "empty") {
          ws[`${alpha}${i}`].v = " ";
        }

        ws[`${alpha}${i}`].s = {
          fill: {
            patternType: "solid",
            fgColor: { theme: 8, tint: 0.3999755851924192, rgb: "b0edee" },
            bgColor: { indexed: 64 },
          },
          font: {
            ...fontStyle,
            bold: true,
          },
          alignment: {
            vertical: "center",
            horizontal: "center",
            wrapText: true,
          },
          border: borderStyle,
        };

        return alpha;
      });

      index++;
    }

    const finalLength = ingredientIndex;

    const merge = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 11 + dayLength } },
      { s: { r: 1, c: 0 }, e: { r: 1, c: 11 + dayLength } },
      { s: { r: 2, c: 0 }, e: { r: 2, c: 11 + dayLength } },

      //Header
      { s: { r: 3, c: 0 }, e: { r: 5, c: 0 } },
      { s: { r: 3, c: 1 }, e: { r: 5, c: 1 } },

      { s: { r: 3, c: 2 + dayLength }, e: { r: 3, c: 3 + dayLength } },
      { s: { r: 3, c: 4 + dayLength }, e: { r: 5, c: 4 + dayLength } },
      { s: { r: 3, c: 5 + dayLength }, e: { r: 3, c: 6 + dayLength } },
      { s: { r: 3, c: 7 + dayLength }, e: { r: 3, c: 8 + dayLength } },

      { s: { r: 4, c: 5 + dayLength }, e: { r: 5, c: 5 + dayLength } },
      { s: { r: 4, c: 6 + dayLength }, e: { r: 5, c: 6 + dayLength } },
      { s: { r: 4, c: 7 + dayLength }, e: { r: 5, c: 7 + dayLength } },
      { s: { r: 4, c: 8 + dayLength }, e: { r: 5, c: 8 + dayLength } },

      { s: { r: 3, c: 9 + dayLength }, e: { r: 5, c: 9 + dayLength } },
      { s: { r: 3, c: 10 + dayLength }, e: { r: 5, c: 10 + dayLength } },
      { s: { r: 3, c: 11 + dayLength }, e: { r: 5, c: 11 + dayLength } },

      // Footer

      { s: { r: finalLength + 11, c: 0 }, e: { r: finalLength + 11, c: 2 } },
      { s: { r: finalLength + 12, c: 0 }, e: { r: finalLength + 12, c: 2 } },
      { s: { r: finalLength + 13, c: 0 }, e: { r: finalLength + 13, c: 2 } },

      { s: { r: finalLength + 13, c: 3 }, e: { r: finalLength + 13, c: 4 } },
      { s: { r: finalLength + 11, c: 3 }, e: { r: finalLength + 11, c: 4 } },
      { s: { r: finalLength + 12, c: 3 }, e: { r: finalLength + 12, c: 4 } },

      {
        s: { r: finalLength + 6, c: 21 - 9 - (7 - dayLength) },
        e: { r: finalLength + 6, c: 21 - 8 - (7 - dayLength) },
      },
      {
        s: { r: finalLength + 6, c: 23 - 9 - (7 - dayLength) },
        e: { r: finalLength + 6, c: 23 - 8 - (7 - dayLength) },
      },
      {
        s: { r: finalLength + 6, c: 25 - 9 - (7 - dayLength) },
        e: { r: finalLength + 7, c: 25 - 9 - (7 - dayLength) },
      },
      {
        s: { r: finalLength + 6, c: 26 - 9 - (7 - dayLength) },
        e: { r: finalLength + 7, c: 26 - 9 - (7 - dayLength) },
      },
      {
        s: { r: finalLength + 8, c: 18 - 9 - (7 - dayLength) },
        e: { r: finalLength + 8, c: 19 - 8 - (7 - dayLength) },
      },
      // 8 9 10 11
      {
        s: { r: finalLength + 9, c: 21 - 9 - (7 - dayLength) },
        e: { r: finalLength + 9, c: 21 - 8 - (7 - dayLength) },
      },
      {
        s: { r: finalLength + 9, c: 23 - 9 - (7 - dayLength) },
        e: { r: finalLength + 9, c: 23 - 8 - (7 - dayLength) },
      },
      {
        s: { r: finalLength + 9, c: 18 - 9 - (7 - dayLength) },
        e: { r: finalLength + 9, c: 19 - 8 - (7 - dayLength) },
      },
      {
        s: { r: finalLength + 10, c: 18 - 9 - (7 - dayLength) },
        e: { r: finalLength + 10, c: 19 - 8 - (7 - dayLength) },
      },

      {
        s: { r: finalLength + 8, c: 19 - 3 - (7 - dayLength) },
        e: { r: finalLength + 9, c: 19 - 3 - (7 - dayLength) },
      },
      {
        s: { r: finalLength + 8, c: 19 - 2 - (7 - dayLength) },
        e: { r: finalLength + 9, c: 19 - 2 - (7 - dayLength) },
      },

      {
        s: { r: finalLength + 11, c: 19 - 7 - (7 - dayLength) },
        e: { r: finalLength + 11, c: 19 - 6 - (7 - dayLength) },
      },
      {
        s: { r: finalLength + 11, c: 19 - 5 - (7 - dayLength) },
        e: { r: finalLength + 11, c: 19 - 4 - (7 - dayLength) },
      },
      {
        s: { r: finalLength + 11, c: 18 - 9 - (7 - dayLength) },
        e: { r: finalLength + 11, c: 19 - 8 - (7 - dayLength) },
      },
      {
        s: { r: finalLength + 12, c: 18 - 9 - (7 - dayLength) },
        e: { r: finalLength + 12, c: 19 - 8 - (7 - dayLength) },
      },
      {
        s: { r: finalLength + 13, c: 25 - 9 - (7 - dayLength) },
        e: { r: finalLength + 14, c: 25 - 9 - (7 - dayLength) },
      },
      {
        s: { r: finalLength + 13, c: 26 - 9 - (7 - dayLength) },
        e: { r: finalLength + 14, c: 26 - 9 - (7 - dayLength) },
      },
      {
        s: { r: finalLength + 13, c: 18 - 9 - (7 - dayLength) },
        e: { r: finalLength + 13, c: 19 - 8 - (7 - dayLength) },
      },
      {
        s: { r: finalLength + 14, c: 18 - 9 - (7 - dayLength) },
        e: { r: finalLength + 14, c: 19 - 8 - (7 - dayLength) },
      },
      {
        s: { r: finalLength + 14, c: 21 - 9 - (7 - dayLength) },
        e: { r: finalLength + 14, c: 21 - 8 - (7 - dayLength) },
      },
      {
        s: { r: finalLength + 14, c: 23 - 9 - (7 - dayLength) },
        e: { r: finalLength + 14, c: 23 - 8 - (7 - dayLength) },
      },
      {
        s: { r: finalLength + 15, c: 18 - 9 - (7 - dayLength) },
        e: { r: finalLength + 15, c: 19 - 8 - (7 - dayLength) },
      },
      {
        s: { r: finalLength + 15, c: 21 - 9 - (7 - dayLength) },
        e: { r: finalLength + 15, c: 21 - 8 - (7 - dayLength) },
      },
      {
        s: { r: finalLength + 15, c: 23 - 9 - (7 - dayLength) },
        e: { r: finalLength + 15, c: 23 - 8 - (7 - dayLength) },
      },
      {
        s: { r: finalLength + 16, c: 18 - 9 - (7 - dayLength) },
        e: { r: finalLength + 16, c: 19 - 8 - (7 - dayLength) },
      },
      {
        s: { r: finalLength + 16, c: 21 - 9 - (7 - dayLength) },
        e: { r: finalLength + 16, c: 21 - 8 - (7 - dayLength) },
      },
      {
        s: { r: finalLength + 16, c: 23 - 9 - (7 - dayLength) },
        e: { r: finalLength + 16, c: 23 - 8 - (7 - dayLength) },
      },

      {
        s: { r: finalLength + 18, c: 0 },
        e: { r: finalLength + 18, c: Math.ceil((10 + dayLength) / 2) },
      },
      {
        s: { r: finalLength + 18, c: Math.ceil((10 + dayLength) / 2) + 1 },
        e: { r: finalLength + 18, c: 19 - 1 - (7 - dayLength) },
      },
    ];

    // Body
    for (let i = index; i < finalLength + index; i++) {
      colAlpha.map((alpha) => {
        if (ws[`${alpha}${i}`].v === "empty") {
          ws[`${alpha}${i}`].v = " ";
        }
        ws[`${alpha}${i}`].s = {
          font: fontStyle,
          alignment: {
            vertical: "center",
            horizontal: alpha === "B" || alpha === "R" ? "left" : "right",
            wrapText: true,
          },
          border: borderStyle,
        };
        return alpha;
      });

      merge.push({
        s: { r: i - 1, c: 2 + dayLength },
        e: { r: i - 1, c: 3 + dayLength },
      });
    }

    index += finalLength;

    const colAlphaFooter1 = ["A", "B", "C", "D", "E"];
    const fullColAlphaFooter2 = [
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
    ];
    const colAlphaFooter2 = fullColAlphaFooter2.slice(
      0,
      fullColAlphaFooter2.length - (7 - dayLength) - 1
    );

    // Footer
    let indexFooter1 = index + 4;

    for (let i = indexFooter1; i <= 7 + indexFooter1; i++) {
      colAlphaFooter1.map((alpha) => {
        if (
          ws[`${alpha}${i}`].v === "empty" ||
          ws[`${alpha}${i}`].v === "NaN"
        ) {
          ws[`${alpha}${i}`].v = " ";
        }

        ws[`${alpha}${i}`].s = {
          fill: {
            patternType: "solid",
            fgColor: {
              theme: 8,
              tint: 0.3999755851924192,
              rgb: alpha === "A" ? "b0edee" : "ffffff",
            },
            bgColor: { indexed: 64 },
          },
          font: {
            ...fontStyle,
            bold: alpha === "A",
          },
          alignment: {
            vertical: "center",
            horizontal: alpha === "A" ? "left" : "center",
            wrapText: true,
          },
          border: borderStyle,
        };
        return alpha;
      });
    }

    let indexFooter2 = index;

    for (let i = indexFooter2; i <= 11 + indexFooter2; i++) {
      colAlphaFooter2.map((alpha) => {
        if (
          ws[`${alpha}${i}`].v === "empty" ||
          ws[`${alpha}${i}`].v === "NaN"
        ) {
          ws[`${alpha}${i}`].v = " ";
        }

        ws[`${alpha}${i}`].s = {
          font: {
            ...fontStyle,
            bold:
              alpha === colAlphaFooter2[colAlphaFooter2.length - 8] ||
              i === indexFooter2 + 1 ||
              i === indexFooter2 + 5 ||
              i === indexFooter2 + 6 ||
              i === indexFooter2,
          },
          fill: {
            patternType: "solid",
            fgColor: {
              theme: 8,
              tint: 0.3999755851924192,
              rgb:
                i === indexFooter2 || i === indexFooter2 + 1
                  ? "b0edee"
                  : "ffffff",
            },
            bgColor: { indexed: 64 },
          },
          alignment: {
            vertical: "center",
            horizontal:
              alpha === colAlphaFooter2[colAlphaFooter2.length - 8]
                ? "right"
                : "center",
            wrapText: true,
          },
          border:
            (alpha === colAlphaFooter2[colAlphaFooter2.length - 8] &&
              i == colAlphaFooter2.length - 8) ||
            (alpha === colAlphaFooter2[colAlphaFooter2.length - 7] &&
              i == colAlphaFooter2.length - 7)
              ? {}
              : borderStyle,
        };
        return alpha;
      });

      ws[`${colAlphaFooter2[colAlphaFooter2.length - 9]}${i}`].s = {
        font: {
          ...fontStyle,
          bold: true,
        },
        border: i == indexFooter2 ? {} : borderStyle,
        alignment: {
          vertical: "center",
          horizontal: "left",
          wrapText: true,
        },
      };
    }

    // Signature
    ws[`A${12 + indexFooter2}`].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };

    ws[
      `${initColAlpha[Math.ceil((10 + dayLength) / 2) + 1]}${12 + indexFooter2}`
    ].s = {
      font: {
        ...fontStyle,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };

    // Cols, row width
    let cols = [{ wch: 2 }, { wch: 15 }];
    for (let i = 0; i < dayLength; i++) {
      cols.push({ wch: 5 });
    }
    cols = cols.concat([
      { wch: 7 },
      { wch: 5 },
      { wch: 11 },
      { wch: 5 },
      { wch: 5 },
      { wch: 5 },
      { wch: 5 },
      { wch: 6 },
      { wch: 6 },
      { wch: 17 },
    ]);
    ws["!cols"] = cols;

    const row = [{ hpt: 40 }, { hpt: 25 }, { hpt: 25 }, { hpt: 30 }];
    ws["!rows"] = row;

    ws["!merges"] = merge;

    return ws;
  } catch (e) {
    console.warn(`Lỗi! Xuất excel "Dinh dưỡng tuần": \n`, e);

    return;
  }
};
const exportNutritionMonth = (groupLength, recommendArray) => {
  const table = document.getElementById("nutrition-month-report");

  // Style cell
  const colAlpha = ["A", "B", "C", "D", "E", "F"];

  var ws = XLSX.utils.table_to_sheet(table, { raw: true });

  ws[`A1`].s = {
    font: {
      ...fontStyle,
      bold: true,
    },
    alignment: {
      vertical: "top",
      horizontal: "left",
    },
  };

  ws[`A2`].s = {
    font: {
      ...fontStyle,
      bold: true,
      sz: 16,
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
    },
  };

  colAlpha.map((alpha) => {
    ws[`${alpha}3`].s = {
      font: {
        ...fontStyle,
        bold: true,
        sz: 14,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
        wrapText: true,
      },
      border: borderStyle,
    };

    return alpha;
  });

  const row = [{ hpt: 30 }, { hpt: 60 }, { hpt: 60 }];

  for (let i = 4; i < groupLength * 2 + 4; i++) {
    colAlpha.map((alpha) => {
      if (ws[`${alpha}${i}`].v === "empty") {
        ws[`${alpha}${i}`].v = " ";
      }

      ws[`${alpha}${i}`].s = {
        font: fontStyle,
        border: borderStyle,
        alignment: {
          vertical: "center",
          horizontal: alpha === "A" ? "left" : "center",
          wrapText: true,
        },
      };
      return alpha;
    });

    row.push({});
  }

  ws[`A${groupLength * 2 + 4}`].v = " ";

  ws[`A${groupLength * 2 + 5}`].s = {
    font: {
      ...fontStyle,
      bold: true,
    },
    alignment: {
      vertical: "center",
      horizontal: "left",
    },
  };
  row.push({});

  const merge = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } },
    { s: { r: 1, c: 0 }, e: { r: 1, c: 5 } },

    {
      s: { r: groupLength * 2 + 3, c: 0 },
      e: { r: groupLength * 2 + 3, c: 5 },
    },
  ];

  const mergeIndex = groupLength * 2 + 4;

  for (let i = mergeIndex; i < mergeIndex + 1 + groupLength * 4; i++) {
    merge.push({ s: { r: i, c: 0 }, e: { r: i, c: 5 } });
    row.push({});
  }

  merge.push({
    s: { r: mergeIndex + 1 + groupLength * 4, c: 0 },
    e: { r: mergeIndex + 1 + groupLength * 4, c: 5 },
  });
  merge.push({
    s: { r: mergeIndex + 2 + groupLength * 4, c: 0 },
    e: { r: mergeIndex + 2 + groupLength * 4, c: 5 },
  });

  row.push({});
  row.push({
    hpt: 16 * (recommendArray?.filter((e) => e.isShow)?.length || 1),
  });

  ws[`A${mergeIndex + 2 + groupLength * 4}`].v = " ";

  ws[`A${mergeIndex + 3 + groupLength * 4}`].s = {
    font: fontStyle,
    alignment: {
      vertical: "top",
      horizontal: "left",
      wrapText: true,
    },
  };

  merge.push({
    s: { r: mergeIndex + 3 + groupLength * 4, c: 0 },
    e: { r: mergeIndex + 3 + groupLength * 4, c: 5 },
  });
  ws[`A${mergeIndex + 4 + groupLength * 4}`].v = " ";

  merge.push({
    s: { r: mergeIndex + 4 + groupLength * 4, c: 0 },
    e: { r: mergeIndex + 4 + groupLength * 4, c: 2 },
  });
  merge.push({
    s: { r: mergeIndex + 4 + groupLength * 4, c: 3 },
    e: { r: mergeIndex + 4 + groupLength * 4, c: 5 },
  });
  merge.push({
    s: { r: mergeIndex + 5 + groupLength * 4, c: 0 },
    e: { r: mergeIndex + 5 + groupLength * 4, c: 2 },
  });
  merge.push({
    s: { r: mergeIndex + 5 + groupLength * 4, c: 3 },
    e: { r: mergeIndex + 5 + groupLength * 4, c: 5 },
  });

  ws[`D${mergeIndex + 5 + groupLength * 4}`].s = {
    font: {
      ...fontStyle,
      bold: true,
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
    },
  };

  ws[`A${mergeIndex + 6 + groupLength * 4}`].s = {
    font: {
      ...fontStyle,
      bold: true,
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
    },
  };

  ws[`D${mergeIndex + 6 + groupLength * 4}`].s = {
    font: {
      ...fontStyle,
      bold: true,
    },
    alignment: {
      vertical: "center",
      horizontal: "center",
    },
  };

  ws["!cols"] = [
    { wch: 15 },
    { wch: 25 },
    { wch: 12 },
    { wch: 12 },
    { wch: 12 },
    { wch: 15 },
  ];
  ws["!rows"] = row;
  ws["!merges"] = merge;

  return ws;
};

export {
  exportNutritionDay,
  exportNutritionWeek,
  exportNutritionMonth,
  exportNutritionWeekOld,
};
