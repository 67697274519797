import React from "react";
import { Alert, Table } from "react-bootstrap";
import {
  priceFormatter,
  findeQuantitativeZone,
  isInRange,
  getNutritionalColorByRange,
} from "../../../utils/helpers";
import { ANIMAL_PLANT_RANGE_RATIO } from "../../../../../constants";

const qualified = { color: "green" };
const unqualified = { color: "red" };

const NutritionTable = ({
  quantitative,
  selectedClassGroup,
  isChangeMarket,
}) => {
  const rangeQuantity = findeQuantitativeZone(selectedClassGroup.id_age_group);

  if (!quantitative) {
    return "";
  }

  const currentCostPerDays = quantitative.costPerDays?.find(
    (costPerDay) => costPerDay.id_group === selectedClassGroup.id
  );

  const enegryRatio = Number(currentCostPerDays?.enegry_ratio);
  const recommendFrom = Number(currentCostPerDays?.recommended_from);
  const recommendTo = Number(currentCostPerDays?.recommended_to);

  // Nutrition variables
  let energyStat = 0;
  let proteinStat = 0;
  let lipidStat = 0;
  let glucidStat = 0;

  let animalProteinStat = 0;
  let animalLipidStat = 0;

  quantitative.times?.map((timeItem) => {
    return timeItem.foods.map((foodItem) => {
      foodItem.groups
        .find(
          (groupItem) =>
            groupItem.id === selectedClassGroup.id &&
            groupItem.more_meal === null
        )
        ?.ingredents?.map((ingredientItem) => {
          const ingredientQuantity = isChangeMarket
            ? ingredientItem.tmp_ing_quantity
            : ingredientItem.ing_quantity;

          const gramOneChild = Number(
            ((ingredientQuantity * ingredientItem.volume_market) /
              ingredientItem.volume_cook) *
              ((100 - ingredientItem.ingredent_ratio) / 100)
          );

          if (ingredientItem.id_subspecies === 1) {
            animalProteinStat +=
              (gramOneChild * Number(ingredientItem.ingredent_protein)) / 100;
            animalLipidStat +=
              (gramOneChild * Number(ingredientItem.ingredent_lipid)) / 100;
          }

          energyStat +=
            (gramOneChild * Number(ingredientItem.ingredent_energy)) / 100;
          proteinStat +=
            (gramOneChild * Number(ingredientItem.ingredent_protein)) / 100;
          lipidStat +=
            (gramOneChild * Number(ingredientItem.ingredent_lipid)) / 100;
          glucidStat +=
            (gramOneChild * Number(ingredientItem.ingredent_glucid)) / 100;

          return ingredientItem;
        });

      return foodItem;
    });
  });

  const plgTotal = proteinStat * 4 + lipidStat * 9 + glucidStat * 4;

  const energyPersen =
    (energyStat * 100 * 100) / (recommendFrom * enegryRatio) || 0;

  const recommendEnergy = Math.round(
    Number((recommendFrom * enegryRatio) / 100)
  );

  const proteinRatio = (proteinStat * 100 * 4) / plgTotal;
  const lipidRatio = (lipidStat * 100 * 9) / plgTotal;
  const glucidRatio = (glucidStat * 100 * 4) / plgTotal;

  const proteinColor = getNutritionalColorByRange(
    proteinRatio,
    "PROTEIN",
    rangeQuantity
  );

  const lipidColor = getNutritionalColorByRange(
    lipidRatio,
    "LIPID",
    rangeQuantity
  );

  const glucidColor = getNutritionalColorByRange(
    glucidRatio,
    "GLUCID",
    rangeQuantity
  );

  const animalProteinRatio = (animalProteinStat * 100) / proteinStat;
  const animalLipidRatio = (animalLipidStat * 100) / lipidStat;
  const plantProteinRatio = 100 - animalProteinRatio;
  const plantLipidRatio = 100 - animalLipidRatio;
  const animalProteinColor = getNutritionalColorByRange(
    animalProteinRatio,
    "ANIMAL",
    ANIMAL_PLANT_RANGE_RATIO
  );
  const animalLipidColor = getNutritionalColorByRange(
    animalLipidRatio,
    "ANIMAL",
    ANIMAL_PLANT_RANGE_RATIO
  );
  const plantProteinColor = getNutritionalColorByRange(
    plantProteinRatio,
    "PLANT",
    ANIMAL_PLANT_RANGE_RATIO
  );
  const plantLipidColor = getNutritionalColorByRange(
    plantLipidRatio,
    "PLANT",
    ANIMAL_PLANT_RANGE_RATIO
  );

  return (
    <div className="mb-2">
      {recommendFrom ? (
        <Alert variant="warning mb-2 p-2">
          <div>
            Năng lượng {currentCostPerDays?.group_name}: Cả ngày{" "}
            <div className="d-inline fw-bold">
              {recommendFrom} - {recommendTo} kcal
            </div>
          </div>
          <div>
            Định mức 100% trong trường là{" "}
            <div className="d-inline fw-bold">{recommendEnergy} kcal</div>{" "}
            (chiếm {enegryRatio}% cả ngày).
          </div>
          {/* </div> */}
        </Alert>
      ) : (
        <></>
      )}
      <div>
        <i className="fa-solid fa-square text-danger" /> Cao hơn;{" "}
        <i className="fa-solid fa-square text-success" /> Đạt chuẩn;{" "}
        <i className="fa-solid fa-square text-info" /> Thấp hơn;
      </div>

      <Table bordered responsive size="sm" className="mt-2 mb-1">
        <thead>
          <tr className="align-middle text-center">
            <th>Tỉ lệ đạt</th>
            <th>Năng lượng</th>
            <th>Đạm</th>
            <th>Béo</th>
            <th>Bột đường</th>
          </tr>
        </thead>
        <tbody className="align-middle text-center">
          <tr className="text-end">
            <td
              className={`text-nowrap text-${
                energyPersen >= 100 ? "success" : "info"
              }`}
              style={energyPersen >= 100 ? qualified : unqualified}
            >
              {priceFormatter.format(energyPersen)} %
            </td>
            <td className="text-nowrap">
              {priceFormatter.format(energyStat)} kcal
            </td>
            <td className="text-nowrap">
              {priceFormatter.format(proteinStat)} g
            </td>
            <td className="text-nowrap">
              {priceFormatter.format(lipidStat)} g
            </td>
            <td className="text-nowrap">
              {priceFormatter.format(glucidStat)} g
            </td>
          </tr>

          <tr className="text-end" style={{ borderTop: "2px solid #000000" }}>
            <th
              colSpan={2}
              className="text-start cursor-help"
              title={`Khuyến nghị tỉ lệ P:L:G theo nhóm ${selectedClassGroup.group_name}`}
            >
              Khuyến nghị
            </th>
            <td className="text-nowrap">
              {rangeQuantity?.PROTEIN?.MIN} - {rangeQuantity?.PROTEIN?.MAX} %
            </td>
            <td className="text-nowrap">
              {rangeQuantity?.LIPID?.MIN} - {rangeQuantity?.LIPID?.MAX} %
            </td>
            <td className="text-nowrap">
              {rangeQuantity?.GLUCID?.MIN} - {rangeQuantity?.GLUCID?.MAX} %
            </td>
          </tr>
          <tr className="text-end">
            <th
              colSpan={2}
              className="text-start cursor-help"
              title={`Tỉ lệ Protein : Lipid : Glucid`}
            >
              P:L:G
            </th>
            <td className={`text-nowrap ${proteinColor}`}>
              {plgTotal !== 0 ? priceFormatter.format(proteinRatio) : "0.00"} %
            </td>
            <td className={`text-nowrap ${lipidColor}`}>
              {plgTotal !== 0 ? priceFormatter.format(lipidRatio) : "0.00"} %
            </td>
            <td className={`text-nowrap ${glucidColor}`}>
              {plgTotal !== 0 ? priceFormatter.format(glucidRatio) : "0.00"} %
            </td>
          </tr>

          <tr className="text-end">
            <th colSpan={2} className="text-start cursor-help">
              Động vật
            </th>
            <td className={`text-nowrap ${animalProteinColor}`}>
              {proteinStat !== 0
                ? priceFormatter.format(animalProteinRatio)
                : "0.00"}{" "}
              %
            </td>
            <td className={`text-nowrap ${animalLipidColor}`}>
              {lipidStat !== 0
                ? priceFormatter.format(animalLipidRatio)
                : "0.00"}{" "}
              %
            </td>
            <td></td>
          </tr>
          <tr className="text-end cursor-help">
            <th colSpan={2} className="text-start" title="Thực vật">
              Thực vật
            </th>
            <td className={`text-nowrap ${plantProteinColor}`}>
              {proteinStat !== 0
                ? priceFormatter.format(plantProteinRatio)
                : "0.00"}{" "}
              %
            </td>
            <td className={`text-nowrap ${plantLipidColor}`}>
              {lipidStat !== 0
                ? priceFormatter.format(plantLipidRatio)
                : "0.00"}{" "}
              %
            </td>
            <td></td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default NutritionTable;
