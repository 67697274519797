import { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import { toast } from "react-toastify";
import Check from "../../other/Check";
import DataTables from "../../datatable/DataTables";
import { dateFormatter, toSlug } from "../../utils/helpers";
import StudyGroupAdd from "./StudyGroupAdd";
import StudyGroupEdit from "./StudyGroupEdit";
import ClassBySubjectDetail from "./ClassBySubjectDetail";
import swal from "sweetalert";
import ClassBySubjectRegistered from "./ClassBySubjectRegistered";
import api from "../../../../helper/axiosInstance";

const SudyGroupIndex = () => {
  const [studyGroups, setStudyGroups] = useState([]);
  const [listuser, setlistuser] = useState([]);
  const [extentSubjects, setExtentSubjects] = useState([]);
  const [studyGroupsSearch, setStudyGroupsSearch] = useState([]);
  const [selectedStudyGroupId, setSelectedStudyGroupId] = useState();
  const [selectedStudyGroupName, setSelectedStudyGroupName] = useState();
  const [subjectInStudyGroupSelected, setSubjectInStudyGroupSelected] =
    useState();
  const [addStudyGroupModalShow, setAddStudyGroupModalShow] = useState(false);
  const [editStudyGroupModalShow, setEditStudyGroupModalShow] = useState(false);
  const [classBySubjectDetailModalShow, setClassBySubjectDetailModalShow] =
    useState(false);
  const [
    classBySubjectRegisteredModalShow,
    setClassBySubjectRegisteredModalShow,
  ] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    document.title = "Nhóm môn học";
    getlistuser();
    getAllSubjects();
    getAllStudyGroups();
  }, []);

  //#region API
  const getlistuser = async () => {
    await api
      .get("/showAllUser")
      .then((res) => {
        if (res.data.status) {
          setlistuser(res.data.data);
        }
      })
      .catch((error) => {});
  };

  const getAllSubjects = async () => {
    await api
      .get(`/subject-shows`)
      .then((res) => {
        if (res.status === 200) {
          setExtentSubjects(
            res.data.data.subjects.filter((subject) => subject.type === "EXTRA")
          );
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error", autoClose: 1000 });
      });
  };

  const getAllStudyGroups = async () => {
    setIsLoading(true);
    await api
      .get(`/study-group-shows`)
      .then((res) => {
        if (res.status === 200) {
          setStudyGroups(res.data.data.studyGroups);
          setStudyGroupsSearch(res.data.data.studyGroups);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast(err.message, { type: "error", autoClose: 1000 });
      });
  };

  const deleteStudyGroup = (studyGroupId) => {
    api
      .delete(`/study-group-delete?id=${studyGroupId}`)
      .then((res) => {
        toast(res.data.messages, {
          type: "success",
          autoClose: 1000,
        });
        getAllStudyGroups();
      })
      .catch((err) => {
        const msg = err.response.data.messages ?? "Hệ thống xảy ra lỗi";
        toast(msg, {
          type: "error",
          autoClose: 1000,
        });
      });
  };
  //#endregion API

  //#region Handle
  const handleShowAddSubjectModal = () => {
    setAddStudyGroupModalShow(true);
    setSelectedStudyGroupId();
  };
  const handleCloseAddSubjectModal = (isReload) => {
    setAddStudyGroupModalShow(false);
    setSelectedStudyGroupId();
    if (isReload) {
      getAllStudyGroups(1);
    }
  };

  const handleShowEditSubjectModal = (subjectId) => {
    setEditStudyGroupModalShow(true);
    setSelectedStudyGroupId(subjectId);
  };
  const handleCloseEditSubjectModal = (isReload) => {
    setEditStudyGroupModalShow(false);
    setSelectedStudyGroupId();
    if (isReload) {
      getAllStudyGroups(1);
    }
  };

  const handleShowClassBySubjectDetailModal = (studyGroup) => {
    setClassBySubjectDetailModalShow(true);
    setSelectedStudyGroupId(studyGroup.id);
    setSelectedStudyGroupName(studyGroup.name);
    setSubjectInStudyGroupSelected(studyGroup.subject);
  };
  const handleShowClassBySubjectRegisteredModal = (studyGroup) => {
    setClassBySubjectRegisteredModalShow(true);
    setSelectedStudyGroupId(studyGroup.id);
    setSelectedStudyGroupName(studyGroup.name);
    setSubjectInStudyGroupSelected(studyGroup.subject);
  };
  const handleCloseClassBySubjectDetailModal = () => {
    setClassBySubjectDetailModalShow(false);
    setSelectedStudyGroupId();
  };
  const handleCloseClassBySubjectRegisteredModal = () => {
    setClassBySubjectRegisteredModalShow(false);
    setSelectedStudyGroupId();
  };

  const handleDeleteStudyGroup = (studyGroup) => {
    swal({
      title: "Bạn muốn xóa nhóm học?",
      text: `"${studyGroup.name}"`,
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    })
      .then(async (ok) => {
        if (ok) {
          deleteStudyGroup(studyGroup.id);
        }
      })
      .catch((err) => {});
  };

  const handleSearch = (searchText) => {
    const listStudyGroups = studyGroups.filter((studyGroup) =>
      toSlug(studyGroup.name).includes(toSlug(searchText))
    );
    setStudyGroupsSearch(listStudyGroups);
  };
  //#endregion Handle

  //#region Render
  const dataTableColumns = [
    {
      name: "Mã nhóm",
      selector: (row) => row.code,
      sortable: true,
      wrap: true,
    },
    {
      name: "Tên nhóm",
      selector: (row) => row.name,
      sortable: true,
      wrap: true,
    },
    {
      name: "Môn học",
      selector: (row) => row.subject.subject_name,
      sortable: true,
      wrap: true,
    },
    {
      name: "Giáo viên",
      selector: (row) => row.teacher?.user_name,
      sortable: true,
      wrap: true,
    },
    {
      name: "Ngày bắt đầu",
      cell: (row) => dateFormatter(new Date(row.start_date * 1000)),
      selector: (row) => parseFloat(row.start_date),
      sortable: true,
      width: "10rem",
    },
    {
      name: "Ngày kết thúc",
      cell: (row) => dateFormatter(new Date(row.end_date * 1000)),
      selector: (row) => parseFloat(row.end_date),
      sortable: true,
      width: "10rem",
    },
    {
      name: "Phương thức",
      center: true,
      minWidth: "200px",
      cell: (row) => (
        <>
          {new Check().permission([143], "and") ? (
            <Button
              size="sm"
              variant="success me-2"
              type="button"
              onClick={() => handleShowClassBySubjectRegisteredModal(row)}
              title="Danh sách học sinh ghi danh"
            >
              <i className="fa-solid fa-hand-fist"></i>
            </Button>
          ) : (
            <></>
          )}

          {new Check().permission([18, 144], "and") ? (
            <Button
              size="sm"
              variant="primary me-2"
              type="button"
              onClick={() => handleShowClassBySubjectDetailModal(row)}
              title="Danh sách học sinh"
            >
              <i className="fa-solid fa-user-graduate" />
            </Button>
          ) : (
            <></>
          )}

          {new Check().permission([144, 146], "or") ? (
            <Button
              size="sm"
              variant="warning me-2"
              type="button"
              onClick={() => handleShowEditSubjectModal(row.id)}
              title="Chi tiết"
            >
              <i className="fa-solid fa-pencil"></i>
            </Button>
          ) : (
            ""
          )}

          {new Check().permission([147]) ? (
            <Button
              size="sm"
              variant="danger"
              type="button"
              onClick={() => handleDeleteStudyGroup(row)}
              title="Xóa"
            >
              <i className="fas fa-trash"></i>
            </Button>
          ) : (
            ""
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Container fluid>
        <Breadcrumb>
          <Breadcrumb.Item active>Môn học</Breadcrumb.Item>
          <Breadcrumb.Item active>Nhóm môn học</Breadcrumb.Item>
        </Breadcrumb>
        <Card className="mb-4">
          <Card.Header>
            <Card.Title>
              <i className="fas fa-list me-1" /> Danh sách nhóm môn học
              {new Check().permission([145]) ? (
                <Button
                  variant="success"
                  size="sm"
                  className="float-end ms-2"
                  onClick={() => handleShowAddSubjectModal()}
                  title="Thêm nhóm môn học"
                >
                  <i className="fa-solid fa-plus" /> Thêm
                </Button>
              ) : null}
            </Card.Title>
          </Card.Header>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <Card.Body>
              <Row>
                <Col md={3} sm={6} className="mb-2">
                  <Form.Control
                    style={{ height: "38px" }}
                    onChange={(event) => handleSearch(event.target.value)}
                    placeholder="Nhập tên nhóm..."
                  />
                </Col>
              </Row>
              <DataTables data={studyGroupsSearch} columns={dataTableColumns} />
            </Card.Body>
          )}
        </Card>
      </Container>
      {addStudyGroupModalShow && (
        <StudyGroupAdd
          show={addStudyGroupModalShow}
          onHide={handleCloseAddSubjectModal}
          listTeachers={listuser}
          listSubjects={extentSubjects}
        />
      )}
      {editStudyGroupModalShow && (
        <StudyGroupEdit
          show={editStudyGroupModalShow}
          onHide={handleCloseEditSubjectModal}
          selectedStudyGroupId={selectedStudyGroupId}
          listTeachers={listuser}
          listSubjects={extentSubjects}
        />
      )}
      {classBySubjectDetailModalShow && (
        <ClassBySubjectDetail
          show={classBySubjectDetailModalShow}
          onHide={handleCloseClassBySubjectDetailModal}
          selectedStudyGroupId={selectedStudyGroupId}
          selectedStudyGroupName={selectedStudyGroupName}
          subject={subjectInStudyGroupSelected}
        />
      )}{" "}
      {classBySubjectRegisteredModalShow && (
        <ClassBySubjectRegistered
          show={classBySubjectRegisteredModalShow}
          onHide={handleCloseClassBySubjectRegisteredModal}
          selectedStudyGroupId={selectedStudyGroupId}
          selectedStudyGroupName={selectedStudyGroupName}
          subject={subjectInStudyGroupSelected}
        />
      )}
    </>
  );
  //#endregion Render
};

export default SudyGroupIndex;
