import React, { useEffect, useState } from "react";
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Container,
  Form,
  Col,
  Row,
  InputGroup,
  Table,
} from "react-bootstrap";
import { toast } from "react-toastify";
import swal from "sweetalert";
import DataTables from "../datatable/DataTables";
import Select from "react-select";
import Check from "../other/Check";
import * as XLSX from "xlsx-js-style";
import api from "../../../helper/axiosInstance";
import { getDate } from "../utils/helpers";
import { IS_ACTIVE, IS_AUTHOR } from "../../../constants";
import UserAddModal from "./UserAddModal";
import UserEditModal from "./UserEditModal";
import { toastError } from "../../../helper/messages";

export default function UserIndex() {
  useEffect(() => {
    document.title = "Nhân viên";
    getRoles();
    getEthnic();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [showAddModal, setShowAddModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [userSelectedFile, setuserSelectedFile] = useState(null);
  const [isAddStaffListLoading, setisAddStaffListLoading] = useState(null);
  const [roles, setRoles] = useState([]);
  const [ethnics, setEthnics] = useState([]);
  const [userId, setUserId] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedRows, setselectedRows] = useState([]);
  const [alertShow, setalertShow] = useState(false);

  const [RoleFilter, setRoleFilter] = useState({
    value: "",
    label: "Tất cả chức vụ",
  });

  const [classsearch, setclasssearch] = useState([]);
  const [RoleSearch, setRoleSearch] = useState([]);
  const [checkload, setCheckload] = useState(true);

  const authId = localStorage.getItem("auth_id_user");

  // Fetch API
  const getUsers = () => {
    api
      .get("/user-shows")
      .then((res) => {
        setUsers(res.data.data);
        setCheckload(false);
        handleClassbySelectOrSearch(res.data.data, RoleFilter, "");
      })
      .catch((error) => {});
  };

  const getRoles = () => {
    api
      .get("/role-shows")
      .then((res) => {
        setRoles(res.data.data);
      })
      .catch((error) => {});
  };

  const getEthnic = () => {
    api
      .get("/viewEthnic")
      .then((res) => {
        if (res.data.status === true) {
          setEthnics(res.data.data);
          getUsers();
        }
      })
      .catch((err) => {});
  };

  const handleDestroy = (item) => {
    swal({
      title: "Bạn muốn xóa nhân viên?",
      text: `"${item.user_name}"`,
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then((ok) => {
      if (ok) {
        api
          .delete("/user-delete", { params: { id: item.id } })
          .then((res) => {
            toast(res.data.messages, { type: "success" });
            getUsers();
          })
          .catch((error) => {
            toastError(error);
          });
      }
    });
  };

  // Handle Logic
  const selectUserByRole = (value) => {
    setRoleFilter(value);
    setclasssearch("");
    handleClassbySelectOrSearch(users, value, "");
  };

  const handleSearchRole = (event) => {
    setclasssearch(event.target.value);

    handleClassbySelectOrSearch(users, RoleFilter, event.target.value.trim());
  };

  const handleClassbySelectOrSearch = (users, value, text) => {
    let root_ = users;

    if (value.value !== "") {
      if (text) {
        root_ = users.filter(
          (e) =>
            e.id_role === value.value &&
            toSlug(e.user_name).includes(toSlug(text))
        );
      } else {
        root_ = users.filter((e) => e.id_role === value.value);
      }
    } else {
      if (text) {
        root_ = users.filter((e) => toSlug(e.user_name).includes(toSlug(text)));
      }
    }

    setRoleSearch(root_);
  };

  // const handleImportStaffListExcel = () => {
  //   var bodyFormData = new FormData();
  //   bodyFormData.append("student_excel", userSelectedFile);

  //   setisAddStaffListLoading(true);

  //   api
  //     .post(`/importUser`, bodyFormData)
  //     .then((res) => {
  //       if (res.data.status === true) {
  //         this.getStudentsData();

  //         toast("Lưu thành công", {
  //           type: "success",
  //           autoClose: 2000,
  //         });

  //         setisAddStaffListLoading(false);
  //         setuserSelectedFile(null);
  //         document.getElementById("stafflistFile").value = "";
  //       } else {
  //         toast(res.data.data.mess, {
  //           type: "error",
  //           autoClose: 2000,
  //         });

  //         setisAddStaffListLoading(false);
  //       }
  //     })
  //     .catch((error) => {});
  // };

  const xport = () => {
    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, listStaff(), "Nhân viên");

    XLSX.writeFile(wb, "Danh sách nhân viên.xlsx");
  };

  const listStaff = () => {
    const table = document.getElementById("list-staff-table");

    var ws = XLSX.utils.table_to_sheet(table, { raw: true });

    const colAlpha = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K"];

    ws[`A1`].s = {
      font: {
        name: "Times New Roman",
        sz: 16,
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };

    ws[`A2`].s = {
      font: {
        name: "Times New Roman",
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
      },
    };

    colAlpha.map((alpha) => {
      ws[`${alpha}3`].s = {
        font: {
          name: "Times New Roman",
          bold: true,
          sz: 11,
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        },
      };

      return alpha;
    });

    for (
      let i = 0;
      i < users.filter((user) => Number(user.is_active) === 1).length;
      i++
    ) {
      colAlpha.map((alpha) => {
        if (ws[`${alpha}${4 + i}`].v === "empty") {
          ws[`${alpha}${4 + i}`].v = " ";
        }

        ws[`${alpha}${4 + i}`].s = {
          font: {
            name: "Times New Roman",
            sz: 11,
          },
          alignment: {
            wrapText: true,
            vertical: "center",
            horizontal:
              alpha === "A" || alpha === "D" || alpha === "E" || alpha === "H"
                ? "center"
                : "left",
          },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
        };

        return alpha;
      });
    }

    ws["!cols"] = [
      { wch: 5 },
      { wch: 25 },
      { wch: 20 },
      { wch: 10 },
      { wch: 8 },
      { wch: 15 },
      { wch: 7 },
      { wch: 11 },
      { wch: 15 },
      { wch: 25 },
      { wch: 25 },
    ];

    ws["!rows"] = [{ hpt: 30 }, {}];

    return ws;
  };

  const stringLastDate = (date) =>
    ("0" + date.getDate()).slice(-2) +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "/" +
    date.getFullYear();

  const toSlug = (str) => {
    str = str.toLowerCase(); // Chuyển hết sang chữ thường
    // xóa dấu
    str = str
      .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
      .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp
    str = str.replace(/[đĐ]/g, "d"); // Thay ký tự đĐ
    str = str.replace(/([^0-9a-z-\s])/g, ""); // Xóa ký tự đặc biệt
    return str;
  };

  // Render
  const loading = () => {
    return (
      <Card>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ width: "3rem", height: "3rem" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </Card.Body>
      </Card>
    );
  };

  const RenderSelectedRows = () => {
    let num = selectedRows.length;
    if (num > 0 && alertShow === true) {
      return (
        <Alert
          style={{
            position: "absolute",
            zIndex: "1",
            left: "91%",
          }}
          variant="info mb-0"
          onClose={() => setalertShow(false)}
          dismissible
        >
          {num} mục đã chọn.
        </Alert>
      );
    }
  };

  const DataTable = () => {
    const columns = [
      {
        name: "Họ và tên",
        selector: (row) => row.user_name,
        sortable: true,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap">
            {row.user_name}
          </div>
        ),
        style: { div: { width: "200px !important" } },
      },
      {
        name: "Chức vụ",
        selector: (row) => row.role_name,
        sortable: true,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap">
            {row.role_name}
          </div>
        ),
      },
      {
        name: "Trạng thái",
        selector: (row) => row.is_status,
        sortable: true,
        cell: (row) =>
          row.is_status === IS_ACTIVE.TRUE ? (
            <span className="text-success">Kích hoạt</span>
          ) : (
            <span className="text-danger"> Tạm khóa</span>
          ),
      },
      {
        name: "Cập nhật",
        selector: (row) => row.updated_at,
        sortable: true,
        right: true,
        cell: (row) => getDate(row.updated_at),
      },
      {
        name: "Người cập nhật",
        selector: (row) => row.updater,
        sortable: true,
      },
      {
        name: "Phương thức",
        center: true,
        cell: (row) => (
          <>
            {new Check().permission(["2", "4"], "or") ? (
              <Button
                variant="warning me-2"
                size="sm"
                title="Chi tiết nhân viên"
                onClick={() => {
                  setShowEditModal(true);
                  setUserId(row.id);
                }}
              >
                <i className="fa-solid fa-pencil"></i>
              </Button>
            ) : null}

            {new Check().permission([5]) &&
            Number(authId) !== row.id &&
            Number(row.is_author) === IS_AUTHOR.FALSE ? (
              <Button
                variant="danger"
                size="sm"
                title="Xóa nhân viên"
                onClick={() => {
                  handleDestroy(row);
                }}
              >
                <i className="fa-solid fa-trash"></i>
              </Button>
            ) : null}
          </>
        ),
      },
    ];

    const data = [];

    const selectedRows = (items) => {
      setselectedRows(items);
      setalertShow(true);
    };

    if (RoleSearch.length > 0) {
      RoleSearch.map((item, i) => {
        data.push({
          id: item.id,
          user_name: item.user_name,
          role_name: item.role_name,
          is_status: item.is_status,
          is_author: item.is_author,
          updated_at: item.updated_at,
          updater: item.updater,
        });

        return item;
      });
    }

    return (
      <DataTables data={data} columns={columns} selectedRows={selectedRows} />
    );
  };

  const renderSearchFilter = () => {
    let options = [];
    roles.map((item) => {
      options.push({
        value: item.id,
        label: item.role_name,
      });

      return item;
    }, options.unshift({ value: "", label: "Tất cả chức vụ" }));

    return (
      <Row>
        <Col md={3} sm={6} className="mb-2">
          <InputGroup>
            <Form.Control
              style={{ height: "38px" }}
              onChange={(event) => handleSearchRole(event)}
              value={classsearch}
              placeholder="Nhập tên nhân viên"
            />
          </InputGroup>
        </Col>

        <Col md={3} sm={6} className="mb-2" style={{ zIndex: "2" }}>
          <Select
            defaultValue={options[0]}
            options={options}
            placeholder="Chọn nhóm chức vụ"
            value={RoleFilter}
            onChange={(choice) => {
              selectUserByRole(choice);
            }}
          />
        </Col>
      </Row>
    );
  };

  const renderStaffList = () => {
    const currentMonth = new Date();

    let startYear = 0;
    let endYear = 0;

    if (currentMonth.getMonth() + 1 >= 7) {
      startYear = currentMonth.getFullYear();
      endYear = currentMonth.getFullYear() + 1;
    } else {
      startYear = currentMonth.getFullYear() - 1;
      endYear = currentMonth.getFullYear();
    }

    return (
      <div className="d-none">
        <Table id="list-staff-table">
          <thead>
            <tr>
              <th colSpan="11">DANH SÁCH NHÂN VIÊN</th>
            </tr>
            <tr>
              <th colSpan="11">
                Năm học {startYear} - {endYear}
              </th>
            </tr>
            <tr className="text-center">
              <th>STT</th>
              <th>Mã định danh Bộ GD&ĐT</th>
              <th>Họ tên</th>
              <th>Ngày sinh</th>
              <th>Giới tính</th>
              <th>Trạng thái</th>
              <th>Dân tộc</th>
              <th>Điện thoại</th>
              <th>Chức vụ</th>
              <th>Hình thức hợp đồng</th>
              <th>T.Độ chuyên môn nghiệp vụ</th>
            </tr>
          </thead>
          <tbody>
            {users
              ?.filter((user) => Number(user.is_active) === 1)
              .map((user, index) => {
                return (
                  <tr key={index}>
                    <td className="text-center">{index + 1}</td>
                    <td>{user.user_identifier}</td>
                    <td>{user.user_name}</td>
                    <td className="text-center">
                      {user
                        ? stringLastDate(new Date(user.user_birthday * 1000))
                        : "empty"}
                    </td>
                    <td>
                      {
                        ["Nam", "Nữ", "Khác"].filter(
                          (_, index) => index + 1 === Number(user.user_gender)
                        )[0]
                      }
                    </td>
                    <td>{user.is_active === 1 ? "Đang làm việc" : "empty"}</td>
                    <td>
                      {user
                        ? ethnics?.filter(
                            (item) =>
                              Number(item.id) === Number(user.user_ethnic)
                          )[0]?.ethnic_name
                        : "empty"}
                    </td>
                    <td className="text-center">
                      {user.user_phone !== "" ? user.user_phone : "empty"}
                    </td>
                    <td>{user.role_name !== "" ? user.role_name : "empty"}</td>
                    <td>
                      {user.user_contract !== "" ? user.user_contract : "empty"}
                    </td>
                    <td>
                      {user.user_diploma !== "" ? user.user_diploma : "empty"}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    );
  };

  return (
    <Container fluid>
      <RenderSelectedRows />

      <Breadcrumb>
        <Breadcrumb.Item active>Nhân viên</Breadcrumb.Item>
        <Breadcrumb.Item active>Danh sách</Breadcrumb.Item>
      </Breadcrumb>

      <Card>
        <Card.Header>
          <div className="d-block d-md-flex justify-content-between">
            <Card.Title>
              <i className="fas fa-list me-1" />
              Danh sách nhân viên
            </Card.Title>
            {new Check().permission(["3"]) ? (
              <div>
                {/* <label className="btn btn-success me-3 mt-3 mt-md-0" style={{ fontSize: 11 }} htmlFor="stafflistFile">
                  <i className="fas fa-file-import"></i> Nhập DSNV
                </label>
                <input
                  type="file"
                  id="stafflistFile"
                  className="d-none"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  multiple={false}
                  onChange={(e) => handleSelectFile(e)}
                  disabled={isAddStaffListLoading}
                /> */}

                {userSelectedFile ? (
                  <>
                    <label
                      style={{
                        minWidth: 100,
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                      className="text-truncate me-3 mt-3 mt-md-0"
                    >
                      Đã chọn: {userSelectedFile.name}
                    </label>

                    <Button
                      type="button"
                      variant="success"
                      size="sm"
                      className="me-3 mt-3 mt-md-0"
                      onClick={() => {
                        // handleImportStaffListExcel();
                      }}
                      disabled={isAddStaffListLoading}
                    >
                      Lưu
                    </Button>

                    <Button
                      type="button"
                      variant="danger"
                      size="sm"
                      className="me-3 mt-3 mt-md-0"
                      onClick={() => {
                        document.getElementById("stafflistFile").value = "";
                        setuserSelectedFile(null);
                      }}
                      disabled={isAddStaffListLoading}
                    >
                      Hủy
                    </Button>
                  </>
                ) : (
                  ""
                )}

                <Button
                  type="button"
                  className="me-3 mt-3 mt-md-0"
                  variant="success"
                  size="sm"
                  onClick={() => xport()}
                >
                  <i className="fa-regular fa-file-excel"></i> Xuất DSNV
                </Button>

                {/* <Button type="button" className="me-3 mt-3 mt-md-0" variant="success" size="sm" href='/File mẫu danh sách nhân viên.xlsx' target="_blank" download>
                  <i className="fa-solid fa-download"></i> Tải file mẫu
                </Button> */}

                <Button
                  type="button"
                  className="mt-3 mt-md-0"
                  variant="success"
                  size="sm"
                  onClick={() => {
                    setShowAddModal(true);
                  }}
                >
                  <i className="fa-solid fa-plus"></i> Thêm
                </Button>
              </div>
            ) : null}
          </div>
        </Card.Header>

        <Card.Body>
          {renderSearchFilter()}
          {checkload ? loading() : <DataTable />}
          {renderStaffList()}

          {showAddModal ? (
            <UserAddModal
              show={showAddModal}
              onHide={() => {
                setShowAddModal(false);
              }}
              onSave={() => {
                setShowAddModal(false);
                getUsers();
              }}
            />
          ) : (
            <></>
          )}

          {showEditModal ? (
            <UserEditModal
              show={showEditModal}
              onHide={() => {
                setShowEditModal(false);
              }}
              userId={userId}
              onSave={() => {
                setShowEditModal(false);
                getUsers();
              }}
            />
          ) : (
            <></>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
}
