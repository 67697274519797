import React from "react";
import { Table } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";

const IngredientTable = ({ ingredients }) => {
  if (!ingredients || ingredients.length === 0) {
    return <p>Không có nguyên liệu nào được mua hôm nay.</p>;
  }

  return (
    <Table bordered striped className="mx-1 mt-2">
      <thead
        style={{
          position: "sticky",
          top: "0",
        }}
      >
        <tr style={{ background: "rgba(54, 162, 235, 0.6)" }}>
          <th scope="col">STT</th>
          <th scope="col">Tên mặt hàng</th>
          <th scope="col">Đơn vị</th>
          <th scope="col" className="text-end">
            Số lượng
          </th>
        </tr>
      </thead>
      <tbody>
        {ingredients
          ?.sort(function (a, b) {
            return a.ingredent_type - b.ingredent_type;
          })
          .map((item, index) => {
            return (
              <tr key={index} className="align-middle">
                <td>{index + 1}</td>
                <td className="text-start">
                  {item.ingredent_name}{" "}
                  {Number(item.ingredent_type) === 2 ? " (Kho)" : ""}
                </td>
                <td>{item.unit_market_name}</td>
                <td className="text-end">
                  <CurrencyFormat
                    value={item.ing_quantity || 0}
                    displayType={"text"}
                    thousandSeparator={true}
                    isNumericString
                    decimalScale={2}
                  />
                </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

export default IngredientTable;
