import ExcelJS from "exceljs";
// import { merge } from "jquery";
// import * as xlsx from "xlsx";
/**
 * Hàm điền dữ liệu vào file Excel mẫu
 * @param {Blob} file - File Excel mẫu (Blob từ API)
 * @param {Object} variables - Đối tượng chứa biến cần thay thế
 * @param {string} fileName - Tên file xuất ra
 */

const FillExcelTemplate = async (file, variables, fileName) => {
  try {
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(file); // Load file Excel mẫu từ Blob

    workbook.eachSheet((worksheet, sheetId) => {
      // Thay thế các biến đơn giản (VD: {date} → "01/01/2025")
      worksheet.eachRow((row) => {
        row.eachCell((cell) => {
          if (typeof cell.value === "string") {
            Object.keys(variables).forEach((key) => {
              const regex = new RegExp(`{${key}}`, "g");
              cell.value = cell.value.replace(regex, variables[key] || "");
            });
          }
        });
      });

      let mergeCells = [];

      // Lưu danh sách các ô đã merge trong worksheet
      const colLetterToNumber = (letters) => {
        let colNumber = 0;
        for (let i = 0; i < letters.length; i++) {
          colNumber *= 26;
          colNumber += letters.charCodeAt(i) - 64; // 'A' = 65, nên cần -64
        }
        return colNumber;
      };

      worksheet.model.merges.forEach((merge) => {
        const [start, end] = merge.split(":"); // Tách "A12:B12" thành ["A12", "B12"]

        const startCol = start.match(/[A-Z]+/g)[0]; // Lấy phần chữ (cột)
        const startRow = start.match(/\d+/g)[0]; // Lấy phần số (dòng)

        const endCol = end.match(/[A-Z]+/g)[0]; // Lấy phần chữ (cột)
        const endRow = end.match(/\d+/g)[0]; // Lấy phần số (dòng)

        mergeCells.push({
          startRow: parseInt(startRow, 10), // Chuyển thành số nguyên
          startCol: colLetterToNumber(startCol), // Chuyển cột sang số
          endRow: parseInt(endRow, 10), // Chuyển thành số nguyên
          endCol: colLetterToNumber(endCol), // Chuyển cột sang số
        });
      });

      // Xử lý mảng (ví dụ: {nguyen_lieu.ten_nguyen_lieu})
      Object.keys(variables).forEach((key) => {
        if (Array.isArray(variables[key])) {
          const arrayData = variables[key]; // Mảng cần điền
          let targetRow = null; // Dòng đầu tiên chứa biến mảng
          let targetCols = {}; // Lưu vị trí cột cho từng biến trong mảng
          let sttCol = null; // Vị trí cột STT (có thể là {nguyen_lieu.stt})

          // Tìm dòng đầu tiên chứa `{mang.tenthuoc}` (ví dụ: `{nguyen_lieu.ten_nguyen_lieu}`)
          worksheet.eachRow((row, rowNumber) => {
            row.eachCell((cell, colNumber) => {
              let hasArray = false;
              if (
                typeof cell.value === "string" &&
                cell.value.includes(`{${key}.`)
              ) {
                hasArray = true;
                // Kiểm tra xem có biến {nguyen_lieu.stt} không
                if (cell.value === `{${key}.stt}`) {
                  sttCol = colNumber; // Ghi nhớ vị trí cột STT
                }

                if (!targetRow) targetRow = rowNumber; // Ghi nhớ dòng đầu tiên chứa mảng
                const fieldName = cell.value
                  .match(/{(.*?)}/)?.[1]
                  .split(".")[1]; // Lấy tên biến (ví dụ: `ten_nguyen_lieu`)

                if (fieldName && !targetCols.hasOwnProperty(fieldName))
                  targetCols[fieldName] = colNumber; // Lưu vị trí cột
              }
            });
          });

          if (targetRow) {
            const countArray = arrayData.length;
            //Cập nhật mảng mergeCells
            mergeCells = mergeCells?.map((merge) => {
              if (merge.startRow > targetRow) {
                return {
                  ...merge,
                  startRow: merge.startRow + countArray - 1,
                  endRow: merge.endRow + countArray - 1,
                };
              }
              return merge;
            });

            const templateRow = worksheet.getRow(targetRow); // Dòng dữ liễu mẫu

            const staticCols = [];

            templateRow.eachCell((cell, colNumber) => {
              // Lấy ký tự bình thường
              if (typeof cell.value === "string" && !cell.value.includes("{")) {
                staticCols.push({ colNumber, value: cell.value });
              }

              // Lấy công thức ex: {=A+B}
              if (typeof cell.value === "string" && cell.value.includes("{=")) {
                staticCols.push({ colNumber, value: cell.value });
              }
            });

            // Chèn dữ liệu mảng vào dòng dưới
            arrayData.forEach((item, index) => {
              const currentRow = targetRow + index; // Tính dòng hiện tại

              // Chỉ cần chèn dòng mới nếu không phải là phần tử đầu tiên
              if (index > 0) worksheet.insertRow(currentRow, []);

              // Duyệt qua từng cột để điền dữ liệu
              Object.keys(targetCols).forEach((field) => {
                let cell = worksheet
                  .getRow(targetRow)
                  .getCell(targetCols[field]); // Ô gốc chứa biến
                let templateValue = cell.value; // Giá trị mẫu (có thể chứa `{}`)

                if (
                  typeof templateValue === "string" &&
                  templateValue.includes(`{${key}.`)
                ) {
                  // Thay thế `{nguyen_lieu.di_cho}` bằng giá trị thực tế từ dữ liệu
                  let newValue = templateValue.replace(
                    `{${key}.${field}}`,
                    item[field] || ""
                  );
                  worksheet
                    .getRow(currentRow)
                    .getCell(targetCols[field]).value = newValue;
                } else {
                  worksheet
                    .getRow(currentRow)
                    .getCell(targetCols[field]).value = item[field] || "";
                }
              });

              // Ghi dữ liệu vào đúng cột
              Object.keys(targetCols).forEach((field) => {
                let value = item[field] || ""; // Lấy giá trị từ item, nếu không có thì để trống

                worksheet.getRow(currentRow).getCell(targetCols[field]).value =
                  value;
              });

              // Ghi số thứ tự vào cột STT
              if (sttCol) {
                worksheet.getRow(currentRow).getCell(sttCol).value = index + 1; // Gán số thứ tự (STT)
              }

              // Sao chép định dạng và hợp nhất từ dòng mẫu sang dòng mới
              staticCols.forEach(({ colNumber, value }) => {
                // Ghi dữ liệu không phải là biến {}
                worksheet.getRow(currentRow).getCell(colNumber).value = value;

                // Cập nhật công thức
                if (typeof value === "string" && value.startsWith("{=")) {
                  // Loại bỏ dấu {} để lấy công thức gốc
                  let formulaTemplate = value
                    .replace("{=", "")
                    .replace("}", "");

                  // Tìm tất cả các tham chiếu cột (A1, B2, C3, ...)
                  let newFormula = formulaTemplate.replace(
                    /([A-Z]+)(\d*)/g,
                    (match, col, row) => {
                      return col + currentRow; // Chỉ thay đổi số dòng, giữ nguyên tên cột
                    }
                  );

                  // Gán công thức mới vào ô tương ứng
                  worksheet.getRow(currentRow).getCell(colNumber).value = {
                    formula: newFormula,
                  };
                }
              });

              const newRow = worksheet.getRow(currentRow);
              // Lưu các ô đã được hợp nhất từ dòng mẫu
              templateRow.eachCell((cell, colNum) => {
                const newCell = newRow.getCell(colNum);
                // Sao chép định dạng như font, màu sắc, viền
                newCell.style = { ...cell.style }; // Sao chép định dạng
              });

              // Tự động tính chiều cao dựa trên wrapText + độ rộng cột
              let maxLines = 1; // Số dòng tối thiểu

              newRow.eachCell((cell, colNum) => {
                if (typeof cell.value === "string") {
                  const lineBreaks = cell.value.split("\n").length; // Số lần xuống dòng
                  const text = (cell.value || "").toString().trim();
                  const columnWidth = worksheet.getColumn(colNum).width || 8.43; // Lấy độ rộng cột
                  const charsPerLine = Math.floor(columnWidth * 1.2); // Số ký tự trên mỗi dòng
                  const estimatedLines =
                    lineBreaks === 1
                      ? Math.ceil(text.length / charsPerLine)
                      : lineBreaks; // Ước lượng số dòng

                  let fontSize = 12;

                  // **Cập nhật style cho ô**
                  maxLines = Math.max(
                    maxLines,
                    estimatedLines * (fontSize * 1.2)
                  );
                }

                // Đảm bảo wrapText nếu chưa có
                if (!cell.alignment || !cell.alignment.wrapText) {
                  cell.alignment = {
                    ...cell.alignment,
                    wrapText: true,
                    vertical: "middle",
                  };
                }
              });

              // Sao chép chiều cao từ dòng mẫu
              newRow.height = maxLines;
              // Đảm bảo nội dung hiển thị đầy đủ
              newRow.eachCell((cell) => {
                if (!cell.alignment) {
                  cell.alignment = { wrapText: true, vertical: "middle" };
                }
              });

              // **Lưu merge cells từ dòng mẫu**
              let startRowSample = 0;
              let startColSample = 0;
              let endRowSample = 0;
              let endColSample = 0;
              worksheet.model.merges.forEach((merge) => {
                const [start, end] = merge.split(":"); // Tách "A12:B12" thành ["A12", "B12"]

                const startColText = start.match(/[A-Z]+/g)[0];
                const startCol = colLetterToNumber(startColText);
                const startRow = parseInt(start.match(/\d+/g)[0], 10);

                const endColText = end.match(/[A-Z]+/g)[0];
                const endCol = colLetterToNumber(endColText);
                const endRow = parseInt(end.match(/\d+/g)[0], 10);

                if (startRow === targetRow && endRow === targetRow) {
                  startRowSample = startRow;
                  startColSample = startCol;
                  endRowSample = endRow;
                  endColSample = endCol;
                }
              });

              if (
                startRowSample >= 1 &&
                startColSample >= 1 &&
                endRowSample >= 1 &&
                endColSample >= 1
              ) {
                mergeCells.push({
                  startRow: startRowSample + index,
                  startCol: startColSample,
                  endRow: endRowSample + index,
                  endCol: endColSample,
                });
              }
            });

            //Delete all merges cells
            worksheet.model.merges.forEach((merge) => {
              const [start, end] = merge.split(":");

              const startCol = start.match(/[A-Z]+/g)[0];
              const startRow = start.match(/\d+/g)[0];

              const endCol = end.match(/[A-Z]+/g)[0];
              const endRow = end.match(/\d+/g)[0];

              worksheet.unMergeCells(
                parseInt(startRow, 10),
                colLetterToNumber(startCol),
                parseInt(endRow, 10),
                colLetterToNumber(endCol)
              );
            });

            mergeCells.forEach((merge) => {
              // Kiểm tra để đảm bảo các chỉ số không vượt ra ngoài phạm vi và ô chưa được hợp nhất
              if (
                merge.startRow >= 1 &&
                merge.startCol >= 1 &&
                merge.endRow >= 1 &&
                merge.endCol >= 1 &&
                merge.startRow <= worksheet.rowCount &&
                merge.startCol <= worksheet.columnCount &&
                merge.endRow <= worksheet.rowCount &&
                merge.endCol <= worksheet.columnCount
              ) {
                // Kiểm tra xem ô đã được hợp nhất chưa
                const alreadyMerged =
                  worksheet.getCell(merge.startRow, merge.startCol).isMerged ||
                  worksheet.getCell(merge.endRow, merge.endCol).isMerged;

                if (!alreadyMerged) {
                  try {
                    worksheet.mergeCells(
                      merge.startRow,
                      merge.startCol,
                      merge.endRow,
                      merge.endCol
                    );
                  } catch (err) {
                    console.warn(
                      "lỗi merge>>",
                      merge.startRow,
                      merge.startCol,
                      merge.endRow,
                      merge.endCol
                    );
                  }
                }
              }
            });

            worksheet.pageSetup = {
              fitToPage: true,
              fitToWidth: 1, // Vừa 1 trang ngang
              fitToHeight: 0, // Không giới hạn chiều dọc
            };
          }
        }
      });
    });

    // Xuất file Excel về client
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Lỗi khi xử lý file Excel:", error);
  }
};

export default FillExcelTemplate;
