import React, { useState, useEffect } from "react";
import { getlastDayOfMonth } from "../utils/helpers";
import api from "../../../helper/axiosInstance";
import { Table } from "react-bootstrap";

import { Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import CurrencyFormat from "react-currency-format";

// Register necessary Chart.js components
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  LinearScale
);

const CountBill = () => {
  const [unpaidStudents, setUnpaidStudents] = useState([]);
  const [paidCount, setPaidCount] = useState(0);
  const [unpaidCount, setUnpaidCount] = useState(0);

  useEffect(() => {
    const fetchBillData = async () => {
      try {
        let start = new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0));
        let end = getlastDayOfMonth(
          new Date().getFullYear(),
          Number(new Date().getMonth()) + 1
        );
        const response = await api.get(
          `/bill-shows?end_date=${parseInt(end / 1000)}&start_date=${parseInt(
            start / 1000
          )}`
        );

        if (response.data) {
          const billsData = response.data?.data?.docs;

          const unpaid = billsData?.filter(
            (bill) => Number(bill.pay_status) === 0
          );
          const paid = billsData?.filter(
            (bill) => Number(bill.pay_status) === 1
          );

          setPaidCount(
            paid?.reduce(
              (acc, e) => {
                return (acc += e.trans_amount);
              },

              0
            )
          );

          setUnpaidCount(
            unpaid?.reduce(
              (acc, e) => {
                return (acc += e.trans_amount);
              },

              0
            )
          );

          setUnpaidStudents(unpaid);
        }
      } catch (error) {
        console.error("Error fetching bill data:", error);
      }
    };

    fetchBillData();
  }, []);

  const chartData = {
    labels: ["Đã thanh toán", "Chưa thanh toán"],
    datasets: [
      {
        data: [paidCount, unpaidCount],
        backgroundColor: ["#4caf50", "rgba(255, 99, 132, 0.6)"],
        hoverBackgroundColor: ["#198754", "rgba(255, 99, 132, 1)"],
      },
    ],
  };
  return (
    <div style={styles.container}>
      <h3>Học phí tháng {new Date()?.getMonth() + 1}</h3>

      {/* Pie Chart */}
      <div
        className="chart-bill"
        // style={styles.chartContainer}
      >
        <Pie data={chartData} />
      </div>

      {/* Table of Unpaid Students */}
      <div>
        <h3>Danh sách chưa thanh toán</h3>
        <div style={styles.tableContainer}>
          <Table bordered striped className="mx-1 mt-2">
            <thead
              style={{
                position: "sticky",
                top: "0",
              }}
            >
              <tr
                style={{
                  background: "rgba(54, 162, 235, 0.6)",
                  whiteSpace: "nowrap",
                }}
              >
                <th scope="col">STT</th>
                <th scope="col">Họ tên </th>
                <th scope="col">Lớp </th>
                <th scope="col">Tổng tiền (đ)</th>
              </tr>
            </thead>
            <tbody>
              {unpaidStudents.length > 0 ? (
                unpaidStudents.map((student, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td className="text-start">
                      {student.student?.student_name}
                    </td>{" "}
                    <td className="text-start">
                      {student.student?.classes[0]?.class_name}
                    </td>
                    <td className="text-end">
                      <CurrencyFormat
                        value={student?.trans_amount || 0}
                        displayType={"text"}
                        thousandSeparator={true}
                        isNumericString
                        decimalScale={2}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="2">Không có dữ liệu</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    textAlign: "center",
    fontFamily: "Arial, sans-serif",
    padding: "10px",
    backgroundColor: "#f9f9f9",
    borderRadius: "8px",
    width: "100%",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
  chartContainer: {
    marginTop: "20px",
    width: "55%",
    maxWidth: "600px",
    margin: "0 auto",
  },
  tableContainer: {
    height: "27vh",
    overflowY: "auto",
    overflowX: "hidden",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  th: {
    backgroundColor: "#f4f4f4",
    padding: "10px",
    textAlign: "left",
  },
  td: {
    padding: "10px",
    textAlign: "left",
  },
  button: {
    backgroundColor: "#4caf50",
    color: "#fff",
    border: "none",
    padding: "5px 10px",
    cursor: "pointer",
    borderRadius: "4px",
  },
};

export default CountBill;
