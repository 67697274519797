import { useState, useEffect } from "react";
import {
  Button,
  Form,
  InputGroup,
  Modal,
  Table,
  Row,
  Col,
} from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import Select from "react-select";
import { toast } from "react-toastify";
import api from "../../../../helper/axiosInstance";

const MenuFoodsModalNullGroupId = ({
  show,
  onHide,
  foodModal,
  setFoodModal,
  foodOptions,
  classGroups,
}) => {
  const [categories, setCategories] = useState([]);
  const [choiceCategory, setChoiceCategory] = useState({});
  const [foodChoice, setFoodChoice] = useState({
    id: 0,
    food_name: "Chọn món",
  });

  useEffect(() => {
    getListCategories();
  }, []);

  const getListCategories = async () => {
    await api
      .get(`/category-show`)
      .then((res) => {
        if (res.data.errCode === 0) {
          let arr = [];
          arr.push({ id: 0, category_name: "Tất cả" });
          res.data.categories?.map((item) => arr.push(item));
          setCategories(arr);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {});
  };

  return (
    <Modal show={show} size="lg" onHide={() => onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>
          {foodModal.day_name} - Buổi {foodModal.time_name}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Table hover>
          <thead>
            <tr>
              <th>Món ăn</th>
              <th>Danh mục</th>
              {classGroups?.map((groupItem) => {
                return (
                  <>
                    <th key={`groupItem0_${groupItem.id}`}>
                      {groupItem.group_name}
                    </th>
                  </>
                );
              })}
              {foodModal?.is_meal_saved === 0 ? <th></th> : <></>}
            </tr>
          </thead>
          <tbody>
            {foodModal.foods.map((foodItem, keyFood) => {
              return (
                <tr key={foodItem.id}>
                  <td>{foodItem.food_name}</td>
                  <td>{foodItem.category_name}</td>
                  {classGroups?.map((groupItem, keyGroup) => {
                    let groupWithFood = [];
                    let keyGroupWithFood = -1;
                    foodItem?.groups.map((groupOfFood, iGroupOfFood) => {
                      if (Number(groupOfFood.id) === groupItem.id) {
                        groupWithFood[iGroupOfFood] = groupOfFood;
                        keyGroupWithFood = iGroupOfFood;
                      }

                      return groupOfFood;
                    });

                    const groupUse = foodItem.groups.filter(
                      (item) => item.id > 0
                    );

                    let more_meal =
                      groupWithFood.length > 0
                        ? groupWithFood[keyGroupWithFood].more_meal
                        : "";

                    return (
                      <>
                        <td key={`groupItem_${groupItem.id}`}>
                          <div
                            className="d-flex align-content-center"
                            title={`${
                              foodItem.groups?.some(
                                (item) => item.id === groupItem.id
                              )
                                ? "Hủy chọn"
                                : "Chọn"
                            } nấu cho nhóm ${groupItem.group_name}`}
                          >
                            <Form.Check
                              type="checkbox"
                              checked={foodItem.groups?.some(
                                (item) => item.id === groupItem.id
                              )}
                              value={groupItem.id}
                              onChange={(e) => {
                                if (foodModal?.is_meal_saved === 0) {
                                  if (e.target.checked) {
                                    foodItem.groups[keyGroup] = {
                                      id: groupItem.id,
                                      group_name: groupItem.group_name,
                                      more_meal: "",
                                      ingredents: [],
                                    };
                                  } else {
                                    delete foodItem.groups[keyGroup];
                                  }

                                  const groupsNoEmpty = foodItem.groups.filter(
                                    (group) => group
                                  );

                                  if (groupsNoEmpty.length > 0) {
                                    foodModal.foods[keyFood] = foodItem;
                                  } else {
                                    delete foodModal.foods[keyFood];
                                  }

                                  setFoodModal({
                                    ...foodModal,
                                  });

                                  if (
                                    groupUse.length === foodItem.groups.length
                                  ) {
                                    foodItem.groups.map(
                                      (groupUpdate, indexUpdate) => {
                                        foodItem.groups[indexUpdate].more_meal =
                                          "";
                                        foodModal.foods[keyFood] = foodItem;
                                        setFoodModal({
                                          ...foodModal,
                                        });

                                        return groupUpdate;
                                      }
                                    );
                                  }
                                } else {
                                  return;
                                }
                              }}
                              style={{
                                maxWidth: "1.5rem",
                                marginRight: ".5rem",
                              }}
                            />
                            {groupUse.length > 0 &&
                            foodItem.groups?.some(
                              (item) => item.id === groupItem.id
                            ) ? (
                              <CurrencyFormat
                                style={{
                                  width: 69,
                                  height: 27,
                                }}
                                className={`text-end ${
                                  foodModal?.is_meal_saved === 0
                                    ? "form-control"
                                    : ""
                                }`}
                                allowNegative={false}
                                thousandSeparator
                                decimalScale={0}
                                value={more_meal === null ? "" : more_meal}
                                onValueChange={(values) => {
                                  foodModal.foods[keyFood].groups[
                                    keyGroupWithFood
                                  ].more_meal = values.value;
                                  setFoodModal({
                                    ...foodModal,
                                  });
                                }}
                                title="Nhập số lượng nấu theo kế hoạch dự tính."
                                displayType={
                                  foodModal?.is_meal_saved === 0
                                    ? "input"
                                    : "text"
                                }
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                        </td>
                      </>
                    );
                  })}
                  {foodModal?.is_meal_saved === 0 ? (
                    <>
                      <td>
                        <Button
                          variant="outline-danger"
                          size="sm"
                          onClick={() => {
                            delete foodModal.foods[keyFood];
                            setFoodModal({
                              ...foodModal,
                            });
                          }}
                          title="Xóa món"
                        >
                          <i className="fa-solid fa-times" />
                        </Button>
                      </td>
                    </>
                  ) : (
                    <></>
                  )}
                </tr>
              );
            })}
          </tbody>
        </Table>
        {foodModal?.is_meal_saved === 0 ? (
          <Row>
            <Col className="mb-2">
              <InputGroup>
                <InputGroup.Text style={{ width: "6em" }}>
                  Danh mục
                </InputGroup.Text>

                <Select
                  options={categories}
                  getOptionLabel={(option) => option.category_name}
                  getOptionValue={(option) => option.id}
                  placeholder="Tất cả"
                  onChange={(choice) => {
                    setChoiceCategory(choice);
                    setFoodChoice({ id: 0, food_name: "Chọn món" });
                  }}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      width: "16em",
                    }),
                  }}
                />
              </InputGroup>
            </Col>

            <Col>
              <InputGroup>
                <InputGroup.Text style={{ width: "6em" }}>
                  Món ăn
                </InputGroup.Text>
                <Select
                  options={
                    choiceCategory.id
                      ? foodOptions
                          ?.filter((e) => e.id_category == choiceCategory.id)
                          ?.filter(
                            (foodItem) =>
                              !foodModal.foods.some(
                                (food) => food.id === foodItem.id
                              )
                          )
                      : foodOptions?.filter(
                          (foodItem) =>
                            !foodModal.foods.some(
                              (food) => food.id === foodItem.id
                            )
                        )
                  }
                  getOptionLabel={(option) => option.food_name}
                  getOptionValue={(option) => option.id}
                  value={foodChoice}
                  placeholder="Nhập tên món ăn"
                  onChange={(choice) => {
                    let foods = foodModal.foods;
                    let groups = [];
                    setFoodChoice(choice);

                    classGroups.map((group) => {
                      groups.push({
                        id: group.id,
                        group_name: group.group_name,
                        more_meal: null,
                        ingredents: [],
                      });
                      return group;
                    });

                    if (
                      !foods.some(
                        (food) => Number(food.id) === Number(choice.id)
                      )
                    ) {
                      foods.push({
                        id: choice.id,
                        food_name: choice.food_name,
                        priority: 1,
                        id_category: choice.id_category,
                        category_name: choice.category_name,
                        groups: groups,
                      });

                      foodModal.foods = foods;

                      setFoodModal({ ...foodModal });
                    } else {
                      toast("Bạn đã chọn món ăn này rồi", {
                        type: "error",
                        autoClose: 1000,
                      });
                    }
                  }}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      width: "16em",
                    }),
                  }}
                />
              </InputGroup>
            </Col>
          </Row>
        ) : (
          <></>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button size="sm" variant="secondary" onClick={() => onHide()}>
          <i className="fa-solid fa-times" /> Đóng
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MenuFoodsModalNullGroupId;
