import React, { useState, useEffect } from "react";
import axios from "axios";
import api from "../../../helper/axiosInstance";

const CountStudent = () => {
  const [studentCount, setStudentCount] = useState(null);

  useEffect(() => {
    const fetchStudentCount = async () => {
      try {
        const response = await api.get("/student-shows");
        if (response.data) {
          setStudentCount(
            response.data.data?.students?.filter(
              (e) => Number(e.student_status) === 1
            )?.length
          );
        }
      } catch (error) {
        console.error("Error fetching student data:", error);
      }
    };

    fetchStudentCount();
  }, []);

  return (
    <div style={styles.container}>
      <h2 className="text-white">Số lượng học sinh</h2>
      <div
      // style={styles.infoBox}
      >
        <i className="fa-solid fa-user-graduate" style={styles.icon}></i>
        <span style={styles.count}>
          {studentCount !== null ? studentCount : "Loading..."}
        </span>
      </div>
    </div>
  );
};

const styles = {
  container: {
    textAlign: "center",
    fontFamily: "Arial, sans-serif",
    padding: "10px 20px 20px 20px",
    backgroundColor: "rgb(18, 128, 245)",
    borderRadius: "8px",
    width: "100%",
    margin: "10px 0",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
  infoBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "10px",
    padding: "10px",
    // backgroundColor: "#fff",
    borderRadius: "4px",
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.2)",
  },
  icon: {
    fontSize: "2rem",
    color: "#fff",
    marginRight: "10px",
  },
  count: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "#fff",
  },
};

export default CountStudent;
