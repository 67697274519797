import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Modal, Button, Form, Row, Col, Tabs, Tab } from "react-bootstrap";
import api from "../../../helper/axiosInstance";
import moment from "moment/moment";
import PasswordInput from "../../uiElements/PasswordInput";
import { IS_AUTHOR, IS_STATUS } from "../../../constants";
import Check from "../other/Check";
import swal from "sweetalert";

const UserEditModal = ({ show, onHide, onSave, userId, isType = "public" }) => {
  const [user, setUser] = useState({
    id: userId,
    user_identifier: "",
    user_population: "",
    user_name: "",
    user_gender: 1,
    user_birthday: "",
    user_ethnic: 1,
    user_phone: "",
    user_email: "",
    user_address: "",
    user_contract: "",
    user_diploma: "",
    id_role: "",
    user_password: "",
    updater_password: "",
    user_confirm_password: "",
  });

  const [errorMessage, setErrorMessage] = useState({});
  const [ethnics, setEthnics] = useState([]);
  const [roles, setRoles] = useState([]);
  const [tabkey, setTabkey] = useState(1);
  const authId = localStorage.getItem("auth_id_user");

  useEffect(() => {
    getEthnics();
    getRoles();
    getUser();
  }, []);

  const getEthnics = () => {
    api
      .get("/viewEthnic")
      .then((res) => {
        if (res.data.status === true) {
          setEthnics(res.data.data);
        }
      })
      .catch((err) => {});
  };

  const getRoles = () => {
    api
      .get("/role-shows")
      .then((res) => {
        setRoles(res.data.data);
      })
      .catch((error) => {});
  };

  const getUser = () => {
    api
      .get("/user-show", { params: { id: userId } })
      .then((res) => {
        const user = res.data?.data || {
          id: "",
          user_identifier: "",
          user_population: "",
          user_name: "",
          user_gender: 1,
          user_birthday: "",
          user_ethnic: 1,
          user_phone: "",
          user_email: "",
          user_address: "",
          user_contract: "",
          user_diploma: "",
          id_role: "",
        };

        setUser({
          ...user,
          user_birthday: moment(user.user_birthday * 1000).format("YYYY-MM-DD"),
        });
      })
      .catch((err) => {});
  };

  const handleInput = (event) => {
    let field = event.target.name;
    let value = event.target.value;
    const type = event.target.type;

    console.log("type>>", type);

    setUser({ ...user, [field]: value });
    setErrorMessage({ ...errorMessage, [field]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    swal({
      title: "Bạn chắc muốn cập nhật?",
      icon: "warning",
      buttons: ["Đóng", "Cập nhật"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        const dataInput = {
          ...user,
          user_birthday: new Date(user.user_birthday) / 1000,
          ownerId: userId,
        };

        api({
          method: "put",
          url: "user-update",
          data: dataInput,
        })
          .then((res) => {
            if (Number(userId) === Number(localStorage.getItem("auth_id_user")))
              localStorage.setItem("auth_name", user.user_name);

            toast(res.data.messages, { type: "success" });
            onSave(true);
          })
          .catch((err) => {
            setErrorMessage(err.response.data?.messages);
            toast(err.response.data?.messages, { type: "error" });
          });
      }
    });
  };

  const handleSubmitChangePassword = async (e) => {
    e.preventDefault();

    swal({
      title: "Bạn chắc muốn cập nhật?",
      icon: "warning",
      buttons: ["Đóng", "Cập nhật"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        const dataInput = {
          ownerId: userId,
          id: userId,
          updater_password: user.updater_password,
          is_status: user.is_status,
          user_account: user.user_account,
          user_password: user.user_password,
          user_confirm_password: user.user_confirm_password,
        };

        api({
          method: "put",
          url: "user-update-account",
          data: dataInput,
        })
          .then((res) => {
            toast(res.data.messages, { type: "success" });
            onSave(true);
            // redirectLogin();
          })
          .catch((err) => {
            setErrorMessage(err.response.data?.messages);
            toast(err.response.data?.messages, { type: "error" });
          });
      }
    });
  };

  const redirectLogin = () => {
    swal({
      title: "Cập nhật thành công!",
      text: `Bạn cần đăng nhập lại để cập nhật tài khoản.`,
      icon: "success",
      successMode: true,
    }).then((ok) => {
      if (ok) {
        localStorage.removeItem("auth_id_shool");
        localStorage.removeItem("auth_id_user");
        localStorage.removeItem("auth_name");
        localStorage.removeItem("permission");
        localStorage.removeItem("access_token");
        localStorage.removeItem("version");

        window.location.href = "/login";
      }
    });
  };

  return (
    <Modal
      size="lg"
      backdrop="static"
      keyboard={false}
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {isType === "private" ? "Tài khoản" : "Chi tiết nhân viên"}
        </Modal.Title>
      </Modal.Header>

      <Tabs
        variant="tabs"
        onSelect={(k) => setTabkey(k)}
        defaultActiveKey={1}
        className="mt-1 d-flex"
      >
        <Tab
          key={1}
          eventKey={1}
          title={
            <>
              <span className="fa-solid fa-user-circle"></span> Thông tin cá
              nhân
            </>
          }
        >
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              <Row>
                <Col md={4}>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Họ và tên"
                      required
                      value={user.user_name}
                      name="user_name"
                      onChange={handleInput}
                      className={`text-capitalize ${
                        errorMessage?.user_name ? "is-invalid" : ""
                      }`}
                    />
                    <Form.Label>
                      Họ và tên<i className="text-danger">*</i>
                    </Form.Label>
                    <span className="text-danger">
                      {errorMessage?.user_name}
                    </span>
                  </Form.Floating>
                </Col>

                <Col md={4}>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Mã định danh Bộ GD & ĐT"
                      value={user.user_identifier}
                      name="user_identifier"
                      onChange={handleInput}
                      className={`${
                        errorMessage?.user_identifier ? "is-invalid" : ""
                      }`}
                    />
                    <Form.Label>Mã định danh Bộ GD & ĐT</Form.Label>
                    <span className="text-danger">
                      {errorMessage?.user_identifier}
                    </span>
                  </Form.Floating>
                </Col>

                <Col md={4}>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Số định danh cá nhân"
                      value={user.user_population}
                      name="user_population"
                      onChange={handleInput}
                      className={`${
                        errorMessage?.user_population ? "is-invalid" : ""
                      }`}
                    />
                    <Form.Label>Số định danh cá nhân</Form.Label>
                    <span className="text-danger">
                      {errorMessage?.user_population}
                    </span>
                  </Form.Floating>
                </Col>

                <Col md={3} xs={6}>
                  <Form.Floating className="mb-3">
                    <input
                      type="date"
                      value={user.user_birthday}
                      required
                      name="user_birthday"
                      onChange={handleInput}
                      className={`form-control ${
                        errorMessage?.user_birthday ? "is-invalid" : ""
                      }`}
                    />
                    <Form.Label>
                      Ngày sinh
                      <i className="text-danger">*</i>
                    </Form.Label>
                  </Form.Floating>
                  <span className="text-danger">
                    {errorMessage?.user_birthday}
                  </span>
                </Col>

                <Col md={3} xs={6}>
                  <Form.Floating className="mb-3">
                    <Form.Select
                      value={user.user_gender}
                      onChange={handleInput}
                      name="user_gender"
                      required
                      className={`${
                        errorMessage?.user_gender ? "is-invalid" : ""
                      }`}
                    >
                      <option value="">--Chọn--</option>
                      <option value="1">Nam</option>
                      <option value="2">Nữ</option>
                    </Form.Select>
                    <Form.Label>
                      Giới tính
                      <i className="text-danger">*</i>
                    </Form.Label>
                    <span className="text-danger">
                      {errorMessage?.user_gender}
                    </span>
                  </Form.Floating>
                </Col>

                <Col md={3} xs={6}>
                  <Form.Floating className="mb-3">
                    <Form.Select
                      value={user.user_ethnic}
                      onChange={handleInput}
                      name="user_ethnic"
                      required
                      className={`${
                        errorMessage?.user_ethnic ? "is-invalid" : ""
                      }`}
                    >
                      <option value="">--Chọn--</option>
                      {ethnics.length > 0
                        ? ethnics.map((ethnic, indexEthnic) => {
                            return (
                              <option
                                key={`key-ethnic-${indexEthnic}`}
                                value={ethnic.id}
                              >
                                {ethnic.ethnic_name}
                              </option>
                            );
                          })
                        : null}
                    </Form.Select>
                    <Form.Label>
                      Dân tộc
                      <i className="text-danger">*</i>
                    </Form.Label>
                    <span className="text-danger">
                      {errorMessage?.user_ethnic}
                    </span>
                  </Form.Floating>
                </Col>

                <Col md={3} xs={6}>
                  {new Check().permission([4]) &&
                  user.is_author !== IS_AUTHOR.TRUE ? (
                    <Form.Floating className="mb-3">
                      <Form.Select
                        value={user.id_role}
                        onChange={handleInput}
                        name="id_role"
                        required
                        className={`${
                          errorMessage?.id_role ? "is-invalid" : ""
                        }`}
                      >
                        <option value="">--Chọn--</option>
                        {roles.length > 0
                          ? roles.map((role, indexRole) => {
                              return (
                                <option
                                  key={`key-role-${indexRole}`}
                                  value={role.id}
                                >
                                  {role.role_name}
                                </option>
                              );
                            })
                          : null}
                      </Form.Select>
                      <Form.Label>
                        Chức vụ
                        <i className="text-danger">*</i>
                      </Form.Label>
                      <span className="text-danger">
                        {errorMessage?.id_role}
                      </span>
                    </Form.Floating>
                  ) : (
                    <Form.Floating className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Chức vụ"
                        value={
                          roles?.find((role) => role.id === user.id_role)
                            ?.role_name || ""
                        }
                        name="user_diploma"
                        onChange={handleInput}
                        className={`${
                          errorMessage?.user_diploma ? "is-invalid" : ""
                        }`}
                        disabled
                      />
                      <Form.Label>Chức vụ</Form.Label>
                      <span className="text-danger">
                        {errorMessage?.user_diploma}
                      </span>
                    </Form.Floating>
                  )}
                </Col>

                <Col md={4}>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Trình độ chuyên môn"
                      value={user.user_diploma}
                      name="user_diploma"
                      onChange={handleInput}
                      className={`${
                        errorMessage?.user_diploma ? "is-invalid" : ""
                      }`}
                    />
                    <Form.Label>Trình độ chuyên môn</Form.Label>
                    <span className="text-danger">
                      {errorMessage?.user_diploma}
                    </span>
                  </Form.Floating>
                </Col>

                <Col md={4} xs={6}>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Số điện thoại"
                      value={user.user_phone}
                      name="user_phone"
                      onChange={handleInput}
                      className={`${
                        errorMessage?.user_phone ? "is-invalid" : ""
                      }`}
                    />
                    <Form.Label>Số điện thoại</Form.Label>
                    <span className="text-danger">
                      {errorMessage?.user_phone}
                    </span>
                  </Form.Floating>
                </Col>

                <Col md={4} xs={6}>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Email"
                      value={user.user_email}
                      name="user_email"
                      onChange={handleInput}
                      className={`${
                        errorMessage?.user_email ? "is-invalid" : ""
                      }`}
                    />
                    <Form.Label>Email</Form.Label>
                    <span className="text-danger">
                      {errorMessage?.user_email}
                    </span>
                  </Form.Floating>
                </Col>

                <Col md={8}>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Địa chỉ"
                      value={user.user_address}
                      name="user_address"
                      onChange={handleInput}
                      className={`${
                        errorMessage?.user_address ? "is-invalid" : ""
                      }`}
                    />
                    <Form.Label>Địa chỉ</Form.Label>
                    <span className="text-danger">
                      {errorMessage?.user_address}
                    </span>
                  </Form.Floating>
                </Col>

                <Col md={4}>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Hình thức hợp đồng"
                      value={user.user_contract}
                      name="user_contract"
                      onChange={handleInput}
                      className={`${
                        errorMessage?.user_contract ? "is-invalid" : ""
                      }`}
                    />
                    <Form.Label>Hình thức hợp đồng</Form.Label>
                    <span className="text-danger">
                      {errorMessage?.user_contract}
                    </span>
                  </Form.Floating>
                </Col>
              </Row>
            </Modal.Body>

            <Modal.Footer>
              <Button size="sm" variant="secondary" onClick={onHide}>
                <i className="fa-solid fa-times" /> Đóng
              </Button>

              {new Check().permission([4]) ||
              Number(authId) === Number(userId) ? (
                <Button size="sm" variant="success" type="submit">
                  <i className="fa-solid fa-check" /> Cập nhật
                </Button>
              ) : (
                <></>
              )}
            </Modal.Footer>
          </Form>
        </Tab>

        {Number(authId) === Number(userId) || new Check().permission([4]) ? (
          <Tab
            key={1}
            eventKey={2}
            title={
              <>
                <span className="fa-solid fa-lock"></span> Bảo mật
              </>
            }
          >
            <Form onSubmit={handleSubmitChangePassword}>
              <Modal.Body>
                <Row>
                  <Col md={4} className="d-flex align-items-center mb-3">
                    <strong className="me-2">Trạng thái:</strong>
                    {new Check().permission([4]) &&
                    Number(authId) !== Number(userId) ? (
                      <>
                        <Form.Check
                          type="switch"
                          value={IS_STATUS.ACTIVE}
                          checked={user.is_status === IS_STATUS.ACTIVE}
                          onChange={(e) => {
                            setUser({
                              ...user,
                              is_status: e.target.checked
                                ? IS_STATUS.ACTIVE
                                : IS_STATUS.IN_ACTIVE,
                            });
                          }}
                          name="is_status"
                          label={
                            user.is_status === IS_STATUS.ACTIVE ? (
                              <span className="text-success">Kích hoạt</span>
                            ) : (
                              <span className="text-danger">Tạm khóa</span>
                            )
                          }
                        />
                      </>
                    ) : user.is_status === IS_STATUS.ACTIVE ? (
                      <span className="text-success">Kích hoạt</span>
                    ) : (
                      <span className="text-danger">Tạm khóa</span>
                    )}
                  </Col>

                  <Col md={8} className="mb-3">
                    {Number(authId) === Number(userId) ? (
                      <Form.Floating>
                        <Form.Control
                          type="text"
                          placeholder="Tài khoản"
                          value={user.user_account}
                          name="user_account"
                          onChange={handleInput}
                          className={`${
                            errorMessage?.user_account ? "is-invalid" : ""
                          }`}
                        />
                        <Form.Label>Tài khoản</Form.Label>
                        <span className="text-danger">
                          {errorMessage?.user_account}
                        </span>
                      </Form.Floating>
                    ) : (
                      <>
                        <strong className="me-2">Tài khoản:</strong>
                        {user.user_account}
                      </>
                    )}
                  </Col>

                  {Number(authId) === Number(userId) ? (
                    <>
                      <Col md={4} className="mb-3">
                        <PasswordInput
                          label={<>Mật khẩu cũ</>}
                          name="updater_password"
                          value={user?.updater_password}
                          placeholde="Mật khẩu cũ"
                          onChange={handleInput}
                          errMessage={errorMessage?.updater_password}
                        />
                      </Col>

                      <Col md={4} className="mb-3">
                        <PasswordInput
                          label={
                            <>
                              Mật khẩu mới
                              {user?.updater_password ? (
                                <span className="text-danger">*</span>
                              ) : (
                                <></>
                              )}
                            </>
                          }
                          name="user_password"
                          value={user?.user_password}
                          placeholde="Mật khẩu"
                          onChange={handleInput}
                          errMessage={errorMessage?.user_password}
                        />
                      </Col>

                      <Col md={4} className="mb-3">
                        <PasswordInput
                          label={
                            <>
                              Nhập lại mật khẩu
                              {user?.updater_password ? (
                                <span className="text-danger">*</span>
                              ) : (
                                <></>
                              )}
                            </>
                          }
                          name="user_confirm_password"
                          value={user?.user_confirm_password}
                          placeholde="Nhập lại mật khẩu"
                          onChange={handleInput}
                          errMessage={errorMessage?.user_confirm_password}
                        />
                      </Col>
                    </>
                  ) : (
                    <></>
                  )}
                </Row>
              </Modal.Body>

              <Modal.Footer>
                <Button size="sm" variant="secondary" onClick={onHide}>
                  <i className="fa-solid fa-times" /> Đóng
                </Button>

                {new Check().permission([4]) ||
                Number(authId) === Number(userId) ? (
                  <Button size="sm" variant="success" type="submit">
                    <i className="fa-solid fa-check" /> Cập nhật
                  </Button>
                ) : (
                  <></>
                )}
              </Modal.Footer>
            </Form>
          </Tab>
        ) : (
          <></>
        )}
      </Tabs>
    </Modal>
  );
};

export default UserEditModal;
