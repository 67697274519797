import { Form, Table } from "react-bootstrap";
import Select from "react-select";
import CurrencyFormat from "react-currency-format";
import { dateFormatter } from "../../utils/helpers";

const MarketListTable = ({
  voucher,
  ingredients,
  setIngredients,
  ingredientsConstant,
  setIngredientsConstant,
  school,
  suppliers,
  isChangeVoucherMarket,
}) => {
  if (!voucher) {
    return "";
  }

  let totalMoney = 0;
  let marketMoney = 0;
  let storeMoney = 0;

  return (
    <div style={{ overflowX: "scroll" }} className="table-responsive-600 ">
      <Table bordered style={{ minWidth: 1024 }}>
        <thead>
          <tr className="text-center tr-sticky-first">
            <th>STT</th>
            <th>MH</th>
            <th>TÊN HÀNG</th>
            <th>NHÀ CUNG CẤP</th>
            <th>ĐVT</th>
            <th>SỐ LƯỢNG</th>
            <th>ĐƠN GIÁ</th>
            <th>THÀNH TIỀN</th>
            <th>GHI CHÚ</th>
          </tr>
        </thead>
        <tbody>
          {ingredients?.length !== 0 ? (
            <>
              {ingredients
                ?.sort((a, b) => {
                  const typeComparison = a.is_next_day - b.is_next_day;
                  if (typeComparison !== 0) {
                    return typeComparison;
                  }
                  return a.ingredent_name.localeCompare(b.ingredent_name);
                })
                ?.map((ingredientItem, ingredientIndex) => {
                  let ingredientQuantity = Number(
                    ingredientItem.ing_quantity || 0
                  ).toFixed(1);

                  const ingredientItemPrice = Number(
                    parseFloat(ingredientQuantity) * ingredientItem.ing_price
                  ).toFixed(0);

                  totalMoney += parseFloat(ingredientItemPrice);

                  return (
                    <tr
                      key={`ingredient-key-2-${ingredientIndex}`}
                      className="align-middle"
                    >
                      <td className="text-center">{ingredientIndex + 1}</td>
                      <td>{ingredientItem.ingredent_code}</td>
                      <td>{ingredientItem.ingredent_name}</td>
                      <td>
                        {isChangeVoucherMarket ? (
                          <Select
                            isDisabled={
                              Number(ingredientItem.ingredent_type) > 1
                            }
                            options={suppliers}
                            getOptionLabel={(option) => option.supplier_name}
                            getOptionValue={(option) => option.id}
                            value={{
                              id: ingredientItem.id,
                              supplier_name: ingredientItem.supplier_name,
                            }}
                            placeholder={<div>Chọn nhà cung cấp</div>}
                            onChange={(choice) => {
                              setIngredients(
                                ingredients.map((ingredientObj) => {
                                  if (
                                    ingredientItem.id_ingredent ===
                                    ingredientObj.id_ingredent
                                  ) {
                                    return {
                                      ...ingredientObj,
                                      id_supplier: choice.id,
                                      supplier_name: choice.supplier_name,
                                    };
                                  }

                                  return ingredientObj;
                                })
                              );

                              setIngredientsConstant(
                                ingredientsConstant.map((ingredientObj) => {
                                  if (
                                    ingredientItem.id_ingredent ===
                                    ingredientObj.id_ingredent
                                  ) {
                                    return {
                                      ...ingredientObj,
                                      id_supplier: choice.id,
                                      supplier_name: choice.supplier_name,
                                    };
                                  }

                                  return ingredientObj;
                                })
                              );
                            }}
                            menuPortalTarget={document.querySelector("body")}
                          />
                        ) : (
                          ingredientItem.supplier_name
                        )}
                      </td>
                      <td>{ingredientItem.unit_market_name}</td>
                      <td className="text-end">
                        <CurrencyFormat
                          value={
                            parseFloat(ingredientItem.ing_quantity)?.toFixed(
                              1
                            ) || 0
                          }
                          displayType={
                            !isChangeVoucherMarket ? "text" : "input"
                          }
                          thousandSeparator={true}
                          onValueChange={(values) => {
                            setIngredients(
                              ingredients.map((ingredientObj) => {
                                if (
                                  JSON.stringify(ingredientItem) ===
                                  JSON.stringify(ingredientObj)
                                ) {
                                  return {
                                    ...ingredientObj,
                                    ing_quantity: values.value,
                                  };
                                }

                                return ingredientObj;
                              })
                            );

                            setIngredientsConstant(
                              ingredientsConstant.map((ingredientObj) => {
                                if (
                                  JSON.stringify(ingredientItem) ===
                                  JSON.stringify(ingredientObj)
                                ) {
                                  return {
                                    ...ingredientObj,
                                    ing_quantity: values.value,
                                  };
                                }

                                return ingredientObj;
                              })
                            );
                          }}
                          className={`${
                            isChangeVoucherMarket ? "form-control" : ""
                          } text-end`}
                          allowNegative={false}
                          disabled={!isChangeVoucherMarket}
                          decimalScale={1}
                        />
                      </td>
                      <td className="text-end">
                        <CurrencyFormat
                          value={ingredientItem.ing_price || 0}
                          displayType={
                            !isChangeVoucherMarket ? "text" : "input"
                          }
                          thousandSeparator={true}
                          onValueChange={(values) => {
                            setIngredients(
                              ingredients.map((ingredientObj) => {
                                if (
                                  ingredientItem.id_ingredent ===
                                  ingredientObj.id_ingredent
                                ) {
                                  return {
                                    ...ingredientObj,
                                    ing_price: values.value,
                                  };
                                }

                                return ingredientObj;
                              })
                            );

                            setIngredientsConstant(
                              (ingredientsConstant = ingredientsConstant.map(
                                (ingredientObj) => {
                                  if (
                                    ingredientItem.id_ingredent ===
                                    ingredientObj.id_ingredent
                                  ) {
                                    return {
                                      ...ingredientObj,
                                      ing_price: values.value,
                                    };
                                  }

                                  return ingredientObj;
                                }
                              ))
                            );
                          }}
                          className={`${
                            isChangeVoucherMarket ? "form-control" : ""
                          } text-end`}
                          allowNegative={false}
                          isNumericString
                          disabled={
                            !isChangeVoucherMarket ||
                            Number(ingredientItem.ingredent_type) > 1
                          }
                          decimalScale={0}
                        />
                      </td>
                      <td className="text-end">
                        <CurrencyFormat
                          value={parseFloat(ingredientItemPrice) || 0}
                          displayType={"text"}
                          thousandSeparator={true}
                          className="text-end"
                          isNumericString
                          decimalScale={0}
                        />
                      </td>
                      <td>
                        {isChangeVoucherMarket ? (
                          <Form.Control
                            type="text"
                            value={
                              ingredientItem.vou_notes
                                ? ingredientItem.vou_notes
                                : ""
                            }
                            onChange={(e) => {
                              setIngredients(
                                ingredients.map((ingredientObj) => {
                                  if (
                                    ingredientItem.id_ingredent ===
                                    ingredientObj.id_ingredent
                                  ) {
                                    return {
                                      ...ingredientObj,
                                      vou_notes: e.target.value,
                                    };
                                  }

                                  return ingredientObj;
                                })
                              );
                            }}
                            disabled={!isChangeVoucherMarket}
                          />
                        ) : (
                          ingredientItem.vou_notes
                        )}
                      </td>
                    </tr>
                  );
                })}
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th className="text-end">CỘNG</th>
                <th className="text-end">
                  <CurrencyFormat
                    value={totalMoney || 0}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={0}
                  />
                </th>
                <th></th>
              </tr>
            </>
          ) : (
            <tr>
              <td colSpan={9} className="text-center">
                Không có dữ liệu . . .
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <Table
        bordered
        style={{ minWidth: 1024 }}
        className="d-none"
        id="market-list-table"
      >
        <thead>
          <tr>
            <th>{school.school_name?.toUpperCase()}</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
          </tr>
          <tr>
            <th>
              PHIẾU ĐI CHỢ NGÀY{" "}
              {dateFormatter(new Date(voucher.menu_date * 1000))}
            </th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
            <th>empty</th>
          </tr>
          <tr>
            <th>STT</th>
            <th>NGUYÊN LIỆU</th>
            <th>NHÀ CUNG CẤP</th>
            <th>ĐƠN VỊ</th>
            <th>SỐ LƯỢNG</th>
            <th>ĐƠN GIÁ</th>
            <th>THÀNH TIỀN</th>
            <th>GHI CHÚ</th>
          </tr>
        </thead>
        <tbody>
          {ingredients?.filter(
            (ingredientItem) => ingredientItem.ingredent_type === 1
          ).length !== 0 ? (
            <>
              {ingredients
                ?.filter(
                  (ingredientItem) =>
                    Number(ingredientItem.ingredent_type) === 1
                )
                ?.sort((a, b) =>
                  a?.ingredent_name?.localeCompare(b?.ingredent_name)
                )
                ?.map((ingredientItem, ingredientIndex) => {
                  const ingredientQuantity = Number(
                    ingredientItem.ing_quantity || 0
                  ).toFixed(1);

                  const ingredientItemPrice = Math.round(
                    Number(
                      parseFloat(ingredientQuantity) * ingredientItem.ing_price
                    ).toFixed(0)
                  );

                  marketMoney += parseInt(ingredientItemPrice);

                  return (
                    <tr
                      key={`ingredient-key-0-${ingredientIndex}`}
                      className="align-middle"
                    >
                      <td>{ingredientIndex + 1}</td>
                      <td>{ingredientItem.ingredent_name}</td>
                      <td>{ingredientItem.supplier_name}</td>
                      <td>{ingredientItem.unit_market_name}</td>
                      <td>{ingredientQuantity}</td>
                      <td>{ingredientItem.ing_price}</td>
                      <td>{ingredientItemPrice}</td>
                      <td>
                        {ingredientItem.vou_notes
                          ? ingredientItem.vou_notes
                          : "empty"}
                      </td>
                    </tr>
                  );
                })}
              <tr>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th>CỘNG</th>
                <th>
                  <CurrencyFormat
                    value={marketMoney || 0}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={0}
                  />
                </th>
                <th>&nbsp;</th>
              </tr>
            </>
          ) : (
            <tr>
              <td>Không có hàng chợ</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
            </tr>
          )}

          {/* Store */}
          {ingredients?.filter(
            (ingredientItem) => ingredientItem.ingredent_type === 2
          ).length !== 0 ? (
            <>
              {ingredients
                ?.filter(
                  (ingredientItem) =>
                    Number(ingredientItem.ingredent_type) === 2
                )
                ?.sort((a, b) =>
                  a?.ingredent_name?.localeCompare(b?.ingredent_name)
                )
                ?.map((ingredientItem, ingredientIndex) => {
                  const ingredientQuantity = Number(
                    ingredientItem.ing_quantity
                  ).toFixed(1);

                  const ingredientItemPrice = Number(
                    parseFloat(ingredientQuantity) * ingredientItem.ing_price
                  ).toFixed(0);

                  storeMoney += parseInt(ingredientItemPrice);

                  return (
                    <tr key={`ingredient-key-1-${ingredientIndex}`}>
                      <td>{ingredientIndex + 1}</td>
                      <td>{ingredientItem.ingredent_name} (Kho)</td>
                      <td>{ingredientItem.supplier_name}</td>
                      <td>{ingredientItem.unit_market_name}</td>
                      <td>{ingredientQuantity}</td>
                      <td>{ingredientItem.ing_price}</td>
                      <td>{ingredientItemPrice}</td>
                      <td>
                        {ingredientItem.vou_notes
                          ? ingredientItem.vou_notes
                          : "empty"}
                      </td>
                    </tr>
                  );
                })}
              <tr>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th>CỘNG</th>
                <th>
                  <CurrencyFormat
                    value={storeMoney || 0}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={0}
                  />
                </th>
                <th>&nbsp;</th>
              </tr>
            </>
          ) : (
            <tr>
              <td>Không có hàng kho</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
            </tr>
          )}
        </tbody>
        <tfoot></tfoot>
      </Table>
    </div>
  );
};

export default MarketListTable;
