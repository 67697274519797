import { Button } from "react-bootstrap";
import api from "../../../helper/axiosInstance";
import FillExcelTemplate from "../../../helper/files/FillExcelTemplate";
import { dateFormatter, groupArrayByField } from "../utils/helpers";
import moment from "moment";
import { useState, useEffect } from "react";
import { INGREDIENT_TYPE, SHOW_INVENTORY } from "../../../constants";

import { toast } from "react-toastify";
const ThreeStepFiles = ({ school, quantitative, formSetting, times }) => {
  useEffect(() => {
    getIngredentsData();
    getAppSettingBook3();
    getAppSettingBook2();
  }, []);
  const getIngredentsData = () => {
    api
      .get(`/ingredent-show?ingredent_type=2`)
      .then((res) => {
        if (res.data.errCode === 0) {
          setIngredent(res.data.ingredents);
        }
      })
      .catch((err) => {});
  };
  const getAppSettingBook3 = async () => {
    await api
      .get(`/app-setting-step-3-show`)
      .then((res) => {
        setSections(res.data.data);
      })
      .catch((error) => {});
  };
  const getAppSettingBook2 = async () => {
    await api
      .get(`/app-setting-step-2-show`)
      .then((res) => {
        setSections2(res.data.data);
      })
      .catch((error) => {});
  };
  const [ingredents, setIngredent] = useState([]);
  const [sections, setSections] = useState({});
  const [sections2, setSections2] = useState({});
  let quantitativeForBook1 = {
    ...quantitative,
    times: quantitative?.times?.map((time) => ({
      ...time,
      foods: time?.foods?.map((food) => ({
        ...food,
        groups: food?.groups?.map((group) => ({
          ...group,
          ingredents: group?.ingredents?.filter((ingredent) => {
            if (formSetting.step1_show_inventory === SHOW_INVENTORY.FALSE)
              return ingredent.ingredent_type === INGREDIENT_TYPE.MARKET;
            else return true;
          }),
        })),
      })),
    })),
  };
  const costPerDays = quantitative?.costPerDays || [];
  let ingredientArray = [];
  quantitativeForBook1?.times?.map((timeItem) => {
    timeItem?.foods?.map((foodItem) => {
      return foodItem.groups?.map((groupItem) => {
        return groupItem.ingredents?.map((ingredientItem) => {
          const mealQuantity =
            groupItem.more_meal !== null
              ? groupItem.more_meal
              : costPerDays.find(
                  (costPerDay) => costPerDay.id_group === groupItem.id
                )?.meal_quantity || 0;
          ingredientArray.push({
            ...ingredientItem,
            meal_quantity: mealQuantity,
          });

          return ingredientItem;
        });
      });
    });
    return timeItem;
  });
  let bookIndex = 0;
  const book3 = quantitative?.times?.flatMap((timeItem) => {
    return timeItem?.foods.flatMap((foodItem) => {
      let mealQuantity = 0;
      foodItem?.groups.forEach((groupItem) => {
        mealQuantity += groupItem.more_meal
          ? groupItem.more_meal
          : Number(
              costPerDays.find(
                (costPerDay) => costPerDay.id_group === groupItem.id
              )?.meal_quantity
            );
      });
      const timeObj = times.find((t) => t.id === timeItem.id_time);
      return {
        stt: bookIndex++,
        ten_bua_an: timeObj?.time_name_now,
        ten_bua_an_va_gio: `${timeObj?.time_name_now} ${
          formSetting.step3_2?.find(
            (item) => parseInt(item.time) === timeItem.id_time
          )?.val
            ? moment(
                Number(
                  formSetting.step3_2?.find(
                    (item) => parseInt(item.time) === timeItem.id_time
                  )?.val
                )
              ).format("HH:mm")
            : ""
        }`,
        gio: moment(
          Number(
            formSetting.step3_2?.find(
              (item) => parseInt(item.time) === timeItem.id_time
            )?.val
          )
        ).format("HH:mm"),
        buoi_va_gio_bat_dau: `${timeObj?.time_name_now} ${
          formSetting.step3_5?.find(
            (item) => parseInt(item.time) === timeItem.id_time
          )?.val
            ? moment(
                Number(
                  formSetting.step3_5?.find(
                    (item) => parseInt(item.time) === timeItem.id_time
                  )?.val
                )
              ).format("HH:mm")
            : ""
        }`,
        buoi_va_gio_ket_thuc: `${timeObj?.time_name_now} ${
          formSetting.step3_6?.find(
            (item) => parseInt(item.time) === timeItem.id_time
          )?.val
            ? moment(
                Number(
                  formSetting.step3_6?.find(
                    (item) => parseInt(item.time) === timeItem.id_time
                  )?.val
                )
              ).format("HH:mm")
            : ""
        }`,
        ten_mon: foodItem.food_name,

        so_luong: mealQuantity,
        gio_bat_dau: moment(
          Number(
            formSetting.step3_5?.find(
              (item) => parseInt(item.time) === timeItem.id_time
            )?.val
          )
        ).format("HH:mm"),
        gio_ket_thuc: moment(
          Number(
            formSetting.step3_6?.find(
              (item) => parseInt(item.time) === timeItem.id_time
            )?.val
          )
        ).format("HH:mm"),
        note: formSetting.step3_7,
        section: sections?.section_8 || "",
      };
    });
  });
  let quantitativeForBook2 = {
    ...quantitative,
    times: quantitative?.times?.map((time) => ({
      ...time,
      foods: time?.foods?.map((food) => ({
        ...food,
        groups: food?.groups?.map((group) => ({
          ...group,
          ingredents: group?.ingredents?.filter((ingredent) => {
            // Kiểm tra `SHOW_INVENTORY`
            const isInventoryValid =
              formSetting.step2_show_inventory === SHOW_INVENTORY.FALSE
                ? ingredent.ingredent_type === INGREDIENT_TYPE.MARKET
                : true;

            // Kiểm tra `section2.show_spice`
            const isSpiceValid =
              sections2.show_spice || ingredent.is_spice === 0;

            return isInventoryValid && isSpiceValid;
          }),
        })),
      })),
    })),
  };

  const book2Array = quantitativeForBook2?.times?.flatMap((timeItem) => {
    return timeItem?.foods.map((foodItem, foodIndex) => {
      let mealQuantity = 0;
      foodItem.groups.forEach((groupItem) => {
        mealQuantity += groupItem.more_meal
          ? groupItem.more_meal
          : Number(
              costPerDays.find(
                (costPerDay) => costPerDay.id_group === groupItem.id
              )?.meal_quantity
            );
      });
      const groupsWithIngredients = foodItem.groups.map((groupItem) => {
        const ingredients =
          formSetting.step1_show_inventory === 1
            ? groupItem.ingredents
            : groupItem.ingredents.filter(
                (ingredient) => ingredient.ingredent_type === 1
              );

        const processedIngredients = ingredients.map((ingredientItem) => ({
          name: ingredientItem.ingredent_name,
          quantity: parseFloat(ingredientItem.ing_quantity),
          unit: ingredientItem.unit_cook_name,
        }));

        return {
          groupName: groupItem.group_name,
          ingredients: processedIngredients,
        };
      });

      const timeName =
        foodIndex === 0
          ? times.find((timeObj) => timeObj.id === timeItem.id_time)
              ?.time_name_now
          : "";

      return {
        stt: bookIndex++,
        timeId: timeItem.id_time,
        ten_buoi: timeName,
        ten_mon: foodItem.food_name,
        nhom: groupsWithIngredients
          ?.map((group) => {
            let result = group.groupName + "\n";
            group.ingredients.forEach((ingredient) => {
              result += ` ${ingredient.name} : ${ingredient.quantity} ${ingredient.unit}\n`;
            });
            return result;
          })
          .join("\n"),
        so_luong: mealQuantity,
        gio_bat_dau: formSetting.step2_6?.find(
          (item) => parseInt(item.time) === timeItem.id_time
        )?.val
          ? moment(
              Number(
                formSetting.step2_6?.find(
                  (item) => parseInt(item.time) === timeItem.id_time
                )?.val
              )
            ).format("HH:mm")
          : "",
        gio_ket_thuc: formSetting.step2_7?.find(
          (item) => parseInt(item.time) === timeItem.id_time
        )?.val
          ? moment(
              Number(
                formSetting.step2_7?.find(
                  (item) => parseInt(item.time) === timeItem.id_time
                )?.val
              )
            ).format("HH:mm")
          : "",
        assignedUsers: formSetting.step2_8?.map((user) => user.label) || [],
        note1: formSetting.step2_9,
        note2: formSetting.step2_10,
        section: sections2?.section_11 || "",
      };
    });
  });
  let dataEmptyBook1 = [
    {
      ten_nguyen_lieu: "",
      thoi_gian_kiem_tra: "",

      ingredent_type: 2,
      nha_cung_cap: "",
      dia_chi: "",
      nguoi_giao_hang: "",
      han_su_dung: "",
      ten_co_so: "",
      dia_chi_san_xuat: "",
      khoi_luong: "",
    },
  ];
  let dataEmptyBook2 = [
    {
      stt: 0,
      ten_mon: "",
      nhom: "",
      so_luong: 0,
      gio_bat_dau: "",
      gio_ket_thuc: "",
      assignedUsers: [],
      note1: "",
      note2: "",
      section: "",
    },
  ];
  let dataEmptyBook3 = [
    {
      stt: 0,
      ten_bua_an: "",
      ten_bua_an_va_gio: "",
      gio: "",
      buoi_va_gio_bat_dau: "",
      buoi_va_gio_ket_thuc: "",
      ten_mon: "",
      so_luong: 0,
      gio_bat_dau: "",
      gio_ket_thuc: "",
      note: "",
      section: "",
    },
  ];

  const groupIngredientArray = groupArrayByField(ingredientArray, "id");
  let data_excel = [];
  groupIngredientArray?.map((f) => {
    let gramMarket = 0;
    f.map((ingredientItem) => {
      const ingredientQuantity = ingredientItem.ing_quantity;
      gramMarket += Number(
        (ingredientQuantity * ingredientItem.meal_quantity) /
          ingredientItem.volume_cook
      );
      return ingredientItem;
    });
    gramMarket = Number(gramMarket).toFixed(2);
    data_excel?.push({
      ten_nguyen_lieu: f[0]?.ingredent_name,
      thoi_gian_kiem_tra: moment(formSetting.step1_m1_3).format("HH:mm"),
      ingredent_type: f[0]?.ingredent_type,
      nha_cung_cap: f[0]?.supplier_name,
      dia_chi: f[0]?.supplier_address,
      nguoi_giao_hang: f[0]?.supplier_shipper,
      han_su_dung:
        ingredents?.filter((e) => e.id === f[0].id)[0]?.ing_exp_date &&
        dateFormatter(
          new Date(
            ingredents?.filter((e) => e.id === f[0].id)[0]?.ing_exp_date *
              1000 || ""
          )
        ),
      ten_co_so: f[0]?.facility_name,
      dia_chi_san_xuat: f[0]?.facility_address,
      khoi_luong: `${gramMarket} ${f[0]?.unit_market_name}`,
    });
    return f;
  });
  const handleExportPortion = async () => {
    try {
      const response = await api.get(
        `/file-report-template-download?code=FILE_0001`,
        {
          responseType: "blob",
        }
      );
      FillExcelTemplate(
        response.data,
        {
          nguyen_lieu_cho:
            data_excel?.filter((e) => Number(e.ingredent_type) === 1)?.length >
            0
              ? data_excel?.filter((e) => Number(e.ingredent_type) === 1)
              : dataEmptyBook1,
          nguyen_lieu_kho:
            data_excel?.filter((e) => Number(e.ingredent_type) === 2)?.length >
            0
              ? data_excel?.filter((e) => Number(e.ingredent_type) === 2)
              : dataEmptyBook1,
          bua_an: book3?.length > 0 ? book3 : dataEmptyBook3,
          buoc_2: book2Array?.length > 0 ? book2Array : dataEmptyBook2,
          ngay_kiem_tra: `ngày ${new Date(
            quantitative?.menu_date * 1000
          ).getDate()} tháng ${
            new Date(quantitative?.menu_date * 1000).getMonth() + 1
          } năm ${new Date(quantitative?.menu_date * 1000).getFullYear()}`,
        },
        `Sổ 3 bước ngày ${new Date(
          quantitative?.menu_date * 1000
        ).getDate()} tháng ${
          new Date(quantitative?.menu_date * 1000).getMonth() + 1
        } năm ${new Date(quantitative?.menu_date * 1000).getFullYear()}`
      );
    } catch (error) {
      toast.error("Đã xảy ra lỗi");
      console.error("Error downloading Excel file:", error);
    }
  };
  const downloadFile = async (code = "FILE_0001") => {
    try {
      const response = await api.get(
        `/file-report-template-download?code=${code}`,
        {
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${code}.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading Excel file:", error);
    }
  };
  return (
    <div>
      <Button
        size="sm"
        variant="success"
        onClick={() => handleExportPortion()}
        className="me-2"
      >
        <i className="fa-solid fa-file-export" /> Xuất file
      </Button>
      {/* <Button
        size="sm"
        variant="success"
        onClick={() => downloadFile()}
        className="me-2"
      >
        <i className="fa-solid fa-file-export" /> File mẫu
      </Button> */}
    </div>
  );
};

export default ThreeStepFiles;
