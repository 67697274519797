import React, { useEffect, useState } from "react";
import {
  Container,
  Breadcrumb,
  Card,
  Tab,
  Tabs,
  Button,
} from "react-bootstrap";
import { toast } from "react-toastify";
import Check from "../other/Check";
import Book1 from "./formSettingBooks/Book1";
import Book3 from "./formSettingBooks/Book3";
import Book2 from "./formSettingBooks/Book2";
import SampleBook from "./formSettingBooks/SampleBook";
import { SHOW_INVENTORY } from "../../../constants";
import swal from "sweetalert";
import api from "../../../helper/axiosInstance";

const FormSetting = () => {
  const [formSetting, setFormSetting] = useState({
    // Sample book
    book_check_address: "",
    book_3: [],
    book_6: "",
    book_7: "",
    book_8: [],
    book_9: [],
    book_11: "",
    book_12: "",

    // Step 1
    step1_checker: "",
    step1_check_address: "",
    step1_show_inventory: SHOW_INVENTORY.TRUE,
    step1_m1_3: new Date().getTime() / 1000,
    step1_m2_5: new Date().getTime() / 1000,
    step1_m2_11: "",

    // Step 2
    step2_checker: "",
    step2_check_address: "",
    step2_show_inventory: SHOW_INVENTORY.TRUE,
    step2_6: [],
    step2_7: [],
    step2_8: [],
    step2_9: "",
    step2_10: "",

    // Step 3
    step3_checker: "",
    step3_check_address: "",
    step3_2: [],
    step3_5: [],
    step3_6: [],
    step3_7: "",
    show_spice: 1,
  });

  const [times, setTimes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = "Cài đặt biểu mẫu";
    getSettingFormData();
    getTimes();
  }, []);

  //#region Fetch API
  const getSettingFormData = async () => {
    setIsLoading(true);

    await api
      .get("/setting-form-show")
      .then((res) => {
        setIsLoading(false);
        if (res.data.errCode === 0) {
          setFormSetting(res.data.settingForm);
          getSettingFormData2(res.data.settingForm);
        }
      })
      .catch((error) => {});
  };
  const getSettingFormData2 = async (formSettingRes) => {
    setIsLoading(true);

    await api
      .get("/app-setting-step-2-show")
      .then((res) => {
        setIsLoading(false);

        setFormSetting({
          ...formSettingRes,
          show_spice: res.data.data.show_spice,
        });

        // if (res.data.errCode === 0) {
        //   setFormSetting2(res.data.settingForm);
        // }
      })
      .catch((error) => {});
  };

  const updateSettingFormData = async () => {
    swal({
      title: `Bạn muốn cập nhật Cài đặt biểu mẫu?`,
      icon: "warning",
      buttons: ["Đóng", "Cập nhật"],
      successMode: true,
    })
      .then(async (ok) => {
        if (ok) {
          var bodyFormData = new FormData();
          bodyFormData.append("step1_checker", formSetting.step1_checker);
          bodyFormData.append(
            "step1_check_address",
            formSetting.step1_check_address
          );
          bodyFormData.append(
            "step1_show_inventory",
            formSetting.step1_show_inventory
          );
          bodyFormData.append("step1_m1_3", formSetting.step1_m1_3);
          bodyFormData.append("step1_m2_5", formSetting.step1_m2_5);
          bodyFormData.append("step1_m2_11", formSetting.step1_m2_11);

          bodyFormData.append("step2_checker", formSetting.step2_checker);
          bodyFormData.append(
            "step2_check_address",
            formSetting.step2_check_address
          );
          bodyFormData.append(
            "step2_show_inventory",
            formSetting.step2_show_inventory
          );
          bodyFormData.append("step2_6", JSON.stringify(formSetting.step2_6));
          bodyFormData.append("step2_7", JSON.stringify(formSetting.step2_7));
          bodyFormData.append("step2_8", JSON.stringify(formSetting.step2_8));
          bodyFormData.append("step2_9", formSetting.step2_9);
          bodyFormData.append("step2_10", formSetting.step2_10);

          bodyFormData.append("step3_checker", formSetting.step3_checker);
          bodyFormData.append(
            "step3_check_address",
            formSetting.step3_check_address
          );
          bodyFormData.append("step3_2", JSON.stringify(formSetting.step3_2));
          bodyFormData.append("step3_5", JSON.stringify(formSetting.step3_5));
          bodyFormData.append("step3_6", JSON.stringify(formSetting.step3_6));
          bodyFormData.append("step3_7", formSetting.step3_7);

          bodyFormData.append(
            "book_check_address",
            formSetting.book_check_address
          );
          bodyFormData.append("book_3", JSON.stringify(formSetting.book_3));
          bodyFormData.append("book_6", formSetting.book_6);
          bodyFormData.append("book_7", formSetting.book_7);
          bodyFormData.append("book_8", JSON.stringify(formSetting.book_8));
          bodyFormData.append("book_9", JSON.stringify(formSetting.book_9));
          bodyFormData.append("book_11", formSetting.book_11);
          bodyFormData.append("book_12", formSetting.book_12);
          let dataForm2 = {
            show_spice: formSetting?.show_spice || 0,
          };
          try {
            const [res1, res2] = await Promise.all([
              api({
                method: "put",
                url: "setting-form-update",
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data" },
              }),
              api({
                method: "put",
                url: "app-setting-step-2-update",
                data: dataForm2,
                headers: { "Content-Type": "application/json" },
              }),
            ]);

            if (res1.data && res2.data) {
              getSettingFormData();
              toast("Cập nhật biểu mẫu thành công", {
                type: "success",
                autoClose: 1000,
              });
            } else {
              toast("Cập nhật biểu mẫu thất bại", {
                type: "error",
                autoClose: 1000,
              });
            }
          } catch (error) {
            toast("Có lỗi xảy ra", {
              type: "error",
              autoClose: 1000,
            });
          }
        }
      })
      .catch((err) => {});
  };

  const getTimes = async () => {
    await api
      .get("/getTimeOfDays")
      .then((res) => {
        if (res.data.errCode === 0) {
          setTimes(res.data.times);
        }
      })
      .catch((error) => {});
  };
  //#endregion

  // Render
  return (
    <Container fluid>
      <Breadcrumb>
        <Breadcrumb.Item active>Biểu mẫu</Breadcrumb.Item>
        <Breadcrumb.Item active>Cài đặt biểu mẫu</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <Card.Title>
            <i className="fas fa-list me-1"></i> Cài đặt biểu mẫu
          </Card.Title>
          {new Check().permission(["80"]) ? (
            <Button
              variant="success"
              size="sm"
              className=""
              onClick={() => {
                updateSettingFormData();
              }}
            >
              <i className="fa-solid fa-check"></i> Cập nhật
            </Button>
          ) : null}
        </Card.Header>
        <Card.Body>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center mt-3">
              <div
                className="spinner-border text-primary"
                role="status"
                style={{ width: "3rem", height: "3rem" }}
              >
                <span className="sr-only">Đang tải cài đặt biểu mẫu...</span>
              </div>
            </div>
          ) : (
            <Tabs defaultActiveKey="1" className="mb-3">
              <Tab eventKey="1" title="Bước 1">
                <Book1
                  formSetting={formSetting}
                  setFormSetting={setFormSetting}
                />
              </Tab>

              <Tab eventKey="2" title="Bước 2">
                <Book2
                  formSetting={formSetting}
                  setFormSetting={setFormSetting}
                  times={times}
                />
              </Tab>

              <Tab eventKey="3" title="Bước 3">
                <Book3
                  formSetting={formSetting}
                  setFormSetting={setFormSetting}
                  times={times}
                />
              </Tab>

              <Tab eventKey="4" title="Mẫu ngày">
                <SampleBook
                  formSetting={formSetting}
                  setFormSetting={setFormSetting}
                  times={times}
                />
              </Tab>
            </Tabs>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default FormSetting;
