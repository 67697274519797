import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Spinner,
  Breadcrumb,
} from "react-bootstrap";
import api from "../../../helper/axiosInstance";
import swal from "sweetalert";
import { toast } from "react-toastify";
import ImageUploadModal from "./ImageUploadModal";
import { TYPE_PREFIX } from "../../../constants";
import Select from "react-select";
import ModalMedia from "./ModalMedia";

const MediaIndex = ({ isModal, onHide }) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModalMedia, setShowModalMeida] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [prefix, setPrefix] = useState([TYPE_PREFIX[0]]);
  const limit = 20;

  useEffect(() => {
    fetchFiles(1, true, prefix);
    document.title = "Quản lý hình ảnh";
  }, [prefix]);

  const fetchFiles = async (pageNum = 1, reset = false, prefix) => {
    if (pageNum === 1) setLoading(true);
    else setLoadingMore(true);

    try {
      const formattedPrefix = {};
      if (prefix?.length > 0) {
        prefix.forEach((item, index) => {
          formattedPrefix[`prefix[${index}]`] = item.value;
        });
      } else {
        formattedPrefix["prefix[0]"] = "000";
      }
      const res = await api.get("/get-files", {
        params: {
          project: "kafood",
          ...formattedPrefix,
          sort: "desc",
          limit,
          page: pageNum,
        },
      });

      const newFiles = res.data.data;
      if (reset) {
        setFiles(newFiles);
      } else {
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);
      }

      setHasMore(newFiles.length === limit);
      setError(null);
    } catch (err) {
      setError("Có lỗi khi tải danh sách file. Vui lòng thử lại sau.");
      console.error("Error fetching files:", err);
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  };

  const handleLoadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchFiles(nextPage, false, prefix);
  };

  const handleDeleteFile = async (fileName) => {
    swal({
      title: "Bạn muốn xóa hình ảnh?",
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      warningMode: true,
    }).then(async (ok) => {
      if (ok) {
        try {
          await api.delete("/delete-files", { data: { files: [fileName] } });
          setFiles((prevFiles) =>
            prevFiles.filter((file) => file.url !== fileName)
          );
          // handleLoadMore();
          // fetchFiles(page);
          toast.success("Xóa thành công");
        } catch (err) {
          toast.error("Xóa thất bại");
          setError("Có lỗi khi xóa file. Vui lòng thử lại sau.");
          console.error("Error deleting file:", err);
        }
      }
    });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("vi-VN");
  };

  // const formatFileSize = (bytes) => {
  //   if (bytes < 1024) return bytes + " B";
  //   else if (bytes < 1048576) return (bytes / 1024).toFixed(1) + " KB";
  //   else return (bytes / 1048576).toFixed(1) + " MB";
  // };

  return (
    <Container fluid className="px-3">
      <Breadcrumb className="mb-2">
        <Breadcrumb.Item active>Hình ảnh</Breadcrumb.Item>
        <Breadcrumb.Item active>Quản lý hình ảnh</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Button
                type="button"
                variant="success"
                size="sm"
                style={{ float: "right" }}
                onClick={() => setShowModal(true)}
                title="Thêm hình ảnh"
              >
                <i className="fa-solid fa-plus"></i> Thêm hình ảnh
              </Button>
              <Card.Title>
                <i className="fas fa-list me-1"></i> Quản lý hình ảnh
              </Card.Title>
            </Card.Header>
            <Row className="mt-3 me-1 d-flex justify-content-end ">
              <Col md={4}>
                <Select
                  options={TYPE_PREFIX}
                  value={prefix}
                  placeholder={<div>Chọn loại hình ảnh</div>}
                  onChange={(choice) => {
                    setPrefix(choice);
                  }}
                  isMulti
                ></Select>
              </Col>
            </Row>

            {error && (
              <Row className="mb-3">
                <Col>
                  <div className="alert alert-danger">{error}</div>
                </Col>
              </Row>
            )}

            {loading ? (
              <div className="text-center my-5">
                <Spinner animation="border" variant="primary" />
                <p className="mt-2">Đang tải danh sách hình ảnh...</p>
              </div>
            ) : (
              <>
                <Row className="m-2">
                  {files.length === 0 ? (
                    <Col>
                      <div className="text-center my-5">
                        <p>Không có hình ảnh nào. Hãy tải lên hình ảnh mới!</p>
                      </div>
                    </Col>
                  ) : (
                    files.map((file) => (
                      <Col
                        key={file.name}
                        xs={12}
                        sm={6}
                        md={isModal ? 4 : 2}
                        className="my-2"
                        onClick={() => {
                          isModal && onHide();

                          navigator.clipboard.writeText(file.url);
                        }}
                        style={{ cursor: isModal ? "pointer" : "" }}
                        title={isModal ? "Click để chọn " : ""}
                      >
                        <Card>
                          <Card.Img
                            variant="top"
                            src={file.url}
                            style={{ height: "200px", objectFit: "cover" }}
                            loading="lazy"
                          />
                          <Card.Body>
                            <Card.Text>
                              <small className="text-muted">
                                {/* Kích thước: {formatFileSize(file.size)}
                                <br /> */}
                                Ngày tạo: {formatDate(file.createdAt)}
                              </small>
                            </Card.Text>
                            <div className="d-md-flex d-sm-block justify-content-between">
                              <Button
                                variant="outline-primary"
                                size="sm"
                                onClick={() => window.open(file.url, "_blank")}
                                hidden={isModal}
                                title="Xem ảnh"
                              >
                                <i className="fa-solid fa-circle-info"></i>
                              </Button>
                              <Button
                                variant="outline-info mx-2"
                                size="sm"
                                onClick={() => {
                                  navigator.clipboard.writeText(file.url);
                                }}
                                hidden={isModal}
                                title="Sao chép link"
                              >
                                <i className="fa-solid fa-copy"></i>
                              </Button>
                              <Button
                                variant="outline-danger"
                                size="sm"
                                onClick={() => handleDeleteFile(file.url)}
                                hidden={isModal}
                                title="Xóa ảnh"
                              >
                                <i className="fa-solid fa-trash"></i>
                              </Button>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))
                  )}
                </Row>
                {hasMore && (
                  <div className="text-center my-3">
                    <Button
                      variant="primary"
                      onClick={handleLoadMore}
                      disabled={loadingMore}
                      hidden={!files?.length}
                    >
                      {loadingMore ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />{" "}
                          Đang tải...
                        </>
                      ) : (
                        "Xem thêm"
                      )}
                    </Button>
                  </div>
                )}
              </>
            )}
          </Card>
        </Col>
      </Row>
      {showModal && (
        <ImageUploadModal
          show={showModal}
          onHide={() => setShowModal(false)}
          onUploadSuccess={() => fetchFiles(1, true, prefix)}
          prefix={prefix}
        />
      )}
      {showModalMedia && (
        <ModalMedia
          show={showModalMedia}
          onHide={() => setShowModalMeida(false)}
        />
      )}
    </Container>
  );
};

export default MediaIndex;
