import React, { Component } from "react";
import { toast } from "react-toastify";
import swal from "sweetalert";
import {
  Button,
  Row,
  Col,
  Card,
  Container,
  Breadcrumb,
  Form,
  Alert,
  Table,
  Tabs,
  Tab,
} from "react-bootstrap";
import api from "../../../helper/axiosInstance";
import CurrencyFormat from "react-currency-format";

class SettingExpenditureNorms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataInput: {},
    };
  }

  componentDidMount() {
    this.getSettingExpenditureNorms(Math.floor(Date.now() / 1000));
  }
  getSettingExpenditureNorms(date) {
    api
      .get(`/get-kitchen-setting-expenditure-norms?date=${date}`)
      .then((res) => {
        this.setState({
          dataInput: res.data.data,
        });
      })
      .catch((error) => {});
  }

  handleMealPriceChange = (indexGroup, idTime, newPrice) => {
    this.setState((prevState) => {
      const updatedGroups = [...prevState.dataInput.groups];
      const times = updatedGroups[indexGroup].times.map((time) =>
        time.id_time === idTime ? { ...time, meal_price: newPrice } : time
      );
      updatedGroups[indexGroup].times = times;

      return {
        dataInput: {
          ...prevState.dataInput,
          groups: updatedGroups,
        },
      };
    });
  };

  handleSubmitSaveMealsPrice = () => {
    swal({
      title: "Bạn chắc muốn cập nhật?",
      icon: "warning",
      buttons: ["Đóng", "Cập nhật"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        api
          .post(`set-kitchen-setting-expenditure-norms`, this.state.dataInput, {
            headers: { "Content-Type": "application/json" },
          })
          .then((res) => {
            toast.success(res.data.messages);

            this.getSettingExpenditureNorms(Math.floor(Date.now() / 1000));
          })
          .catch((error) => {
            toast.error("Đã xảy ra lỗi. Vui lòng thử lại sau.");
          });
      }
    });
  };

  renderSettingExpenditureNorms() {}

  render() {
    return (
      <Card className="mb-4">
        <Card.Header>
          <Card.Title>Định mức chi bữa ăn</Card.Title>
        </Card.Header>

        <Form onSubmit={this.submitSetService}>
          <Card.Body>
            <Tabs defaultActiveKey={0} className="mb-1">
              {this.state.dataInput?.groups?.map((group, indexGroup) => {
                const times = this.state.dataInput?.groups?.flatMap(
                  (group) => group.times
                );

                const uniqueTimes = times.filter(
                  (time, index, self) =>
                    index === self.findIndex((t) => t.id_time === time.id_time)
                );

                return (
                  <Tab
                    key={indexGroup}
                    title={group.group_name}
                    eventKey={indexGroup}
                  >
                    {
                      <div style={{ overflowX: "scroll" }}>
                        <Table size="lg" bordered hover>
                          <thead>
                            <tr className="align-middle text-center">
                              {uniqueTimes?.map((time, indexTime) => {
                                return <th>{time.time_name}</th>;
                              })}
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              {group?.times?.map((time, indexTime) => {
                                return (
                                  <td className={`text-center `}>
                                    <CurrencyFormat
                                      className="form-control text-end"
                                      value={time?.meal_price || ""}
                                      displayType={"input"}
                                      thousandSeparator={true}
                                      decimalScale={0}
                                      onValueChange={(values) => {
                                        const { value } = values;
                                        this.handleMealPriceChange(
                                          indexGroup,
                                          time.id_time,
                                          value
                                        );
                                      }}
                                    />
                                  </td>
                                );
                              })}
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    }
                  </Tab>
                );
              })}
            </Tabs>
          </Card.Body>

          <Card.Footer className="text-end">
            <Button
              size="sm"
              variant="success"
              type="button"
              onClick={this.handleSubmitSaveMealsPrice}
            >
              <i className="fas fa-check"></i> Cập nhật
            </Button>
          </Card.Footer>
        </Form>
      </Card>
    );
  }
}

export default SettingExpenditureNorms;
