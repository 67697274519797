import MediaIndex from "./MediaIndex";
import { Modal } from "react-bootstrap";
const ModalMedia = ({ show, onHide }) => {
  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Chọn hình ảnh</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <MediaIndex isModal={true} onHide={onHide} />
      </Modal.Body>
    </Modal>
  );
};
export default ModalMedia;
