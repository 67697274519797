import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as XLSX from "xlsx-js-style";
import DatePicker from "react-datepicker";
import { vi } from "date-fns/locale";
import { startOfWeek, endOfWeek } from "date-fns";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Nav,
  Row,
  Spinner,
  Tab,
  Tabs,
} from "react-bootstrap";
import swal from "sweetalert";
import QuantitativeKitchenTable from "./QuantitativeKitchenTable";
import { dateFormatter, updateTimes } from "../utils/helpers";
import {
  exportQuantitativeKitchen,
  exportWeekQuantitativeKitchen,
  exportQuantitativeKitchenByGroup,
  createWorksheetQuantitative,
} from "./exportFunctions";
import api from "../../../helper/axiosInstance";
import QuantitativeKitchenTableExport from "./QuantitativeKitchenTableExport";
import ProductPlanExport from "./ProductPlanExport";
// import WeekQuantitativeKitchenTable from "./WeekQuantitativeKitchenTable";

const CustomInput = ({ value, onClick }) => (
  <Button variant="primary" onClick={onClick}>
    <i className="fa-solid fa-calendar" />
  </Button>
);

const QuantitativeKitchen = () => {
  const [listQuantitative, setListQuantitative] = useState([]);
  const [listQuantitative2, setListQuantitative2] = useState([]);
  const [quantitative, setQuantitative] = useState({
    menu_date: new Date().getTime() / 1000,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [classGroups, setClassGroups] = useState([]);
  const [selectedClassGroup, setSelectedClassGroups] = useState({});
  const [listDays, setListDays] = useState([]);
  const [selectedDay, setSelectedDay] = useState({});
  const [listTimes, setListTimes] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [groupExcel, setGroupExcel] = useState([]);
  const [
    showModalExportProductPlanExport,
    setShowModalExportProductPlanExport,
  ] = useState(false);
  const [showModalExportQuantitative, setShowModalExportQuantitative] =
    useState(false);

  useEffect(() => {
    document.title = "Định lượng cho bếp";

    let initialWeek = setDatesOfWeek(new Date());
    // setWeekInMonth((0 | (initialWeek[0].getDate() / 7)) + 1);

    let todayIs = new Date().getDay();
    if (todayIs === 0) {
      todayIs = 7;
    }
    getResultRegisterMeal1(initialWeek[0], initialWeek[6], todayIs);
    getResultRegisterMeal2(
      getNextWeekRange(new Date(initialWeek[0]))?.startOfWeek,
      getNextWeekRange(new Date(initialWeek[0]))?.endOfWeek,
      todayIs
    );
    getListClassGroups();
  }, []);

  //#region  API
  const getNextWeekRange = (date) => {
    const currentDay = date.getDay();
    const diffToMondayNextWeek = 8 - currentDay;
    const diffToSundayNextWeek = 14 - currentDay;
    const nextMonday = new Date(date);
    nextMonday.setDate(date.getDate() + diffToMondayNextWeek);

    const nextSunday = new Date(date);
    nextSunday.setDate(date.getDate() + diffToSundayNextWeek);

    return {
      startOfWeek: new Date(nextMonday.setHours(0, 0, 0)),
      endOfWeek: new Date(nextSunday?.setHours(0, 0, 0)),
    };
  };

  //  two weeks
  const getResultRegisterMeal2 = async (startDate, endDate, dayId) => {
    setIsLoading(true);
    await api
      .get(`get-menu-list-with-registered-servings`, {
        params: {
          from_date: parseInt(startDate.getTime() / 1000),
          to_date: parseInt(endDate.getTime() / 1000),
        },
      })
      .then((res) => {
        setIsLoading(false);
        getListQuantitative2(res.data.data || [], startDate, endDate, dayId);
      })
      .catch((error) => {
        toast("Lỗi", {
          type: "error",
          autoClose: 1000,
        });
      });
  };

  const getListQuantitative2 = async (
    resultRegister,
    startDate,
    endDate,
    dayId
  ) => {
    setIsLoading(true);
    await api
      .get(`menu-show`, {
        params: {
          start_at: parseInt(startDate.getTime() / 1000),
          term_at: parseInt(endDate.getTime() / 1000),
        },
      })
      .then((res) => {
        if (res.data.errCode === 0) {
          const updatedDays = res.data.days?.map((dayItem) => ({
            ...dayItem,
            isApproved: resultRegister?.some((e) => e.id === dayItem.id_day),
            times: dayItem.times?.map((time) => ({
              ...time,
              foods: time.foods?.map((f) => ({
                ...f,
                groups: f.groups?.map((g) => ({
                  ...g,
                  totalRegister:
                    findNumberOfPortions(
                      resultRegister,
                      dayItem.id_day,
                      time.id_time,
                      f.id,
                      g.id
                    ) || 0,
                })),
              })),
            })),
          }));

          setListQuantitative2(updatedDays);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((error) => {});
  };
  // two weeks

  const getResultRegisterMeal1 = async (startDate, endDate, dayId) => {
    setIsLoading(true);
    await api
      .get(`get-menu-list-with-registered-servings`, {
        params: {
          from_date: startDate.getTime() / 1000,
          to_date: endDate.getTime() / 1000,
        },
      })
      .then((res) => {
        setIsLoading(false);
        getListQuantitative1(res.data.data || [], startDate, endDate, dayId);
      })
      .catch((error) => {
        toast("Lỗi", {
          type: "error",
          autoClose: 1000,
        });
      });
  };

  const getListQuantitative1 = async (
    resultRegister,
    startDate,
    endDate,
    dayId
  ) => {
    setIsLoading(true);
    await api
      .get(`menu-show`, {
        params: {
          start_at: startDate.getTime() / 1000,
          term_at: endDate.getTime() / 1000,
        },
      })
      .then((res) => {
        setIsLoading(false);

        if (res.data.errCode === 0) {
          setGroupExcel(res.data.groups);
          setListDays(
            res.data.days.map((dayItem) => {
              return {
                id_day: dayItem.id_day,
                day_name: dayItem.day_name,
                date: new Date(dayItem.menu_date * 1000),
              };
            })
          );
          setListTimes(res.data.times);
          const updatedDays = res.data.days?.map((dayItem) => ({
            ...dayItem,
            isApproved: resultRegister?.some((e) => e.id === dayItem.id_day),
            times: dayItem.times?.map((time) => ({
              ...time,
              foods: time.foods?.map((f) => ({
                ...f,
                groups: f.groups?.map((g) => ({
                  ...g,
                  totalRegister:
                    findNumberOfPortions(
                      resultRegister,
                      dayItem.id_day,
                      time.id_time,
                      f.id,
                      g.id
                    ) || 0,
                })),
              })),
            })),
          }));

          setSelectedDay(
            updatedDays.find(
              (listQuantitativeItem) => listQuantitativeItem.id_day === dayId
            )
              ? updatedDays.find(
                  (listQuantitativeItem) =>
                    listQuantitativeItem.id_day === dayId
                )?.id_day
              : updatedDays[0].id_day
          );

          setListQuantitative(updatedDays);

          let dayItem = updatedDays.find(
            (listQuantitativeItem) => listQuantitativeItem.id_day === dayId
          )
            ? updatedDays.find(
                (listQuantitativeItem) => listQuantitativeItem.id_day === dayId
              )
            : updatedDays[0];

          let updatedDayItem = {
            ...dayItem,
            isApproved:
              resultRegister?.filter((e) => e.id === dayItem.id_day)?.length > 0
                ? true
                : false,
            times: dayItem.times?.map((time) => ({
              ...time,
              foods: time.foods?.map((f) => ({
                ...f,
                groups: f.groups?.map((g) => ({
                  ...g,
                  totalRegister:
                    findNumberOfPortions(
                      resultRegister,
                      dayItem.id_day,
                      time.id_time,
                      f.id,
                      g.id
                    ) || 0,
                })),
              })),
            })),
          };
          setQuantitative(updatedDayItem);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((error) => {});
  };

  const getListClassGroups = async () => {
    await api
      .get(`/group-show`)
      .then((res) => {
        if (res.data.errCode === 0) {
          setClassGroups(res.data.groups);
          setSelectedClassGroups(res.data.groups[0]);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((error) => {});
  };

  // #endregion
  const findNumberOfPortions = (data, id_day, id_time, food_id, group_id) => {
    for (const day of data) {
      if (day.id === id_day) {
        for (const time of day.times) {
          if (time.id === id_time) {
            for (const food of time.foods) {
              if (food.id === food_id) {
                for (const group of food.groups) {
                  if (group.id === group_id) {
                    return Number(group.number_of_portions || 0);
                  }
                }
              }
            }
          }
        }
      }
    }
    return 0;
  };
  //#region Logic
  const exportQuantitativeExcel = () => {
    const wb = XLSX.utils.book_new();

    let ingredientQuantity = 0;

    quantitative.times?.map((timeItem) => {
      let timeQuantityIngredient = 0;
      if (timeItem.foods.length !== 0) {
        timeItem.foods?.map((foodItem) => {
          if (foodItem.groups[0].ingredents.length === 0) {
            timeQuantityIngredient++;

            return foodItem;
          }

          return foodItem.groups[0]?.ingredents?.map((ingredientItem) => {
            timeQuantityIngredient++;
            return ingredientItem;
          });
        });
      } else {
        timeQuantityIngredient = 1;
      }

      ingredientQuantity += timeQuantityIngredient;
      return timeItem;
    });

    const ws = exportQuantitativeKitchen(
      quantitative,
      ingredientQuantity,
      groupExcel
    );

    XLSX.utils.book_append_sheet(wb, ws, "Định lượng cho bếp");
    XLSX.writeFile(
      wb,
      `Định lượng cho bếp (${dateFormatter(
        new Date(quantitative.menu_date * 1000)
      )}).xlsx`
    );
  };
  const exportQuantitativeExcelByGroup = () => {
    try {
      const wb = XLSX.utils.book_new();

      let ingredientQuantity = 0;
      let quantitativeT = updateTimes(
        listQuantitative,
        listQuantitative2
      )?.filter((e) => Number(e.id_day) === Number(selectedDay))[0];

      const filteredCostPerDays = quantitativeT?.costPerDays;

      const id_day = quantitativeT?.id_day;

      const updatedTimes = quantitativeT?.times
        ?.slice(id_day === 1 ? 0 : 1)
        ?.map((time) => ({
          ...time,
          foods: time.foods
            ?.filter((fo) => Number(fo.is_main) === 1)
            ?.map((f) => ({
              ...f,
              groups: f.groups?.map((g) => ({
                ...g,
                ingredents: g.ingredents?.filter(
                  (i) => Number(i.is_main) === 1
                ),
              })),
            }))
            ?.filter((e) => e.groups?.length > 0),
        }));

      quantitativeT = {
        ...quantitativeT,
        costPerDays: filteredCostPerDays,
        times: updatedTimes,
      };

      quantitativeT?.times?.map((timeItem) => {
        let timeQuantityIngredient = 0;
        if (timeItem.foods.length !== 0) {
          timeItem.foods
            ?.filter((fo) => Number(fo.is_main) === 1)
            ?.map((foodItem) => {
              if (
                foodItem?.groups[0]?.ingredents?.filter(
                  (i) => Number(i.is_main) === 1
                )?.length === 0
              ) {
                timeQuantityIngredient++;
                return foodItem;
              } else if (foodItem?.groups?.length == 0) {
                timeQuantityIngredient++;
              }
              return foodItem.groups[0]?.ingredents
                ?.filter((i) => Number(i.is_main) === 1)
                ?.map((ingredientItem) => {
                  timeQuantityIngredient++;
                  return ingredientItem;
                });
            });
        }

        ingredientQuantity += timeQuantityIngredient;
        return timeItem;
      });

      const ws = exportQuantitativeKitchenByGroup(
        quantitativeT,
        // ingredientQuantity,
        // groupExcel,
        selectedClassGroup
      );

      XLSX.utils.book_append_sheet(wb, ws, "Tổng hợp định lượng");

      if (!wb.SheetNames.length) {
        throw new Error("Không có dữ liệu");
      }

      XLSX.writeFile(
        wb,
        `Tổng hợp định lượng (${dateFormatter(
          new Date(quantitative.menu_date * 1000)
        )}).xlsx`
      );
    } catch (error) {
      toast.error("Xuất file lỗi");
    }
  };
  const exportWeekQuantitativeExcel = () => {
    const wb = XLSX.utils.book_new();

    const ws = exportWeekQuantitativeKitchen(
      listDays.length,
      listTimes,
      listQuantitative,
      selectedClassGroup
    );

    XLSX.utils.book_append_sheet(wb, ws, "Định lượng cho bếp");

    XLSX.writeFile(
      wb,
      `Định lượng cho bếp (${dateFormatter(startDate)} - ${dateFormatter(
        endDate
      )}).xlsx`
    );

    setModalShow(false);
  };

  const handleXportQuantitativeKitchen = () => {
    swal({
      title: `Bạn muốn xuất file định lượng cho bếp`,
      text: `Ngày ${dateFormatter(new Date(quantitative.menu_date * 1000))}`,
      icon: "info",
      buttons: ["Đóng", "Xuất"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        exportQuantitativeExcel();
      }
    });
  };

  const handleExportQuantitativeKitchenByGroup = () => {
    exportQuantitativeExcelByGroup();
  };
  const handleWeedQuantitativeKitchenByGroup = () => {
    exportWeekQuantitativeExcelByGroup();
  };
  const exportWeekQuantitativeExcelByGroup = (
    listData = updateTimes(listQuantitative, listQuantitative2)
  ) => {
    try {
      const wb = XLSX.utils.book_new();
      listData
        ?.filter((e) => Number(e.id_day) === Number(selectedDay))

        ?.forEach((data) => {
          const filteredCostPerDays = data?.costPerDays;
          const updatedTimes = data?.times?.map((time) => ({
            ...time,
            foods: time.foods
              ?.map((f) => ({
                ...f,
                groups: f.groups?.map((g) => ({
                  ...g,
                  ingredents: g.ingredents,
                })),
              }))
              ?.filter((e) => e.groups?.length > 0),
          }));

          data = {
            ...data,
            costPerDays: filteredCostPerDays,
            times: updatedTimes,
          };

          const ws = createWorksheetQuantitative(data);
          XLSX.utils.book_append_sheet(
            wb,
            ws,
            `${new Date(data?.menu_date * 1000)?.getDate()}.${
              new Date(data?.menu_date * 1000)?.getMonth() + 1
            }`
          );
        });

      if (!wb.SheetNames.length) {
        throw new Error("Không có dữ liệu");
      }

      XLSX.writeFile(
        wb,
        `Kế hoạch sản xuất ${dateFormatter(
          new Date(
            listData?.filter((e) => Number(e.id_day) === Number(selectedDay))[0]
              ?.menu_date * 1000
          )
        )}.xlsx`
      );
    } catch (error) {
      toast.error("Hệ thống xảy ra lỗi");
    }
  };
  const datesOfWeek = (current) => {
    var week = [];
    current.setDate(current.getDate() - current.getDay() + 1);
    current.setHours(0, 0, 0, 0);
    for (var i = 0; i < 7; i++) {
      week.push(new Date(current));
      current.setDate(current.getDate() + 1);
      current.setHours(0, 0, 0, 0);
    }
    return week;
  };

  const setDatesOfWeek = (theDate) => {
    const week = datesOfWeek(theDate);

    setStartDate(week[0]);
    setEndDate(week[week.length - 1]);
    return week;
  };
  // #endregion

  // Render
  const RenderOption = () => {
    return (
      <section className="mb-2">
        <Row>
          <Col md={12} lg={8} className="d-flex" style={{ flexWrap: "wrap" }}>
            {listDays.map((dayItem, dayIndex) => {
              return (
                <Form.Check
                  key={`day-key-${dayIndex}`}
                  type="radio"
                  value={dayItem.id_day}
                  checked={selectedDay === dayItem.id_day}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedDay(Number(e.target.value));
                      setQuantitative(
                        listQuantitative.find(
                          (quantitativeItem) =>
                            quantitativeItem.id_day === Number(e.target.value)
                        )
                      );
                    }
                  }}
                  name="day"
                  className="me-4"
                  label={dayItem.day_name}
                />
              );
            })}
          </Col>

          <Col md={12} lg={4} className="text-lg-end">
            <Button
              size="sm"
              variant="success me-2"
              onClick={() => setShowModalExportProductPlanExport(true)}
              disabled={isLoading || !quantitative?.isApproved}
            >
              <i className="fa-solid fa-file-export" /> Xuất KHSX
            </Button>
            <Button
              size="sm"
              variant="success"
              onClick={() => setShowModalExportQuantitative(true)}
              disabled={isLoading || !quantitative?.isApproved}
            >
              <i className="fa-solid fa-file-export" /> Xuất TH định lượng
            </Button>
          </Col>
        </Row>
      </section>
    );
  };

  return (
    <Container fluid id="quantitative-kitchen">
      <Breadcrumb>
        <Breadcrumb.Item active>Thực đơn</Breadcrumb.Item>
        <Breadcrumb.Item active>Định lượng cho bếp</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <Card.Header>
          <div className="d-block d-md-flex justify-content-between">
            <div className="d-flex d-md-flex align-items-center">
              <Card.Title className="me-2">
                Định lượng cho bếp{" "}
                {dateFormatter(new Date(quantitative.menu_date * 1000))}
              </Card.Title>

              {/* Select Week */}
              <div
                className="me-2 d-flex align-items-center"
                style={{ zIndex: "1021" }}
              >
                <DatePicker
                  disabled={isLoading}
                  selected={
                    listDays?.length > 0
                      ? new Date(listDays[0]?.date)
                      : new Date()
                  }
                  startDate={
                    listDays?.length > 0
                      ? new Date(listDays[0]?.date)
                      : new Date()
                  }
                  endDate={
                    listDays?.length > 0
                      ? new Date(listDays[listDays.length - 1]?.date)
                      : new Date()
                  }
                  onChange={(date) => {
                    const startDateGMT = startOfWeek(date, { weekStartsOn: 1 });
                    const endDateGMT = startOfWeek(
                      endOfWeek(date, { weekStartsOn: 1 })
                    );
                    setDatesOfWeek(new Date(startDateGMT));

                    getResultRegisterMeal1(
                      new Date(startDateGMT),
                      new Date(endDateGMT),
                      selectedDay
                    );

                    getResultRegisterMeal2(
                      getNextWeekRange(new Date(startDateGMT))?.startOfWeek,
                      getNextWeekRange(new Date(startDateGMT))?.endOfWeek,
                      selectedDay
                    );
                  }}
                  showWeekNumbers
                  dateFormat="yyyy-MM-dd"
                  calendarClassName="custom-calendar"
                  customInput={<CustomInput />}
                  locale={vi}
                />
              </div>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          {isLoading ? (
            <div className="d-flex justify-content-center my-5">
              <Spinner
                animation="border"
                role="status"
                variant="primary"
                style={{ width: "3rem", height: "3rem" }}
              >
                <span className="visually-hidden">Đang tải...</span>
              </Spinner>
            </div>
          ) : (
            <Row>
              {RenderOption()}

              {quantitative?.isApproved ? (
                <QuantitativeKitchenTable quantitative={quantitative} />
              ) : (
                <p className="text-center">Vui lòng duyệt đăng ký suất ăn</p>
              )}

              <Modal
                fullscreen={true}
                show={showModalExportProductPlanExport}
                onHide={() => setShowModalExportProductPlanExport(false)}
              >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                  <ProductPlanExport
                    listQuantitative={updateTimes(
                      listQuantitative,
                      listQuantitative2
                    )}
                    selectedDay={selectedDay}
                    classGroups={classGroups}
                    selectedClassGroup={selectedClassGroup}
                    setSelectedClassGroups={setSelectedClassGroups}
                    handleExport={handleWeedQuantitativeKitchenByGroup}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    size="sm"
                    variant="success me-2"
                    onClick={() => handleWeedQuantitativeKitchenByGroup()}
                    disabled={isLoading || !quantitative?.isApproved}
                  >
                    <i className="fa-solid fa-file-export" /> Xuất KHSX
                  </Button>
                  <Button
                    size="sm"
                    variant="secondary"
                    onClick={() => setShowModalExportProductPlanExport(false)}
                  >
                    <i className="fa-solid fa-times"></i> Đóng
                  </Button>
                </Modal.Footer>
              </Modal>

              <Modal
                fullscreen={true}
                show={showModalExportQuantitative}
                onHide={() => setShowModalExportQuantitative(false)}
              >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                  <QuantitativeKitchenTableExport
                    quantitative={
                      updateTimes(listQuantitative, listQuantitative2)?.filter(
                        (e) => Number(e.id_day) === Number(selectedDay)
                      )[0] || {}
                    }
                    classGroups={classGroups}
                    selectedClassGroup={selectedClassGroup}
                    setSelectedClassGroups={setSelectedClassGroups}
                    onHide={() => setModalShow(false)}
                    show={modalShow}
                    handleExport={handleExportQuantitativeKitchenByGroup}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    size="sm"
                    variant="success"
                    onClick={() => handleExportQuantitativeKitchenByGroup()}
                    disabled={isLoading || !quantitative?.isApproved}
                  >
                    <i className="fa-solid fa-file-export" /> Xuất TH định lượng
                  </Button>
                  <Button
                    size="sm"
                    variant="secondary"
                    onClick={() => setShowModalExportQuantitative(false)}
                  >
                    <i className="fa-solid fa-times"></i> Đóng
                  </Button>
                </Modal.Footer>
              </Modal>
            </Row>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default QuantitativeKitchen;
