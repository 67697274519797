import React from "react";
import { Popover, OverlayTrigger, Button } from "react-bootstrap";

const NotePopover = ({
  title = "",
  content = "",
  variant = "primary",
  icon = "fa-solid fa-info-circle",
  className = "",
  placement = "top",
  trigger = "hover",
}) => {
  const popover = (
    <Popover id="popover-basic" variant={variant}>
      {title !== "" ? <Popover.Header as="h3">{title}</Popover.Header> : <></>}
      <Popover.Body>
        <>{content}</>
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger trigger={trigger} placement={placement} overlay={popover}>
      <Button variant={` text-${variant}`} size="sm">
        <i className={`fas ${icon} ${className}`}></i>
      </Button>
    </OverlayTrigger>
  );
};

export default NotePopover;
