import { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Col, Form, Image } from "react-bootstrap";
import { toast } from "react-toastify";
import swal from "sweetalert";
import api from "../../../helper/axiosInstance";
import { Alert } from "react-bootstrap";

const PaymentIndex = () => {
  const [validatedVTBForm, setValidatedVTBForm] = useState(false);
  const [validatedHDBForm, setValidatedHDBForm] = useState(false);
  const [vtbPaymentSettingValid, setVTBPaymentSettingValid] = useState(false);
  const [hdbPaymentSettingValid, setHDBPaymentSettingValid] = useState(false);
  const [showVTB, setShowVTB] = useState(false);
  const [showHD, setShowHD] = useState(false);
  const [bank, setBank] = useState([]);
  const [bankSettingValid, setBankSettingValid] = useState(false);
  const [transferErrors, setTransferErrors] = useState({});

  const getPaymentSettings = async () => {
    try {
      const response = await api({
        method: "get",
        url: "/payment-setting-shows",
      });
      const responseTransfer = await api({
        method: "get",
        url: "/get-transfer-setting",
      });
      const settings = response.data.data;
      setBank(
        responseTransfer.data.data || {
          account: "",
          owner: "",
          branch: "",
        }
      );
      settings?.forEach((setting) => {
        if (setting.setting_key === "HDB_API_KEY") {
          setHDBFormData({
            id: setting.id || "",
            provider: "hdbank",
            merchantId: setting.payload.HDB_MERCHANT_ID || "",
            secretKey: setting.payload.HDB_CLIENT_SECRET || "",
            is_delete: setting.is_delete || "",
          });

          setHDBPaymentSettingValid(true);
        }

        if (setting.setting_key === "VTB_API_KEY") {
          setVTBFormData({
            id: setting.id,
            provider: "vietinbank",
            clientId: setting.payload.VTB_CLIENT_ID || "",
            clientSecret: setting.payload.VTB_CLIENT_SECRET || "",
            merchantName: setting.payload.VTB_MERCHANT_NAME || "",
            providerId: setting.payload.VTB_PROVIDER_ID || "",
            merchantId: setting.payload.VTB_MERCHANT_ID || "",
            terminalId: setting.payload.VTB_TERMINAL_ID || "",
            is_delete: setting.is_delete || "",
          });
          setVTBPaymentSettingValid(true);
        }
      });
    } catch (error) {
      toast.error("Đã có lỗi xảy ra khi tải dữ liệu");
    }
  };

  const [hdbFormData, setHDBFormData] = useState({
    provider: "hdbank",
    merchantId: "",
    secretKey: "",
    id: "",
    is_delete: "",
  });

  const handleHDBInputChange = (event) => {
    const { name, value } = event.target;
    setHDBFormData({
      ...hdbFormData,
      [name]: value,
    });
  };

  const [hdbErrors, setHDBErrors] = useState({});

  const handleSubmitHDBankForm = async (event) => {
    event.preventDefault();

    swal({
      title: `Bạn muốn cập nhật?`,
      icon: "warning",
      buttons: ["Đóng", "Đồng ý"],
      successMode: true,
    }).then(async (ok) => {
      if (ok) {
        setValidatedHDBForm(true);

        try {
          const data = {
            provider: hdbFormData.provider,
            payload: {
              HDB_MERCHANT_ID: hdbFormData.merchantId,
              HDB_CLIENT_SECRET: hdbFormData.secretKey,
            },
          };

          const response = await api({
            method: "post",
            url: "/payment-setting-create-or-update",
            data,
            headers: { "Content-Type": "application/json" },
          });

          toast.success(response.data.messages);
          await getPaymentSettings();
        } catch (error) {
          setHDBErrors(error.response?.data?.messages);
        }
      }
    });
  };

  const [vtbFormData, setVTBFormData] = useState({
    provider: "vietinbank",
    clientId: "",
    clientSecret: "",
    merchantName: "",
    providerId: "",
    merchantId: "",
    terminalId: "",
    id: "",
    is_delete: "",
  });

  const handleVTBInputChange = (event) => {
    const { name, value } = event.target;
    setVTBFormData({
      ...vtbFormData,
      [name]: value,
    });
  };
  const handleTransferInputChange = (event) => {
    const { name, value } = event.target;
    setBank({
      ...bank,
      [name]: value,
    });
    delete transferErrors[name];
  };
  const [vtbErrors, setVTBErrors] = useState({});

  const handleSubmitTransfer = async (event) => {
    event.preventDefault();

    swal({
      title: `Bạn muốn cập nhật?`,
      icon: "warning",
      buttons: ["Đóng", "Đồng ý"],
      successMode: true,
    }).then(async (ok) => {
      if (ok) {
        setBankSettingValid(true);

        try {
          const data = {
            account: bank.account || "",
            owner: bank.owner || "",
            branch: bank.branch || "",
            notes: bank.notes || "",
          };

          const response = await api({
            url: "/transfer-setting",
            method: "post",
            data,
            headers: { "Content-Type": "application/json" },
          });

          toast.success(response.data.message);
          await getPaymentSettings();
        } catch (error) {
          setTransferErrors(error.response?.data?.messages);
          if (!error.response?.data?.messages) toast.error("Lỗi");
        }
      }
    });
  };
  const handleSubmitVTBankForm = async (event) => {
    event.preventDefault();

    swal({
      title: `Bạn muốn cập nhật?`,
      icon: "warning",
      buttons: ["Đóng", "Đồng ý"],
      successMode: true,
    }).then(async (ok) => {
      if (ok) {
        // const form = event.currentTarget;

        // if (form.checkValidity() === false) {
        //   event.preventDefault();
        //   event.stopPropagation();
        // }

        setValidatedVTBForm(true);

        try {
          const data = {
            provider: vtbFormData.provider,
            payload: {
              VTB_CLIENT_ID: vtbFormData.clientId,
              VTB_CLIENT_SECRET: vtbFormData.clientSecret,
              VTB_MERCHANT_NAME: vtbFormData.merchantName,
              VTB_PROVIDER_ID: vtbFormData.providerId,
              VTB_MERCHANT_ID: vtbFormData.merchantId,
              VTB_TERMINAL_ID: vtbFormData.terminalId,
            },
          };

          const response = await api({
            url: "/payment-setting-create-or-update",
            method: "post",
            data,
            headers: { "Content-Type": "application/json" },
          });

          toast.success(response.data.messages);
          await getPaymentSettings();
        } catch (error) {
          setVTBErrors(error.response?.data?.messages);
        }
      }
    });
  };

  const handleTogglePaymentSetting = async (id) => {
    swal({
      title: `Bạn muốn  ${
        hdbFormData.is_delete === 1 ? "Vô hiệu hóa" : "Kích hoạt"
      }?`,
      icon: "warning",
      buttons: ["Đóng", "Đồng ý"],
      successMode: true,
    }).then(async (ok) => {
      if (ok) {
        try {
          const response = await api({
            method: "put",
            url: `/payment-setting-toggle?id=${id}`,
          });

          toast.success(response.data.messages);
          await getPaymentSettings();
        } catch (error) {
          console.log(error.message);
          toast.error("Lỗi trong quá trình cập nhật");
        }
      }
    });
  };

  useEffect(() => {
    document.title = "Phương thức thanh toán";

    getPaymentSettings();
  }, []);

  return (
    <div className="container-fluid px-3 mt-3">
      <Breadcrumb>
        <Breadcrumb.Item active>Cài đặt</Breadcrumb.Item>
        <Breadcrumb.Item active>Thanh toán</Breadcrumb.Item>
      </Breadcrumb>

      <div className="card">
        <div className="card-header">
          <h5 className="card-title">Phương thức thanh toán</h5>
        </div>

        <div className="card-body">
          <div className="row">
            <div className="col-md-2">
              <Image
                roundedCircle
                fluid
                src="https://api.vietqr.io/img/HDB.png"
                alt="Ngân hàng thương mại cổ phần Phát triển Thành phố Hồ Chí Minh"
                title="Ngân hàng thương mại cổ phần Phát triển Thành phố Hồ Chí Minh"
              />
            </div>

            <div className="col-md-10 d-flex align-items-center">
              <div>
                Thanh toán bằng QR code của HDBank. Vui lòng liên hệ với HDBank
                để được cung cấp các mã thanh toán.
                <div>
                  <Alert
                    variant="primary"
                    className="d-flex align-items-center py-1 mt-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                      />
                    </svg>
                    {/* <ol> */}
                    <strong> Lưu ý:</strong> Nếu chọn thanh toán qua HDBank vui
                    lòng nhập đầy đủ thông tin học sinh về Mã định danh dân cư.
                    {/* </ol> */}
                  </Alert>
                </div>
              </div>
            </div>
          </div>

          <Card>
            <Card.Body>
              <Form
                name="hdbankForm"
                noValidate
                validated={validatedHDBForm}
                onSubmit={handleSubmitHDBankForm}
              >
                <div className="row">
                  <Col xs={12}>
                    <Button
                      variant="outline-primary"
                      size="sm"
                      className="float-end"
                      onClick={() => setShowHD(!showHD)}
                    >
                      {!showHD ? (
                        <>
                          <i className="fa-solid fa-eye"></i> Hiện
                        </>
                      ) : (
                        <>
                          <i className="fa-solid fa-eye-slash"></i> Ẩn
                        </>
                      )}
                    </Button>
                  </Col>

                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Merchant ID<i className="text-danger">*</i>
                      </Form.Label>
                      <Form.Control
                        type={showHD ? "text" : "password"}
                        name="merchantId"
                        value={hdbFormData.merchantId}
                        onChange={handleHDBInputChange}
                        required
                      />

                      <Form.Control.Feedback type="invalid">
                        {hdbErrors["payload.HDB_MERCHANT_ID"]}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Secret key<i className="text-danger">*</i>
                      </Form.Label>
                      <Form.Control
                        type={showHD ? "text" : "password"}
                        name="secretKey"
                        value={hdbFormData.secretKey}
                        onChange={handleHDBInputChange}
                        required
                      />

                      <Form.Control.Feedback type="invalid">
                        {hdbErrors["payload.HDB_CLIENT_SECRET"]}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-md-12 d-flex justify-content-end">
                    {hdbPaymentSettingValid && (
                      <div className="mx-2">
                        <Button
                          onClick={() =>
                            handleTogglePaymentSetting(hdbFormData.id)
                          }
                          variant={
                            hdbFormData.is_delete === 1
                              ? "outline-secondary"
                              : "outline-success"
                          }
                          type="button"
                        >
                          {hdbFormData.is_delete === 1
                            ? "Vô hiệu hóa"
                            : "Kích hoạt"}
                        </Button>
                      </div>
                    )}

                    <div>
                      <Button
                        variant="success"
                        className="text-white"
                        type="submit"
                      >
                        <i className="fa-solid fa-check" /> Cập nhật
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            </Card.Body>
          </Card>

          <div className="row mt-3">
            <div className="col-md-2">
              <Image
                roundedCircle
                fluid
                src="https://api.vietqr.io/img/ICB.png"
                alt="Ngân hàng thương mại cổ phần Công thương Việt Nam"
                title="Ngân hàng thương mại cổ phần Công thương Việt Nam"
              />
            </div>

            <div className="col-md-10 d-flex align-items-center">
              Thanh toán bằng QR code của VietinBank. Vui lòng liên hệ với
              VietinBank để được cung cấp các mã thanh toán.
            </div>
          </div>

          <Card>
            <Card.Body>
              <div></div>

              <Form
                name="vietinbankForm"
                noValidate
                validated={validatedVTBForm}
                onSubmit={handleSubmitVTBankForm}
              >
                <div className="row">
                  <Col xs={12}>
                    <Button
                      variant="outline-primary"
                      size="sm"
                      className="float-end"
                      onClick={() => setShowVTB(!showVTB)}
                    >
                      {!showVTB ? (
                        <>
                          <i className="fa-solid fa-eye"></i> Hiện
                        </>
                      ) : (
                        <>
                          <i className="fa-solid fa-eye-slash"></i> Ẩn
                        </>
                      )}{" "}
                    </Button>
                  </Col>

                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Client ID<i className="text-danger">*</i>
                      </Form.Label>

                      <Form.Control
                        type={showVTB ? "text" : "password"}
                        name="clientId"
                        value={vtbFormData.clientId}
                        onChange={handleVTBInputChange}
                        required
                      />

                      <Form.Control.Feedback type="invalid">
                        {vtbErrors["payload.VTB_CLIENT_ID"]}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Client Secret<i className="text-danger">*</i>
                      </Form.Label>
                      <Form.Control
                        type={showVTB ? "text" : "password"}
                        name="clientSecret"
                        value={vtbFormData.clientSecret}
                        onChange={handleVTBInputChange}
                        required
                      />

                      <Form.Control.Feedback type="invalid">
                        {vtbErrors["payload.VTB_CLIENT_SECRET"]}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Merchant Name<i className="text-danger">*</i>
                      </Form.Label>
                      <Form.Control
                        type={showVTB ? "text" : "password"}
                        name="merchantName"
                        value={vtbFormData.merchantName}
                        onChange={handleVTBInputChange}
                        required
                      />

                      <Form.Control.Feedback type="invalid">
                        {vtbErrors["payload.VTB_MERCHANT_NAME"]}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Provider ID<i className="text-danger">*</i>
                      </Form.Label>
                      <Form.Control
                        type={showVTB ? "text" : "password"}
                        name="providerId"
                        value={vtbFormData.providerId}
                        onChange={handleVTBInputChange}
                        required
                      />

                      <Form.Control.Feedback type="invalid">
                        {vtbErrors["payload.VTB_PROVIDER_ID"]}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Merchant ID<i className="text-danger">*</i>
                      </Form.Label>
                      <Form.Control
                        type={showVTB ? "text" : "password"}
                        name="merchantId"
                        value={vtbFormData.merchantId}
                        onChange={handleVTBInputChange}
                        required
                      />

                      <Form.Control.Feedback type="invalid">
                        {vtbErrors["payload.VTB_MERCHANT_ID"]}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Terminal ID<i className="text-danger">*</i>
                      </Form.Label>
                      <Form.Control
                        type={showVTB ? "text" : "password"}
                        name="terminalId"
                        value={vtbFormData.terminalId}
                        onChange={handleVTBInputChange}
                        required
                      />

                      <Form.Control.Feedback type="invalid">
                        {vtbErrors["payload.VTB_TERMINAL_ID"]}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-md-12 d-flex justify-content-end">
                    {vtbPaymentSettingValid && (
                      <div className="mx-2">
                        <Button
                          onClick={() =>
                            handleTogglePaymentSetting(vtbFormData.id)
                          }
                          variant={
                            vtbFormData.is_delete === 1
                              ? "outline-secondary"
                              : "outline-success"
                          }
                          type="button"
                        >
                          {vtbFormData.is_delete === 1
                            ? "Vô hiệu hóa"
                            : "Kích hoạt"}
                        </Button>
                      </div>
                    )}

                    <div>
                      <Button
                        variant="success"
                        className="text-white"
                        type="submit"
                      >
                        <i className="fa-solid fa-check" /> Cập nhật
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            </Card.Body>
          </Card>

          <h6 className="mt-4">Thanh toán bằng hình thức chuyển khoản</h6>

          <Card>
            <Card.Body>
              <Form
                name="Transfer"
                noValidate
                validated={bankSettingValid}
                onSubmit={handleSubmitTransfer}
              >
                <div className="row">
                  <div className="col-md-4">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Tên tài khoản<i className="text-danger">*</i>
                      </Form.Label>

                      <Form.Control
                        name="owner"
                        value={bank.owner || ""}
                        onChange={handleTransferInputChange}
                        required
                      />

                      <Form.Control.Feedback type="invalid">
                        {transferErrors?.owner}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-md-4">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Số tài khoản<i className="text-danger">*</i>
                      </Form.Label>
                      <Form.Control
                        name="account"
                        value={bank.account}
                        onChange={handleTransferInputChange}
                        required
                      />

                      <Form.Control.Feedback type="invalid">
                        {transferErrors.account}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-md-4">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Ngân hàng<i className="text-danger">*</i>
                      </Form.Label>
                      <Form.Control
                        name="branch"
                        value={bank.branch}
                        onChange={handleTransferInputChange}
                        required
                      />

                      <Form.Control.Feedback type="invalid">
                        {transferErrors?.branch}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-md-8">
                    <Form.Group className="mb-3">
                      <Form.Label>Ghi chú</Form.Label>

                      <Form.Control
                        as="textarea"
                        rows={3}
                        name="notes"
                        value={bank.notes}
                        onChange={handleTransferInputChange}
                        required
                      />

                      <Form.Control.Feedback type="invalid">
                        {transferErrors?.notes}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-md-12 d-flex justify-content-end">
                    <div>
                      <Button
                        variant="success"
                        className="text-white"
                        type="submit"
                      >
                        <i className="fa-solid fa-check" /> Cập nhật
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default PaymentIndex;
