import { useState, useEffect } from "react";
import {
  Button,
  Form,
  InputGroup,
  Modal,
  Table,
  Row,
  Col,
} from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import Select from "react-select";
import { toast } from "react-toastify";
import api from "../../../../helper/axiosInstance";

const MenuFoodsModal = ({
  show,
  onHide,
  foodModal,
  setFoodModal,
  foodOptions,
  classGroups,
  selectedClassGroup,
}) => {
  const [categories, setCategories] = useState([]);
  const [choiceCategory, setChoiceCategory] = useState({});
  const [foodChoice, setFoodChoice] = useState({
    id: 0,
    food_name: "Chọn món",
    id_category: "",
    category_name: "",
  });

  useEffect(() => {
    getListCategories();
  }, []);

  const getListCategories = async () => {
    await api
      .get(`/category-show`)
      .then((res) => {
        if (res.data.errCode === 0) {
          let arr = [];
          arr.push({ id: 0, category_name: "Tất cả" });
          res.data.categories?.map((item) => arr.push(item));
          setCategories(arr);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {});
  };

  const foods = foodModal.foods;

  return (
    <Modal show={show} size="lg" onHide={() => onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>
          {selectedClassGroup.group_name} - {foodModal.day_name} - Buổi{" "}
          {foodModal.time_name}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Table>
          <thead>
            <tr>
              <th>Món ăn</th>
              <th>Danh mục</th>
              {foodModal?.is_meal_saved === 0 ? (
                <>
                  <th>Dự trù</th>
                  <th></th>
                </>
              ) : (
                <>
                  <th className="text-end">Dự trù</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {foods.map((foodItem, keyFood) => {
              if (
                foodItem?.groups.some(
                  (group) => group.id === selectedClassGroup.id
                )
              ) {
                const groups = foodModal.foods[keyFood]?.groups;

                const keyGroup = groups.findIndex(
                  (group) => group.id == selectedClassGroup.id
                );

                const more_meal = groups?.find(
                  (item) => item.id === selectedClassGroup.id
                )?.more_meal;

                return (
                  <tr key={`food-key-${keyFood}`}>
                    <td>{foodItem.food_name}</td>
                    <td>{foodItem.category_name}</td>
                    {foodModal?.is_meal_saved === 0 ? (
                      <>
                        <td className="text-end">
                          <CurrencyFormat
                            style={{
                              width: 69,
                              height: 27,
                            }}
                            className="form-control text-end"
                            allowNegative={false}
                            thousandSeparator
                            decimalScale={0}
                            value={more_meal === null ? "" : more_meal}
                            onValueChange={(values) => {
                              foodModal.foods[keyFood].groups[
                                keyGroup
                              ].more_meal = values.value;
                              setFoodModal({
                                ...foodModal,
                              });
                            }}
                            title="Nhập số lượng nấu theo kế hoạch dự tính."
                          />
                        </td>
                        <td>
                          <Button
                            variant="outline-danger"
                            size="sm"
                            onClick={() => {
                              if (groups.length > 1) {
                                foodModal.foods[keyFood].groups =
                                  groups?.filter(
                                    (group) => group.id != selectedClassGroup.id
                                  );
                              } else delete foodModal.foods[keyFood];

                              setFoodModal({
                                ...foodModal,
                              });
                            }}
                            title="Xóa món"
                          >
                            <i className="fa-solid fa-times" />
                          </Button>
                        </td>
                      </>
                    ) : (
                      <>
                        <td className="text-end">
                          <CurrencyFormat
                            allowNegative={false}
                            thousandSeparator
                            decimalScale={0}
                            value={more_meal === null ? "" : more_meal}
                            displayType={"text"}
                          />
                        </td>
                      </>
                    )}
                  </tr>
                );
              }
            })}
          </tbody>
        </Table>
        {foodModal?.is_meal_saved === 0 ? (
          <Row>
            <Col className="mb-2">
              <InputGroup>
                <InputGroup.Text>
                  <i className="fa-solid fa-filter" />
                  &nbsp;Danh mục
                </InputGroup.Text>

                <Select
                  options={categories}
                  getOptionLabel={(option) => option.category_name}
                  getOptionValue={(option) => option.id}
                  placeholder="Tất cả"
                  onChange={(choice) => {
                    setChoiceCategory(choice);
                    setFoodChoice({ id: 0, food_name: "Chọn món" });
                  }}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      minWidth: "235px",
                    }),
                  }}
                />
              </InputGroup>
            </Col>
            <Col>
              <InputGroup>
                <InputGroup.Text>
                  <i className="fa-solid fa-search" />
                  &nbsp;Món ăn
                </InputGroup.Text>
                <Select
                  options={
                    choiceCategory.id
                      ? foodOptions
                          ?.filter(
                            (foodItem) =>
                              foodItem.id_category == choiceCategory.id &&
                              !foodModal.foods.some(
                                (food) =>
                                  food.id === foodItem.id &&
                                  food?.groups?.find(
                                    (group) =>
                                      group.id === selectedClassGroup.id
                                  )
                              )
                          )
                          ?.filter(
                            (foodItem) =>
                              !foodModal.foods.some(
                                (food) =>
                                  food.id === foodItem.id &&
                                  food?.groups?.find((group) => group.id)
                                    ?.id ===
                                    foods.find((food) =>
                                      food?.groups?.find(
                                        (group) =>
                                          group.id === selectedClassGroup.id
                                      )
                                    )
                              )
                          )
                      : // ?.filter((foodItem) => {
                        //   if (foods.length > 0) {
                        //     return foods.some(
                        //       (food) =>
                        //         !food?.groups?.some(
                        //           (group) =>
                        //             group.id === selectedClassGroup.id &&
                        //             food.id === foodItem.id
                        //         )
                        //     );
                        //   } else return foodItem;
                        // })
                        foodOptions?.filter((foodItem) => {
                          return !foodModal?.foods?.some((food) => {
                            return (
                              food?.id === foodItem?.id &&
                              food?.groups?.find(
                                (group) => group?.id === selectedClassGroup.id
                              )
                            );
                          });
                        })
                  }
                  getOptionLabel={(option) => option.food_name}
                  getOptionValue={(option) => option.id}
                  value={foodChoice}
                  placeholder="Nhập tên món ăn"
                  onChange={(choice) => {
                    let foods = foodModal.foods;

                    let groups = [];

                    setFoodChoice(choice);

                    // classGroups.map((group) => {
                    //   groups.push({
                    //     id: group.id,
                    //     group_name: group.group_name,
                    //     more_meal: "",
                    //     ingredents: [],
                    //   });
                    //   return group;
                    // });

                    groups.push({
                      id: selectedClassGroup.id,
                      group_name: selectedClassGroup.group_name,
                      more_meal: "",
                      ingredents: [],
                    });

                    if (
                      !foods.some(
                        (food) => Number(food.id) === Number(choice.id)
                      )
                    ) {
                      foods.push({
                        id: choice.id,
                        food_name: choice.food_name,
                        id_category: choice.id_category,
                        category_name: choice.category_name,
                        priority: 1,
                        groups: groups,
                      });

                      foodModal.foods = foods;

                      setFoodModal({ ...foodModal });
                    } else if (
                      !foods.some(
                        (food) =>
                          Number(food.id) === Number(choice.id) &&
                          food?.groups.some(
                            (group) => group.id === selectedClassGroup.id
                          )
                      )
                    ) {
                      const indexFood = foods.findIndex(
                        (food) => Number(food.id) === Number(choice.id)
                      );

                      const groupsOld = foods.find(
                        (food) => Number(food.id) === Number(choice.id)
                      )?.groups;

                      foods[indexFood] = {
                        id: choice.id,
                        food_name: choice.food_name,
                        id_category: choice.id_category,
                        category_name: choice.category_name,
                        priority: 1,
                        groups: [...groups, ...groupsOld],
                      };
                    } else {
                      toast("Bạn đã chọn món ăn này rồi", {
                        type: "error",
                        autoClose: 1000,
                      });
                    }
                  }}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      minWidth: "250px",
                    }),
                  }}
                />
              </InputGroup>
            </Col>
          </Row>
        ) : (
          <></>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button size="sm" variant="secondary" onClick={() => onHide()}>
          <i className="fa-solid fa-times" /> Đóng
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MenuFoodsModal;
