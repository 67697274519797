import React, { Component } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import AddChildUnit from "../unit/UnitAdd";
import SupplierFormModal2 from "../Supplier/SupplierFormModal";
import CurrencyFormat from "react-currency-format";
import Select from "react-select";
import Check from "../../admin/other/Check";
import swal from "sweetalert";
import api from "../../../helper/axiosInstance";

class IngredentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      units: [],
      unitOptions: [],
      suppliers: [],
      ingredent: [],
      choiceIngrdent: {},
      err: {},
      dataInput: {
        id: this.props.typeModal == "TM" ? "" : this.props.obj?.ingredent.id,
        id_unit_market:
          this.props.typeModal == "TM"
            ? ""
            : this.props.obj?.ingredent.id_unit_market,
        id_unit_cook:
          this.props.typeModal == "TM"
            ? ""
            : this.props.obj?.ingredent.id_unit_cook,
        facility_address:
          this.props.typeModal == "TM"
            ? ""
            : this.props.obj?.ingredent.facility_address,
        id_supplier:
          this.props.typeModal == "TM"
            ? ""
            : this.props.obj?.ingredent.id_supplier,
        ingredent_name:
          this.props.typeModal == "TM"
            ? ""
            : this.props.obj?.ingredent.ingredent_name,
        ing_price_expect:
          this.props.typeModal == "TM"
            ? ""
            : this.props.obj?.ingredent.ing_price_expect,
        ingredent_code:
          this.props.typeModal == "TM"
            ? ""
            : this.props.obj?.ingredent.ingredent_code == "null"
            ? ""
            : this.props.obj?.ingredent.ingredent_code,
        ingredent_ratio:
          this.props.typeModal == "TM"
            ? 0
            : this.formatter.format(
                this.props.obj?.ingredent.ingredent_ratio * 100
              ),
        ingredent_FineMass: 100,
        volume_market:
          this.props.typeModal == "TM"
            ? ""
            : this.props.obj?.ingredent.volume_market,
        volume_cook:
          this.props.typeModal == "TM"
            ? ""
            : this.props.obj?.ingredent.volume_cook,
        ingredent_energy:
          this.props.typeModal == "TM"
            ? 0
            : this.props.obj?.ingredent.ingredent_energy,
        ingredent_protein:
          this.props.typeModal == "TM"
            ? 0
            : this.props.obj?.ingredent.ingredent_protein,
        ingredent_lipid:
          this.props.typeModal == "TM"
            ? 0
            : this.props.obj?.ingredent.ingredent_lipid,
        ingredent_glucid:
          this.props.typeModal == "TM"
            ? 0
            : this.props.obj?.ingredent.ingredent_glucid,
        id_subspecies:
          this.props.typeModal == "TM"
            ? ""
            : this.props.obj?.ingredent.id_subspecies,
        is_bank:
          this.props.typeModal == "TM" ? 0 : this.props.obj?.ingredent.is_bank,
        ingredent_type:
          this.props.keyTab == undefined || this.props.keyTab == ""
            ? 0
            : this.props.keyTab,
        ingredent_alarm:
          this.props.typeModal == "TM"
            ? 0
            : this.props.obj?.ingredent.ingredent_alarm,
        facility_name:
          this.props.typeModal == "TM"
            ? ""
            : this.props.obj?.ingredent.facility_name,
        facility_address:
          this.props.typeModal == "TM"
            ? ""
            : this.props.obj?.ingredent?.facility_address,
        is_spice:
          this.props.typeModal == "TM" ? 0 : this.props.obj?.ingredent.is_spice,
        is_main:
          this.props.typeModal == "TM" ? 0 : this.props.obj?.ingredent.is_main,
        is_fresh:
          this.props.typeModal == "TM" ? 0 : this.props.obj?.ingredent.is_fresh,
        volume_cook:
          this.props.typeModal == "TM"
            ? ""
            : this.props.obj?.ingredent.volume_cook,
      },
      childUnitModalShow: false,
      modalSuppliershow: false,
      keyIngredent: 1,
      bntAdd: false,
    };
  }

  componentDidMount() {
    this.getUnitsData();
    this.getSuppliersData();
    if (new Check().permission([44])) this.getIngredentLibraryData();
  }
  formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
  handleSubmit = (e) => {
    this.props.typeModal == "TM"
      ? this.createIngredent()
      : this.updateIngredent();
  };
  handleInput = (event) => {
    let field = event.target.name;
    let value = event.target.value;

    let dataInput = { ...this.state.dataInput };

    if (event.target.type === "checkbox") value = event.target.checked ? 1 : 0;

    dataInput[field] = value;

    if (field == "id_unit_cook" || field == "id_unit_market") {
      const unitCook = this.state.units?.find(
        (item) => item.id === parseInt(dataInput.id_unit_cook)
      );

      dataInput.volume_cook =
        dataInput.id_unit_cook == dataInput.id_unit_market
          ? 1
          : unitCook?.unit_name === "Gam" || unitCook?.unit_name === "gam"
          ? dataInput[`volume_market`]
          : dataInput[`volume_cook`];

      this.setState({ dataInput: dataInput });
    }

    this.setState({ dataInput: dataInput });

    delete this.state.err[field];

    this.setState({ err: { ...this.state.err } });
  };

  updateIngredent = () => {
    swal({
      title: `Bạn chắc muốn cập nhật?`,
      icon: "warning",
      buttons: ["Đóng", "Cập nhật"],
      successMode: true,
    }).then(async (ok) => {
      if (ok) {
        let data = {
          id: this.state.dataInput.id,
          id_supplier: this.state.dataInput.id_supplier,
          ingredent_name: this.state.dataInput.ingredent_name,
          ing_price_expect: this.state.dataInput.ing_price_expect,
          ingredent_type:
            this.state.dataInput.ingredent_type == undefined
              ? this.props.keyTab
              : this.state.dataInput.ingredent_type,
          ingredent_code: this.state.dataInput.ingredent_code,
          id_subspecies: this.state.dataInput.id_subspecies,
          ingredent_ratio: this.state.dataInput.ingredent_ratio / 100,
          ingredent_energy: this.state.dataInput.ingredent_energy,
          ingredent_protein: this.state.dataInput.ingredent_protein,
          ingredent_lipid: this.state.dataInput.ingredent_lipid,
          ingredent_glucid: this.state.dataInput.ingredent_glucid,
          volume_cook: this.state.dataInput.volume_cook || 1,
          volume_market: this.state.dataInput.volume_market || 0,
          ingredent_alarm: this.state.dataInput.ingredent_alarm,
          facility_name: this.state.dataInput.facility_name,
          facility_address: this.state.dataInput.facility_address,
          is_spice: this.state.dataInput.is_spice ? 1 : 0,
          is_main: this.state.dataInput.is_main ? 1 : 0,
          is_fresh: this.state.dataInput.is_fresh ? 1 : 0,
          id_unit_market: this.state.dataInput.id_unit_market,
          id_unit_cook: this.state.dataInput.id_unit_cook,
        };

        await api({
          method: "put",
          url: "ingredent-update",
          data: data,
          headers: { "Content-Type": "multipart/form-data" },
        }).then((res) => {
          if (res.data.errCode == 0) {
            toast(res.data.message, { type: "success" });
            this.setState({
              dataInput: {
                id: "",
                id_unit: "",
                id_supplier: "",
                ingredent_name: "",
                ingredent_price: "",
                ingredent_type: "2",
                is_bank: 0,
              },
            });
            this.handleHide();
          } else {
            this.setState({ err: res.data.message });
          }
        });
      }
    });
  };
  checkNull = (item) => {
    return (
      item == null ||
      item == "" ||
      item == 0 ||
      item == undefined ||
      item == "undefined" ||
      item == "null"
    );
  };
  createIngredent = async () => {
    let ingredent_type_create =
      this.state.dataInput.ingredent_type == undefined
        ? this.props.keyTab
        : this.state.dataInput.ingredent_type;
    let data = {
      id_supplier: this.state.dataInput.id_supplier,
      ingredent_name: this.state.dataInput.ingredent_name,
      ing_price_expect: this.state.dataInput.ing_price_expect,
      ingredent_type:
        this.state.dataInput.ingredent_type == undefined
          ? this.props.keyTab
          : this.state.dataInput.ingredent_type,
      ingredent_code:
        ingredent_type_create == 3 ? "" : this.state.dataInput.ingredent_code,
      id_subspecies:
        ingredent_type_create == 3 ? 1 : this.state.dataInput.id_subspecies,
      ingredent_ratio: this.state.dataInput.ingredent_ratio / 100,
      ingredent_energy:
        ingredent_type_create == 3 ? 0 : this.state.dataInput.ingredent_energy,
      ingredent_protein:
        ingredent_type_create == 3 ? 0 : this.state.dataInput.ingredent_protein,
      ingredent_lipid:
        ingredent_type_create == 3 ? 0 : this.state.dataInput.ingredent_lipid,
      ingredent_glucid:
        ingredent_type_create == 3 ? 0 : this.state.dataInput.ingredent_glucid,
      volume_cook:
        ingredent_type_create == 3 ? 0 : this.state.dataInput.volume_cook,
      volume_market:
        ingredent_type_create == 3 ? 0 : this.state.dataInput.volume_market,
      ingredent_alarm: this.state.dataInput.ingredent_alarm,
      facility_name: this.state.dataInput.facility_name,
      facility_address: this.state.dataInput.facility_address,
      is_spice: this.state.dataInput.is_spice ? 1 : 0,
      is_main: this.state.dataInput.is_main ? 1 : 0,
      is_fresh: this.state.dataInput.is_fresh ? 1 : 0,
      id_unit_market: this.state.dataInput.id_unit_market || "",
      id_unit_cook:
        ingredent_type_create == 3
          ? this.state.dataInput.id_unit_market
          : this.state.dataInput.id_unit_cook,
    };
    this.setState({ bntAdd: true });

    await api({
      method: "post",
      url: "ingredent-create",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        if (res.data.errCode == 0) {
          this.setState({ bntAdd: false });

          toast(res.data.message, { type: "success" });

          this.setState({
            dataInput: {
              id: "",
              id_unit: "",
              id_supplier: "",
              ingredent_name: "",
              ingredent_price: "",
              ingredent_type: "2",
              is_bank: 0,
            },
          });
          this.handleHide();
        } else {
          this.setState({ bntAdd: false });
          this.setState({ err: res.data.message });
          toast(res.data.message, { type: "error" });
        }
      })
      .catch((err) => {
        this.setState({ bntAdd: false });
      });
  };

  handleHide = () => {
    return this.props.onHide();
  };

  addUnit = () => {
    this.setState({ childUnitModalShow: true });
  };
  handleChildModalClose = () => {
    this.setState({ childUnitModalShow: false });
    this.getUnitsData();
  };
  getUnitsData = () => {
    api.get(`/unit-show`).then((res) => {
      if (res.data.errCode == 0) {
        this.setState({
          units: res.data.units,
          unitOptions: res.data.units,
        });
      }
    });
  };
  dataUnits() {
    if (this.state.units.length > 0) {
      return this.state.units.map((item, i) => {
        return (
          <option key={item.id} value={item.id}>
            {item.unit_name}
          </option>
        );
      });
    }
  }

  getSuppliersData = (limit = 100) => {
    api.post(`/viewSupplier?limit=${limit}`).then((res) => {
      if (res.data.status === true) {
        const arrayUniqueByKey = [
          ...new Map(res.data.data.map((item) => [item["id"], item])).values(),
        ];

        this.setState({ suppliers: arrayUniqueByKey });
      }
    });
  };

  getIngredentLibraryData = () => {
    api
      .get(`/ingredent-bank`)
      .then((res) => {
        if (res.data.errCode === 0) {
          const ingredients = res.data.ingredents?.map((ingredient) => {
            return {
              value: ingredient.ingredent_name,
              label: ingredient.ingredent_name,
              ingredent_code: ingredient.ingredent_code,
              id_subspecies: ingredient.id_subspecies,
              ingredent_ratio: ingredient.ingredent_ratio,
              // ingredent_FineMass: ingredient.volume_cook,
              volume_cook: ingredient.volume_cook,

              volume_market: ingredient.volume_market,
              ingredent_energy: ingredient.ingredent_energy,
              ingredent_protein: ingredient.ingredent_protein,
              ingredent_lipid: ingredient.ingredent_lipid,
              ingredent_glucid: ingredient.ingredent_glucid,
              unit_market_name: ingredient.unit_market_name ?? "",
              unit_cook_name: ingredient.unit_cook_name ?? "",
            };
          });
          this.setState({ ingredent: ingredients });
        }
      })
      .catch((e) => {});
  };
  toSlug(str) {
    if (str !== "undefined") {
      str = str.toLowerCase(); // Chuyển hết sang chữ thường
      str = str
        .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
        .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp
      str = str.replace(/[đĐ]/g, "d"); // Thay ký tự đĐ
      str = str.replace(/([^0-9a-z-\s])/g, ""); // Xóa ký tự đặc biệt
    }

    return str;
  }
  changeIngredent = (choice) => {
    this.setState({
      choiceIngrdent: choice,

      dataInput: {
        ...this.state.dataInput,
        id: this.state.dataInput.id,
        id_unit_market:
          this.state.units?.filter(
            (e) =>
              this.toSlug(e.unit_name) == this.toSlug(choice.unit_market_name)
          )[0]?.id || "",
        id_unit_cook:
          this.state.units?.filter(
            (e) =>
              this.toSlug(e.unit_name) == this.toSlug(choice.unit_cook_name)
          )[0]?.id || "",
        ingredent_price: this.state.dataInput.ingredent_price,
        id_supplier: this.state.dataInput.id_supplier,
        ingredent_name: choice.value,
        ingredent_code: choice.ingredent_code,
        id_subspecies: choice.id_subspecies,
        ingredent_ratio: this.formatter.format(choice.ingredent_ratio * 100),
        ingredent_lipid: choice.ingredent_lipid,
        ingredent_protein: choice.ingredent_protein,
        ingredent_glucid: choice.ingredent_glucid,
        ingredent_energy: choice.ingredent_energy,
        ingredent_FineMass: choice.ingredent_FineMass,
        ingredent_type: this.state.dataInput.ingredent_type,
        is_bank: this.state.dataInput.is_bank,
        volume_market: choice.volume_market,
        volume_cook:
          this.state.units?.filter(
            (e) =>
              this.toSlug(e.unit_name) == this.toSlug(choice.unit_market_name)
          )[0]?.id != 0 &&
          this.state.units?.filter(
            (e) =>
              this.toSlug(e.unit_name) == this.toSlug(choice.unit_cook_name)
          )[0]?.id != 0
            ? choice.volume_cook
            : "",
      },
    });
  };

  dataSuppliers() {
    if (this.state.suppliers.length > 0) {
      return this.state.suppliers.map((item, i) => {
        return (
          <option
            key={item.id}
            value={item.id}
            supplier_name={item.supplier_name}
            address={item.supplier_address}
          >
            {" "}
            {item.supplier_name}{" "}
          </option>
        );
      });
    }
  }

  render() {
    let option = [
      { label: "Động vật", value: 1 },
      { label: "Thực vật", value: 2 },
    ];
    let optionStore = [
      { label: "Hàng chợ", value: 1 },
      { label: "Hàng lưu kho", value: 2 },
      { label: "Vật tư", value: 3 },
    ];

    return (
      <>
        <Modal
          {...this.props}
          size="lg"
          backdrop="static"
          keyboard={false}
          id="addIngredentModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <i className="fa-solid fa-carrot"></i>{" "}
              {this.props.typeModal === "TM"
                ? "Thêm nguyên liệu"
                : "Chi tiết nguyên liệu"}{" "}
              &nbsp;&nbsp;&nbsp;
            </Modal.Title>
          </Modal.Header>

          <Form onSubmit={this.handleSubmit}>
            <Modal.Body>
              <Row>
                <Col md={4}>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="text"
                      value={this.state.dataInput.ingredent_code}
                      onChange={this.handleInput}
                      name="ingredent_code"
                      id="ingredent_code"
                      placeholder="Mã nguyên liệu"
                    />
                    <Form.Label>
                      Mã nguyên liệu<i className="text-danger"></i>
                    </Form.Label>
                    <span className="text-form-err">
                      {this.state.err?.ingredent_code}
                    </span>
                  </Form.Floating>
                </Col>

                {this.state.dataInput.ingredent_type == 3 ? (
                  <Col md={6}>
                    <Form.Floating className="mb-3">
                      <Form.Control
                        type="text"
                        defaultValue={
                          this.props?.typeModal != "TM"
                            ? this.props.obj.ingredent?.ingredent_name
                            : this.state.dataInput.ingredent_name
                        }
                        onChange={this.handleInput}
                        name="ingredent_name"
                        id="ingredent_name"
                        placeholder="Tên nguyên liệu"
                        required
                      />
                      <Form.Label>
                        Tên nguyên liệu<i className="text-danger">*</i>
                      </Form.Label>
                      <span className="text-form-err">
                        {this.state.err?.ingredent_name}
                      </span>
                    </Form.Floating>
                  </Col>
                ) : this.props?.typeModal != "TM" ||
                  !new Check().permission([44]) ? (
                  <Col md={8}>
                    <Form.Floating className="mb-3">
                      <Form.Control
                        type="text"
                        defaultValue={this.state.dataInput.ingredent_name}
                        onChange={this.handleInput}
                        name="ingredent_name"
                        id="ingredent_name"
                        placeholder="Tên nguyên liệu"
                        required
                      />
                      <Form.Label>
                        Tên nguyên liệu<i className="text-danger">*</i>
                      </Form.Label>
                      <span className="text-form-err">
                        {this.state.err?.ingredent_name}
                      </span>
                    </Form.Floating>
                  </Col>
                ) : this.state.keyIngredent == 0 ? (
                  <Col md={6}>
                    <Form.Floating className="mb-3">
                      <Form.Control
                        type="text"
                        defaultValue={this.state.dataInput.ingredent_name}
                        onChange={this.handleInput}
                        name="ingredent_name"
                        id="ingredent_name"
                        placeholder="Tên nguyên liệu"
                        required
                      />
                      <Form.Label>
                        Tên nguyên liệu<i className="text-danger">*</i>
                      </Form.Label>
                      <span className="text-form-err">
                        {this.state.err?.ingredent_name}
                      </span>
                    </Form.Floating>
                  </Col>
                ) : (
                  <Col md={6} className="mb-3 z-index-1021">
                    <Select
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          minHeight: "50px",
                        }),
                      }}
                      options={this.state.ingredent}
                      placeholder={
                        <>
                          Chọn nguyên liệu<i className="text-danger">*</i>
                        </>
                      }
                      name="ingredient_name"
                      value={
                        this.state.ingredent.filter(
                          (item) =>
                            item.value === this.state.dataInput.ingredent_name
                        )[0] || "Chọn nguyên liệu"
                      }
                      onChange={(choice) => {
                        this.changeIngredent(choice);
                        delete this.state.err["ingredent_name"];
                        delete this.state.err["volume_market"];
                        delete this.state.err["id_subspecies"];
                        delete this.state.err["ingredent_code"];
                        delete this.state.err["ingredent_glucid"];
                        delete this.state.err["ingredent_protein"];
                        delete this.state.err["ingredent_lipid"];
                        delete this.state.err["id_unit_market"];
                        delete this.state.err["id_unit_cook"];
                        delete this.state.err["volume_cook"];
                      }}
                    />
                    <span className="text-form-err">
                      {this.state.err?.ingredent_name}
                    </span>
                  </Col>
                )}

                {this.props.typeModal != "TM" ||
                this.state.dataInput.ingredent_type == 3 ||
                !new Check().permission([44]) ? (
                  ""
                ) : (
                  <Col className="mb-3">
                    {this.props.typeModal != "TM" ? (
                      ""
                    ) : this.state.keyIngredent == 0 ||
                      this.state.dataInput.ingredent_type == 3 ? (
                      <Button
                        size="sm"
                        onClick={() => {
                          let dataInput = { ...this.state.dataInput };
                          dataInput.is_bank = 1;
                          dataInput.id_supplier = "";
                          dataInput.id_unit = "";
                          dataInput.ingredent_price = "";
                          dataInput.ingredent_FineMass = 0;
                          dataInput.volume_market = "";
                          dataInput.ingredent_name = "";

                          this.setState({
                            dataInput: dataInput,
                            keyIngredent: 1,
                          });
                          delete this.state.err["ingredent_name"];
                          delete this.state.err["ingredent_code"];
                        }}
                        variant="success w-md-100 h-md-100"
                        hidden={this.state.dataInput.ingredent_type == 3}
                      >
                        <i className="fa-solid fa-book"></i> Ngân hàng Ng.Liệu
                      </Button>
                    ) : (
                      <Button
                        size="sm"
                        onClick={() => {
                          let dataInput = { ...this.state.dataInput };
                          dataInput.is_bank = 0;
                          dataInput.id_supplier = "";
                          dataInput.id_unit = "";
                          dataInput.ingredent_FineMass = 0;
                          dataInput.volume_market = 0;
                          dataInput.ingredent_name = "";
                          dataInput.ingredent_code = "";
                          dataInput.id_unit_market = "";
                          dataInput.id_unit_cook = "";
                          dataInput.id_subspecies = "";
                          this.setState({
                            dataInput: dataInput,
                            keyIngredent: 0,
                          });
                        }}
                        variant="primary w-md-100 h-md-100"
                        hidden={this.state.dataInput.ingredent_type == 3}
                      >
                        <i className="fa-solid fa-hashtag" /> Nguyên liệu khác
                      </Button>
                    )}
                  </Col>
                )}

                {this.state.dataInput.ingredent_type == 3 ? (
                  <></>
                ) : (
                  <Col xs={6} md={4}>
                    <Form.Floating className="mb-3">
                      <Form.Select
                        value={this.state.dataInput.id_subspecies}
                        onChange={this.handleInput}
                        name="id_subspecies"
                        required
                        className="form-control"
                        style={{ height: "50px" }}
                      >
                        <option value="">--Chọn--</option>
                        {option.map((item) => {
                          return (
                            <option value={item.value}>{item.label}</option>
                          );
                        })}
                      </Form.Select>
                      <Form.Label>
                        Nguồn gốc<i className="text-danger">*</i>
                      </Form.Label>
                      <span className="text-form-err">
                        {this.state.err?.id_subspecies}
                      </span>
                    </Form.Floating>
                  </Col>
                )}
                <Col
                  xs={6}
                  md={
                    this.state.dataInput.ingredent_type == 3
                      ? 6
                      : this.state.dataInput.ingredent_type == 2
                      ? 4
                      : 8
                  }
                >
                  {this.state.dataInput.ingredent_type == 3 ? <br /> : <></>}
                  <Form.Floating className="mb-3">
                    <Form.Select
                      defaultValue={this.state.dataInput.ingredent_type}
                      onChange={this.handleInput}
                      name="ingredent_type"
                      required
                      className="form-control"
                      style={{ height: "50px", marginBottom: "10px" }}
                      placeholder="Loại kho"
                    >
                      <option value="">--Chọn--</option>
                      {this.state.keyIngredent == 0
                        ? optionStore.map((item) => {
                            return (
                              <option value={item.value}>{item.label}</option>
                            );
                          })
                        : optionStore.map((item) => {
                            return (
                              <option value={item.value}>{item.label}</option>
                            );
                          })}
                    </Form.Select>
                    <Form.Label>
                      Loại kho<i className="text-danger">*</i>
                    </Form.Label>
                    <span className="text-form-err">
                      {this.state.err?.ingredent_type}
                    </span>
                  </Form.Floating>
                </Col>

                {this.state.dataInput.ingredent_type == 2 ? (
                  <Col xs={12} md={4}>
                    <Form.Floating>
                      <CurrencyFormat
                        className="form-control"
                        name="ingredent_alarm"
                        thousandSeparator={true}
                        placeholder="Cảnh báo hết hạn"
                        value={this.state.dataInput.ingredent_alarm || 0}
                        onValueChange={(values) => {
                          const { value } = values;
                          let dataInput = { ...this.state.dataInput };
                          dataInput.ingredent_alarm = value;
                          this.setState({ dataInput: dataInput });
                        }}
                      />
                      <Form.Label>
                        Mức cảnh báo hết hạn<i className="text-danger"></i>{" "}
                        (Ngày)
                      </Form.Label>
                    </Form.Floating>
                  </Col>
                ) : (
                  <></>
                )}

                <Col xs={6} md={4}>
                  {new Check().permission(["68"]) ? (
                    <Form.Group className="text-end">
                      <Link className="fst-italic" onClick={this.addUnit}>
                        + Thêm đơn vị tính
                      </Link>
                    </Form.Group>
                  ) : (
                    <> </>
                  )}
                  <Form.Floating className="mb-3">
                    <Form.Select
                      value={this.state.dataInput.id_unit_market}
                      onChange={this.handleInput}
                      name="id_unit_market"
                      required
                    >
                      <option value="">--Chọn--</option>
                      {this.dataUnits()}
                    </Form.Select>
                    <Form.Label>
                      Đơn vị đi chợ<i className="text-danger">*</i>
                    </Form.Label>
                    <span className="text-form-err">
                      {this.state.err?.id_unit_market}
                    </span>
                  </Form.Floating>
                </Col>
                {this.state.dataInput.ingredent_type == 3 ? (
                  <></>
                ) : (
                  <Col xs={6} md={4}>
                    {new Check().permission(["68"]) ? (
                      <Form.Group className="text-end">
                        <Link className="fst-italic" onClick={this.addUnit}>
                          + Thêm đơn vị tính
                        </Link>
                      </Form.Group>
                    ) : null}

                    <Form.Floating className="mb-3">
                      <Form.Select
                        value={this.state.dataInput.id_unit_cook}
                        onChange={this.handleInput}
                        name="id_unit_cook"
                        required
                      >
                        <option value="">--Chọn--</option>
                        {this.dataUnits()}
                      </Form.Select>
                      <Form.Label>
                        Đơn vị định lượng<i className="text-danger">*</i>
                      </Form.Label>
                      <span className="text-form-err">
                        {this.state.err?.id_unit_cook}
                      </span>
                    </Form.Floating>
                  </Col>
                )}
                <Col md={4}>
                  {new Check().permission(["24"]) ? (
                    <Form.Group className="text-end">
                      <Link
                        className="fst-italic"
                        onClick={() =>
                          this.setState({ modalSuppliershow: true })
                        }
                      >
                        {" "}
                        +Thêm nhà cung cấp
                      </Link>
                    </Form.Group>
                  ) : null}
                  <Form.Floating className="mb-3">
                    <Form.Select
                      value={this.state.dataInput.id_supplier}
                      onChange={this.handleInput}
                      // onChange={(e)=>console.log(e.target.address, e.target.value)}
                      name="id_supplier"
                      // required
                    >
                      <option value="">--Chọn--</option>
                      {this.dataSuppliers()}
                    </Form.Select>
                    <Form.Label>
                      Nhà cung cấp<i className="text-danger">*</i>
                    </Form.Label>
                    <span className="text-form-err">
                      {this.state.err?.id_supplier}
                    </span>
                  </Form.Floating>
                </Col>
                {this.state.dataInput.ingredent_type == 3 ? (
                  <></>
                ) : (
                  <>
                    <Col md={4}>
                      <Form.Floating className="mb-3">
                        <CurrencyFormat
                          className="form-control text-end"
                          thousandSeparator={true}
                          name="volume_market"
                          required
                          placeholder="Khối lượng đi chợ (g)"
                          value={this.state.dataInput.volume_market || ""}
                          onValueChange={(values) => {
                            const { value } = values;

                            let dataInput = { ...this.state.dataInput };

                            const unitCook = this.state.units?.find(
                              (item) =>
                                item.id === parseInt(dataInput.id_unit_cook)
                            );

                            if (
                              unitCook.unit_name === "gam" ||
                              unitCook.unit_name === "Gam"
                            )
                              dataInput.volume_cook = value;

                            dataInput.volume_market = value;
                            dataInput.is_bank = 0;

                            this.setState({ dataInput: dataInput });

                            delete this.state.err["volume_market"];
                          }}
                        />
                        <Form.Label>
                          Khối lượng đi chợ (g)<i className="text-danger">*</i>
                        </Form.Label>
                        <span className="text-form-err">
                          {this.state.err?.volume_market}
                        </span>
                      </Form.Floating>
                    </Col>
                    <Col md={4}>
                      <Form.Floating className="mb-3">
                        <CurrencyFormat
                          className="form-control text-end"
                          thousandSeparator={true}
                          name="volume_cook"
                          required
                          placeholder="Tỷ lệ ĐV (Chợ / Định lượng)"
                          value={this.state.dataInput.volume_cook || ""}
                          disabled={
                            this.state.dataInput?.id_unit_cook ==
                              this.state.dataInput?.id_unit_market &&
                            (this.state.dataInput?.id_unit_cook != 0 ||
                              this.state.dataInput?.id_unit_market != 0)
                          }
                          onValueChange={(values) => {
                            const { value } = values;
                            let dataInput = { ...this.state.dataInput };
                            const unitCook = this.state.units?.find(
                              (item) =>
                                item.id === parseInt(dataInput.id_unit_cook)
                            );
                            if (
                              unitCook.unit_name === "gam" ||
                              unitCook.unit_name === "Gam"
                            )
                              dataInput.volume_market = value;

                            dataInput.volume_cook = value;
                            dataInput.is_bank = 0;
                            this.setState({ dataInput: dataInput });
                            delete this.state.err["volume_cook"];
                          }}
                        />
                        <Form.Label>
                          Tỷ lệ ĐV (Chợ / Định lượng)
                          <i className="text-danger">*</i>
                        </Form.Label>
                        <span className="text-form-err">
                          {this.state.err?.volume_cook}
                        </span>
                      </Form.Floating>
                    </Col>
                  </>
                )}
                <Col md={4}>
                  <Form.Floating className="mb-3">
                    <CurrencyFormat
                      className="form-control text-end"
                      name="ing_price_expect"
                      thousandSeparator={true}
                      placeholder="Đơn giá (đ)"
                      required
                      value={this.state.dataInput.ing_price_expect || ""}
                      onValueChange={(values) => {
                        const { value } = values;
                        let dataInput = { ...this.state.dataInput };
                        dataInput.ing_price_expect = value;
                        this.setState({ dataInput: dataInput });
                        delete this.state.err["ing_price_expect"];
                      }}
                    />
                    <Form.Label>
                      Đơn giá (đ)<i className="text-danger">*</i>
                    </Form.Label>
                    <span className="text-form-err">
                      {this.state.err?.ing_price_expect}
                    </span>
                  </Form.Floating>
                </Col>
                {this.state.dataInput.ingredent_type == 3 ? (
                  <></>
                ) : (
                  <>
                    <Col xs={6} md={4}>
                      <Form.Floating>
                        <CurrencyFormat
                          className="form-control text-end mb-3"
                          thousandSeparator={true}
                          name="ingredent_ratio"
                          placeholder="Tỉ lệ thải bỏ (%)"
                          value={this.state.dataInput.ingredent_ratio || 0}
                          onValueChange={(values) => {
                            const { value } = values;

                            let dataInput = { ...this.state.dataInput };
                            dataInput.ingredent_ratio = value;
                            dataInput.is_bank = 0;
                            this.setState({ dataInput: dataInput });
                            delete this.state.err["ingredent_ratio"];
                          }}
                        />
                        <Form.Label>Tỉ lệ thải bỏ (%)</Form.Label>
                        <span className="text-form-err">
                          {this.state.err?.ingredent_ratio}
                        </span>
                      </Form.Floating>
                    </Col>
                    <Col xs={6} md={4}>
                      <Form.Floating>
                        <CurrencyFormat
                          className="form-control text-end"
                          thousandSeparator={true}
                          name="ingredent_FineMass"
                          placeholder="Khối lượng tinh (g)"
                          value={
                            this.formatter.format(
                              this.state.dataInput.volume_market -
                                (this.state.dataInput.volume_market *
                                  this.state.dataInput.ingredent_ratio) /
                                  100
                            ) || 0
                          }
                          onValueChange={(values) => {
                            const { value } = values;
                            let dataInput = { ...this.state.dataInput };
                            dataInput.ingredent_FineMass = value;
                            this.setState({ dataInput: dataInput });
                          }}
                          disabled
                        />
                        <Form.Label>Khối lượng tinh (g)</Form.Label>
                      </Form.Floating>
                    </Col>
                    <Col md={4}>
                      <Form.Check
                        type="switch"
                        inline
                        label="Gia vị"
                        name="is_spice"
                        onChange={this.handleInput}
                        checked={this.state.dataInput.is_spice}
                        title="Là gia vị 'Nguyên liệu' sẽ không được tính dinh dưỡng trong 'Tính khẩu phần ăn' (VD: Đường, muối, bột ngọt,...)."
                      />

                      <Form.Check
                        type="switch"
                        inline
                        label="Nguyên liệu chính"
                        name="is_main"
                        onChange={this.handleInput}
                        checked={this.state.dataInput.is_main}
                        title="Là 'Nguyên liệu' chính trong chế biến món ăn (VD: Thịt, tôm, cá,...)."
                      />

                      <Form.Check
                        type="switch"
                        inline
                        label="Thực phẩm tươi"
                        name="is_fresh"
                        onChange={this.handleInput}
                        checked={this.state.dataInput.is_fresh}
                        title="Là thực phẩm thường dễ hư hỏng và cần được tiêu thụ trong ngày (VD: Bún gạo tươi, bánh phở,...)."
                      />
                    </Col>
                  </>
                )}

                <Col md={4}>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="text"
                      value={this.state.dataInput.facility_name}
                      onChange={this.handleInput}
                      name="facility_name"
                      id="facility_name"
                      placeholder="Tên cơ sở"
                    />
                    <Form.Label>
                      Tên cơ sở sản xuất<i className="text-danger"></i>
                    </Form.Label>
                    <span className="text-form-err">
                      {this.state.err?.facility_name}
                    </span>
                  </Form.Floating>
                </Col>
                <Col md={8}>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="text"
                      value={this.state.dataInput.facility_address}
                      onChange={this.handleInput}
                      name="facility_address"
                      id="facility_address"
                      placeholder="Địa chỉ"
                    />
                    <Form.Label>
                      Địa chỉ cơ sở sản xuất<i className="text-danger"></i>
                    </Form.Label>
                    <span className="text-form-err">
                      {this.state.err?.facility_address}
                    </span>
                  </Form.Floating>
                </Col>
              </Row>
              {this.state.dataInput.ingredent_type == 3 ? (
                <></>
              ) : (
                <>
                  <p className="text-center" style={{ fontWeight: "bold" }}>
                    Thành phần dinh dưỡng trong 100g ăn được
                  </p>
                  <Row>
                    <Col xs={6} md={3}>
                      <Form.Floating>
                        <CurrencyFormat
                          className="form-control text-end mb-3"
                          thousandSeparator={true}
                          name="ingredent_energy"
                          placeholder="Năng lượng (kcal)"
                          value={this.state.dataInput.ingredent_energy || 0}
                          onValueChange={(values) => {
                            const { value } = values;
                            let dataInput = { ...this.state.dataInput };
                            dataInput.ingredent_energy = value;
                            dataInput.is_bank = 0;
                            this.setState({ dataInput: dataInput });
                          }}
                        />
                        <Form.Label>Năng lượng (kcal)</Form.Label>
                        <span className="text-form-err">
                          {this.state.err?.ingredent_energy}
                        </span>
                      </Form.Floating>
                    </Col>
                    <Col xs={6} md={3}>
                      <Form.Floating>
                        <CurrencyFormat
                          className="form-control text-end mb-3"
                          thousandSeparator={true}
                          placeholder="Protein (g)"
                          name="ingredent_protein"
                          // suffix=" g"
                          value={this.state.dataInput.ingredent_protein || 0}
                          onValueChange={(values) => {
                            const { value } = values;
                            let dataInput = { ...this.state.dataInput };
                            dataInput.ingredent_protein = value;
                            dataInput.is_bank = 0;
                            this.setState({ dataInput: dataInput });
                            delete this.state.err["ingredent_protein"];
                          }}
                        />
                        <Form.Label>Protein (g)</Form.Label>
                        <span className="text-form-err">
                          {this.state.err?.ingredent_protein}
                        </span>
                      </Form.Floating>
                    </Col>
                    <Col xs={6} md={3}>
                      <Form.Floating>
                        <CurrencyFormat
                          className="form-control text-end mb-3"
                          thousandSeparator={true}
                          placeholder="Lipid (g)"
                          name="ingredent_lipid"
                          // suffix=" g"
                          value={this.state.dataInput.ingredent_lipid || 0}
                          onValueChange={(values) => {
                            const { value } = values;
                            let dataInput = { ...this.state.dataInput };
                            dataInput.ingredent_lipid = value;
                            dataInput.is_bank = 0;
                            this.setState({ dataInput: dataInput });
                            delete this.state.err["ingredent_lipid"];
                          }}
                        />
                        <Form.Label>Lipid (g)</Form.Label>
                        <span className="text-form-err">
                          {this.state.err?.ingredent_lipid}
                        </span>
                      </Form.Floating>
                    </Col>
                    <Col xs={6} md={3}>
                      <Form.Floating>
                        <CurrencyFormat
                          className="form-control text-end mb-3"
                          thousandSeparator={true}
                          placeholder=" Glucid (g)"
                          name="ingredent_glucid"
                          value={this.state.dataInput.ingredent_glucid || 0}
                          onValueChange={(values) => {
                            const { value } = values;
                            let dataInput = { ...this.state.dataInput };
                            dataInput.ingredent_glucid = value;
                            dataInput.is_bank = 0;
                            this.setState({ dataInput: dataInput });
                            delete this.state.err["ingredent_glucid"];
                          }}
                        />
                        <Form.Label>Glucid (g)</Form.Label>
                        <span className="text-form-err">
                          {this.state.err?.ingredent_glucid}
                        </span>
                      </Form.Floating>
                    </Col>
                  </Row>
                </>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button size="sm" variant="secondary" onClick={this.handleHide}>
                <i className="fa-solid fa-times"></i> Đóng
              </Button>
              {this.props.typeModal == "TM" ? (
                <Button
                  size="sm"
                  variant="success"
                  // type="submit"
                  onClick={() => this.createIngredent()}
                  // disabled={this.state.bntAdd}
                >
                  <i className="fa-solid fa-check"></i> Lưu
                </Button>
              ) : new Check().permission(["42"]) ? (
                <Button
                  size="sm"
                  variant="success"
                  onClick={() => this.updateIngredent()}
                >
                  <i className="fa-solid fa-check"></i> Cập nhật
                </Button>
              ) : null}
            </Modal.Footer>
          </Form>
        </Modal>
        {this.state.childUnitModalShow ? (
          <AddChildUnit
            show={this.state.childUnitModalShow}
            onHide={this.handleChildModalClose}
            onSubmit={this.handleChildModalClose}
            units={this.state.unitOptions}
          />
        ) : (
          ""
        )}

        {this.state.modalSuppliershow ? (
          <SupplierFormModal2
            show={this.state.modalSuppliershow}
            onHide={() => this.setState({ modalSuppliershow: false })}
            modal={{
              title: "Thêm nhà cung cấp",
              button: (
                <>
                  <i className="fa-solid fa-check" /> Lưu
                </>
              ),
            }}
            data={{
              id: "",
              supplier_name: "",
              supplier_phone: "",
              supplier_address: "",
              supplier_author: "",
              supplier_shipper: "",
            }}
            submit={() => {
              this.setState({ modalSuppliershow: false });
              this.getSuppliersData();
            }}
          />
        ) : null}
      </>
    );
  }
}

export default IngredentModal;
