import React, { useState, useEffect } from "react";
import api from "../../../helper/axiosInstance";

const CountUser = () => {
  const [userCount, setUserCount] = useState(null);

  useEffect(() => {
    const fetchUserCount = async () => {
      try {
        const response = await api.get("/user-shows");
        if (response.data) {
          setUserCount(
            response.data.data?.filter((e) => Number(e.is_status) === 1)?.length
          );
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserCount();
  }, []);

  return (
    <div style={styles.container}>
      <h2>Số lượng nhân viên</h2>
      <div
      //  style={styles.infoBox}
      >
        <i className="fa-solid fa-users" style={styles.icon}></i>
        <span style={styles.count}>
          {userCount !== null ? userCount : "Loading..."}
        </span>
      </div>
    </div>
  );
};

const styles = {
  container: {
    textAlign: "center",
    fontFamily: "Arial, sans-serif",
    padding: "10px 20px 20px 20px",
    backgroundColor: "rgb(117, 182, 249)",
    borderRadius: "8px",
    width: "100%",
    margin: "10px 0",
    color: "#fff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
  infoBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "10px",
    padding: "10px",
    backgroundColor: "#fff",
    borderRadius: "4px",
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.2)",
  },
  icon: {
    fontSize: "2rem",
    color: "#fff",
    // color: "#2196f3",
    marginRight: "10px",
  },
  count: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "#fff",
  },
};

export default CountUser;
