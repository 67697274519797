import React, { Component } from "react";
import { Modal, Button, Form, Row, Col, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import Select from "react-select";
import DataTables from "../datatable/DataTables";
import { dateFormatter } from "../utils/helpers";
import CurrencyFormat from "react-currency-format";
import api from "../../../helper/axiosInstance";
import { IS_STATUS } from "../../../constants";
import DatePickerCustom from "../../uiElements/DatePickerCustom";
class BillModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataInput: { ...this.props.dataBill },
      listclass: this.props.dataClass?.filter((e) => e.value > 0),
      listclassID: [],
      choiceClass: this.props.dataClass?.filter((e) => e.value > 0)[0],
      filterText: "",
      students: [],
      studentsSearch: [],
      isTableSelectable: true,
      ids: [],
      err: {},
      tuition: [],
      additional_tuition: [],
      bill_notes: "",
      startDate: this.props.startDate,
      discountChoice: [],
    };
  }

  componentDidMount() {
    this.getTuition();
    this.getClassbyId(
      this.props.dataClass?.filter((e) => e.value > 0)[0]?.value
    );
  }
  getTuition = () => {
    api
      .get("/tuition-shows")
      .then((res) => {
        // if (res.data.data?.status == true) {
        this.setState({
          tuition: res.data.data?.filter((e) => e.type == 0) || [],
        });

        // }
      })
      .catch((err) => {});
  };

  getClassbyId = (id) => {
    api.get(`/student-shows?id_class=${id}`).then((res) => {
      this.setState(
        {
          listclassID: res.data.data?.students.filter(
            (e) =>
              parseInt(e.student_status) !== IS_STATUS.IN_ACTIVE &&
              e.id_class === id
          ),
          isTableSelectable: true,
          ids: [],
        },
        () => {
          this.filterStudent(id, "");
        }
      );
      this.setState({ filterText: "" });
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.createBill();
  };
  createBill = () => {
    let data = {
      classId: this.state.choiceClass?.value,
      dateOfMakingReceipt: new Date(this.state.startDate),
      billNotes: this.state.bill_notes || "",
      discounts: this.state.discountChoice?.map((d) => d.value) || [],
      students: this.state.ids?.map((e) => {
        return e.id;
      }),
      additionalFees: this.state.additional_tuition?.map((e) => {
        return {
          id: e.id,
          price: e.price,
        };
      }),
    };

    api
      .post("bill-create", data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        if (res.data?.data?.invalidStudentsInfo?.length > 0) {
          res.data?.data?.invalidStudentsInfo?.map((item) => {
            toast.error(
              `Vui lòng kiểm tra vào ngày lớp của ${item.student_name}`,
              { autoClose: 2000 }
            );
          });
        }
        if (res.data?.data?.validStudentsInfo?.length > 0) {
          res.data?.data?.validStudentsInfo?.map((item) => {
            toast.success(`Tạo thành công ${item.student_name}`, {
              autoClose: 2000,
            });
          });
        }

        // toast(res.data.message || "Tạo thành công", { type: "success" });
        this.setState({ bill_notes: "", discountChoice: [] });
        return this.props.submit();
      })
      .catch((error) => {
        this.setState({ err: error.response.data.messages });
        toast(error.response.data.messages || `Hệ thống xảy ra lỗi `, {
          type: "error",
          autoClose: 2000,
        });
        // if (error.response.data.messages) {
        //   let isErr = false;
        //   this.setState({ err: error.response.data.messages });
        //   Object?.keys(error.response.data.messages)?.forEach((key) => {
        //     toast.error(error.response.data.messages[key]);
        //     isErr = true;
        //   });

        // } else
        //   toast(error.message || `Hệ thống xảy ra lỗi `, {
        //     type: "error",
        //     autoClose: 1000,
        //   });
      });
  };
  toSlug(str) {
    if (str !== "undefined") {
      str = str.toLowerCase(); // Chuyển hết sang chữ thường
      str = str
        .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
        .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp
      str = str.replace(/[đĐ]/g, "d"); // Thay ký tự đĐ
      str = str.replace(/([^0-9a-z-\s])/g, ""); // Xóa ký tự đặc biệt
    }

    return str;
  }
  filterStudent = (idclass, student_name) => {
    const filteredStudents = this.state.listclassID?.filter(
      (student) =>
        (idclass == 0 || student.id_class == idclass) &&
        this.toSlug(student?.student_name)?.includes(
          this.toSlug(student_name?.trim())
        )
    );
    this.setState({
      studentsSearch: filteredStudents,
      isTableSelectable: false,
      filterText: student_name,
    });
  };
  onSelectedRowsChange = (rows) => {
    this.setState({ ids: rows });
  };
  handleHide = () => {
    this.setState({ bill_notes: "" });
    return this.props.onHide();
  };

  dataTable() {
    const columns = [
      {
        name: "Họ và tên",
        selector: (row) => row.student_name,
        sortable: true,
      },
      {
        name: "Ngày sinh",
        selector: (row) => row.student_birthday,
        cell: (row) => dateFormatter(new Date(row.student_birthday * 1000)),
        sortable: true,
      },
    ];

    const data = [];

    const selectedRows = (items) => {
      this.setState({ selectedRows: items, alertShow: true });
    };

    if (this.state.studentsSearch?.length > 0) {
      this.state.studentsSearch.map((item, i) => {
        data.push({
          ...item,
        });

        return item;
      });
    }

    const selectableRowDisabled = (row) => {
      return this.props.databill?.some((item) => item.student?.id == row.id);
    };

    if (data.length > 0)
      return (
        <DataTables
          data={data}
          columns={columns}
          selectedRows={selectedRows}
          selectableRowDisabled={selectableRowDisabled}
          selectableRows
          clearSelectedRows={this.state.isTableSelectable}
          onSelectedRowsChange={(item) =>
            this.onSelectedRowsChange(item.selectedRows)
          }
        />
      );
    else return <></>;
  }
  render() {
    const { onHide, ...other } = { ...this.props };
    return (
      <>
        <Modal
          show={other.show}
          onHide={onHide}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {other.modal.title} tháng {this.state.startDate?.getMonth() + 1}/
              {this.state.startDate?.getFullYear()}{" "}
            </Modal.Title>
            <div
              className="mx-2 d-md-flex d-block align-items-center"
              style={{ zIndex: "1021" }}
            >
              <DatePickerCustom
                selected={this.state.startDate}
                onChange={(date) => {
                  this.setState({
                    startDate: date,
                    // endDate: getlastDayOfMonth(
                    //   date?.getFullYear(),
                    //   date?.getMonth()
                    // ),
                  });
                  // this.getClassRoom(
                  //   parseInt(date / 1000),
                  //   parseInt(
                  //     getlastDayOfMonth(
                  //       date?.getFullYear(),
                  //       date?.getMonth()
                  //     ) / 1000
                  //   )
                  // );
                  // this.getRoles(
                  //   date,
                  //   getlastDayOfMonth(date?.getFullYear(), date?.getMonth())
                  // );
                }}
                showMonthYearPicker
              />
            </div>
          </Modal.Header>

          <Form onSubmit={this.handleSubmit}>
            <Modal.Body>
              <Row>
                <Col className="d-flex col-md-12 col-sm-12 mb-2">
                  <div
                    className="col-6 col-md-5 pe-1"
                    style={{ minWidth: "70px", zIndex: 3 }}
                  >
                    <Select
                      defaultValue={this.state.listclass[0]}
                      options={this.state.listclass?.filter((e) => e.value > 0)}
                      placeholder="Chọn Lớp"
                      value={this.state.choiceClass}
                      onChange={(choice) => {
                        this.setState({ choiceClass: choice });
                        this.getClassbyId(choice.value);
                      }}
                    />
                  </div>
                  <div className="col-3 col-md-5 ps-1">
                    <Form.Control
                      style={{ height: 39 }}
                      type="text"
                      placeholder="Nhập tên học sinh"
                      value={this.state.filterText}
                      onChange={(e) => {
                        this.filterStudent(
                          this.state.choiceClass?.value,
                          e.target.value
                        );
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>{this.dataTable()}</Col>
              </Row>
              {this.state.ids?.length > 0 ? (
                <>
                  <p className="text-center fw-bold">HỌC PHÍ THÊM</p>
                  <Form.Floating className="mb-3">
                    <Form.Select
                      id="selectTeacherId"
                      name="teachers"
                      placeholder="Giáo viên"
                      value={0}
                      onChange={(e) => {
                        const idTuition = Number(e.target.value);
                        let newDataInput = [...this.state.additional_tuition];

                        const tuitionChose = this.state.tuition?.filter(
                          (item) => item.id == idTuition
                        );

                        newDataInput.push({
                          id: tuitionChose[0]?.id, // required, integer, unique
                          quantity: 1, // required, integer, min: 1
                          name: tuitionChose[0]?.name,
                          price: parseFloat(tuitionChose[0]?.amount),
                        });

                        this.setState({
                          additional_tuition: newDataInput,
                        });
                      }}
                    >
                      <option value="">--Chọn--</option>

                      {this.state?.tuition?.map((tuition) => {
                        const checkIsset = this.state.additional_tuition
                          ? this.state.additional_tuition.some(
                              (item) => item.id == tuition.id
                            )
                          : false;

                        if (!checkIsset)
                          return (
                            <option key={tuition.id} value={tuition.id}>
                              {tuition.name}
                            </option>
                          );
                      })}
                    </Form.Select>
                    <Form.Label for="selectTeacherId">Khoản thu</Form.Label>
                  </Form.Floating>
                  <Table bordered style={{ borderColor: "#000" }}>
                    <thead>
                      <tr className="text-center">
                        <th>STT</th>
                        <th>Khoản thu</th>
                        <th>Thành tiền (vnđ)</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.additional_tuition?.map((e, i) => (
                        <tr key={i} className="align-middle">
                          <td className="text-center">{i + 1}</td>
                          <td>
                            {e.name}

                            <span className="text-form-err">
                              {this.state.err[`additional_tuition[${i}].id`]}
                            </span>
                          </td>

                          <td>
                            {/* <Form.Control
                              type="number"
                              name="role_name"
                              defaultValue={e.amount}
                              placeholder="Số lượng"
                              className="text-end"
                              onChange={(event) => {
                                let data = [...this.state.additional_tuition];
                                data[i].amount = event.target.value;
                                this.setState({ additional_tuition: data });
                                delete this.state.err[
                                  `additional_tuition[${i}].amount`
                                ];
                              }}
                              required
                            /> */}
                            <CurrencyFormat
                              className="form-control text-end"
                              thousandSeparator={true}
                              placeholder="vnđ"
                              // defaultValue={e.amount}
                              required
                              // suffix=" VNĐ"
                              value={e.price}
                              onValueChange={(values) => {
                                const { value } = values;
                                let data = [...this.state.additional_tuition];
                                data[i].price = value;
                                this.setState({ additional_tuition: data });
                                delete this.state.err[
                                  `additional_tuition[${i}].price`
                                ];
                              }}
                            />
                            <span className="text-form-err">
                              {this.state.err[`additional_tuition[${i}].price`]}
                            </span>
                          </td>

                          <td>
                            <Button
                              size="sm"
                              variant="outline-danger"
                              title="Xóa"
                              onClick={() => {
                                let newDataInput = [
                                  ...this.state.additional_tuition,
                                ];
                                let newDetail = newDataInput?.filter(
                                  (tuition) => tuition != e
                                );
                                newDataInput = newDetail;

                                this.setState({
                                  additional_tuition: newDataInput,
                                });
                              }}
                            >
                              <i className="fa-solid fa-times" />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              ) : null}
              <Row>
                <Col>
                  <Form.Floating className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Ghi chú"
                      value={this.state?.bill_notes || ""}
                      name="bill_notes"
                      onChange={(e) => {
                        this.setState({ bill_notes: e.target.value });

                        delete this.state.err[`bill_notes`];
                      }}
                    />
                    <Form.Label>Ghi chú</Form.Label>
                    <span className="text-form-err">
                      {this.state.err[`bill_notes`]}
                    </span>
                  </Form.Floating>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Select
                    className="mb-3"
                    options={this.props.discounts}
                    placeholder={<div>Chọn giảm giá</div>}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        minHeight: "50px",
                      }),
                    }}
                    value={this.state?.discountChoice}
                    onChange={(choice) =>
                      this.setState({ discountChoice: choice })
                    }
                    isMulti
                  />
                </Col>
              </Row>
            </Modal.Body>

            <Modal.Footer>
              <Button
                size="sm"
                variant="secondary"
                id="btnCloseAddIngredent"
                onClick={this.handleHide}
              >
                <i className="fa-solid fa-times"></i> Đóng
              </Button>
              <Button
                size="sm"
                variant="success"
                className="px-1 mx-2"
                type="submit"
                disabled={
                  this.state.ids?.length < 1 ||
                  this.state.choiceClass.value == 0
                }
              >
                <i className="fa-solid fa-check" /> Tạo phiếu
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}

export default BillModal;
