import { useEffect, useContext } from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";
import swal from "sweetalert";
const useBlocker = (shouldBlock, message) => {
  const { navigator } = useContext(NavigationContext);
  useEffect(() => {
    if (!shouldBlock) return;
    const originalPush = navigator.push;
    navigator.push = async (...args) => {
      const willLeave = await swal({
        title: "Cảnh báo!",
        text: message,
        icon: "warning",
        buttons: {
          cancel: "Ở lại",
          confirm: "Rời đi",
        },
        successMode: true,
      });
      if (willLeave) {
        originalPush(...args);
      }
    };
    return () => {
      navigator.push = originalPush;
    };
  }, [shouldBlock, message, navigator]);
};
export const UnsavedChangesHandler = ({ hasChanges }) => {
  useBlocker(hasChanges, "Dữ liệu chưa được lưu. Bạn có chắc muốn rời đi?");
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (hasChanges) {
        e.preventDefault();
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasChanges]);
  return null;
};
